/**
 *
 */

import React, { useState, useEffect, useCallback } from "react";

/* Import configuration starts */
import { Link } from "react-router-dom";
import GLOBAL from "../../../../setup/constants/global";
/* Import configuration ends */

/* Import redux slices component starts */

/* Import redux slices component ends */

/* Import react bootstrap component starts */
import Table from "react-bootstrap/Table";
/* Import react bootstrap component ends */

/* Import image and SVG starts */
import {
  EyeFilledBlue,
  DeleteGrey,
  EditWhite,
  DeleteRed,
  EditGreen,
} from "../../../../setup/constants/images";
/* Import image and SVG ends */

/* Import local pages and component starts */
import StatusHighLight from "../StatusHighLight/StatusHighLight";
import IconButton from "../../../ui/button/IconButton/IconButton";
import SliderButton from "../../../ui/button/SliderButton/SliderButton";
import TextButton from "../../../ui/button/TextButton/TextButton";
/* Import local pages and component ends */

import "./style.scss";
import { useSelector } from "react-redux";
import { getUserTheme } from "../../../../setup/store/slices/authSlice";
// import { SelectManagementModelPage } from "../../../../pages";
// import SelectMemberRoleModal from "../SelectMemberRoleModal/SelectMemberRoleModal";

/* Component starts */
const ORTableUnderOwner = (props) => {
  /* Props destructuring starts */
  const {
    ownerList,
    subMembers,
    userRoles,
    parent,
    disabledStatusSwitch,
    toggleActiveSuspend,
    onViewDetail,
    onEdit,
    onDelete,
    canInvite,
    showAddMemberButton,
    canResendInvitation,
    canCancelInvitation,
    setSelectedTeamMember,
    showWarning,
  } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [userRole, setUserRole] = useState(null);
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const userTheme = useSelector(getUserTheme);
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const showSlider = subMembers?.length > 1;
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  /* Component function definition ends */

  /* Component useEffect starts */
  // Filtering and setting PM and Accountant role details on load of all user roles
  useEffect(() => {
    if (userRoles) {
      setUserRole(
        userRoles.filter((role) => role.name === GLOBAL.USER_ROLE.OR)[0]
      );
    }
  }, [userRoles]);
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <>
      <div className="or-under-owner-table-module-wrapper">
        <div className="employee-heading">
          <h3 className="font_m fw_5">Owner Representative</h3>
          {showAddMemberButton("or", parent) && (
            <>
              <p
                onClick={() => {
                  canInvite(
                    GLOBAL.USER_ROLE.OR,
                    `owner/${parent?.user_id}/or/add`
                  );
                }}
                // to={`pmc/${parent?.user_id}/pa/add`}
                // `pmc/${parent?.user_id}/accountant/add`
                className="fw_6 font_s font_blue"
              >
                + Add Representative
              </p>
              {/* <Link
          to={`pmc/${parent?.user_id}/pa/add`}
          // `pmc/${parent?.user_id}/accountant/add`
          className="fw_6 font_s font_blue"
        >
          + Add PA
        </Link> */}
            </>
          )}
        </div>

        {subMembers?.length > 0 && (
          <div className="custom-table-wrapper">
            <Table className="custom-table font_s" borderless responsive>
              {/* Table Heading */}
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Name</th>
                  <th></th>
                  <th>Status</th>
                  <th className="text-start">Actions</th>
                </tr>
              </thead>
              {/* Table Body */}
              <tbody>
                {subMembers?.map((item, index) => (
                  <tr key={index}>
                    {/* ID */}
                    <td>
                      <p className="col-different1">#{index + 1}</p>
                    </td>

                    {/* PM Name */}
                    <td>
                      <p className="col-different2">
                        {item?.first_name} {item?.last_name}
                      </p>
                    </td>

                    {/* Status Slider Button */}
                    <td>
                      <div className="col-different3">
                        {showSlider && item?.status !== "expired" && (
                          <SliderButton
                            active={item?.status === GLOBAL.USER_STATUS.ACTIVE}
                            onChange={(state) =>
                              toggleActiveSuspend(item, state, "or")
                            }
                            disabled={disabledStatusSwitch(item?.status)}
                          />
                        )}
                      </div>
                    </td>

                    {/* Status */}
                    <td className="fw_6">
                      <p className="col-different4">
                        <StatusHighLight status={item?.status} />
                      </p>
                    </td>

                    {/* Actions */}
                    <td className="text-start">
                      {/* View Details */}
                      <IconButton
                        className="action-btn"
                        onClick={() => onViewDetail(item)}
                      >
                        <img
                          src={EyeFilledBlue}
                          className="h_100"
                          alt="View Details"
                        />
                      </IconButton>

                      {/* Edit */}
                      {item?.status === GLOBAL.USER_STATUS.PENDING_APPROVAL && (
                        <IconButton
                          className="action-btn"
                          onClick={() => onEdit(item, "or")}
                        >
                          <img src={EditGreen} className="h_100" alt="Edit" />
                        </IconButton>
                      )}
                      {/* Delete */}
                      {item?.status === GLOBAL.USER_STATUS.SUSPENDED && (
                        <IconButton
                          className="action-btn"
                          onClick={() => onDelete(item)}
                        >
                          <img src={DeleteRed} className="h_100" alt="Delete" />
                        </IconButton>
                      )}

                      {canResendInvitation(item?.status) && (
                        <TextButton
                          onClick={() => {
                            setSelectedTeamMember(item);
                            showWarning("resend");
                          }}
                          className="font_m v_middle fw_6 action-txt-btn"
                        >
                          Resend
                        </TextButton>
                      )}
                      {canCancelInvitation(item?.status) && (
                        <TextButton
                          onClick={() => {
                            setSelectedTeamMember(item);
                            showWarning("cancel");
                          }}
                          className="font_m v_middle fw_6 font_red action-txt-btn"
                        >
                          Cancel
                        </TextButton>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        )}

        {subMembers?.length === 0 && (
          <div className="not-added-yet-employee text-center">
            <h3
              className={`
              ${userTheme === "dark" ? "bg_darkgrey" : "bg_lightThemeInput"}
               font_m font_yellow fw_6`}
            >
              No Owner Representative added yet
            </h3>
          </div>
        )}
      </div>

      {/* <SelectMemberRoleModal
        showModal={openModal}
        onSubmit={handleInvitateMember}
        selectedRole={selectedRole}
        setSelectedRole={setSelectedRole}
        // title={confirmationMessage.title}
        // description={confirmationMessage.description}
        onClose={() => setOpenModal(!openModal)}
      /> */}
    </>
  );
};
/* Component ends */

export default ORTableUnderOwner;
