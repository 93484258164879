/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import GLOBAL from "../../../../../setup/constants/global";
import { useDispatch } from "react-redux";
/* Import configuration ends */

/* Import redux slices component starts */
import {
  updateTenantApplicationStatus,
  rejectTenantApplicationData,
} from "../../../../../setup/store/slices/tenantSlice";
import {
  showSuccessAlert,
  showErrorAlert,
} from "../../../../../setup/store/slices/globalAlertSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import CustomButton from "../../../../ui/button/CustomButton/CustomButton";
import ApplicationDataRejectionModal from "../../ApplicationDataRejectionModal/ApplicationDataRejectionModal";
import SuccessModal from "../../../../ui/modal/SuccessModal/SuccessModal";
import ErrorModal from "../../../../ui/modal/ErrorModal/ErrorModal";
/* Import local pages and component ends */

/* Component starts */
const ApproveOrRejectApplication = (props) => {
  /* Props destructuring starts */
  const {
    application_id,
    getApplication,
    application,
    canApprove = true,
    canReject = true,
    canSendForReview = true,
  } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [applicationRejectModal, setApplicationRejectModal] = useState(false);
  const [message, setMessage] = useState("");
  const [showErrModal, setShowErrModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const dispatch = useDispatch();
  const isPM = localStorage.getItem("user_role") === "Property Manager";
  const isLO = localStorage.getItem("user_role") === "Leasing Owner";

  const isOwner = localStorage.getItem("user_role") === "Owner";
  const property_Model =
    application?.application?.unit?.property_id?.mgmt_model;

  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  // On taking action on whole tenant application. Accepting or Rejecting
  const approveRejectTenantApplication = async (approve = true) => {
    // Creating or collecting payload data to be sent
    const payload = {
      application_id,
      status: approve
        ? GLOBAL.TENANT_APPLICATION_STATUS.DATA_APPROVED
        : GLOBAL.TENANT_APPLICATION_STATUS.DATA_REJECTED,
    };

    // Backend response. Try, Catch
    try {
      const result = await dispatch(updateTenantApplicationStatus(payload));

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          setMessage(result.payload);
          setShowErrModal(true);
          break;

        case GLOBAL.FULFILLED:
          getApplication();
          setMessage(result.payload.message);
          setShowSuccessModal(true);
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };

  const onRejectApplication = async (rejection_reason) => {
    // Creating or collecting payload data to be sent
    const payload = {
      application_id,
      status: GLOBAL.TENANT_APPLICATION_STATUS.DATA_REJECTED,
      rejection_reason,
      rejected_by: localStorage.getItem("user_role"),
    };
    console.log("Payload", payload);

    // Backend response. Try, Catch
    try {
      const result = await dispatch(updateTenantApplicationStatus(payload));
      setApplicationRejectModal(false);
      console.log("Response", result);

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          dispatch(
            showErrorAlert({
              title: "Error!",
              description:
                typeof result?.payload === "string" ? result?.payload : "",
            })
          );
          break;

        case GLOBAL.FULFILLED:
          getApplication();
          dispatch(
            showSuccessAlert({
              title: "Success!",
              description:
                typeof result?.payload?.message === "string"
                  ? result?.payload?.message
                  : "",
            })
          );
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };

  const sentFormForReview = async () => {
    // Creating or collecting payload data to be sent
    const payload = { application_id };
    console.log("Payload", payload);

    // Backend response. Try, Catch
    try {
      const result = await dispatch(rejectTenantApplicationData(payload));
      console.log("Response", result);

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          dispatch(
            showErrorAlert({
              title: "Error!",
              description:
                typeof result?.payload === "string" ? result?.payload : "",
            })
          );
          break;

        case GLOBAL.FULFILLED:
          getApplication();
          dispatch(
            showSuccessAlert({
              title: "Success!",
              description:
                typeof result?.payload?.message === "string"
                  ? result?.payload?.message
                  : "",
            })
          );
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="application-approve-or-reject-wrapper mt-4">
      {canApprove &&
        !application?.can_resend_for_review &&
        application?.can_approve_application && (
          // (property_Model === 2 ? isPM || isLO : isOwner) &&
          <CustomButton
            onClick={() => {
              if (application.can_approve_application) {
                approveRejectTenantApplication(true);
              } else {
                setMessage(application.error_message);
                setShowErrModal(true);
              }
            }}
            size="l"
          >
            Accept
          </CustomButton>
        )}

      {canReject &&
        // && (property_Model === 2 ? isPM || isLO : isOwner)
        (application?.can_approve_application ||
          application.can_resend_for_review) && (
          <CustomButton
            onClick={() => setApplicationRejectModal(true)}
            size="l"
            variant="red"
            className={`${!canApprove && !canSendForReview ? "btn-full" : ""}`}
          >
            Reject {!canApprove && !canSendForReview && "Application"}
          </CustomButton>
        )}

      {canSendForReview && application.can_resend_for_review && (
        // (property_Model === 2 ? isPM || isLO : isOwner) &&
        <CustomButton onClick={sentFormForReview} size="l" variant="yellow">
          Send For Review
        </CustomButton>
      )}

      {/* Success Modal */}
      <SuccessModal
        showModal={showSuccessModal}
        title="Success!"
        description={message}
        onClose={() => setShowSuccessModal(false)}
      />

      {/* Error Modal */}
      <ErrorModal
        showModal={showErrModal}
        title="Error!"
        description={message}
        onClose={() => setShowErrModal(false)}
      />

      <ApplicationDataRejectionModal
        showModal={applicationRejectModal}
        title="Reason for rejection"
        onCancel={() => setApplicationRejectModal(false)}
        onRejectWithNote={onRejectApplication}
      />
    </div>
  );
};
/* Component ends */

export default ApproveOrRejectApplication;
