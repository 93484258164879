/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
/* Import configuration ends */

/* Import redux slices component starts */
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import FileViewerModal from "../../../../ui/modal/FileViewerModal/FileViewerModal";
import Image from "../../../../ui/other/Image/Image";
/* Import local pages and component ends */

/* Component starts */
const FileGrid = (props) => {
  /* Props destructuring starts */
  const { application } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [file, setFile] = useState({});
  const [showFile, setShowFile] = useState(false);
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="file-grid-wrapper">
      {application?.key_docs?.map((item, index) => (
        <div className="file">
          <Image
            src={item?.url}
            videoThumbnail={item?.type2 === "video"}
            onClick={() => {
              setFile(item);
              setShowFile(true);
            }}
            alt="File"
          />
        </div>
      ))}

      <FileViewerModal
        show={showFile}
        file={file?.url}
        type={file?.type}
        onClose={() => setShowFile(false)}
      />
    </div>
  );
};
/* Component ends */

export default FileGrid;
