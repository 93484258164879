import { act } from "react-dom/test-utils";
import GLOBAL from "../../../../setup/constants/global";

export const logTitle = (item) => {
  let title = "";
  let role = item?.requester_role?.name;
  let currentStatus = item?.current_status;
  let logType = item?.log_type;

  //   Generating title according to action string
  switch (item?.action) {
    //
    case "":
      title = "";
      break;

    // Default Case...
    default:
      title = item?.action;
  }

  return title;
};

export const statusColorGenerate = (status) => {
  switch (status) {
    case "New":
      return "font_blue";

    case "Closed":
      return "font_green";

    case "Rejected":
      return "font_red";

    default:
      return "font_yellow";
  }
};

export const orderStatusGenerate = (status) => {
  if (status === "Approved") {
    return {
      status: "Order Placed",
      button: "Place Order",
    };
  }
  if (status === "Order Placed") {
    return {
      status: "Parts Recieved",
      button: "Recieve Parts",
    };
  }
};

export const taskStatusGenerate = (status) => {
  switch (status) {
    case "technician_acceptance_pending":
    case "Pending":
      return { color: "font_yellow", text: "Pending Technician approval" };

    case "accepted_by_technician":
      return { color: "font_green", text: "Technician Accepted" };

    case "rejected_by_technician":
      return { color: "font_red", text: "Technician Rejected" };

    case "Completed":
      return { color: "font_blue", text: "Task Completed" };

    case "pm_approval_pending":
      return { color: "font_yellow", text: "PM Approval Pending" };

    case "Approved":
      return { color: "font_green", text: "Approved" };

    case "rejected_by_pm":
      return { color: "font_red", text: "Rejected By PM" };

    default:
      return {};
  }
};

const userActionPermission = (item, user) => {
  const logRequester = item?.requester?._id;
  const logRequesterRole = item?.requester_role?._id;
  const currentUser = user?._id;
  const currentUserRole = user?.role_id;
  if (logRequesterRole === currentUserRole && logRequester === currentUser) {
    return true;
  }
  return false;
};

// If Owner can start maintenance
export const canOwnerStartMaintenance = (item, user) => {
  const action = item?.action;
  const currentStatus = item?.current_status;
  const logType = item?.log_type;
  const requesterRole = item?.requester_role?.name;
  if (
    (action === "Maintenance ticket assigned" && currentStatus === "pending") ||
    // action === "Quoatation approved To Owner" ||
    action === "Order has been recieved" ||
    currentStatus === "Parts Recieved"
  ) {
    return true;
  }
  return false;
};

// If Owner can add quotation
export const canOwnerAddQuotation = (item, user) => {
  const action = item?.action;
  const currentStatus = item?.current_status;
  const logType = item?.log_type;
  const requesterRole = item?.requester_role?.name;
  if (action === "Maintenance ticket assigned") {
    return true;
  }
  return false;
};

// If Owner can choose who can cover cost
export const canOwnerActionOnQuotation = (item, user) => {
  const action = item?.action;
  const currentStatus = item?.current_status;
  const logType = item?.log_type;
  const requesterRole = item?.requester_role?.name;
  if (action === "Quoatation approved To Owner" && logType === "quoatation") {
    return true;
  }
  return false;
};

// If Owner can place order
export const canOwnerPlaceOrder = (item, user) => {
  const action = item?.action;
  const currentStatus = item?.current_status;
  const logType = item?.log_type;
  const requesterRole = item?.requester_role?.name;
  if (action === "Quoatation approved To Owner") {
    return true;
  }
  return false;
};

// If Owner can choose who can cover cost
export const canOwnerRecieveParts = (item, user) => {
  const action = item?.action;
  const currentStatus = item?.current_status;
  const logType = item?.log_type;
  const requesterRole = item?.requester_role?.name;
  if (currentStatus === "Order Placed") {
    return true;
  }
  return false;
};

// If Owner can complete maintenance
export const canOwnerCompleteMaintenance = (item, user) => {
  const action = item?.action;
  const currentStatus = item?.current_status;
  const logType = item?.log_type;
  const requesterRole = item?.requester_role?.name;
  if (action === "Maintenance is in progress") {
    return true;
  }
  return false;
};

// If FM can reassign ticket to other Managers **
export const canFmReassignManagers = (item, user) => {
  const action = item?.action;
  const currentStatus = item?.current_status;
  const logType = item?.log_type;
  if (!userActionPermission(item, user)) {
    return false;
  }
  if (
    action === "Maintenance ticket assigned" &&
    logType === "maintenance" &&
    currentStatus === "pending"
  ) {
    return true;
  }
  return false;
};

// If TPM can reassign ticket to other Managers **
export const canTPMReassignManagers = (item, user) => {
  const action = item?.action;
  const currentStatus = item?.current_status;
  const logType = item?.log_type;
  if (!userActionPermission(item, user)) {
    return false;
  }
  if (
    action === "Maintenance ticket is resassigned" &&
    logType === "maintenance" &&
    currentStatus === "pending"
  ) {
    return true;
  }
  return false;
};

// If FM can accept maintenance request **
export const canFMAcceptReq = (item, user) => {
  const action = item?.action;
  const currentStatus = item?.current_status;
  const logType = item?.log_type;
  if (!userActionPermission(item, user)) {
    return false;
  }
  if (
    action === "Maintenance ticket assigned" &&
    logType === "maintenance" &&
    currentStatus === "pending"
  ) {
    return true;
  }
  return false;
};

// If TPM can accept maintenance request **
export const canTPMAcceptReq = (item, user) => {
  const action = item?.action;
  const currentStatus = item?.current_status;
  const logType = item?.log_type;
  const requesterRoleName = item?.requester_role?.name;
  if (requesterRoleName === "Property Manager") {
    return false;
  }
  if (!userActionPermission(item, user)) {
    return false;
  }
  if (
    action === "Maintenance ticket is resassigned" &&
    logType === "maintenance" &&
    currentStatus === "pending"
  ) {
    return true;
  }
  return false;
};

// If FM can assign technician **
export const canFMAssignMT = (item, user) => {
  const action = item?.action;
  const currentStatus = item?.current_status;
  const logType = item?.log_type;
  if (!userActionPermission(item, user)) {
    return false;
  }
  if (
    currentStatus === "assign_mt_pending" ||
    currentStatus === "Parts Recieved"
  ) {
    return true;
  }
  return false;
};

// If technician can respond to maitenance request **
export const canMTRespondMaintenance = (item, user) => {
  const action = item?.action;
  const currentStatus = item?.current_status;
  const logType = item?.log_type;
  if (!userActionPermission(item, user)) {
    return false;
  }
  if (
    action === "Maintenance ticket assigned to Technician" &&
    currentStatus === "MT_acceptance_pending"
  ) {
    return true;
  }
  return false;
};

// If technician mark that he is on the way **
export const canMTMarkOnTheWay = (item, user) => {
  const action = item?.action;
  const currentStatus = item?.current_status;
  const logType = item?.log_type;
  if (!userActionPermission(item, user)) {
    return false;
  }
  if (currentStatus === "accepted_by_mt") {
    return true;
  }
  return false;
};

// If third party technician mark that he is on the way **
export const canTPTMarkOnTheWay = (item, user) => {
  const action = item?.action;
  const currentStatus = item?.current_status;
  const logType = item?.log_type;
  if (!userActionPermission(item, user)) {
    return false;
  }
  if (currentStatus === "accepted_by_mt") {
    return true;
  }
  return false;
};

// If technician can Check In **
export const canMTCheckIn = (item, user) => {
  const action = item?.action;
  const currentStatus = item?.current_status;
  const logType = item?.log_type;
  if (!userActionPermission(item, user)) {
    return false;
  }
  if (currentStatus === "technician_on_the_way") {
    return true;
  }
  return false;
};

// If third party technician can Check In **
export const canTPTCheckIn = (item, user) => {
  const action = item?.action;
  const currentStatus = item?.current_status;
  const logType = item?.log_type;
  if (!userActionPermission(item, user)) {
    return false;
  }
  if (currentStatus === "technician_on_the_way") {
    return true;
  }
  return false;
};

// If technician has No Access **
export const canMTNoAccess = (item, user) => {
  const action = item?.action;
  const currentStatus = item?.current_status;
  const logType = item?.log_type;
  if (!userActionPermission(item, user)) {
    return false;
  }
  if (currentStatus === "technician_on_the_way") {
    return true;
  }
  return false;
};

// If third party technician has No Access **
export const canTPTNoAccess = (item, user) => {
  const action = item?.action;
  const currentStatus = item?.current_status;
  const logType = item?.log_type;
  if (!userActionPermission(item, user)) {
    return false;
  }
  if (currentStatus === "technician_on_the_way") {
    return true;
  }
  return false;
};

// If TPT can start work or request for spare parts **
export const canTPTStrtWrkReq4Parts = (item, user) => {
  const action = item?.action;
  const currentStatus = item?.current_status;
  const logType = item?.log_type;
  if (!userActionPermission(item, user)) {
    return false;
  }
  if (
    currentStatus === "inspection_report_added" ||
    currentStatus === "parts_request_rejected" ||
    currentStatus == "technician_on_the_location_again"
  ) {
    return true;
  }
  return false;
};

// If MT can start work or request for spare parts **
export const canMTStrtWrkReq4Parts = (item, user) => {
  const action = item?.action;
  const currentStatus = item?.current_status;
  const logType = item?.log_type;
  if (!userActionPermission(item, user)) {
    return false;
  }
  if (
    currentStatus === "inspection_report_added" ||
    currentStatus === "parts_request_rejected" ||
    currentStatus == "technician_on_the_location_again"
  ) {
    return true;
  }
  return false;
};

// If FM can review spare parts requested **
export const canFMReviewSpareParts = (item, user) => {
  const action = item?.action;
  const currentStatus = item?.current_status;
  const logType = item?.log_type;
  if (!userActionPermission(item, user)) {
    return false;
  }
  if (currentStatus === "parts_requested") {
    return true;
  }
  return false;
};

// If FM can create quote **
export const canFMCreateQuote = (item, user) => {
  const action = item?.action;
  const currentStatus = item?.current_status;
  const logType = item?.log_type;
  if (!userActionPermission(item, user)) {
    return false;
  }
  if (
    currentStatus === "parts_request_accepted" ||
    // action === "Quoatation reassigned to 3PM"
    action === "Quoatation reassigned to Third Party Manager" ||
    action === "Quoatation reassigned to Facility Manager"
  ) {
    return true;
  }
  return false;
};

// If PM can review spare parts quotaions **
export const canFMReviewQuotation = (item, user) => {
  const action = item?.action;
  const currentStatus = item?.current_status;
  const logType = item?.log_type;
  if (!userActionPermission(item, user)) {
    return false;
  }
  if (action === "Quoatation Request Assigned To Facility Manager") {
    return true;
  }
  return false;
};

// If PM can review spare parts quotaions **
export const canPMReviewQuotation = (item, user) => {
  const action = item?.action;
  const currentStatus = item?.current_status;
  const logType = item?.log_type;
  if (!userActionPermission(item, user)) {
    return false;
  }
  if (
    //action === "Quoatation assigned To PM"
    action === "Quoatation Request Assigned To Property Manager"
  ) {
    return true;
  }
  return false;
};

// If MT can handover items **
export const canMTHandover = (item, user) => {
  const action = item?.action;
  const currentStatus = item?.current_status;
  const logType = item?.log_type;
  if (!userActionPermission(item, user)) {
    return false;
  }
  if (currentStatus === "work_in_progress") {
    return true;
  }
  return false;
};

// If TPT can handover items **
export const canTPTHandover = (item, user) => {
  const action = item?.action;
  const currentStatus = item?.current_status;
  const logType = item?.log_type;
  if (!userActionPermission(item, user)) {
    return false;
  }
  if (currentStatus === "work_in_progress") {
    return true;
  }
  return false;
};

// If MT can mark job complete **
export const canMTMarkJobComplete = (item, user) => {
  const action = item?.action;
  const currentStatus = item?.current_status;
  const logType = item?.log_type;
  if (!userActionPermission(item, user)) {
    return false;
  }
  if (
    currentStatus === "work_in_progress" ||
    currentStatus === "marked_items_to_handover"
  ) {
    return true;
  }
  return false;
};

// If TPT can mark job complete **
export const canTPTMarkJobComplete = (item, user) => {
  const action = item?.action;
  const currentStatus = item?.current_status;
  const logType = item?.log_type;
  if (!userActionPermission(item, user)) {
    return false;
  }
  if (
    currentStatus === "work_in_progress" ||
    currentStatus === "marked_items_to_handover"
  ) {
    return true;
  }
  return false;
};

// If FM can place order **
export const canFMPlaceOrder = (item, user) => {
  const action = item?.action;
  const currentStatus = item?.current_status;
  const logType = item?.log_type;
  if (!userActionPermission(item, user)) {
    return false;
  }
  if (
    // action === "FM received approval" ||
    action === "Facility Manager received approval" ||
    action === "Quoatation Approved By Facility Manager"
  ) {
    return true;
  }
  return false;
};

// If TPM can place order **
export const canTPMPlaceOrder = (item, user) => {
  const action = item?.action;
  const currentStatus = item?.current_status;
  const logType = item?.log_type;
  if (!userActionPermission(item, user)) {
    return false;
  }
  if (
    //action === "3PM recieved approval"
    action === "Third Party Manager received approval"
  ) {
    return true;
  }
  return false;
};

// If FM can recieve spare parts **
export const canFMRecieveSpareParts = (item, user) => {
  const action = item?.action;
  const currentStatus = item?.current_status;
  const logType = item?.log_type;
  if (!userActionPermission(item, user)) {
    return false;
  }
  if (currentStatus === "Order Placed") {
    return true;
  }
  return false;
};

// If TPM can assign TPT **
export const canTPMAssignTPT = (item, user) => {
  const action = item?.action;
  const currentStatus = item?.current_status;
  const logType = item?.log_type;
  if (!userActionPermission(item, user)) {
    return false;
  }
  if (
    currentStatus === "assign_mt_pending" ||
    currentStatus === "Parts Recieved"
  ) {
    return true;
  }
  return false;
};

// If TPT can respond to maintenance request sent by TPM **
export const canTPTRespondMaintenance = (item, user) => {
  const action = item?.action;
  const currentStatus = item?.current_status;
  const logType = item?.log_type;
  if (!userActionPermission(item, user)) {
    return false;
  }
  if (
    action === "Maintenance ticket assigned to Technician" &&
    currentStatus === "MT_acceptance_pending"
  ) {
    return true;
  }
  return false;
};

// If TPM can accept maintenance request **
export const canTPMRespondToTicket = (item, user) => {
  const action = item?.action;
  const currentStatus = item?.current_status;
  const logType = item?.log_type;
  if (!userActionPermission(item, user)) {
    return false;
  }
  if (
    action === "Waiting for Manager approval" &&
    logType === "maintenance" &&
    currentStatus === "fm_approval_pending"
  ) {
    return true;
  }
  return false;
};

// If FM can accept maintenance request **
export const canFMRespondToTicket = (item, user) => {
  const action = item?.action;
  const currentStatus = item?.current_status;
  const logType = item?.log_type;
  if (!userActionPermission(item, user)) {
    return false;
  }
  if (
    // (
    //   action === "Waiting for Manager approval" ||
    //   action === "Waiting for FM approval") &&
    // logType === "maintenance" &&
    currentStatus === "fm_approval_pending" ||
    currentStatus === "waiting_for_fm_approval"
  ) {
    return true;
  }
  return false;
};

// If FM can accept maintenance request **
export const canPMRespondToTicket = (item, user) => {
  const action = item?.action;
  const currentStatus = item?.current_status;
  const logType = item?.log_type;
  if (!userActionPermission(item, user)) {
    return false;
  }
  if (
    // action === "PM acceptance pending" &&
    action === "Property Manager acceptance pending" &&
    logType === "maintenance" &&
    currentStatus === "pm_approval_pending"
  ) {
    return true;
  }
  return false;
};

// If tenant can rate service
export const canPMRateService = (item, user) => {
  const action = item?.action;
  const currentStatus = item?.current_status;
  const logType = item?.log_type;
  const requesterRole = item?.requester_role?.name;

  if (!userActionPermission(item, user)) {
    return false;
  }

  if (
    //action === "Maintenance accepted by PM" ||
    // (action === "Maintenance accepted by Property Manager" ||
    //   action === "Maintenance accepted by Manager" ||
    //   action === "Maintenance completed by Owner") &&
    currentStatus === "rating_pending"
  ) {
    return true;
  }
  return false;
};

// If Technician can add inspection report
export const canTechnicianAddInspectionReport = (item, user) => {
  const action = item?.action;
  const currentStatus = item?.current_status;
  const logType = item?.log_type;
  const requesterRole = item?.requester_role?.name;

  if (!userActionPermission(item, user)) {
    return false;
  }

  if (currentStatus === "technician_on_the_location") {
    return true;
  }
  return false;
};

// If PM can approve final maintenance work
export const canPMapproveMaintenanceJob = (item, user) => {
  const action = item?.action;
  const currentStatus = item?.current_status;
  const logType = item?.log_type;
  const requesterRole = item?.requester_role?.name;
  if (!userActionPermission(item, user)) {
    return false;
  }
  if (currentStatus === "waiting_for_pm_approval") {
    return true;
  }
  return false;
};

// Template
export const functionTemplate = (item, user) => {
  const action = item?.action;
  const currentStatus = item?.current_status;
  const logType = item?.log_type;
  const requesterRole = item?.requester_role?.name;
  if (false) {
    return true;
  }
  return false;
};
