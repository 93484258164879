/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import GLOBAL from "../../../setup/constants/global";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
/* Import configuration ends */

/* Import redux slices component starts */
import {
  fetchSecurityIncidents,
  getSecurityComplaints,
  getSecurityIncidents,
  fetchSecurityComplaintRequests,
} from "../../../setup/store/slices/securitySlice";
import {
  showErrorAlert,
  showSuccessAlert,
} from "../../../setup/store/slices/globalAlertSlice";

import {
  fetchPropertyDetailByID,
  getPropertyDetail,
} from "../../../setup/store/slices/propertySlice";
import { setLoading } from "../../../setup/store/slices/unpersistedSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import Searchbar from "../../../components/ui/other/Searchbar/Searchbar";
import SecurityTable from "../../../components/module/SecurityManage/ClientSecurityManage/SecurityTable/SecurityTable";
import TabButton from "../../../components/ui/other/TabButton/TabButton";
import SecurityIncidentTable from "../../../components/module/SecurityManage/ClientSecurityManage/SecurityIncidentTable/SecurityIncidentTable";
import BreadCrumb from "../../../components/ui/other/BreadCrumb/BreadCrumb";
/* Import local pages and component ends */

// styles sheet
import "./style.scss";

/* Component starts */
const ClientSecurity = (props) => {
  /* Props destructuring starts */
  const {} = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [buttonLists, setButtonLists] = useState([]);
  const [subButtonLists, setSubButtonLists] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { propertyId, securityListTab, securityListSubTab } = useParams();
  /* Other hooks declaration ends */

  // Redux Selectors
  const property = useSelector(getPropertyDetail);
  const complainList = useSelector(getSecurityComplaints);
  const incidentList = useSelector(getSecurityIncidents);
  const pathnameArrList = pathname.split("/");
  /* Component variable declaration / object destructure starts */

  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  const onTabSelect = (key) => {
    setCurrentPage(1);
    const pathnameArr = pathname.split("/");
    pathnameArr.pop();
    pathnameArr.pop();
    navigate(pathnameArr.join("/") + "/" + key + "/open");
    if (key === "closed") {
      navigate(pathnameArr.join("/") + "/" + key + "/complaints");
    }

    // setCurrentKey(key);
    // const tabPath = pathname.split("/");
    // // tabPath.pop();
    // navigate(tabPath.join("/") + "/" + key);
  };

  const onSubTabSelect = (key) => {
    setCurrentPage(1);
    const pathnameArr = pathname.split("/");
    pathnameArr.pop();
    navigate(pathnameArr.join("/") + "/" + key);
    // setCurrentSubKey(key);
  };

  const handleFetchComplaintsList = async (
    property_id,
    tab,
    subTab,
    page,
    path,
    search = ""
  ) => {
    // Creating or collecting payload data to be sent
    const payload = { property_id, page };

    if (path?.includes("closed")) {
      payload["status"] = "resolved";
    }

    if (search !== "") {
      payload["search"] = search;
    }

    console.log("Payload", payload);

    // Backend response. Try, Catch
    try {
      const result = dispatch(fetchSecurityComplaintRequests(payload));
      console.log("Response", result);

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          dispatch(
            showErrorAlert,
            showSuccessAlert({
              title: "Error!",
              description:
                typeof result?.payload === "string" ? result?.payload : "",
            })
          );
          break;

        case GLOBAL.FULFILLED:
          // dispatch(
          //   showSuccessAlert({
          //     title: "Success!",
          //     description:
          //       typeof result?.payload?.message === "string"
          //         ? result?.payload?.message
          //         : "",
          //   })
          // );
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };

  const handleFetchIncidentList = async (
    property_id,
    tab,
    subTab,
    page,
    path,
    search = ""
  ) => {
    // Creating or collecting payload data to be sent
    const payload = { property_id, page };

    if (path?.includes("incidents") && tab === "closed") {
      payload["status"] = "Closed";
    }

    if (search !== "") {
      payload["search"] = search;
    }

    console.log("Payload", payload);

    // Backend response. Try, Catch
    try {
      const result = await dispatch(fetchSecurityIncidents(payload));
      console.log("Response", result);

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          dispatch(
            showErrorAlert({
              title: "Error!",
              description:
                typeof result?.payload === "string" ? result?.payload : "",
            })
          );
          break;

        case GLOBAL.FULFILLED:
          // dispatch(
          //   showSuccessAlert({
          //     title: "Success!",
          //     description:
          //       typeof result?.payload?.message === "string"
          //         ? result?.payload?.message
          //         : "",
          //   })
          // );
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };

  const handleFetchList = async (
    property_id,
    tab,
    subTab,
    page,
    path,
    search = ""
  ) => {
    if (tab === "incidents" || tab === "closed") {
      handleFetchIncidentList(property_id, tab, subTab, page, path, search);
    }
    if (tab === "complaints" || tab === "closed") {
      handleFetchComplaintsList(property_id, tab, subTab, page, path, search);
    }
  };
  /* Component function definition ends */

  /* Component useEffect starts */

  useEffect(() => {
    (async () => {
      try {
        await dispatch(setLoading(true));
        await dispatch(fetchPropertyDetailByID({ property_id: propertyId }));
        await dispatch(setLoading(false));
      } catch (e) {}
    })();
  }, [propertyId]);

  useEffect(() => {
    if (searchQuery !== "") {
      handleFetchList(
        propertyId,
        securityListTab,
        securityListSubTab,
        currentPage,
        pathnameArrList,
        searchQuery
      );
    } else {
      handleFetchList(
        propertyId,
        securityListTab,
        securityListSubTab,
        currentPage,
        pathnameArrList,
        ""
      );
    }
  }, [currentPage, securityListTab, securityListSubTab]);

  useEffect(() => {
    let timeoutid = null;
    if (searchQuery !== "") {
      timeoutid = setTimeout(() => {
        handleFetchList(
          propertyId,
          securityListTab,
          securityListSubTab,
          currentPage,
          pathnameArrList,
          searchQuery
        );
      }, 1000);
    } else {
      handleFetchList(
        propertyId,
        securityListTab,
        securityListSubTab,
        currentPage,
        pathnameArrList,
        ""
      );
    }

    return () => {
      if (timeoutid) {
        clearTimeout(timeoutid);
      }
    };
  }, [searchQuery]);

  useEffect(() => {
    setCurrentPage(1);
  }, [searchQuery]);

  // useEffect(() => {
  //   onSubTabSelect(securityListSubTab);
  //   setCurrentPage(1);
  //   setSearchQuery("");
  // }, [securityListTab, securityListSubTab, property]);

  useEffect(() => {
    if (property) {
      if (property?.mgmt_model === 2) {
        setButtonLists([
          { key: "complaints", label: "Complaints" },
          { key: "incidents", label: "Incidents" },
          { key: "closed", label: "Closed" },
        ]);
        setSubButtonLists([
          { key: "complaints", label: "Complaints" },
          {
            key: "incidents",
            label: "Incident",
          },
        ]);
      } else {
        setButtonLists([
          { key: "complaints", label: "Complaints" },
          { key: "closed", label: "Closed" },
        ]);
      }
    }
  }, [property]);

  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */

  return (
    <>
      <BreadCrumb type="client_security" />

      <div className="security">
        <div className="heading">
          <h2 className="font_xxl fw_6">Security</h2>
          {property?.mgmt_model === 2 &&
            pathnameArrList?.includes("incidents") && (
              <Link to="add" className="font_m fw_6 d_block link">
                + Create Security Incident
              </Link>
            )}
        </div>

        <Searchbar query={searchQuery} setQuery={setSearchQuery} />

        {/* TAB BUTTONS */}
        <TabButton
          buttonList={buttonLists}
          currentKey={securityListTab}
          onSelect={onTabSelect}
          disabled={searchQuery !== ""}
        />

        {securityListTab === "complaints" ? (
          <SecurityTable
            complainList={complainList?.securityComplaints}
            totalCount={complainList?.total}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        ) : securityListTab === "incidents" ? (
          <SecurityIncidentTable
            incidentList={incidentList?.securityIncident}
            totalCount={incidentList?.totalCount}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        ) : (
          <>
            <TabButton
              buttonList={subButtonLists}
              currentSubKey={securityListSubTab}
              onSelect={onSubTabSelect}
              disabled={searchQuery !== ""}
            />
            {securityListSubTab === "complaints" ? (
              <SecurityTable
                complainList={complainList?.securityComplaints}
                totalCount={complainList?.total}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
              />
            ) : (
              <SecurityIncidentTable
                incidentList={incidentList?.securityIncident}
                totalCount={incidentList?.totalCount}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
              />
            )}
          </>
        )}
      </div>
    </>
  );
};
/* Component End */

export default ClientSecurity;
