/**
 *
 */

import React, { useEffect, useState } from "react";

/* Import configuration starts */
import { shuffleArray } from "../../../../setup/utils/global-helper";
/* Import configuration ends */

/* Import redux slices component starts */
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
import { ExcalmatoryYellow } from "../../../../setup/constants/images";
/* Import image and SVG ends */

/* Import local pages and component starts */
import SliderItem from "./sub-component/SliderItem";
import SliderItem2 from "./sub-component/SliderItem2";
import CustomSlider from "../../../ui/other/CustomSlider/CustomSlider";
import SliderItemDefault from "./sub-component/SliderItemDefault";
/* Import local pages and component ends */

import "./style.scss";

/* Component starts */
const UnitSliderTenantHome = (props) => {
  /* Props destructuring starts */
  const { units, fetchTenantUnitsNew, advertisements } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [shuffleAds, setShuffleAds] = useState([]);
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  /* Component function definition ends */

  /* Component useEffect starts */

  useEffect(() => {
    setShuffleAds(shuffleArray(advertisements)); // Shuffle once on mount
  }, [advertisements]);

  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="unit-slider-tenant-home container_md">
      {/* Slider 1 */}

      {advertisements?.length === 0 ? (
        <SliderItemDefault />
      ) : (
        <>
          <CustomSlider
            type="tenantHome"
            autoPlay={true}
            autoPlaySpeed={3000}
            infinite={true}
            showDots={true}
            arrows={false}
          >
            {shuffleAds?.map((item, index) => (
              <SliderItem2 item={item} key={index} />
            ))}
          </CustomSlider>
        </>
      )}

      {units?.length === 0 && (
        <div className="text-center default_message ">
          <img src={ExcalmatoryYellow} alt="Info" />
          <h3 className=" font_l font_yellow fw_6 my-4">
            You are presently not associated with any property. If you
            anticipate being part of one, kindly review your email inbox for an
            invitation message.
          </h3>
        </div>
      )}

      {/* Slider 2 */}
      <CustomSlider type="tenantHome">
        {units?.map((item, index) => (
          <SliderItem
            // onClick={() => selectUnit(item)}
            fetchTenantUnitsNew={fetchTenantUnitsNew}
            item={item}
            key={index}
          />
        ))}
      </CustomSlider>
    </div>
  );
};
/* Component ends */

export default UnitSliderTenantHome;
