/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import moment from "moment";
/* Import configuration ends */

/* Import redux slices component starts */
import { useDispatch, useSelector } from "react-redux";
import {
  fetchPartyInvoiceById,
  getPartyInvoice,
  updtePartyInvCheque,
} from "../../../../../../setup/store/slices/partyInvoiceSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
import { Table } from "react-bootstrap";
/* Import react bootstrap component ends */

/* Import image and SVG starts */
import {
  DocFilledWhite,
  DocumentGreen,
  ExcalmatoryYellow,
  EyeFilledBlue,
  PrintIcon,
} from "../../../../../../setup/constants/images";
/* Import image and SVG ends */

/* Import local pages and component starts */
import IconButton from "../../../../../ui/button/IconButton/IconButton";
import GLOBAL from "../../../../../../setup/constants/global";
import SparePartAccordion from "../../../../../ui/other/SparePartAccordion/SparePartAccordion";
import FileViewerModal from "../../../../../ui/modal/FileViewerModal/FileViewerModal";
import InvoiceDetailModal from "../InvoiceDetailModal/InvoiceDetailModal";
import {
  showErrorAlert,
  showSuccessAlert,
} from "../../../../../../setup/store/slices/globalAlertSlice";
import ChecqueStatusChangeModal from "../../../ChecqueStatusChangeModal/ChecqueStatusChangeModal";
/* Import local pages and component ends */

/* Component starts */
const PartiesInvoiceDetail = (props) => {
  /* Props destructuring starts */
  const { state, invoiceID, currency, settings } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [showContractDetailModal, setShowContractDetailModal] = useState(false);
  const [detail, setDetail] = useState({});

  const [showFileViewer, setShowFileViewer] = useState(false);
  const [file, setFile] = useState({});

  const [updateCheque, setUpdateCheque] = useState(false);
  const [selectedInv, setSelectedInv] = useState({});
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */

  const dispatch = useDispatch();
  const partyInvoice = useSelector(getPartyInvoice);

  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const USER_DATA = localStorage.getItem("user_data")
    ? JSON.parse(localStorage.getItem("user_data"))
    : null;
  const DATE_FORMAT = USER_DATA ? USER_DATA?.date_format : "";
  const TIME_FORMAT = USER_DATA ? USER_DATA?.time_format : "";
  const DATE_TIME_FORMAT = `${DATE_FORMAT} ${TIME_FORMAT}`;

  const isOwner = localStorage.getItem("user_role") === "Owner";
  const isFM = localStorage.getItem("user_role") === "Facility Manager";
  const isAccountant = localStorage.getItem("user_role") === "Accountant";
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  const handleView = (item) => {
    setDetail(item);
    setShowContractDetailModal(!showContractDetailModal);
  };

  const getStatus = (status) => {
    let txt_status = status;
    let statusColor = "";
    switch (status) {
      case "pending_clearance":
        statusColor = "font_yellow";
        txt_status = "Pending Clearance";
        break;
      case "bounced":
        statusColor = "font_yellow";
        txt_status = "Bounced";
        break;
      case "succeeded":
        statusColor = "font_green";
        txt_status = "Success";
        break;

      case "cleared":
        statusColor = "font_green";
        txt_status = "Cleared";
        break;

      default:
      // case GLOBAL.SECURITY_STATUS.SO_ASSIGNED:
      //   statusColor = "font_yellow";
      //   txt_status = "Security Officer Assigned";
      //   break;
      // case GLOBAL.SECURITY_STATUS.RESOLVED:
      //   statusColor = "font_green";
      //   txt_status = "resolved";
      //   break;
    }
    return <span className={`font_s fw_4 ${statusColor}`}>{txt_status}</span>;
  };

  const onChequeStatusChange = async (status, reason) => {
    // Creating or collecting payload data to be sent
    let payload = {};

    if (status?.selectedOption === "replace") {
      payload = {
        invoice_id: selectedInv?.invoice_id,
        transaction_id: selectedInv?._id,
        status: status?.selectedOption,
        cheque_reason: status?.note,
        cheque_number: status?.cheque_num,
        payment_amount: parseInt(status?.cheque_amt),
        cheque_date: status?.recpdate,
        bank_name: status?.bank_name,
        issuer: status?.issuer_name,
        invoice_doc: status?.receipt?.current,
        account_number: status?.account_number,
      };
    } else {
      payload = {
        invoice_id: selectedInv?.invoice_id,
        transaction_id: selectedInv?._id,
        status: status,
        cheque_reason: reason,
      };
    }

    console.log(payload, "[CHEQ]");

    // Backend response. Try, Catch
    try {
      const result = await dispatch(updtePartyInvCheque(payload));
      await dispatch(fetchPartyInvoiceById(invoiceID));

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          dispatch(
            showErrorAlert({
              title: "Error!",
              description: result.payload.error.message,
            })
          );
          break;

        case GLOBAL.FULFILLED:
          dispatch(
            showSuccessAlert({
              title: "Success!",
              description: result.payload.message,
            })
          );
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };

  /* Component function definition ends */

  /* Component useEffect starts */
  useEffect(() => {
    if (invoiceID) {
      dispatch(fetchPartyInvoiceById(invoiceID));
    }
  }, [invoiceID]);
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="parties-detail-card">
      <div className="d-flex justify-content-between">
        <p>
          Invoice#{" "}
          <span className="font_blue fw_6">
            {partyInvoice?.invoice?.invoice_num}
          </span>
        </p>
        <p>
          <IconButton>
            <img src={PrintIcon} alt="Print" className="h_100" />
            <span className="font_blue px-2">Print</span>
          </IconButton>
        </p>
      </div>

      <hr />

      <div className="property-wrapper">
        <div className="property-data">
          <div>
            <span className="font_m fw_4">Facility Management Company</span>
          </div>

          <div>
            <span className="font_m fw_4">To : {state?.issuer} </span>
          </div>
        </div>
      </div>

      <hr />

      <div className="invoice-data-price">
        <p className="font_m fw_4 font_grey">
          Description <br />
          <span className="font_m fw_4">
            {partyInvoice?.invoice?.description}
          </span>
        </p>

        <p className="font_m fw_4 font_grey">
          Quantity <br />
          <span className="font_m fw_4">
            {partyInvoice?.invoice?.quantity !== undefined
              ? partyInvoice?.invoice?.quantity
              : "1"}
          </span>
        </p>

        {/* <p className="font_m fw_4 font_grey">
          Amount <br />
          <span className="font_m fw_4 font_blue">
            {partyInvoice?.invoice?.total} {currency}
          </span>
        </p> */}

        <div>
          <p className="font_m fw_4 font_grey">
            Invoice Amount <br />
          </p>
          <p>
            <span className="font_s fw_4">
              Amount : {partyInvoice?.invoice?.amount}
            </span>
            <br />
            <span className="font_s fw_4">
              VAT({settings?.collect_vat_value}):{" "}
              {partyInvoice?.invoice?.vat_amount?.toFixed(2)}
            </span>
            <br />
            <span className="font_s fw_4">
              Tax({settings?.collect_tax_value}):{" "}
              {partyInvoice?.invoice?.tax_amount?.toFixed(2)}
            </span>
            <hr />
            <span className="font_s fw_4">
              Total :{" "}
              <span className="font_m fw_6 font_blue">
                {partyInvoice?.invoice?.total !== undefined
                  ? `${partyInvoice?.invoice?.total?.toLocaleString(
                      "en-US"
                    )}${" "}${currency}`
                  : "-"}
              </span>
            </span>
          </p>
        </div>
      </div>

      <hr />

      <div className="invoice-data">
        <p className="font_m fw_4 font_grey">
          Invoice Due Date <br />
          <span className="font_m fw_4">
            {moment(partyInvoice?.invoice?.due_date).format(DATE_FORMAT)}
          </span>
        </p>

        {partyInvoice?.payment_date && (
          <p className="font_m fw_4 font_grey">
            Payment Date <br />
            <span className="font_m fw_3">05/09/2023</span>
          </p>
        )}
      </div>

      <hr />

      <div className="status-wrapper">
        <p className="font_m fw_4">Status</p>
        <p
          className={`font_m fw_4
        ${
          partyInvoice?.invoice?.status === "Paid"
            ? "font_green"
            : partyInvoice?.invoice?.status === "Unpaid"
            ? "font_yellow"
            : "font_red"
        }
        `}
        >
          {partyInvoice?.invoice?.status}
        </p>
      </div>

      <hr />

      {partyInvoice?.invoice?.parts?.length > 0 && (
        <div className="spareParts-wrapper bg_black">
          <div className="sparePart-details ">
            <div className="sparePart">
              <p className="font_m fw_4 font_grey">
                Spare Parts Request Number
                <br />
                <span className="font_s fw_3">
                  {partyInvoice?.invoice?.request_number !== undefined
                    ? partyInvoice?.invoice?.request_number
                    : "-"}
                </span>
              </p>

              <p className="font_m fw_4 font_grey">
                Spare Parts Quote Number
                <br />
                <span className="font_s fw_3">
                  {partyInvoice?.invoice?.quote_number}
                </span>
              </p>
            </div>

            <hr />

            <SparePartAccordion
              spareQuotes={partyInvoice?.invoice?.parts}
              currency={partyInvoice?.invoice?.currency}
            />
            {/* {partyInvoice?.invoice?.parts?.length > 1 && <hr />} */}
            <hr />

            <div className="sparePart">
              <p className="font_m fw_4 font_grey">Total (USD)</p>

              <p className="font_l fw_5 font_blue">
                {partyInvoice?.invoice?.total}
              </p>
            </div>
          </div>
        </div>
      )}

      {/* <div className="service-data">
        <p className="font_m fw_4 font_grey">
          Service Name
          <br />
          <span className="font_m fw_4">
            {partyInvoice?.invoice?.service_name}
          </span>
        </p>

        <p className="font_m fw_4 font_grey">
          Description <br />
          <span className="font_m fw_4">
            {partyInvoice?.invoice?.description}
          </span>
        </p>
      </div> */}

      {partyInvoice?.invoice_docs?.length > 0 && (
        <>
          <hr />
          <div className="doc-wrapper">
            <p className="font_m fw_4">Documents</p>

            <div className="doc">
              <img src={DocumentGreen} alt="Doc" /> 
              {/* Untitled 1 -{" "} */}
              <span className="font_s fw_4 font_blue text-decorationunderline">
                Download
              </span>
            </div>
          </div>
        </>
      )}

      {/* <hr /> */}

      <div className="payment-data">
        <p className="font_l fw_4">Payment Details</p>

        {partyInvoice?.invoice?.invoice_transaction?.length > 0 ? (
          <div className="trans-table">
            <Table className="custom-table font_s" borderless responsive>
              {/* Table Heading */}
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Amount</th>
                  <th>Method</th>
                  <th>Date</th>
                  {/* <th>Received By</th> */}
                  <th>Status</th>
                  <th>Actions</th>
                </tr>
              </thead>
              {/* Table Body */}
              <tbody>
                {partyInvoice?.invoice?.invoice_transaction?.map(
                  (trans, idx) => (
                    <tr key={trans._id}>
                      {/* ID */}
                      <td>{idx + 1}</td>

                      {/* Payment Amount */}
                      <td>
                        {trans?.associated_invoices?.length > 0
                          ? partyInvoice?.invoice?.amount?.toLocaleString(
                              "en-US"
                            )
                          : trans?.payment_amount?.toLocaleString("en-US") ||
                            trans?.cheque_amount?.toLocaleString("en-US") ||
                            trans?.total_amount?.toLocaleString("en-US")}{" "}
                        {currency}
                      </td>

                      {/* Payment Method */}
                      <td className="font_blue">
                        {trans?.payed_via?.charAt(0).toUpperCase() +
                          trans?.payed_via?.slice(1)}
                      </td>

                      {/* Date */}
                      <td>
                        {moment(trans?.createdAt).format(DATE_TIME_FORMAT)}
                      </td>

                      <td>{getStatus(trans?.status)}</td>

                      {/* Actions */}
                      <td>
                        <IconButton
                          className="action-button"
                          onClick={() =>
                            handleView(trans, partyInvoice?.invoice?.tenant)
                          }
                        >
                          <img
                            src={EyeFilledBlue}
                            className="h_100"
                            alt="Eye"
                          />
                        </IconButton>

                        {((isOwner &&
                          partyInvoice?.invoice?.property?.mgmt_model === 0) ||
                          (isFM &&
                            partyInvoice?.invoice?.property?.mgmt_model ===
                              1) ||
                          (isAccountant &&
                            partyInvoice?.invoice?.property?.mgmt_model ===
                              2)) &&
                          partyInvoice?.invoice?.status !== "paid" &&
                          trans?.status === "pending_clearance" && (
                            <IconButton
                              className="action-button"
                              onClick={() => {
                                setSelectedInv(trans);
                                setDetail(trans);
                                setUpdateCheque(true);
                              }}
                            >
                              <img
                                src={ExcalmatoryYellow}
                                className="h_100"
                                alt="EXC"
                              />
                            </IconButton>
                          )}
                      </td>
                    </tr>
                  )
                )}
              </tbody>
            </Table>
          </div>
        ) : (
          <div className="pay-data text-center">
            <h3 className=" font_m font_yellow fw_6">No Payment Details</h3>
          </div>
        )}
      </div>

      <InvoiceDetailModal
        showModal={showContractDetailModal}
        detail={detail}
        onClose={() => {
          setShowContractDetailModal(false);
        }}
        currency={partyInvoice?.invoice?.currency}
      />

      {/* Change Cheque Status */}
      <ChecqueStatusChangeModal
        showModal={updateCheque}
        onClose={() => setUpdateCheque(false)}
        onSubmit={onChequeStatusChange}
        detail={detail}
        currency={partyInvoice?.invoice?.currency}
      />

      {/* File viewer modal */}
      <FileViewerModal
        show={showFileViewer}
        type={file.type}
        file={file.url}
        onClose={() => {
          setShowFileViewer(false);
          setFile({});
        }}
      />
    </div>
  );
};
/* Component ends */

export default PartiesInvoiceDetail;
