/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import { useDispatch } from "react-redux";
import GLOBAL from "../../../../setup/constants/global";
import { useNavigate } from "react-router";
/* Import configuration ends */

/* Import redux slices component starts */
import {
  acceptUnitKey,
  rejectUnitKey,
} from "../../../../setup/store/slices/tenantSlice";
import {
  showErrorAlert,
  showSuccessAlert,
} from "../../../../setup/store/slices/globalAlertSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
import { SuccessIconOutlinedBlue } from "../../../../setup/constants/images";
/* Import image and SVG ends */

/* Import local pages and component starts */
import CustomButton from "../../../ui/button/CustomButton/CustomButton";
import TextButton from "../../../ui/button/TextButton/TextButton";
import OnboardKeyRejectionModal from "../OnboardKeyRejectionModal/OnboardKeyRejectionModal";
import FileGrid from "./sub-components/FileGrid";
/* Import local pages and component ends */

// Styles
import "./style.scss";
import NoteModal from "../../Maintenance/NoteModal/NoteModal";

/* Component starts */
const UnitKeys = (props) => {
  /* Props destructuring starts */
  const { application_id, application } = props;

  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [rejectionModal, setRejectionModal] = useState(false);
  const [openNote, setOpenNote] = useState(false);
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const dispatch = useDispatch();
  const navigate = useNavigate();
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  // On rejecting key collection with note
  const rejectWithNote = async ({ reason, document }) => {
    // Creating or collecting payload data to be sent
    const payload = {
      application_id,
      reason,
      document,
    };
    console.log("Payload", payload);

    // Backend response. Try, Catch
    try {
      // const result = {};
      const result = await dispatch(rejectUnitKey(payload));
      setRejectionModal(false);
      console.log("Response", result);

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          dispatch(
            showErrorAlert({
              title: "Error!",
              description: result.payload,
            })
          );
          break;

        case GLOBAL.FULFILLED:
          dispatch(
            showSuccessAlert({
              title: "Success!",
              description: result.payload.message,
            })
          );
          navigate("/tenant/home");
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };

  const respondAction = (value) => {
    // On re-assigning MT by FM
    acceptKeys(value);
  };

  // On accepting keys
  const acceptKeys = async (comment) => {
    // Creating or collecting payload data to be sent
    const payload = {
      application_id,
      note: comment,
    };
    console.log("Payload", payload);

    // Backend response. Try, Catch
    try {
      const result = await dispatch(acceptUnitKey(payload));
      console.log("Response", result);

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          dispatch(
            showErrorAlert({
              title: "Error!",
              description: result.payload,
            })
          );
          break;

        case GLOBAL.FULFILLED:
          dispatch(
            showSuccessAlert({
              title: "Success!",
              description: result.payload.message,
            })
          );
          navigate("/tenant/home");
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="unitKey-module-wrapper container_sm">
      <h3 className="font_xxl fw_5 text-center mb-3 heading">Key Collection</h3>

      <div className="img-wrapper">
        <img src={SuccessIconOutlinedBlue} alt="Success" />
        <p className="font_l fw_4">
          {/* I have received unit keys and have inspected <br />
          the Unit, It is in satisfactory condition. */}
          {/* We are pleased to inform you that the unit has been inspected and
          found to be in satisfactory condition.
          <br /> Keys may be picked up at the office. */}
          This confirms that I have reviewed the unit and found it to be in
          satisfactory condition. I am happy to accept the keys and proceed with
          the move-in process.
        </p>
      </div>

      <div className="unit-wrapper">
        {application?.rejection_reason ? (
          <div className="rejection-section">
            <h3 className="rejection-heading font_m font_yellow fw_6 text-center">
              Note : Maintenance review has been rejected by{" "}
              {application?.application?.unit?.property_id?.mgmt_model === 2
                ? "Property Manager"
                : "Owner"}
            </h3>
            <p className="rejection-reason font_xs fw_5">
              Rejection Reason :{" "}
              <span className="font_m fw_6 font_red">
                {application?.rejection_reason}
              </span>
            </p>
          </div>
        ) : (
          <TextButton
            type="button"
            onClick={() => setRejectionModal(true)}
            className="font_m fw_6 font_red"
          >
            Reject
          </TextButton>
        )}

        <hr />

        {application?.key_docs?.length > 0 && (
          <>
            <h3 className="attachments-heading font_blue font_l fw_6 text-center">
              Attachments
            </h3>
            <FileGrid application={application} />
          </>
        )}
      </div>

      <div className="button-wrapper text-center">
        <CustomButton
          //onClick={acceptKeys}
          onClick={() => {
            setOpenNote(true);
          }}
          size="l"
        >
          Accept
        </CustomButton>

        {/* <>
          <div className="rejection-section">
            <h3 className="rejection-heading font_m font_red fw_6 text-center">
              Maintenance review has been rejected by Owner
            </h3>
            <p className="rejection-reason font_xs font_yellow fw_5">
              Rejection Reason : {application?.rejection_reason}
            </p>
          </div>

          <TextButton
            type="button"
            onClick={() => setRejectionModal(true)}
            className="font_m fw_6 font_red"
          >
            Reject
          </TextButton>
        </> */}
      </div>

      {/* Modals */}
      {/* Data Rejection Modal */}
      {/* <ApplicationDataRejectionModal
        showModal={rejectionModal}
        onCancel={() => setRejectionModal(false)}
        onRejectWithNote={rejectWithNote}
        title="Reject Key"
      /> */}

      {/* Adding Note Modal */}
      <NoteModal
        showModal={openNote}
        title="Note"
        btnText="Accept"
        onCancel={() => setOpenNote(false)}
        actionWithNote={respondAction}
      />

      <OnboardKeyRejectionModal
        showModal={rejectionModal}
        onClose={() => setRejectionModal(false)}
        onReject={rejectWithNote}
      />
    </div>
  );
};
/* Component ends */

export default UnitKeys;
