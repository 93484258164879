/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import { joinAddress } from "../../../setup/utils/global-helper";
/* Import configuration ends */

/* Import redux slices component starts */
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
import {
  LocationPinFilledGrey,
  EyeFilledBlue,
} from "../../../setup/constants/images";
/* Import image and SVG ends */

/* Import local pages and component starts */
import IconButton from "../../../components/ui/button/IconButton/IconButton";
import FileViewerModal from "../../../components/ui/modal/FileViewerModal/FileViewerModal";
/* Import local pages and component ends */

/* Component starts */
const Details = (props) => {
  /* Props destructuring starts */
  const { detail } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [selectedOccupancyIdx, setSelectedOccupancyIdx] = useState(0);
  const [address, setAddress] = useState("");
  const [showFileViewer, setShowFileViewer] = useState(false);
  const [file, setFile] = useState({});
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const owner =
    detail?.owner && detail?.owner?.first_name && detail?.owner?.last_name
      ? `${detail?.owner?.first_name} ${detail?.owner?.last_name}`
      : "Not Assigned";
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  /* Component function definition ends */

  /* Component useEffect starts */
  useEffect(() => {
    if (detail?.is_part_community === "No") {
      let newAddr = joinAddress({
        address1: detail.address_line1,
        address2: detail.address_line2,
        city: detail.city,
        state: detail.state,
        country: detail.country,
      });
      setAddress(newAddr);
    } else {
      let newAddr = joinAddress({
        address1: detail?.community[0]?.address_line1,
        address2: detail?.community[0]?.address_line2,
        city: detail?.community[0]?.city,
        state: detail?.community[0]?.state,
        country: detail?.community[0]?.country,
      });
      setAddress(newAddr);
    }
  }, [detail]);
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="details">
      {/*  Propert Name */}
      <p className="name font_l fw_5">{detail?.property_name}</p>
      {/* Property Location */}
      <p className="address font_m font_grey fw_5">
        <img
          src={LocationPinFilledGrey}
          className="v_middle location"
          alt="Location Icon"
        />{" "}
        <span className="font_grey font_m v_middle">{address}</span>
      </p>
      {/* Units Assigned and Owner Name */}
      <div className="units-owner">
        {/* Units */}
        <p className="units font_grey font_m">
          No. of units :{" "}
          <span>{detail?.units.length || "No units assigned"}</span>
        </p>

        {/* Owner */}
        <p className="owner font_grey font_m">
          Owner : <span>{owner}</span>
        </p>
      </div>
      {/* Description */}
      <div className="description">
        <h3 className="font_m">Description</h3>
        <p className="font_grey font_m">
          {detail?.description && detail?.description}
          {!detail?.description && "Not Added"}
        </p>
      </div>
      {/* Other details */}
      <div className="other-detail">
        <h3 className="font_m w_100 grid_full">Property Details</h3>

        {/* Property Type */}
        <p className="font_m font_grey">
          Type of Property : <span>{detail?.property_type[0].name}</span>
        </p>

        {/* Property Title Deed */}
        {detail?.property_title_deed?.url && (
          <p className="font_m font_grey">
            <span className="font_m font_grey v_middle">
              Property Title Deed :{" "}
            </span>
            {detail?.property_title_deed && (
              <IconButton
                className="eye v_middle"
                onClick={() => {
                  setFile(detail?.property_title_deed);
                  setShowFileViewer(true);
                }}
              >
                <img src={EyeFilledBlue} className="h_100 d_block" alt="" />
              </IconButton>
            )}
          </p>
        )}

        {/* Property Permit */}
        {detail?.property_permit && (
          <p className="font_m font_grey">
            Property Permit : <span>{detail?.property_permit}</span>
          </p>
        )}

        {/* Site Plan */}
        {detail?.affection_plan && (
          <p className="font_m font_grey">
            <span className="font_m font_grey v_middle">Site Plan :</span>{" "}
            {detail?.affection_plan && (
              <IconButton
                className="eye v_middle"
                onClick={() => {
                  setFile(detail?.affection_plan);
                  setShowFileViewer(true);
                }}
              >
                <img src={EyeFilledBlue} className="h_100 d_block" alt="" />
              </IconButton>
            )}
          </p>
        )}

        {/* Plot Number */}
        {detail?.plot_number && (
          <p className="font_m font_grey">
            Plot Number : <span>{detail?.plot_number}</span>
          </p>
        )}

        {/* Electricity ID */}
        {detail?.electricity_id && (
          <p className="font_m font_grey">
            Electricity ID : <span>{detail?.electricity_id}</span>
          </p>
        )}

        {/* Gas ID */}
        {detail?.gas_id && (
          <p className="font_m font_grey">
            GAS ID : <span>{detail?.gas_id}</span>
          </p>
        )}

        {/* Water ID */}
        {detail?.water_id && (
          <p className="font_m font_grey">
            Water ID : <span>{detail?.water_id}</span>
          </p>
        )}

        {/* Cooling ID */}
        {detail?.colling_id && (
          <p className="font_m font_grey">
            Cooling ID : <span>{detail?.colling_id}</span>
          </p>
        )}

        {/* Heating ID */}
        {detail?.heating_id && (
          <p className="font_m font_grey">
            Heating ID : <span>{detail?.heating_id}</span>
          </p>
        )}

        {/* Sewerrage ID */}
        {detail?.sewerage_id && (
          <p className="font_m font_grey">
            Sewerage ID : <span>{detail?.sewerage_id}</span>
          </p>
        )}
      </div>

      {/* Modal */}
      {/* File viewer modal */}
      <FileViewerModal
        show={showFileViewer}
        type={file.type}
        file={file.url}
        onClose={() => {
          setShowFileViewer(false);
          setFile({});
        }}
      />
    </div>
  );
};
/* Component ends */

export default Details;
