import GLOBAL from "../../../../setup/constants/global";

// Conditions for disabled status switch according to status
export const disabledStatusSwitch = (status) => {
  return (
    status === GLOBAL.USER_STATUS.PENDING_APPROVAL ||
    status === GLOBAL.USER_STATUS.PENDING_INVITATION ||
    status === GLOBAL.USER_STATUS.REJECTED ||
    status === GLOBAL.USER_STATUS.DEACTIVATED ||
    status === GLOBAL.USER_STATUS.EXPIRED ||
    status === GLOBAL.USER_STATUS.CANCELLED
  );
};

export const canResendInvitation = (status, item, loggedInUserRole) => {
  return (
    (loggedInUserRole === GLOBAL.USER_ROLE.CLIENT ||
      loggedInUserRole === GLOBAL.USER_ROLE.OWNER) &&
    status === GLOBAL.USER_STATUS.PENDING_INVITATION
  );
};

export const canCancelInvitation = (status, item, loggedInUserRole) => {
  return (
    (loggedInUserRole === GLOBAL.USER_ROLE.CLIENT ||
      loggedInUserRole === GLOBAL.USER_ROLE.OWNER) &&
    status === GLOBAL.USER_STATUS.PENDING_INVITATION
  );
};
