/**
 *
 */

import React, { useState } from "react";

/* Import configuration starts */
import { useDispatch } from "react-redux";
/* Import configuration ends */

/* Import redux slices component starts */
import { fetchAdvertisementForTenantById } from "../../../../../setup/store/slices/advertisementSlice";
import { showErrorAlert } from "../../../../../setup/store/slices/globalAlertSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
import GLOBAL from "../../../../../setup/constants/global";
import { DefaultAdvertisment } from "../../../../../setup/constants/images";
/* Import image and SVG ends */

/* Import local pages and component starts */
import AdvertisementViewModal from "../../../../ui/modal/AdvertisementViewModal/AdvertisementViewModal";

/* Import local pages and component ends */

/* Component starts */
const SliderItem2 = (props) => {
  /* Props destructuring starts */
  const { item, index } = props;

  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [openAdvertisement, setOpenAdvertisement] = useState(false);
  const [data, setData] = useState();

  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const dispatch = useDispatch();
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  const handleView = (item) => {
    handleFetch(item?._id);
  };

  const handleFetch = async (id) => {
    // Backend response. Try, Catch
    try {
      const result = await dispatch(fetchAdvertisementForTenantById(id));
      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          dispatch(
            showErrorAlert({
              title: "Error!",
              description: result.payload,
            })
          );
          break;
        case GLOBAL.FULFILLED:
          setOpenAdvertisement(!openAdvertisement);
          setData(result?.payload);
          break;
        default:
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };

  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="slider2-wrapper">
      <div className="image-wrapper w_100" key={index}>
        <img
          src={item?.banner ? item?.banner?.url : DefaultAdvertisment}
          alt="slider"
          onClick={() => handleView(item)}
        />
      </div>

      <AdvertisementViewModal
        showModal={openAdvertisement}
        details={data}
        onClose={() => {
          setOpenAdvertisement(false);
        }}
      />
    </div>
  );
};
/* Component ends */

export default SliderItem2;
