/**
 *
 */

import React, { useState, useEffect, useRef } from "react";

/* Import configuration starts */
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import { useSelector } from "react-redux";
/* Import configuration ends */

/* Import redux slices component starts */
import { getUserTheme } from "../../../../setup/store/slices/authSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
/* Import local pages and component ends */

import "./style.scss";

/* Component starts */
const NewHTMLTextEditor = (props) => {
  /* Props destructuring starts */
  const { descVal, label, setDescVal, setIsEmptyContent, setIsEditorFocused } =
    props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  // const [isEditorFocused, setIsEditorFocused] = useState(false); // State to track editor focus
  const [editorContent, setEditorContent] = useState(descVal?.value || "");
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const userTheme = useSelector(getUserTheme);
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */

  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  // const handleEditorChange = (value) => {
  //   const isEmpty =
  //     !value ||
  //     value?.trim() === "" ||
  //     !value?.trim() ||
  //     value?.trim() === "<p><br></p>";
  //   console.log(isEmpty, "CHECK");

  //   // const isEmpty = !value || value.trim() === "" || value.trim() === "<p><br></p>";
  //   setIsEmptyContent(isEmpty);
  //   setEditorContent(value);

  //   if (isEmpty) {
  //     setIsEmptyContent(isEmpty);
  //   } else {
  //     setIsEmptyContent(isEmpty);
  //   }
  //   // setDescVal({ value });
  //   setEditorContent(value);
  // };

  // Handler for editor focus

  const handleEditorChange = (value) => {
    const isEmpty =
      !value || value.trim() === "" || value.trim() === "<p><br></p>";
    setIsEmptyContent(isEmpty);

    // Avoid unnecessary state updates
    if (value !== editorContent) {
      setEditorContent(value);
      setDescVal({ value });
    }
  };

  const handleFocus = () => {
    setIsEditorFocused(true);
  };

  // Handler for editor blur
  const handleBlur = () => {
    setIsEditorFocused(false);
  };

  /* Component function definition ends */

  /* Component useEffect starts */
  // Update editor content when descVal changes (avoid infinite loop)
  useEffect(() => {
    if (descVal?.value !== undefined && descVal.value !== editorContent) {
      setEditorContent(descVal.value);
    }
  }, [descVal]);
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <>
      <div
        className={`text-main-wrapper
    ${
      userTheme === "light" || userTheme === "automatic"
        ? "bg_lightThemeInput font_lightTheme"
        : "bg_black"
    }
    `}
      >
        <div className="lable-name">
          <label className="font_l fw_6 font_grey mt-3 mx-3">
            {label}
            <span className="font_m font_red px-1">*</span>
          </label>
        </div>
        <div className="text-editor font_black">
          <SunEditor
            // defaultValue={
            //   descVal !== undefined ? editorContent || descVal?.value : ""
            // }

            setContents={editorContent}
            onChange={handleEditorChange}
            onFocus={handleFocus}
            onBlur={handleBlur}
            setOptions={{
              buttonList: [
                [
                  "undo",
                  "redo",
                  "font",
                  "fontSize",
                  "formatBlock",
                  "paragraphStyle",
                  "blockquote",
                  "bold",
                  "underline",
                  "italic",
                  "strike",
                  "subscript",
                  "superscript",
                  "fontColor",
                  "hiliteColor",
                  "textStyle",
                  "removeFormat",
                  "outdent",
                  "indent",
                  "align",
                  "horizontalRule",
                  "list",
                  "lineHeight",
                  "table",
                  "link",
                  "image",
                  "video",
                  "audio" /** 'math', */, // You must add the 'katex' library at options to use the 'math' plugin.
                  /** 'imageGallery', */ // You must add the "imageGalleryUrl".
                  "fullScreen",
                  "showBlocks",
                  "codeView",
                  "preview",
                  "print",
                  "save",
                  "template",
                  /** 'dir', 'dir_ltr', 'dir_rtl' */ // "dir": Toggle text direction, "dir_ltr": Right to Left, "dir_rtl": Left to Right
                ],
              ],
            }}
            setDefaultStyle={`height: 400  ${
              userTheme === "light" || userTheme === "automatic"
                ? "bg_lightThemeInput font_lightTheme"
                : "bg_black"
            }`}
          />
        </div>
        {/* <p className="font_red fw_6 font_m">
          {isEditorFocused && "Please finish editing before submitting!"}
        </p> */}
      </div>
    </>
  );
};
/* Component ends */

export default NewHTMLTextEditor;
