import {
  validEmail,
  validateAtleastOneNumber,
  validateAtleastOneSpecialChar,
  containsSpecialChar,
} from "../../../../setup/utils/validations";

// Initial Form values
export const observerInitFormValue = {
  first_name: "",
  last_name: "",
  mobile: "",
  email: "",
};

// Validations
export const observerValidation = (values, customPhoneNum) => {
  const errorFields = {};

  /* Validation for first name starts */
  if (values.first_name === "") {
    errorFields.first_name = "First Name is required";
  }
  if (values.first_name && validateAtleastOneNumber(values.first_name)) {
    errorFields.first_name = "First Name Cannot Contains Numbers";
  }
  if (values.first_name && containsSpecialChar(values.first_name)) {
    errorFields.first_name = "First Name Cannot Contains Special Characters";
  }
  /* Validation for first name ends */
  /* *******************************************************************************  */
  /* Validation for last name starts */
  if (values.last_name === "") {
    errorFields.last_name = "Last Name is required";
  }
  if (values.last_name && validateAtleastOneNumber(values.last_name)) {
    errorFields.last_name = "Last Name Cannot Contains Numbers";
  }
  if (values.last_name && containsSpecialChar(values.last_name)) {
    errorFields.last_name = "Last Name Cannot Contains Special Characters";
  }
  /* Validation for last name ends */
  /* *******************************************************************************  */
  /* Validation for email starts */
  if (values.email === "") {
    errorFields.email = "Email is required";
  }
  if (values.email && !validEmail(values.email)) {
    errorFields.email = "Email is not valid";
  }
  /* Validation for email ends */
  /* *******************************************************************************  */
  /* Validation for phone number starts */
  if (values.mobile === "") {
    errorFields.mobile = "Phone Number is required";
  }
  if (customPhoneNum.current.num.length < 8) {
    errorFields.mobile = "Phone number is not valid";
  }
  /* Validation for phone number ends */
  /* *******************************************************************************  */
  /* Validation for Observer Photo starts */
  /* Validation for Observer Photo ends */

  return errorFields;
};

export const formReset = (
  setExistingObserver,
  setInitialFormValues,
  setMemberSelected,
  customPhoneNum
) => {
  customPhoneNum.current = { code: "", num: "" };
  setExistingObserver(false);
  setInitialFormValues(observerInitFormValue);
  setMemberSelected(false);
};
