/**
 *
 */

import React, { useEffect, useState } from "react";

/* Import configuration starts */
import { useNavigate } from "react-router-dom";
import { generateNavigationTenant, generateNavigation } from "./helper";
import { useDispatch } from "react-redux";

/* Import configuration ends */

/* Import redux slices component starts */
import { fetchUser } from "../../../../setup/store/slices/authSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import NotificationItemDetailModal from "../../../ui/modal/NotificationItemDetailModal/NotificationItemDetailModal";
import Item from "./sub-component/Item";
import Pagination from "../../../ui/other/Pagination/Pagination";
import BreadCrumb from "../../../ui/other/BreadCrumb/BreadCrumb";
/* Import local pages and component ends */

import "./style.scss";

/* Component starts */
const NotificationList = (props) => {
  /* Props destructuring starts */
  const {
    notifications,
    fetchNotifs,
    isTenant,
    currentPage,
    setCurrentPage,
    totalCount,
  } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [showDetail, setShowDetail] = useState();
  const [detail, setDetail] = useState(null);
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const navigate = useNavigate();
  // const user = useSelector(getUser);
  const dispatch = useDispatch();
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */

  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  const handleNavigate = (item) => {
    // if (!item?.seen) {
    //   handleSeen(item);
    // }

    if (isTenant) {
      navigate(generateNavigationTenant(item));
    } else {
      navigate(generateNavigation(item));
    }
  };

  const handleClose = () => {
    setShowDetail(false);
    if (!detail?.seen) {
      window.location.reload();
    }
  };

  /* Component function definition ends */

  /* Component useEffect starts */
  useEffect(() => {
    (async () => {
      try {
        await dispatch(fetchUser());
      } catch (e) {}
    })();
  }, []);
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <>
      {!isTenant && <BreadCrumb type="notification_page" />}
      <div className="notification-list-wrapper container_sm">
        {notifications?.map((item, index) => (
          <>
            <Item
              fetchNotifs={fetchNotifs}
              key={index}
              item={item}
              setDetail={setDetail}
              setShowDetail={setShowDetail}
              isTenant={isTenant}
            />

            {/* User */}
            {/* {user?._id === item?.user && (
              <Item
                fetchNotifs={fetchNotifs}
                key={index}
                item={item}
                setDetail={setDetail}
                setShowDetail={setShowDetail}
              />
            )} */}

            {/* Tenant */}
            {/* {user?._id === item?.tenant && (
              <Item
                fetchNotifs={fetchNotifs}
                key={index}
                item={item}
                setDetail={setDetail}
                setShowDetail={setShowDetail}
              />
            )} */}
          </>
        ))}

        {notifications?.length === 0 && (
          <div className=" text-center">
            <h3 className=" font_m font_yellow fw_6">No Data</h3>
          </div>
        )}

        {notifications?.length > 0 && (
          <Pagination
            itemsPerPage="10"
            totalItems={totalCount}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        )}

        <NotificationItemDetailModal
          showModal={showDetail}
          item={detail}
          handleNavigate={handleNavigate}
          onClose={handleClose}
        />
      </div>
    </>
  );
};
/* Component ends */

export default NotificationList;
