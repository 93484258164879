/**
 *
 */

import React from "react";

/* Import configuration starts */
/* Import configuration ends */

/* Import redux slices component starts */
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import MUI component starts */
/* Import MUI component ends */

/* Import image and SVG starts */
import {
  LocationPinFilledGrey,
  BlankImagePlaceholder,
} from "../../../../setup/constants/images";
/* Import image and SVG ends */

/* Import local pages and component starts */
import Image from "../../../ui/other/Image/Image";
/* Import local pages and component ends */

import "./style.scss";

/* Component starts */
const CommunitySliderItem = (props) => {
  /* Props destructuring starts */
  const { info, onClick } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const name = info?.community_name;
  const address = info?.address;
  const image = info?.image.url || BlankImagePlaceholder;
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="community-item-component-wrapper" onClick={onClick}>
      <Image
        className="image"
        src={image}
        objectFit="cover"
        objectPosition="center center"
        alt="Community Image"
      />

      <div className="details">
        <p className="name font_s fw_6">{name}</p>
        <p className="address font_xs">
          <img
            className="location-pin"
            src={LocationPinFilledGrey}
            alt="Location Pin"
          />
          <span className="font_grey font_xs">{address}</span>
        </p>
      </div>
    </div>
  );
};
/* Component ends */

export default CommunitySliderItem;
