/**
 * @auther Abdul Ahad <abdulahad.ss@smartdatainc.net>
 * Slice to handle property
 */

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios, { axiosInstance } from "../axios";
import { PLATFORM } from "../../platformUtil";
import { API_URL } from "../../config/devKeys";
import {
  setLoading,
  setShowUploadProgress,
  setUploadProgress,
} from "./unpersistedSlice";
import { setSelectedProperty } from "./selectionSlice";
import { fetchCommunities } from "./communitySlice";

const initialState = {
  properties: [],
  propertyTypes: [],
  communityProperties: [],
  propertyTenants: [],
  moveOutTenants: [],
  propertySettings: {},
  propertyDetail: {},
  existingTenants: [],
  cancelledTenants: [],
  transitionedTenants: [],
  newTenants: [],
  oldTenants: [],
  userDetails: null,
  activeMembers: [],
  activeManagers: [],
};

export const fetchPropertyDetailByID = createAsyncThunk(
  "property/detail_by_id",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const { property_id } = payload;
      const { data } = await axios.post(
        API_URL + "/property/get_property",
        { id: property_id },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      dispatch(setLoading(false));
      if (data.success) {
        return data.data;
      } else {
        rejectWithValue(data.error);
      }
    } catch (error) {
      return rejectWithValue(error?.response);
    }
  }
);

export const fetchProperties = createAsyncThunk(
  // Done
  "property/list",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const { data } = await axios.get(API_URL + "/property/list", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("user_token")}`,
        },
      });
      dispatch(setLoading(false));
      if (data.success) {
        if (getState().selection.property != null) {
          const selectedProperty = data.data.filter((property) => {
            return property._id == getState().selection.property._id;
          })[0];
          await dispatch(setSelectedProperty(selectedProperty));
        }
        return data.data;
      } else {
        rejectWithValue(data.error);
      }
    } catch (error) {
      return rejectWithValue(error?.response);
    }
  }
);

export const fetchMoveoutTenants = createAsyncThunk(
  "property/tenants/moveout",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const { data } = await axios.post(
        `${API_URL}/contract_renewal/get_move_out_request`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      if (data.success) {
        return data.data;
      } else {
        rejectWithValue(data.error);
      }
    } catch (error) {
      return rejectWithValue(error?.response);
    }
  }
);

export const fetchPropertyTenants = createAsyncThunk(
  "property/tenants",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const { property_id } = payload;
      const { data } = await axios.get(
        `${API_URL}/property/${property_id}/tenants`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      if (data.success) {
        return data.data.tenants;
      } else {
        rejectWithValue(data.error);
      }
    } catch (error) {
      return rejectWithValue(error?.response);
    }
  }
);

export const fetchPropertySettings = createAsyncThunk(
  "property/get_settings",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const { property_id } = payload;
      const { data } = await axios.get(
        `${API_URL}/property/get_settings?property_id=${property_id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      if (data.success) {
        return data.data.settings;
      } else {
        rejectWithValue(data.error);
      }
    } catch (error) {
      return rejectWithValue(error?.response);
    }
  }
);

export const updatePropertySettings = createAsyncThunk(
  "property/update_settings",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const { property_id } = payload;
      dispatch(setLoading(true));
      const { data } = await axios.patch(
        `${API_URL}/property/settings?property_id=${property_id}`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      if (data.success) {
        await dispatch(fetchPropertySettings({ property_id }));
        dispatch(setLoading(false));
        return data.data;
      } else {
        dispatch(setLoading(false));
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return error?.response;
    }
  }
);

export const updatePropertyLogo = createAsyncThunk(
  "property/update_logo",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const { property_id } = payload;
      dispatch(setLoading(true));
      const formData = new FormData();
      Object.keys(payload).map((key) => {
        if (key == "logo") {
          if (PLATFORM !== "web") {
            formData.append("logo", {
              name: payload.logo.name,
              type: payload.logo.type,
              uri:
                PLATFORM === "android"
                  ? payload.logo.uri
                  : payload.logo.uri.replace("file://", ""),
            });
          } else {
            formData.append("logo", payload[key], payload[key].name);
          }
        } else {
          formData.append(key, payload[key]);
        }
      });
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("user_token")}`,
      };
      if (PLATFORM !== "web") {
        headers["Content-Type"] = "multipart/form-data";
      }
      // const response = await fetch(API_URL + "/property/update_logo", {
      //   method: "POST",
      //   headers: headers,
      //   body: formData,
      // });
      // const data = await response.json();
      dispatch(setShowUploadProgress(true));
      const config = {
        onUploadProgress: ({ loaded, total }) => {
          const progress = (loaded / total) * 100;
          dispatch(setUploadProgress(progress));
        },
        method: "POST",
        url: "/property/update_logo",
        headers,
        transformRequest: (data, headers) => {
          return formData;
        },
        data: formData,
        responseType: "json",
      };

      // const data = await response.json();

      const { data } = await axiosInstance.request(config);

      if (data.success) {
        await dispatch(fetchPropertySettings({ property_id }));
        dispatch(setLoading(false));
        return data.data;
      } else {
        dispatch(setLoading(false));
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error.message);
    }
  }
);

export const fetchPropertyTypes = createAsyncThunk(
  "property_type/list",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const { data } = await axios.get(API_URL + "/property_type/list", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("user_token")}`,
        },
      });
      return data.data;
    } catch (error) {
      return rejectWithValue(error?.response);
    }
  }
);

export const fetchCommunityProperties = createAsyncThunk(
  // Done
  "property/get_community_properties",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const community_id = payload
        ? payload
        : getState().selection.community._id;
      const { data } = await axios.get(
        API_URL +
          "/property/get_community_properties?community_id=" +
          community_id,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      dispatch(setLoading(false));
      if (data.success) {
        if (getState().selection.property != null) {
          const selectedProperty = data.data.filter((property) => {
            return property._id == getState().selection.property._id;
          })[0];
          await dispatch(setSelectedProperty(selectedProperty));
        }
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      return rejectWithValue(error?.response);
    }
  }
);

export const addProperty = createAsyncThunk(
  "property/add",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const { is_part_community, community_id } = payload;
      // dispatch(setLoading(true));
      const { data } = await axios.post(API_URL + "/property/add", payload, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("user_token")}`,
        },
      });
      dispatch(setLoading(false));
      if (data.success) {
        if (is_part_community === "Yes") {
          await dispatch(fetchCommunityProperties(community_id)); // Done
        } else {
          await dispatch(fetchProperties()); // Done
        }
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return error?.response;
    }
  }
);

export const updateProperty = createAsyncThunk(
  "property/update",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const formData = new FormData();
      Object.keys(payload).map((key) => {
        if (key == "property_title_deed" || key == "affection_plan") {
          if (PLATFORM !== "web") {
            formData.append(key, {
              name: payload[key].name,
              type: payload[key].type,
              uri:
                PLATFORM === "android"
                  ? payload[key].uri
                  : payload[key].uri.replace("file://", ""),
            });
          } else {
            formData.append(key, payload[key], payload[key].name);
          }
        } else {
          formData.append(key, payload[key]);
        }
      });
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("user_token")}`,
      };
      if (PLATFORM !== "web") {
        headers["Content-Type"] = "multipart/form-data";
      }
      // const response = await fetch(API_URL + "/property/update", {
      //   method: "POST",
      //   headers: headers,
      //   body: formData,
      // });
      // const data = await response.json();
      dispatch(setShowUploadProgress(true));
      const config = {
        onUploadProgress: ({ loaded, total }) => {
          const progress = (loaded / total) * 100;
          dispatch(setUploadProgress(progress));
        },
        method: "POST",
        url: "/property/update",
        headers,
        transformRequest: (data, headers) => {
          return formData;
        },
        data: formData,
        responseType: "json",
      };

      // const data = await response.json();

      const { data } = await axiosInstance.request(config);

      if (data.success) {
        await dispatch(fetchPropertyDetailByID({ property_id: payload?.id }));
        dispatch(setLoading(false));
        return data.data;
      } else {
        dispatch(setLoading(false));
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error.message);
    }
  }
);

export const addMgmtModel = createAsyncThunk(
  "property/add_mgmt_model",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const { property_id } = payload;
      dispatch(setLoading(true));
      const { data } = await axios.patch(
        API_URL + "/property/add_mgmt_model?id=" + payload.property_id,
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      if (data.success) {
        await dispatch(fetchPropertyDetailByID({ property_id }));
        dispatch(setLoading(false));
        return data.data;
      } else {
        dispatch(setLoading(false));
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return error?.response;
    }
  }
);

export const setDefaultImage = createAsyncThunk(
  "property/set_default_image",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const { property_id } = payload;
      dispatch(setLoading(true));
      const { data } = await axios.patch(
        API_URL + "/property/set_default_image",
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      if (data.success) {
        await dispatch(fetchPropertyDetailByID({ property_id }));
        dispatch(setLoading(false));
        return data.data;
      } else {
        dispatch(setLoading(false));
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return error?.response;
    }
  }
);

export const uploadPropertyImage = createAsyncThunk(
  "property/image",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const { property_id } = payload;
      dispatch(setLoading(true));
      const formData = new FormData();
      Object.keys(payload).map((key) => {
        if (key == "image") {
          if (PLATFORM !== "web") {
            formData.append("image", {
              name: payload.image.name,
              type: payload.image.type,
              uri:
                PLATFORM === "android"
                  ? payload.image.uri
                  : payload.image.uri.replace("file://", ""),
            });
          } else {
            formData.append("image", payload[key], payload[key].name);
          }
        } else {
          formData.append(key, payload[key]);
        }
      });
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("user_token")}`,
      };
      if (PLATFORM !== "web") {
        headers["Content-Type"] = "multipart/form-data";
      }
      // const response = await fetch(API_URL + "/property/image", {
      //   method: "POST",
      //   headers: headers,
      //   body: formData,
      // });
      // const data = await response.json();
      dispatch(setShowUploadProgress(true));
      const config = {
        onUploadProgress: ({ loaded, total }) => {
          const progress = (loaded / total) * 100;
          dispatch(setUploadProgress(progress));
        },
        method: "POST",
        url: "/property/image",
        headers,
        transformRequest: (data, headers) => {
          return formData;
        },
        data: formData,
        responseType: "json",
      };

      // const data = await response.json();

      const { data } = await axiosInstance.request(config);

      if (data.success) {
        await dispatch(fetchPropertyDetailByID({ property_id }));
        dispatch(setLoading(false));
        return data.data;
      } else {
        dispatch(setLoading(false));
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error.message);
    }
  }
);

export const deleteProperty = createAsyncThunk(
  "property/delete",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const { is_part_community, community_id } = payload;
      const { data } = await axios.post(API_URL + "/property/delete", payload, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("user_token")}`,
        },
      });
      dispatch(setLoading(false));
      if (data.success) {
        if (is_part_community === "No") {
          await dispatch(fetchProperties()); // Done
        } else {
          dispatch(setLoading(true));
          await dispatch(fetchCommunityProperties(community_id)); // Done
        }
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const deletePropertyImage = createAsyncThunk(
  "property/delete_property_image",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const { property_id } = payload;
      dispatch(setLoading(true));
      const { data } = await axios.delete(
        API_URL + "/property/delete_property_image",
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
          data: payload,
        }
      );
      if (data.success) {
        await dispatch(fetchPropertyDetailByID({ property_id }));
        dispatch(setLoading(false));
        return data.data;
      } else {
        dispatch(setLoading(false));
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

// FETCH EXISTING TENANTS DETAILS
export const fetchExistingTenantsByProperty = createAsyncThunk(
  "property/tenants/existing",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const { property_id, page } = payload;

      const { data } = await axios.get(
        `${API_URL}/property/${property_id}/tenants/existing?page=${page}`,
        // payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );

      dispatch(setLoading(false));
      if (data.success) {
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

// FETCH TRANSITIONED TENANTS DETAILS
export const fetchTransitionedTenantsByProperty = createAsyncThunk(
  "property/tenants/transitioned",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const { property_id, page } = payload;
      const { data } = await axios.post(
        `${API_URL}/property/${property_id}/tenants/transitioned`,
        // `http://localhost:3000/property/${property_id}/tenants/existing?page=${page}`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      dispatch(setLoading(false));
      console.log(data, "[DATA]");
      if (data.success) {
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

// FETCH NEW TENANTS DETAILS
export const fetchNewTenantsByProperty = createAsyncThunk(
  "property/tenants/newTenants",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const { property_id, page } = payload;
      const { data } = await axios.post(
        `${API_URL}/property/${property_id}/tenants/newTenants`,
        // `http://localhost:3000/property/${property_id}/tenants/existing?page=${page}`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      dispatch(setLoading(false));

      if (data.success) {
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

// FETCH OLD TENANTS DETAILS
export const fetchOldTenantsByProperty = createAsyncThunk(
  "property/tenants/oldTenant",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const { property_id, page } = payload;
      const { data } = await axios.post(
        `${API_URL}/property/${property_id}/tenants/oldTenants`,
        // `http://localhost:3000/property/${property_id}/tenants/existing?page=${page}`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      dispatch(setLoading(false));
      if (data.success) {
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

// FETCH CANCELLED TENANTS DETAILS
export const fetchCancelledTenantsByProperty = createAsyncThunk(
  "property/tenants/cancelled",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const { property_id, page } = payload;
      const { data } = await axios.post(
        `${API_URL}/property/${property_id}/tenants/cancelled`,
        // `http://localhost:3000/property/${property_id}/tenants/existing?page=${page}`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      dispatch(setLoading(false));
      if (data.success) {
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

// FETCH USER DETAILS BY USER ID
export const fetchUserDetailByID = createAsyncThunk(
  "property/get_userById",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const { property_id } = payload;
      dispatch(setLoading(true));
      const { data } = await axios.get(
        `${API_URL}/property/get_userById?id=${payload}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      dispatch(setLoading(false));
      if (data.success) {
        return data.data;
      } else {
        rejectWithValue(data.error);
      }
    } catch (error) {
      dispatch(setLoading(true));
      return rejectWithValue(error?.response);
    }
  }
);

export const fetchPropertyTenantsBasic = createAsyncThunk(
  "property/tenants_basic",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const { data } = await axios.get(
        `${API_URL}/property/${payload?.property_id}/tenantslist`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      if (data.success) {
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      return rejectWithValue(error?.response);
    }
  }
);

// FETCH THE PROPERTY LEVEL ACTIVE TEAM MEMBERS
export const fetchPropertyActiveTeamMembers = createAsyncThunk(
  "property/get_activeTeamMembersList",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const { data } = await axios.post(
        API_URL + "/property/get_activeTeamMembersList",
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      dispatch(setLoading(false));
      if (data.success) {
        return data.data;
      } else {
        dispatch(setLoading(false));
        rejectWithValue(data.error);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

// FETCH THE PROPERTY LEVEL ACTIVE TEAM MANAGER MEMBERS
export const fetchPropertyActiveTeamManagerMembers = createAsyncThunk(
  "property/get_activeotherManagersList",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const { data } = await axios.post(
        API_URL + "/property/get_activeotherManagersList",
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      dispatch(setLoading(false));
      if (data.success) {
        return data.data;
      } else {
        dispatch(setLoading(false));
        rejectWithValue(data.error);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const propertySlice = createSlice({
  name: "property",
  initialState,
  reducers: {
    setCommunityProperties: (state, action) => {
      state.communityProperties = action.payload;
    },
  },
  extraReducers: {
    [fetchPropertyDetailByID.rejected]: (state, action) => {
      state.propertyDetail = {};
    },
    [fetchPropertyDetailByID.fulfilled]: (state, action) => {
      state.propertyDetail = action.payload;
    },
    [fetchProperties.rejected]: (state, action) => {
      state.properties = [];
    },
    [fetchProperties.fulfilled]: (state, action) => {
      state.properties = action.payload;
    },
    [fetchPropertyTenants.rejected]: (state, action) => {
      state.propertyTenants = [];
    },
    [fetchPropertyTenants.fulfilled]: (state, action) => {
      state.propertyTenants = action.payload;
    },
    [fetchMoveoutTenants.rejected]: (state, action) => {
      state.moveOutTenants = [];
    },
    [fetchMoveoutTenants.fulfilled]: (state, action) => {
      state.moveOutTenants = action.payload;
    },
    [fetchPropertyTypes.fulfilled]: (state, action) => {
      state.propertyTypes = action.payload;
    },
    [fetchCommunityProperties.fulfilled]: (state, action) => {
      state.communityProperties = action.payload;
    },
    [fetchCommunityProperties.rejected]: (state, action) => {
      state.communityProperties = [];
    },
    [fetchPropertySettings.fulfilled]: (state, action) => {
      state.propertySettings = action.payload;
    },
    [fetchExistingTenantsByProperty.fulfilled]: (state, action) => {
      state.existingTenants = action.payload;
    },
    [fetchExistingTenantsByProperty.rejected]: (state, action) => {
      state.existingTenants = [];
    },

    [fetchTransitionedTenantsByProperty.fulfilled]: (state, action) => {
      state.transitionedTenants = action.payload;
    },
    [fetchTransitionedTenantsByProperty.rejected]: (state, action) => {
      state.transitionedTenants = [];
    },

    [fetchNewTenantsByProperty.fulfilled]: (state, action) => {
      state.newTenants = action.payload;
    },
    [fetchNewTenantsByProperty.rejected]: (state, action) => {
      state.newTenants = [];
    },

    [fetchOldTenantsByProperty.fulfilled]: (state, action) => {
      state.oldTenants = action.payload;
    },
    [fetchOldTenantsByProperty.rejected]: (state, action) => {
      state.oldTenants = [];
    },

    [fetchCancelledTenantsByProperty.fulfilled]: (state, action) => {
      state.cancelledTenants = action.payload;
    },
    [fetchCancelledTenantsByProperty.rejected]: (state, action) => {
      state.cancelledTenants = [];
    },

    [fetchUserDetailByID.rejected]: (state, action) => {
      state.userDetails = null;
    },
    [fetchUserDetailByID.fulfilled]: (state, action) => {
      state.userDetails = action.payload;
    },

    [fetchPropertyActiveTeamMembers.fulfilled]: (state, action) => {
      state.activeMembers = action.payload;
    },
    [fetchPropertyActiveTeamMembers.rejected]: (state, action) => {
      state.activeMembers = [];
    },

    [fetchPropertyActiveTeamManagerMembers.fulfilled]: (state, action) => {
      state.activeManagers = action.payload;
    },
    [fetchPropertyActiveTeamManagerMembers.rejected]: (state, action) => {
      state.activeManagers = [];
    },
  },
});

export const { setCommunityProperties } = propertySlice.actions;

export const getProperties = (state) => state.property.properties;
export const getPropertyTypes = (state) => state.property.propertyTypes;
export const getCommunityProperties = (state) =>
  state.property.communityProperties;
export const getPropertyTenants = (state) => state.property.propertyTenants;
export const getPropertySettings = (state) => state.property.propertySettings;
export const getMoveOutTenants = (state) => state.property.moveOutTenants;
export const getPropertyDetail = (state) => state.property.propertyDetail;
export const getExistingTenants = (state) => state.property.existingTenants;

export const getTransitionedTenants = (state) =>
  state.property.transitionedTenants;
export const getNewTenants = (state) => state.property.newTenants;

export const getOldTenants = (state) => state.property.oldTenants;
export const getCancelledTenants = (state) => state.property.cancelledTenants;

// FETCH THE PROPERTY LEVEL ACTIVE TEAM MEMBERS
export const getActiveMembers = (state) => state.property.activeMembers;

// FETCH THE PROPERTY LEVEL ACTIVE TEAM MANAGER MEMBERS
export const getActiveManagerMembers = (state) => state.property.activeManagers;

export default propertySlice.reducer;
