/**
 *
 */

import React, { useEffect } from "react";

/* Import configuration starts */
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
/* Import configuration ends */

/* Import redux slices component starts */
import {
  fetchItemBasedOnId,
  getLostOrFoundItemDetail,
} from "../../../setup/store/slices/lostAndFoundSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import Header from "./local-component/Header";
import Report from "./local-component/Report";
import BreadCrumb from "../../../components/ui/other/BreadCrumb/BreadCrumb";
/* Import local pages and component ends */

import "./style.scss";

/* Component starts */
const ClientClaimedDetail = (props) => {
  /* Props destructuring starts */
  const {} = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  // const { state } = useLocation();
  const dispatch = useDispatch();
  const state = useSelector(getLostOrFoundItemDetail);
  const { id } = useParams();
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  /* Component function definition ends */

  /* Component useEffect starts */
  useEffect(() => {
    if (id) {
      dispatch(fetchItemBasedOnId(id));
    }
  }, [id]);
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <>
      <BreadCrumb type="client_claim_detail" />
      <div className="claimed-details-wrapper">
        <div className="header">
          <Header />
        </div>

        <div className="report-wrapper container_sm">
          <div className="detail-box">
            {state?.found_id !== undefined ? (
              <Report incident={state} />
            ) : (
              <Report incident={state} type={"Lost"} />
            )}
          </div>
        </div>
      </div>
    </>
  );
};
/* Component ends */

export default ClientClaimedDetail;
