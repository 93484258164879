/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
/* Import configuration ends */

/* Import redux slices component starts */
import { getUnitFilters } from "../../../../setup/store/slices/filterSlice";
import { fetchUnits, getUnits } from "../../../../setup/store/slices/unitSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
import Table from "react-bootstrap/Table";
/* Import react bootstrap component ends */

/* Import image and SVG starts */
import {
  EyeFilledBlue,
  EditGreen,
  FilterGreen,
  DocumentGreen,
} from "../../../../setup/constants/images";
/* Import image and SVG ends */

/* Import local pages and component starts */
import IconButton from "../../../ui/button/IconButton/IconButton";
import FileViewerModal from "../../../ui/modal/FileViewerModal/FileViewerModal";
import UnitTableFilterModal from "../UnitTableFilterModal/UnitTableFilterModal";
import TextButton from "../../../ui/button/TextButton/TextButton";
import Searchbar from "../../../ui/other/Searchbar/Searchbar";
import Pagination from "../../../ui/other/Pagination/Pagination";
/* Import local pages and component ends */

import "./style.scss";
import { setLoading } from "../../../../setup/store/slices/unpersistedSlice";
import {
  fetchPropertyDetailByID,
  getPropertyDetail,
} from "../../../../setup/store/slices/propertySlice";

/* Component starts */
const UnitsTable = (props) => {
  /* Props destructuring starts */
  const { handleFetchUnitList, filterConfig, setFilterConfig } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [searchQ, setSearchQ] = useState("");
  const [showFileViewer, setShowFileViewer] = useState(false);
  const [file, setFile] = useState({});
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { propertyId } = useParams();
  const propertyDetail = useSelector(getPropertyDetail);
  const units = useSelector(getUnits);
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const isOwner = localStorage.getItem("user_role") === "Owner";
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  // On clicking floorplan icon
  const onViewFloorPlan = (file) => {
    setFile(file);
    setShowFileViewer(true);
  };

  // On View Unit detail
  const onViewDetails = (unit) => {
    navigate(`${unit._id}`);
  };

  // On Edit Unit
  const onEdit = (unit) => {
    navigate(`${unit._id}/edit`);
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  // Fetching all units on initial load
  useEffect(() => {
    let timeoutid = null;
    if (searchQ !== "") {
      timeoutid = setTimeout(() => {
        handleFetchUnitList(propertyId, currentPage, searchQ);
      }, 1000);
    } else {
      handleFetchUnitList(propertyId, currentPage, "");
    }

    return () => {
      if (timeoutid) {
        clearTimeout(timeoutid);
      }
    };
  }, [searchQ, currentPage]);

  useEffect(() => {
    setCurrentPage(1);
  }, [searchQ]);
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="unit-table-module-wrapper container_lg">
      {/* Filter Button */}
      <div className="filter-add-btn-wrapper">
        <div>
          <h2 className="font_xxl fw_6">Units</h2>
        </div>

        <div className="unit">
          {/* <IconButton
            onClick={() => setShowFilterModal(true)}
            className="filter-btn"
          >            
            <img className="w_100" src={FilterGreen} alt="Filter Icon" />
          </IconButton> */}

          {/* {propertyDetail?.mgmt_model === 1 && isOwner ? (
            ""
          )  : (propertyDetail?.mgmt_model === 0 && isOwner ) && (
            <TextButton
              onClick={() => navigate("add")}
              className="add-btn font_l fw_6"
            >
              + Add Unit
            </TextButton>
          ) 
          : (
            <TextButton
              onClick={() => navigate("add")}
              className="add-btn font_l fw_6"
            >
              + Add Unit
            </TextButton>
          )} */}

          {propertyDetail?.mgmt_model === 0 && isOwner ? (
            <TextButton
              onClick={() => navigate("add")}
              className="add-btn font_l fw_6"
            >
              + Add Unit
            </TextButton>
          ) : propertyDetail?.mgmt_model === 1 && isOwner ? (
            ""
          ) : propertyDetail?.mgmt_model === 2 ? (
            <TextButton
              onClick={() => navigate("add")}
              className="add-btn font_l fw_6"
            >
              + Add Unit
            </TextButton>
          ) : (
            ""
          )}
        </div>
      </div>

      <div className="search-box mt-4 mb-4">
        <div>
          <div className="search">
            <Searchbar
              setQuery={setSearchQ}
              query={searchQ}
              // className="dash-search"
            />
          </div>

          {/* <div className="btn-wrapper">
            <button onClick={() => handleSearch()} disabled={!searchQ}>
              Apply
            </button>

            <button className="btn-clear" onClick={() => handleClear()}>
              Clear
            </button>
          </div> */}
        </div>
      </div>

      {/* Unit Table */}
      <div className="custom-table-wrapper">
        {units && units?.units && units?.units?.length > 0 && (
          <Table
            className="custom-table font_s unit-table"
            borderless
            responsive
          >
            <thead>
              <tr>
                <th>Unit No.</th>
                <th>Name</th>
                <th>Unit Floor</th>
                <th>Floor Plan</th>
                <th>Type</th>
                <th>Unit Status</th>
                <th>Tenant Name</th>
                <th>Sub Status</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {units?.units?.map((item, index) => (
                <tr key={index}>
                  {/* Unit No. */}
                  <td>{item?.unit_number}</td>

                  {/* Unit Name. */}
                  <td>{item?.unit_name || "-"}</td>

                  {/* Unit Floor */}
                  <td>{item?.unit_floor || "-"}</td>

                  {/* Floor Plans */}
                  <td>
                    {item?.floor_plan?.url ? (
                      <IconButton
                        onClick={() => onViewFloorPlan(item?.floor_plan)}
                        className="action-button document"
                      >
                        <img
                          className="h_100 v_middle"
                          src={DocumentGreen}
                          alt="Edit Unit"
                        />
                      </IconButton>
                    ) : (
                      "-"
                    )}
                  </td>

                  {/* Type */}
                  <td>{item?.unit_type || "-"}</td>

                  {/* Status */}
                  <td>
                    {item?.is_active ? (
                      <span className="fw_6 font_blue">Active</span>
                    ) : (
                      <span className="fw_6 font_grey">Inactive</span>
                    )}
                  </td>

                  {/* Tenant name */}
                  <td>{item?.tenant !== null ? item?.tenant : "-"}</td>

                  {/* Sub Status */}
                  <td
                    className={`${
                      item?.status === "occupied"
                        ? "font_green"
                        : item?.status === "vacant"
                        ? "font_yellow"
                        : "font_grey"
                    }`}
                  >
                    {item?.is_active ? item?.status.toUpperCase() : "-"}
                  </td>

                  {/* Action Buttons */}
                  <td className="text-start txt-nowrap">
                    {/* View Details */}
                    {item?.is_active && (
                      <IconButton
                        onClick={() => {
                          onViewDetails(item);
                        }}
                        className="action-button"
                      >
                        <img
                          className="h_100 v_middle"
                          src={EyeFilledBlue}
                          alt="View Unit"
                        />
                      </IconButton>
                    )}

                    {/* Edit */}
                    {!item?.is_active && (
                      <IconButton
                        onClick={() => {
                          onEdit(item);
                        }}
                        className="action-button"
                      >
                        <img
                          className="h_100 v_middle"
                          src={EditGreen}
                          alt="Edit Unit"
                        />
                      </IconButton>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}

        {units?.units?.length === 0 && (
          <h3 className="not-added-yet text-center font_m font_yellow fw_6">
            No Units Added!
          </h3>
        )}
      </div>

      {/* PAGINATION */}
      {units?.units?.length > 0 && (
        <Pagination
          itemsPerPage="10"
          totalItems={units?.totalCount}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      )}

      {/* Modals */}
      {/* Filter Modal */}
      <UnitTableFilterModal
        show={showFilterModal}
        propertyId={propertyId}
        currentPage={currentPage}
        setFilterConfig={setFilterConfig}
        filterConfig={filterConfig}
        onClose={() => {
          setShowFilterModal(false);
        }}
      />

      {/* Fileviewer Modal */}
      <FileViewerModal
        show={showFileViewer}
        file={file.url}
        type={file.type}
        onClose={() => {
          setShowFileViewer(false);
          setFile({});
        }}
      />
    </div>
  );
};
/* Component ends */

export default UnitsTable;
