/**
 *
 */

import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

/* Import configuration starts */
import { Formik } from "formik";
import { validation, prefill, isApproved, isDataChanged } from "./helper";
import { useDispatch } from "react-redux";
import GLOBAL from "../../../../setup/constants/global";
/* Import configuration ends */

/* Import redux slices component starts */
import { updateTenantContract } from "../../../../setup/store/slices/tenantSlice";
import { showErrorAlert } from "../../../../setup/store/slices/globalAlertSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import CustomFileInput from "../../../ui/input/CustomFileInput/CustomFileInput";
import CustomTextInput from "../../../ui/input/CustomTextInput/CustomTextInput";
import TextButton from "../../../ui/button/TextButton/TextButton";
import CustomButton from "../../../ui/button/CustomButton/CustomButton";
import SuccessModal from "../../../ui/modal/SuccessModal/SuccessModal";
import ErrorModal from "../../../ui/modal/ErrorModal/ErrorModal";
/* Import local pages and component ends */

import "./style.scss";

// import { fieldPropExtractor, initialValues } from "./helper";

/* Component starts */
const UtilityRegistrationForm = (props) => {
  /* Props destructuring starts */
  const { onNext, application_id, tenant_contract, data, utilities } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [initialFormValues, setInitialFormValues] = useState({});
  const [message, setMessage] = useState("");
  const [showErrModal, setShowErrModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  const [formCountList, setFormCountList] = useState([]);
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const utilityPhoto = useRef([null]);
  let navigate = useNavigate();
  const dispatch = useDispatch();
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const utilityRequiredData = JSON.parse(utilities);
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  // Form validations
  const formValidation = (values) => {
    return validation(values, formCountList);
  };

  // Form Submit
  const onSubmit = async (values) => {
    // Creating or collecting payload data to be sent
    const payload = {
      application_id,
      type: "utilities",
      tenant_contract,
      utilities: [],
    };

    if (
      data &&
      data?.length > 0 &&
      !isDataChanged(values, data, formCountList)
    ) {
      dispatch(
        showErrorAlert({
          title: "Error",
          description: "Please update the form",
        })
      );
      return;
    }

    formCountList?.forEach((item, index) => {
      if (!isApproved(values[`status_${item}`])) {
        const utility = {};

        utility.name = values[`utility_name_${item}`];

        if (index < data.length) {
          utility._id = data[index]._id;
        }

        if (
          values[`utility_photo_${item}`] &&
          values[`utility_photo_${item}`] instanceof File
        ) {
          payload[`img_${index}`] = values[`utility_photo_${item}`];
        }
        utility["index"] = index;
        payload?.utilities.push(utility);
      }
    });

    console.log("Payload", payload);

    // Backend response. Try, Catch
    try {
      const result = await dispatch(updateTenantContract(payload));
      console.log("Response", result);

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          setMessage(result.payload);
          setShowErrModal(true);
          break;

        case GLOBAL.FULFILLED:
          setMessage(result.payload.message);
          setShowSuccessModal(true);
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };

  // Form Cancel
  const onCancel = () => {
    // console.log(values, "[]");
    navigate(-1);
  };

  // Getting or Processing action error note from PM
  const getActionError = (d) => {
    if (d?.actions?.length > 0) {
      let lastAction = d["actions"][d["actions"].length - 1];
      if (
        lastAction.status === GLOBAL.TENANT_APPLICATION_STATUS.DATA_REJECTED
      ) {
        return true;
      }
    }
    return false;
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  useEffect(() => {
    if (utilityRequiredData && utilityRequiredData?.length > 0) {
      prefill(
        utilityRequiredData,
        data,
        setInitialFormValues,
        setFormCountList
      );
    }
  }, [data]);
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="utility-registration-form-wrapper container_sm">
      <h2 className="heading text-center font_xxl mb-5">
        Onboarding Documents
      </h2>

      {/* Form */}
      <Formik
        onSubmit={onSubmit}
        initialValues={initialFormValues}
        validate={formValidation}
        validateOnBlur={true}
        enableReinitialize={true}
      >
        {({
          values,
          errors,
          handleChange,
          handleSubmit,
          handleBlur,
          touched,
          isSubmitting,
          setFieldValue,
          setFieldTouched,
        }) => (
          <form className="form" onSubmit={handleSubmit}>
            <div className="bg_gray form-wrapper">
              {/* If no pets added */}
              {formCountList?.length === 0 && (
                <p className="font_m fw_5 text-center">
                  No onboarding document added !
                </p>
              )}

              {/* Update Utility Registration Form */}
              {formCountList?.map((item, index) => (
                <div key={index}>
                  <h3 className="title-bubble font_blue font_m fw_6 w_100">
                    Onboarding #{index + 1}
                    {isApproved(values[`status_${item}`]) && (
                      <p className="font_m fw_5 already-approved-warning">
                        <span className="font_yellow">
                          Uploading onboarding document is disabled, as this
                          document is already
                        </span>{" "}
                        <span className="font_green">Approved</span>
                      </p>
                    )}
                  </h3>

                  {/* Require action */}
                  {data[index]?.status === "rejected" &&
                    getActionError(data[index]) && (
                      <p className="w_100 font_red font_s fw_5">
                        {
                          data[index]?.actions[data[index]?.actions?.length - 1]
                            .note
                        }
                      </p>
                    )}

                  <div className="form-group">
                    <div>
                      <CustomTextInput
                        name={`utility_name_${item}`}
                        label="Onboarding Doucment Name"
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        touched={touched[`utility_name_${item}`]}
                        errors={errors[`utility_name_${item}`]}
                        value={values[`utility_name_${item}`]}
                        readOnly={true}
                      />
                    </div>

                    <div>
                      <CustomFileInput
                        name={`utility_photo_${item}`}
                        label="Photo"
                        setFieldValue={setFieldValue}
                        setFieldTouched={setFieldTouched}
                        formikState={true}
                        touched={touched[`utility_photo_${item}`]}
                        errors={errors[`utility_photo_${item}`]}
                        value={values[`utility_photo_${item}`]}
                        required={true}
                        disabled={isApproved(values[`status_${item}`])}
                      />
                    </div>
                  </div>
                </div>
              ))}
            </div>

            <div className="button-wrapper text-center">
              <>
                <CustomButton type="submit" size="l">
                  Submit
                </CustomButton>
                <TextButton
                  type="button"
                  className="font_m fw_6"
                  onClick={onCancel}
                >
                  Cancel
                </TextButton>
              </>
            </div>
          </form>
        )}
      </Formik>

      {/* Modals */}
      {/* Success Modal */}
      <SuccessModal
        showModal={showSuccessModal}
        description={message}
        title="Success"
        onClose={() => onNext()}
      />

      {/* Error Modal */}
      <ErrorModal
        showModal={showErrModal}
        description={message}
        title="Error"
        onClose={() => setShowErrModal(false)}
      />
    </div>

    // </div>
  );
};
/* Component ends */

export default UtilityRegistrationForm;
