/**
 *
 */

import React from "react";

/* Import configuration starts */
/* Import configuration ends */

/* Import redux slices component starts */
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
import { DefaultAdvertisment } from "../../../setup/constants/images";
/* Import image and SVG ends */

/* Import local pages and component starts */
/* Import local pages and component ends */

/* Component starts */
const AdDetails = (props) => {
  /* Props destructuring starts */
  const { details } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <>
      <div className="advertisement-component mt-4">
        {/* <div className="heading my-2">
          <h1 className="font_l fw_4">
            Title :- <span className="font_yellow">{details?.title}</span>
          </h1>

          <p className="font_l fw_4 font_blue">
            Open By :{" "}
            <span className="font_l fw_6 font_yellow">
              {details?.advt_open_count}
            </span>
          </p>
        </div> */}

        <div className="image-wrapper">
          <img
            src={
              details?.banner?.url ? details?.banner?.url : DefaultAdvertisment
            }
            alt="banner"
          />
        </div>
        <div className="document-wrapper">
          <div
            className="description"
            dangerouslySetInnerHTML={{ __html: details?.html_messege }}
          />
        </div>
      </div>
    </>
  );
};
/* Component ends */

export default AdDetails;
