/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
/* Import configuration ends */

/* Import redux slices component starts */
import {
  fetchCommunityProperties,
  getCommunityProperties,
  setCommunityProperties,
} from "../../setup/store/slices/propertySlice";

import {
  fetchUserRoles,
  getUserRoles,
} from "../../setup/store/slices/masterSlice";
import { fetchUser, getUser } from "../../setup/store/slices/authSlice";
import {
  fetchCommunityDetailByID,
  getCommunityDetail,
} from "../../setup/store/slices/communitySlice";
import { setLoading } from "../../setup/store/slices/unpersistedSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
import { BlankImagePlaceholder } from "../../setup/constants/images";
/* Import image and SVG ends */

/* Import local pages and component starts */
import DetailBox from "./local-components/DetailBox";
import CommunityProperties from "./local-components/CommunityProperties";
import Heading from "./local-components/Heading";
import Image from "../../components/ui/other/Image/Image";
import OverviewSlider from "../../components/module/Overview/OverviewSlider/OverviewSlider";
import Searchbar from "../../components/ui/other/Searchbar/Searchbar";
import Modules from "./local-components/Modules";
/* Import local pages and component ends */

import "./style.scss";
import BreadCrumb from "../../components/ui/other/BreadCrumb/BreadCrumb";

/* Component starts */
const CommunityDetail = (props) => {
  /* Props destructuring starts */
  const {} = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [role, setRole] = useState();
  const [searchQ, setSearchQ] = useState("");
  const [filteredProperties, setFilteredProperties] = useState([]);
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const dispatch = useDispatch();
  const { communityId } = useParams();

  // Redux Selector
  const userRoles = useSelector(getUserRoles);
  const user = useSelector(getUser);
  const community = useSelector(getCommunityDetail);
  const properties = useSelector(getCommunityProperties);
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const image = community?.image?.url || BlankImagePlaceholder;
  const propertyFilterKeys = [
    "property_name",
    "community_name",
    "address",
    "mgmt_model_name",
    "property_type",
    "owner",
  ];
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  const handleSearchFilter_properties = () => {
    // Filter Property data based on search query
    const filtered = filteredProperties?.filter((property) =>
      propertyFilterKeys?.some(
        (key) =>
          property[key] &&
          property[key]
            ?.toString()
            ?.toLowerCase()
            ?.includes(searchQ?.toLowerCase()) // Ensure property[key] exists
      )
    );

    setFilteredProperties(filtered);
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  // Fetching all properties under the selected community on load of seleted community
  useEffect(() => {
    (async () => {
      try {
        await dispatch(setLoading(true));
        await dispatch(fetchUserRoles());
        await dispatch(fetchUser());
        await dispatch(setLoading(false));
      } catch (e) {}
    })();
    if (community) {
      dispatch(fetchCommunityProperties(communityId));
    }
  }, [community, communityId]);

  // Setting role after all user roles loaded
  useEffect(() => {
    let userRole = userRoles?.filter((d) => d?._id === user?.role_id)[0]?.name;
    setRole(userRole);
  }, [userRoles]);

  /* Component useEffect ends */
  useEffect(() => {
    dispatch(setCommunityProperties([]));
    dispatch(fetchCommunityDetailByID({ community_id: communityId }));
    return () => {
      dispatch(setCommunityProperties([]));
    };
  }, []);

  useEffect(() => {
    if (properties?.length > 0) {
      setFilteredProperties(properties);
    }
  }, [properties]);

  useEffect(() => {
    if (searchQ !== "") {
      handleSearchFilter_properties();
    } else {
      setFilteredProperties(properties);
    }
  }, [searchQ, properties]);
  /* ************* Component rendering. JSX code ************* */
  return (
    <>
      <BreadCrumb type="community_detail" />
      <div className="community-detail-page-wrapper">
        <div className="container_lg">
          <h1 className="font_xl fw_6 ">Community Overview</h1>

          <div className="search-bar">
            <Searchbar query={searchQ} setQuery={setSearchQ} />
          </div>

          <div className="slider">
            <OverviewSlider type="community" communityID={communityId} />
          </div>

          <div className="p-4 m-2 community-wrap">
            <Heading community={community} />

            <div className="detail-box">
              <div className="image">
                <Image
                  className="image-inner w_100 h_100"
                  src={image}
                  objectFit="cover"
                  objectPosition="center center"
                  alt="Community Image"
                />
              </div>

              <DetailBox community={community} />
            </div>
            <div>
              {role === "Client" && <Modules communityID={communityId} />}
            </div>
          </div>
        </div>

        {/* Community Properties */}
        <div className="container_lg">
          <CommunityProperties
            community={community}
            properties={filteredProperties}
          />
        </div>
      </div>
    </>
  );
};
/* Component ends */

export default CommunityDetail;
