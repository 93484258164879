/**
 *
 */

import React, { useState, useEffect, useRef } from "react";

/* Import configuration starts */
import { useDispatch } from "react-redux";
import {
  useNavigate,
  useSearchParams,
  useParams,
  useLocation,
} from "react-router-dom";
import GLOBAL from "../../setup/constants/global";
import { initialSequence } from "./helper";
/* Import configuration ends */

/* Import redux slices component starts */
import {
  fetchPropertyDetailByID,
  getPropertyDetail,
} from "../../setup/store/slices/propertySlice";
import {
  showErrorAlert,
  showSuccessAlert,
} from "../../setup/store/slices/globalAlertSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import MUI component starts */
/* Import MUI component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import ProgressSteps from "../../components/ui/other/ProgressSteps/ProgressSteps";
import SuccessModal from "../../components/ui/modal/SuccessModal/SuccessModal";
import WarningModal from "../../components/ui/modal/WarningModal/WarningModal";

// All steps window
import AddingPropertyStep from "./local-components/AddingPropertyStep";
import AddingLicenseStep from "./local-components/AddingLicenseStep";
import AddingManagementModelStep from "./local-components/AddingManagementModelStep";
import AddingPropertyUnderCommunityStep from "./local-components/AddingPropertyUnderCommunityStep";
import AddOwnerWizard from "./local-components/AddOwnerWizard/AddOwnerWizard";
import AddPMCWizard from "./local-components/AddPMCWizard/AddPMCWizard";
import AddFMCWizard from "./local-components/AddFMCWizard/AddFMCWizard";
import AddSMCWizard from "./local-components/AddSMCWizard/AddSMCWizard";
/* Import local pages and component ends */

import "./style.scss";
import BreadCrumb from "../../components/ui/other/BreadCrumb/BreadCrumb";

/* Component starts */
const AddProperty = () => {
  /* Props destructuring starts */
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [skipped, setSkipped] = useState(false);
  const [showMissingModal, setShowMissingModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [currentStep, setCurrentStep] = useState("add_property");
  const [selectedMgmtModel, setSelectedMgmtModel] = useState(); // 0 - Single, 1 - FMC, 2 - Corporate
  const [currentPropertyID, setCurrentPropertyID] = useState("");
  const [isWizardCompleted, setIsWizardCompleted] = useState(false);
  const [propertyDetail, setPropertyDetail] = useState(null);
  const [propertyLoading, setPropertyLoading] = useState(false);
  const [sequence, setSequence] = useState(initialSequence);
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const { propertyId } = useParams();
  const { pathname } = useLocation();
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const underCommunity = searchParams.get("under-community") ? true : false;
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  // On skipping or cancelling the current step
  const skipStep = () => {
    if (currentStep === "add_property") {
      navigate("/provider/home");
    } else if (currentStep === "add_license") {
      setCurrentStep("add_mgmt");
    } else if (currentStep === "add_mgmt") {
      navigate("/provider/home");
    } else if (currentStep === "add_owner") {
      setShowMissingModal(true);
    } else if (currentStep === "add_pmc") {
      setShowMissingModal(true);
    } else if (currentStep === "add_fmc") {
      if (propertyDetail?.mgmt_model === 1) {
        setShowMissingModal(true);
      } else {
        setSkipped(true);
        setCurrentStep("add_smc");
      }
    } else if (currentStep === "add_smc") {
      setShowMissingModal(true);
    }
  };

  // TODO : NEW
  const fetchProperty = async () => {
    if (currentPropertyID === "") {
      dispatch(
        showErrorAlert({
          title: "Error!",
          description: "There is an issue while fetching property detail",
        })
      );
      return;
    }

    setPropertyLoading(true);
    // Creating or collecting payload data to be sent
    const payload = {};
    console.log("Payload", payload);

    // Backend response. Try, Catch
    try {
      const result = await dispatch(
        fetchPropertyDetailByID({
          property_id: currentPropertyID,
        })
      );
      console.log("Response", result);
      setPropertyLoading(false);

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          dispatch(
            showErrorAlert({
              title: "Error!",
              description:
                typeof result?.payload === "string" ? result?.payload : "",
            })
          );
          break;

        case GLOBAL.FULFILLED:
          setPropertyDetail(result?.payload);
          // dispatch(
          //   showSuccessAlert({
          //     title: "Success!",
          //     description:
          //       typeof result?.payload?.message === "string"
          //         ? result?.payload?.message
          //         : "",
          //   })
          // );
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };

  // On closing success modal and warning modal
  const onModalClose = () => {
    setShowSuccessModal(false);
    setShowMissingModal(false);
    navigate("/");
  };

  // TODO : NEW
  const onStepSuccess = (values) => {
    if (currentStep === "add_property") {
      const { under_community, property_id } = values;
      setCurrentPropertyID(property_id);
    } else if (currentStep === "add_license") {
      fetchProperty();
    } else if (currentStep === "add_mgmt") {
      fetchProperty();
    } else if (currentStep === "add_owner") {
      if (propertyDetail?.mgmt_model === 0) {
        setShowSuccessModal(true);
      } else {
        fetchProperty();
      }
    } else if (currentStep === "add_pmc") {
      fetchProperty();
    } else if (currentStep === "add_fmc") {
      if (propertyDetail?.mgmt_model === 1) {
        setShowSuccessModal(true);
      } else {
        fetchProperty();
      }
    } else if (currentStep === "add_smc") {
      if (propertyDetail?.mgmt_model === 2) {
        if (skipped) {
          setShowMissingModal(true);
        } else {
          setShowSuccessModal(true);
        }
      } else {
        fetchProperty();
      }
    }
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  // TODO : NEW
  useEffect(() => {
    if (pathname.includes("resume")) {
      setCurrentPropertyID(propertyId);
    }
  }, [pathname]);

  // TODO : NEW
  useEffect(() => {
    if (currentPropertyID !== "") {
      fetchProperty();
    }
  }, [currentPropertyID]);

  // TODO : New
  useEffect(() => {
    if (propertyDetail) {
      const generateSequence = [];

      if (propertyDetail?.mgmt_model && propertyDetail?.mgmt_model === 0) {
        generateSequence.push({ title: "Add Owner", key: "add_owner" });
      }

      if (propertyDetail?.mgmt_model && propertyDetail?.mgmt_model === 1) {
        generateSequence.push({
          title: "Add Facility Management Company",
          key: "add_fmc",
        });
      }

      if (propertyDetail?.mgmt_model && propertyDetail?.mgmt_model === 2) {
        generateSequence.push(
          {
            title: "Add Owner",
            key: "add_owner",
          },
          {
            title: "Add Property Management Company",
            key: "add_pmc",
          },
          {
            title: "Add Facility Management Company",
            key: "add_fmc",
          },
          {
            title: "Add Security Management Company",
            key: "add_smc",
          }
        );
      }

      if (propertyDetail?.wizard !== GLOBAL.PROP_STEP.DONE) {
        setCurrentStep(() => {
          switch (propertyDetail?.wizard) {
            case GLOBAL.PROP_STEP.LICENSE:
              return "add_license";

            case GLOBAL.PROP_STEP.MGMT:
              return "add_mgmt";

            case GLOBAL.PROP_STEP.OWNER:
              return "add_owner";

            case GLOBAL.PROP_STEP.PMC:
              return "add_pmc";

            case GLOBAL.PROP_STEP.FMC:
              return "add_fmc";

            case GLOBAL.PROP_STEP.SMC:
              return "add_smc";

            default:
              return "done";
          }
        });
      } else {
        setShowSuccessModal(true);
      }
      setSequence([...sequence, ...generateSequence]);
    }
  }, [propertyDetail]);
  /* Component useEffect ends */
  
  /* ************* Component rendering. JSX code ************* */
  return (
    <>
      <BreadCrumb type="add-property" />
      <div className="add-property-page-wrapper-main">
        <div className="container_sm">
          {sequence && sequence.length > 1 && (
            <div className="steps">
              <ProgressSteps
                steps={sequence}
                currentStep={currentStep}
                version="2"
              />
            </div>
          )}

          {!propertyLoading && (
            <>
              {currentStep === "add_property" && (
                <>
                  {underCommunity ? (
                    <AddingPropertyUnderCommunityStep
                      onStepSuccess={onStepSuccess}
                      onCancelStep={skipStep}
                    />
                  ) : (
                    <AddingPropertyStep
                      onStepSuccess={onStepSuccess}
                      onCancelStep={skipStep}
                    />
                  )}
                </>
              )}
              {currentStep === "add_license" && (
                <>
                  <AddingLicenseStep
                    onStepSuccess={onStepSuccess}
                    onCancelStep={skipStep}
                    onBack={() => navigate(-1)}
                  />
                </>
              )}
              {currentStep === "add_mgmt" && (
                <>
                  <AddingManagementModelStep
                    setSelectedMgmtModel={setSelectedMgmtModel}
                    onStepSuccess={onStepSuccess}
                    onCancelStep={skipStep}
                  />
                </>
              )}
              {currentStep === "add_owner" && (
                <>
                  <AddOwnerWizard
                    onSuccess={onStepSuccess}
                    onCancel={skipStep}
                  />
                </>
              )}
              {currentStep === "add_pmc" && (
                <>
                  <AddPMCWizard onSuccess={onStepSuccess} onCancel={skipStep} />
                </>
              )}
              {currentStep === "add_fmc" && (
                <>
                  <AddFMCWizard onSuccess={onStepSuccess} onCancel={skipStep} />
                </>
              )}
              {currentStep === "add_smc" && (
                <>
                  <AddSMCWizard onSuccess={onStepSuccess} onCancel={skipStep} />
                </>
              )}
            </>
          )}
        </div>

        {/* Modals */}
        {/* Success Modal */}
        <SuccessModal
          showModal={showSuccessModal}
          title="Property Added"
          description="Property Added sucessfully. After closing this you will be redirected to Home."
          onClose={onModalClose}
        />

        {/* Warning Modal */}
        <WarningModal
          showModal={showMissingModal}
          title="Missing Items"
          description="Property Added sucessfully, but missing the minimum required roles to operate the property."
          onClose={onModalClose}
        />
      </div>
    </>
  );
};
/* Component ends */

export default AddProperty;
