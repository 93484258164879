/**
 *
 */

import React, { useEffect } from "react";

/* Import configuration starts */
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
/* Import configuration ends */

/* Import redux slices component starts */
import { setLoading } from "../../../../setup/store/slices/unpersistedSlice";
import {
  fetchPropertyDetailByID,
  getPropertyDetail,
} from "../../../../setup/store/slices/propertySlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import Deposit from "./sub-component/Deposit";
import InspectionReport from "./sub-component/InspectionReport";
import ReleaseClearence from "./sub-component/ReleaseClearence";
import TextButton from "../../../ui/button/TextButton/TextButton";
import OtherInvoices from "./sub-component/OtherInvoices";
import RentDetailsHistory from "./sub-component/RentDetailsHistory";
import RentInstallmentDetail from "./sub-component/RentInstallmentDetail";
import Causes from "./sub-component/Causes";
/* Import local pages and component ends */

import "./style.scss";

/* Component starts */
const AccountantMoveOutClearence = (props) => {
  /* Props destructuring starts */
  const {
    moveOut,
    move_out_id,
    forceMoveout,
    application_id,
    getMoveoutDetails,
  } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { propertyId } = useParams();
  const property = useSelector(getPropertyDetail);
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const isObserver =
    localStorage.getItem("user_role") &&
    localStorage.getItem("user_role") === "Observer";
  const moveoutStatus = moveOut?.move_out?.status;
  const clearenceDoc = moveOut?.move_out?.clearance_certificate_pdf;
  const clearenceAuditLog = moveOut?.move_out?.clearance_certificate_audit_logs;
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  /* Component function definition ends */

  /* Component useEffect starts */
  useEffect(() => {
    (async () => {
      try {
        await dispatch(setLoading(true));
        await dispatch(fetchPropertyDetailByID({ property_id: propertyId }));
        await dispatch(setLoading(false));
      } catch (e) {}
    })();
  }, [propertyId]);
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="accountant-moveout-clearance-wrapper">
      <div className="create-inv-wrapper container-md text-end">
        {!isObserver &&
          (moveoutStatus === "Accountant clearance pending" ||
            moveoutStatus === "Owner clearance pending") && (
            <TextButton
              onClick={() => {
                if (moveoutStatus === "Accountant clearance pending") {
                  navigate(
                    `invoicing/add/?type=Tenants&moveout=1&unitId=${moveOut.tenant_application.unit._id}&from=accountant-clearence`,
                    {
                      state: {
                        unitID: moveOut?.tenant_application.unit?._id,
                        tenantId: moveOut?.tenant_application.tenant?._id,
                        applicationId: application_id,
                        fromInvoice: true,
                      },
                    }
                  );
                } else {
                  navigate(
                    `invoicing/add/?type=Tenants&moveout=1&unitId=${moveOut.tenant_application.unit._id}&from=owner-clearence`,
                    {
                      state: {
                        unitID: moveOut?.tenant_application.unit?._id,
                        tenantId: moveOut?.tenant_application.tenant?._id,
                        applicationId: application_id,
                        fromInvoice: true,
                      },
                    }
                  );
                }
              }}
              className="create-inv font_m fw_6"
            >
              + Create Invoice
            </TextButton>
          )}
      </div>

      <div className="container_md">
        <Causes
          causes={
            moveOut?.causes && moveOut?.causes?.length > 0
              ? moveOut?.causes
              : null
          }
        />
      </div>

      {/* <ApplicationFeeInvoice property={property} moveOut={moveOut} /> */}
      <Deposit
        property={property}
        moveOut={moveOut}
        getMoveoutDetails={getMoveoutDetails}
      />
      <RentDetailsHistory
        property={property}
        moveOut={moveOut}
        getMoveoutDetails={getMoveoutDetails}
      />

      <RentInstallmentDetail
        property={property}
        instalments={moveOut?.installments}
        data={moveOut?.installments}
        instalmentPlan={moveOut?.installment_plan}
        application_id={application_id}
        currency={moveOut?.installment_plan?.currency}
        depositData={moveOut?.installment_plan?.deposit_data}
      />

      <InspectionReport property={property} moveOut={moveOut} />
      <OtherInvoices
        property={property}
        moveOut={moveOut}
        getMoveoutDetails={getMoveoutDetails}
      />
      {/* <Document moveOut={moveOut} /> */}
      <ReleaseClearence
        moveOut={moveOut}
        move_out_id={move_out_id}
        forceMoveout={forceMoveout}
        application_id={application_id}
        moveoutStatus={moveoutStatus}
        clearenceDoc={clearenceDoc}
        clearenceAuditLog={clearenceAuditLog}
        getMoveoutDetails={getMoveoutDetails}
      />
    </div>
  );
};
/* Component ends */

export default AccountantMoveOutClearence;
