/**
 *
 */

import React, { useEffect } from "react";

/* Import configuration starts */
import { useState } from "react";
import GLOBAL from "../../setup/constants/global";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
/* Import configuration ends */

/* Import redux slices component starts */
import {
  showErrorAlert,
  showSuccessAlert,
} from "../../setup/store/slices/globalAlertSlice";
import { fetchUnits } from "../../setup/store/slices/unitSlice";
import { fetchPropertyDetailByID } from "../../setup/store/slices/propertySlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import MUI component starts */
/* Import MUI component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import UnitsTable from "../../components/module/Unit/UnitsTable/UnitsTable";
/* Import local pages and component ends */

import "./style.scss";
import BreadCrumb from "../../components/ui/other/BreadCrumb/BreadCrumb";

/* Component starts */
const UnitList = () => {
  /* Props destructuring starts */
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [propertyDetail, setPropertyDetail] = useState(null);
  const [unitListData, setUnitListData] = useState([]);
  const [filterConfig, setFilterConfig] = useState({
    status: null,
    sub_status: null,
    unit_type: null,
    query: "",
  });
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const dispatch = useDispatch();
  const { propertyId } = useParams();
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  const handleFetchPropertyDetail = async () => {
    // Creating or collecting payload data to be sent
    const payload = { property_id: propertyId };

    console.log("Payload", payload);

    // Backend response. Try, Catch
    try {
      const result = await dispatch(fetchPropertyDetailByID(payload));
      console.log("Response", result);

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          dispatch(
            showErrorAlert({
              title: "Error!",
              description:
                typeof result?.payload === "string" ? result?.payload : "",
            })
          );
          setPropertyDetail(null);
          break;

        case GLOBAL.FULFILLED:
          setPropertyDetail(result?.payload);
          break;
      }
    } catch (error) {
      setPropertyDetail(null);
      // Handling error response
      console.error(error.message);
    }
  };

  const handleFetchUnitList = async (
    propertyIdArgs,
    pageArgs,
    searchArgs = ""
  ) => {
    // Creating or collecting payload data to be sent
    const payload = {
      property_id: propertyIdArgs,
      page: pageArgs,
    };

    if (searchArgs !== "") {
      payload["search"] = searchArgs;
    }
    if (filterConfig !== null) {
      payload["filters"] = filterConfig;
    }

    console.log("Payload", payload);

    // Backend response. Try, Catch
    try {
      const result = await dispatch(fetchUnits(payload));
      console.log("Response", result);

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          dispatch(
            showErrorAlert({
              title: "Error!",
              description:
                typeof result?.payload === "string" ? result?.payload : "",
            })
          );
          setUnitListData([]);
          break;

        case GLOBAL.FULFILLED:
          setUnitListData(result?.payload?.units);
          break;
      }
    } catch (error) {
      setUnitListData([]);
      // Handling error response
      console.error(error.message);
    }
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  useEffect(() => {
    handleFetchPropertyDetail({ property_id: propertyId });
  }, [propertyId]);
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <>
      <BreadCrumb type="unit_list" />
      <div className="unit-list-page-wrapper">
        <UnitsTable
          handleFetchUnitList={handleFetchUnitList}
          filterConfig={filterConfig}
          setFilterConfig={setFilterConfig}
        />
      </div>
    </>
  );
};
/* Component ends */

export default UnitList;
