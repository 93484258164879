/**
 * This component is a Modal that is shown on success
 */

import React from "react";

/* Import react bootstrap component starts */
import { Modal } from "react-bootstrap";
import { useSelector } from "react-redux";

/* Import react bootstrap component ends */

/* Import image and SVG starts */
import { DefaultAdvertisment } from "../../../../setup/constants/images";
/* Import image and SVG ends */

import { getUserTheme } from "../../../../setup/store/slices/authSlice";

/* Import local pages and component starts */
import CustomButton from "../../button/CustomButton/CustomButton";
/* Import local pages and component ends */

import "./style.scss";

/* Component starts */
const AdvertisementViewModal = (props) => {
  /* Props destructuring starts */
  const { showModal, details, onClose } = props;
  /* Props destructuring ends */

  const userTheme = useSelector(getUserTheme);

  /* ************* Component rendering. JSX code ************* */
  return (
    <Modal
      fullscreen={true}
      centered
      show={showModal}
      className="esign-doc-modal-wrapper"
    >
      <Modal.Body
        className={`${userTheme === "dark" ? "bg_darkTheme" : "bg_lightTheme"}`}
      >
        <div className="modal-header-custom">
          <div className="icon-wrapper text-center">
            <CustomButton
              onClick={onClose}
              className="close-btn"
              variant="outline"
            >
              Close
            </CustomButton>
          </div>
        </div>
        <div className="modal-body-custom">
          <h1 className="font_xxl fw_7 font_yellow text-center my-2">
            {details?.title}
          </h1>

          <div className={`docuseal-form-sign-component container_sm mt-2`}>
            <div className="image-wrapper w_100">
              <img
                src={
                  details?.banner?.url
                    ? details?.banner?.url
                    : DefaultAdvertisment
                }
                alt="banner"
              />
            </div>
            <div className="document-wrapper">
              <div
                className="description"
                dangerouslySetInnerHTML={{ __html: details?.html_messege }}
              />
            </div>
          </div>
        </div>
        <div className="modal-footer-custom"></div>
      </Modal.Body>
    </Modal>
  );
};
/* Component ends */

export default AdvertisementViewModal;
