/**
 *
 */

import React, { useRef, useState } from "react";

/* Import configuration starts */

/* Import configuration ends */

/* Import redux slices component starts */
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
import { DocFilledWhite } from "../../../../../setup/constants/images";
/* Import image and SVG ends */

/* Import local pages and component starts */

/* Import local pages and component ends */

/* Component starts */
const Report = (props) => {
  /* Props destructuring starts */
  const {} = props;

  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const documents = [
    {
      id: 1,
      type: "Electricity Connection",
      img: DocFilledWhite,
      status: "Approved",
      date: "20/07/2023 10:30 AM",
    },
    {
      id: 2,
      type: "Water Connection",
      img: DocFilledWhite,
      status: "Approved",
      date: "24/07/2023 10:00 PM",
    },
    {
      id: 3,
      type: "Gas Connection",
      img: DocFilledWhite,
      status: "Approved",
      date: "20/07/2023 08:30 PM",
    },
  ];
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */

  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="moveout-unit-detail-box">
      <div className="log-header">
        <p className="font_m fw_4">Request ID : R12452</p>
        <p className="font_m fw_4 font_yellow">
          Your move out is pending - Documents review
        </p>
      </div>

      <hr />

      <div className="unit-info">
        <p className="font_m fw_4">
          Orchard Side <br />
          <span className="font_s fw_4 font_grey">
            766 Roloh Rpad, Painesvile OH 4752145
          </span>
        </p>
        <span className="font_s fw_4 font_grey unit">Unit : 03</span>
      </div>

      <hr />

      <div className="time-info">
        <p className="font_s fw_4">
          Your preferred move out date <br /> 10/07/2023
        </p>
        <p className="font_s fw_4 font_grey">
          Contract Expiry Date : 10/07/2023
        </p>
      </div>

      <hr />

      <div>
        <p className="font_m fw_4">Utility clearence documents</p>
        <div className="documents-wrapper">
          {documents.map((doc) => (
            <div key={doc?.id}>
              <p className="font_xxs fw_4">{doc.type}</p>
              <p className="font_xxs fw_4">
                <img src={doc.img} alt="document" />
                {/* Untitled-1{" "} */}
                <span className="font_xxs fw_4 font_blue">Document</span>
              </p>
              <p className="font_xxs fw_4 font_blue">{doc.status}</p>
              <p className="font_xxs fw_4">{doc.date}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
/* Component Ends */
export default Report;
