/**
 *
 */

import React, { useState, useEffect, useRef } from "react";

/* Import configuration starts */
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
/* Import configuration ends */

/* Import redux slices component starts */
import {
  fetchTenantInvoiceById,
  getTenantInvoice,
} from "../../../../../../setup/store/slices/invoiceSlice";
import {
  showErrorAlert,
  showSuccessAlert,
} from "../../../../../../setup/store/slices/globalAlertSlice";
import { updteTenantInvCheque } from "../../../../../../setup/store/slices/propertyInvoiceSlice";
import {
  initiateOfflineRefund,
  initiateOnlineRefund,
} from "../../../../../../setup/store/slices/invoiceSlice";
import {
  getIsACC,
  getIsOwner,
} from "../../../../../../setup/store/slices/authSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
import { Table } from "react-bootstrap";
/* Import react bootstrap component ends */

/* Import image and SVG starts */
import {
  DocumentGreen,
  ExcalmatoryYellow,
  EyeFilledBlue,
  LocationPinFilledGrey,
  PrintIcon,
} from "../../../../../../setup/constants/images";
/* Import image and SVG ends */

/* Import local pages and component starts */
import GLOBAL from "../../../../../../setup/constants/global";
import FileViewerModal from "../../../../../ui/modal/FileViewerModal/FileViewerModal";
import IconButton from "../../../../../ui/button/IconButton/IconButton";
import InvoiceDetailModal from "../InvoiceDetailModal/InvoiceDetailModal";
import ChecqueStatusChangeModal from "../../../ChecqueStatusChangeModal/ChecqueStatusChangeModal";
import RefundPaymentModal from "../../../RefundPaymentModal/RefundPaymentModal";
/* Import local pages and component ends */

/* Component starts */
const TenantInvoiceDetail = (props) => {
  /* Props destructuring starts */
  const { invoiceID, currency, settings } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [showFileViewer, setShowFileViewer] = useState(false);
  const [file, setFile] = useState({});
  const [updateCheque, setUpdateCheque] = useState(false);
  const [showContractDetailModal, setShowContractDetailModal] = useState(false);
  const [detail, setDetail] = useState({});
  const [selectedInv, setSelectedInv] = useState({});
  const [showRefundModal, setShowRefundModal] = useState(false);
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const dispatch = useDispatch();
  const tenantInvoice = useSelector(getTenantInvoice);
  const isAccountant = useSelector(getIsACC);
  const isOwner = useSelector(getIsOwner);

  // Ref for Print Component
  const componentRef = useRef();
  const navigate = useNavigate();

  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const USER_DATA = localStorage.getItem("user_data")
    ? JSON.parse(localStorage.getItem("user_data"))
    : null;
  const DATE_FORMAT = USER_DATA ? USER_DATA?.date_format : "";
  const TIME_FORMAT = USER_DATA ? USER_DATA?.time_format : "";
  const DATE_TIME_FORMAT = `${DATE_FORMAT} ${TIME_FORMAT}`;
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */

  const getStatus = (status) => {
    let txt_status = status;
    let statusColor = "";
    switch (status) {
      case "pending_clearance":
        statusColor = "font_yellow";
        txt_status = "Pending Clearance";
        break;
      case "bounced":
        statusColor = "font_yellow";
        txt_status = "Bounced";
        break;
      case "succeeded":
        statusColor = "font_green";
        txt_status = "Success";
        break;
      // case GLOBAL.SECURITY_STATUS.SO_ASSIGNED:
      //   statusColor = "font_yellow";
      //   txt_status = "Security Officer Assigned";
      //   break;
      // case GLOBAL.SECURITY_STATUS.RESOLVED:
      //   statusColor = "font_green";
      //   txt_status = "resolved";
      //   break;
    }
    return (
      <span className={`font_s fw_4 ${statusColor}`}>
        {txt_status.toUpperCase()}
      </span>
    );
  };

  const handleView = (item, tenant) => {
    setDetail(item);
    setShowContractDetailModal(!showContractDetailModal);
  };

  const onChequeStatusChange = async (status, reason) => {
    // Creating or collecting payload data to be sent
    let payload = {};

    if (status?.selectedOption === "replace") {
      payload = {
        invoice_id: selectedInv?.invoice,
        transaction_id: selectedInv?._id,
        status: status?.selectedOption,
        cheque_reason: status?.note,
        cheque_number: status?.cheque_num,
        payment_amount: status?.cheque_amt,
        cheque_date: status?.recpdate,
        bank_name: status?.bank_name,
        issuer: status?.issuer_name,
        invoice_doc: status?.receipt?.current,
        account_number: status?.account_number,
      };
    } else {
      payload = {
        invoice_id: selectedInv?.invoice,
        transaction_id: selectedInv?._id,
        status: status,
        cheque_reason: status === "bounced" ? reason : "",
      };
    }

    // Backend response. Try, Catch
    try {
      const result = await dispatch(updteTenantInvCheque(payload));
      await dispatch(fetchTenantInvoiceById(invoiceID));

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          dispatch(
            showErrorAlert({
              title: "Error!",
              description: result.payload.error.message,
            })
          );
          break;

        case GLOBAL.FULFILLED:
          dispatch(
            showSuccessAlert({
              title: "Success!",
              description: result.payload.message,
            })
          );
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };

  const onSuccess = () => {
    setShowRefundModal(!showRefundModal);
    navigate(-1);
  };

  const handleRefund = async (values) => {
    const { invoice_doc, refunded_via } = values;
    // Creating or collecting payload data to be sent
    const payload = {
      invoice_id: tenantInvoice?.invoice?._id,
      refunded_via,
      ...values,
    };
    if (!invoice_doc) {
      delete payload["invoice_doc"];
    }

    // Backend response. Try, Catch
    try {
      let result;
      if (refunded_via === "online") {
        result = await dispatch(initiateOnlineRefund(payload));
      } else {
        result = await dispatch(initiateOfflineRefund(payload));
      }

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          dispatch(
            showErrorAlert({
              title: "Error!",
              description: result.payload.error.message,
            })
          );
          break;

        case GLOBAL.FULFILLED:
          setShowRefundModal(!showRefundModal);
          dispatch(
            showSuccessAlert({
              title: "Success!",
              description: result.payload.message,
            })
          );
          onSuccess = { onSuccess };
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };

  function printPDF(url) {
    // Open PDF in a new window/tab
    const newWindow = window.open(url);

    // Wait for the new window/tab to load
    newWindow.onload = function () {
      // Trigger print dialog
      newWindow.print();
    };
  }

  /* Component function definition ends */

  /* Component useEffect starts */
  useEffect(() => {
    if (invoiceID) {
      dispatch(fetchTenantInvoiceById(invoiceID));
    }
  }, [invoiceID]);
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="detail-card" ref={componentRef}>
      <div className="d-flex justify-content-between">
        <p>
          Invoice#{" "}
          <span className="font_blue fw_6">
            {tenantInvoice?.invoice?.invoice_num || tenantInvoice?.invoice?.invoice_no }
          </span>
          {(tenantInvoice?.invoice?.status === "paid" ||
            tenantInvoice?.invoice?.status === "collected" ||
            tenantInvoice?.invoice?.status === "partially_settled") && (
            <p
              className="font_m fw_6 font_blue refund_text"
              onClick={() => setShowRefundModal(true)}
            >
              Refund
            </p>
          )}
        </p>
      </div>
      <hr />
      <div className="property-wrapper">
        <div className="property-data">
          <div>
            {/* <span className="font_m fw_6">{state?.property}</span> */}
            <span className="font_m fw_6">
              {tenantInvoice?.invoice?.property?.property_name}
            </span>
          </div>

          <div>
            <p className="font_m fw_4 font_grey">
              Unit :{" "}
              <span className="font_m fw_6 font_blue">
                {tenantInvoice?.invoice?.unit}{" "}
              </span>
            </p>
          </div>
        </div>

        <div className="property-data">
          <div>
            <img src={LocationPinFilledGrey} alt="Location-Pin" />
            <span className="font_s fw_4 font_grey">
              {tenantInvoice?.invoice?.property?.address_line1},{" "}
              {tenantInvoice?.invoice?.property?.city},
              {tenantInvoice?.property?.state},
              {tenantInvoice?.invoice?.property?.country}{" "}
              {tenantInvoice?.invoice?.property?.zipcode !== undefined
                ? `- ${tenantInvoice?.invoice?.property?.zipcode}`
                : ""}
            </span>
          </div>
        </div>

        <div className="property-data ">
          <p className="font_m fw_4 font_grey my-2">
            Tenant Name :{" "}
            <span className="font_m fw_6 font_blue">
              {tenantInvoice?.invoice?.tenant}
            </span>
          </p>
        </div>
      </div>
      <hr />
      <div className="invoice-data-price">
        {tenantInvoice?.invoice?.type !== "rent" && (
          <p className="font_m fw_4 font_grey">
            Description <br />
            <span className="font_xs fw_3">
              {tenantInvoice?.invoice?.description !== undefined
                ? tenantInvoice?.invoice?.description
                : "-"}
            </span>
          </p>
        )}

        {tenantInvoice?.invoice?.type === "rent" && (
          <p className="font_m fw_4 font_grey">
            Description <br />
            <span className="font_m fw_6">
              {tenantInvoice?.invoice?.instaName !== undefined
                ? tenantInvoice?.invoice?.instaName
                : "-"}
            </span>
          </p>
        )}

        <p className="font_m fw_4 font_grey">
          Quantity <br />
          <span className="font_m fw_4">
            {tenantInvoice?.invoice?.quantity !== undefined
              ? tenantInvoice?.invoice?.quantity
              : "1"}
          </span>
        </p>

        <div>
          <p className="font_m fw_4 font_grey">
            Invoice Amount <br />
          </p>
          <p>
            <span className="font_s fw_4">
              Amount : {tenantInvoice?.invoice?.amount?.toLocaleString("en-US")}
            </span>
            <br />
            <span className="font_s fw_4">
              VAT({settings?.collect_vat_value}%):{" "}
              {tenantInvoice?.invoice?.vat_amount
                ?.toFixed(2)
                ?.toLocaleString("en-US")}
            </span>
            <br />
            <span className="font_s fw_4">
              Tax({settings?.collect_tax_value}%):{" "}
              {tenantInvoice?.invoice?.tax_amount
                ?.toFixed(2)
                ?.toLocaleString("en-US")}
            </span>
            <hr />
            <span className="font_s fw_4">
              Total :{" "}
              <span className="font_m fw_6 font_blue">
                {tenantInvoice?.invoice?.total !== undefined
                  ? `${tenantInvoice?.invoice?.total?.toLocaleString(
                      "en-US"
                    )}${" "}${currency}`
                  : "-"}
              </span>
            </span>
          </p>
        </div>
      </div>

      <hr />

      <div className="invoice-data">
        <p className="font_m fw_4 font_grey">
          Invoice Date <br />
          <span className="font_m fw_4">
            {moment(tenantInvoice?.invoice?.invoice_date).format(DATE_FORMAT)}
          </span>
        </p>

        <p className="font_m fw_4 font_grey">
          Due Date <br />
          <span className="font_m fw_4">
            {moment(tenantInvoice?.invoice?.due_date).format(DATE_FORMAT)}
          </span>
        </p>

        <p className="font_m fw_4 font_grey">
          Payment Date <br />
          <span className="font_m fw_4">
            {tenantInvoice?.invoice?.updatedAt &&
            (tenantInvoice?.invoice?.status === "paid" ||
              tenantInvoice?.invoice?.status === "partial paid" ||
              tenantInvoice?.invoice?.status === "collected" ||
              tenantInvoice?.invoice?.status === "partially_collected")
              ? `${moment(tenantInvoice?.invoice?.updatedAt).format(
                  DATE_TIME_FORMAT
                )}`
              : "-"}
          </span>
        </p>
      </div>
      <hr />
      <div className="status-wrapper">
        <p className="font_m fw_4">Status</p>
        <p
          className={`font_m fw_4
        ${
          tenantInvoice?.invoice?.status === "paid" ||
          tenantInvoice?.invoice?.status === "collected"
            ? "font_green"
            : tenantInvoice?.status === "unpaid" ||
              tenantInvoice?.invoice?.status === "settled"
            ? "font_yellow"
            : "font_red"
        }
        `}
        >
          {tenantInvoice?.invoice?.status === "collected"
            ? "Collected"
            : tenantInvoice?.invoice?.status === "partially_collected"
            ? "Partially Collected"
            : tenantInvoice?.invoice?.status === "not_collected"
            ? "Not Collected"
            : tenantInvoice?.invoice?.status === "partially_settled"
            ? "Partially Settled"
            : tenantInvoice?.invoice?.status === "settled"
            ? "Settled"
            : tenantInvoice?.invoice?.status?.charAt(0).toUpperCase() +
              tenantInvoice?.invoice?.status?.slice(1)}
        </p>
      </div>

      {tenantInvoice?.invoice?.invoice_pdf && (
        <>
          <hr />
          <div className="doc-wrapper">
            <p className="font_m fw_4">Invoice</p>

            {tenantInvoice?.invoice?.invoice_pdf && (
              <>
                <div className="doc">
                  <img
                    src={DocumentGreen}
                    onClick={() => {
                      setFile(tenantInvoice?.invoice?.invoice_pdf);
                      setShowFileViewer(true);
                    }}
                  />{" "}
                  {/* Untitled 1 -{" "}
                  <span className="font_s fw_4 font_blue text-decoration-underline">
                    Download
                  </span> */}
                  <img
                    src={PrintIcon}
                    onClick={() => {
                      printPDF(tenantInvoice?.invoice?.invoice_pdf?.url);
                      // window.print();
                      // setFile(tenantInvoice?.invoice?.invoice_pdf);
                      // setShowFileViewer(true);
                    }}
                  />
                </div>
              </>
            )}
          </div>

          {tenantInvoice?.invoice &&
            tenantInvoice?.invoice?.receiptPayment_pdf && (
              <div className="doc-wrapper">
                <p className="font_m fw_4">Receipt</p>

                {tenantInvoice?.invoice?.receiptPayment_pdf ? (
                  <>
                    <div className="doc">
                      <img
                        src={DocumentGreen}
                        onClick={() => {
                          setFile(tenantInvoice?.invoice?.receiptPayment_pdf);
                          setShowFileViewer(true);
                        }}
                      />{" "}
                      {/* Untitled 1 -{" "}
                  <span className="font_s fw_4 font_blue text-decoration-underline">
                    Download
                  </span> */}
                      <img
                        src={PrintIcon}
                        onClick={() => {
                          printPDF(
                            tenantInvoice?.invoice?.receiptPayment_pdf?.url
                          );
                          // setFile(tenantInvoice?.invoice?.receiptPayment_pdf);
                          // setShowFileViewer(true);
                        }}
                      />
                    </div>
                  </>
                ) : (
                  <p>No Receipt</p>
                )}
              </div>
            )}
        </>
      )}
      <hr />
      <div className="payment-data">
        <p className="font_l fw_4">Payment Details</p>

        {tenantInvoice?.invoice?.invoice_transaction?.length > 0 ? (
          <div className="trans-table">
            <Table className="custom-table font_s" borderless responsive>
              {/* Table Heading */}
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Amount</th>
                  <th>Method</th>
                  <th>Date</th>
                  {/* <th>Received By</th> */}
                  <th>Status</th>
                  <th>Actions</th>
                </tr>
              </thead>
              {/* Table Body */}
              <tbody>
                {tenantInvoice?.invoice?.invoice_transaction?.map(
                  (trans, idx) => (
                    <tr key={trans._id}>
                      {/* ID */}
                      <td>{idx + 1}</td>

                      {/* Payment Amount */}
                      <td>
                        {trans?.associated_invoices?.length > 0
                          ? tenantInvoice?.invoice?.amount?.toLocaleString(
                              "en-US"
                            )
                          : trans?.payment_amount?.toLocaleString("en-US") ||
                            trans?.cheque_amount?.toLocaleString("en-US") ||
                            trans?.total_amount?.toLocaleString("en-US")}{" "}
                        {currency}
                      </td>

                      {/* Payment Method */}
                      <td className="font_blue">
                        {
                          trans?.payment_method?.charAt(0).toUpperCase() +
                            trans?.payment_method?.slice(1)
                          //  ||
                          // tenantInvoice?.invoice?.method
                          //   ?.charAt(0)
                          //   .toUpperCase() +
                          //   tenantInvoice?.invoice?.method?.slice(1)
                        }
                      </td>

                      {/* Date */}
                      <td>
                        {moment(trans?.createdAt).format(DATE_TIME_FORMAT)}
                      </td>

                      {/* Payment Recieved By */}
                      {/* <td>
                      {trans?.received_by?.first_name}{" "}
                      {trans?.received_by?.last_name}
                    </td> */}

                      {/* Status */}
                      {/* <td>
                        {trans?.sub_status ||
                          trans?.status?.charAt(0).toUpperCase() +
                            trans?.status?.slice(1)}
                      </td> */}

                      <td>{getStatus(trans?.status)}</td>

                      {/* Actions */}
                      <td>
                        <IconButton
                          className="action-button"
                          onClick={() =>
                            handleView(trans, tenantInvoice?.invoice?.tenant)
                          }
                        >
                          <img src={EyeFilledBlue} className="h_100" />
                        </IconButton>

                        {((isOwner &&
                          tenantInvoice?.invoice?.property?.mgmt_model === 0) ||
                          (isAccountant &&
                            tenantInvoice?.invoice?.property?.mgmt_model ===
                              2)) &&
                          tenantInvoice?.invoice?.status !== "paid" &&
                          trans?.status === "pending_clearance" && (
                            <IconButton
                              className="action-button"
                              onClick={() => {
                                setSelectedInv(trans);
                                setDetail(trans);
                                setUpdateCheque(true);
                              }}
                            >
                              <img src={ExcalmatoryYellow} className="h_100" />
                            </IconButton>
                          )}
                      </td>
                    </tr>
                  )
                )}
              </tbody>
            </Table>
          </div>
        ) : (
          <div className="pay-data text-center">
            <h3 className=" font_m font_yellow fw_6">No Payment Details</h3>
          </div>
        )}
      </div>
      <InvoiceDetailModal
        showModal={showContractDetailModal}
        detail={detail}
        currency={tenantInvoice?.invoice?.currency}
        onClose={() => {
          setShowContractDetailModal(false);
        }}
      />
      {/* File viewer modal */}
      <FileViewerModal
        show={showFileViewer}
        type={file?.type}
        file={file?.url}
        onClose={() => {
          setShowFileViewer(false);
          setFile({});
        }}
      />
      <ChecqueStatusChangeModal
        showModal={updateCheque}
        onClose={() => setUpdateCheque(false)}
        onSubmit={onChequeStatusChange}
        detail={detail}
      />

      {/* REFUND MODAL */}
      <RefundPaymentModal
        showModal={showRefundModal}
        onClose={() => {
          setShowRefundModal(false);
        }}
        // onSuccess={onSuccess}
        invoiceData={tenantInvoice?.invoice}
        paidOnline={tenantInvoice?.invoice?.method === "online"}
        depositInvoice={tenantInvoice?.invoice?.type === "deposit"}
        onSubmit={handleRefund}
        // property_id={property?._id}
      />
    </div>
  );
};
/* Component ends */

export default TenantInvoiceDetail;
