/**
 *
 */

import React, { useEffect, useState } from "react";

/* Import configuration starts */
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
/* Import configuration ends */

/* Import redux slices component starts */
import { setLoading } from "../../../setup/store/slices/unpersistedSlice";
import {
  fetchUserRoles,
  getUserRoles,
} from "../../../setup/store/slices/masterSlice";
import { fetchUser, getUser } from "../../../setup/store/slices/authSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import MUI component starts */
/* Import MUI component ends */

/* Import image and SVG starts */
import {
  Unit,
  License,
  WarningRoundedOutlineYellow,
  Teams,
  LostAndFound,
  Voilation,
  VisitorLogBook,
  VisitorPass,
  Security,
  Tenants,
  SettingsFilledBlue,
  ManageMaintenance,
  RoutineRequest,
  InternalMaintenance,
  InternalCommunication,
  Advertising,
  Annoucments,
  BluePrint,
  RatingAndReview,
  ManageFacilities,
  StaffSchedule,
  Dashboard,
  Invoicing,
  ExcalmatoryYellow,
} from "../../../setup/constants/images";
/* Import image and SVG ends */

/* Import local pages and component starts */
import IconButton from "../../../components/ui/button/IconButton/IconButton";

/* Import local pages and component ends */

/* Component starts */
const Modules = (props) => {
  /* Props destructuring starts */
  const { property } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [role, setRole] = useState();
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const userRoles = useSelector(getUserRoles);
  const user = useSelector(getUser);
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const missingLicense = property?.missing_items.includes("license");
  const missingUnit = property?.missing_items.includes("units");
  const missingTeam = property?.missing_items.includes("team");
  const missingPropertySetting =
    property?.missing_items.includes("property_setting");
  const manageModel = property?.mgmt_model;

  // For Exisiting Modules

  const units = property?.active_cards?.units;
  const teams = property?.active_cards?.teams;
  const tenants = property?.active_cards?.tenants;
  const maintenance = property?.active_cards?.maintenance;
  const routine_requests = property?.active_cards?.routine_requests;
  const internal_maintenance = property?.active_cards?.internal_maintenance;
  const invoicing = property?.active_cards?.invoicing;
  const violations = property?.active_cards?.violations;
  const announcements = property?.active_cards?.announcements;
  const advertising = property?.active_cards?.advertising;
  const lost_found = property?.active_cards?.lost_found;
  const security = property?.active_cards?.security;
  const prop_settings = property?.active_cards?.prop_settings;
  const rating_review = property?.active_cards?.rating_review;
  const visitor_access_pass = property?.active_cards?.visitor_access_pass;
  const visitor_log_book = property?.active_cards?.visitor_log_book;

  // For Upcomming Modules

  const dashboards = property?.inactive_cards?.dashboards;
  const facilities = property?.inactive_cards?.facilities;
  const internal_communication =
    property?.inactive_cards?.internal_communication;

  const blueprint = property?.inactive_cards?.blueprint;
  const staff_scheduling = property?.inactive_cards?.staff_scheduling;

  const isMT = localStorage.getItem("user_role") === "Maintenance Technician";
  // const isFM = localStorage.getItem("user_role") === "Facility Manager";
  // const isOwner = localStorage.getItem("user_role") === "Owner";
  const isThirdPT =
    localStorage.getItem("user_role") === "Third Party Technician";
  const isAcc = localStorage.getItem("user_role") === "Accountant";
  const isSO = localStorage.getItem("user_role") === "Security Officer";
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  // On clicking on manage teams
  const manageTeam = () => {};

  // On clicking on manage teams
  const manageUnits = () => {};
  /* Component function definition ends */

  /* Component useEffect starts */
  useEffect(() => {
    (async () => {
      try {
        await dispatch(setLoading(true));
        await dispatch(fetchUserRoles());
        await dispatch(fetchUser());
        await dispatch(setLoading(false));
      } catch (e) {}
    })();
  }, []);

  // Setting role after all user roles loaded
  useEffect(() => {
    let userRole = userRoles?.filter((d) => d?._id === user?.role_id)[0]?.name;
    setRole(userRole);
  }, [userRoles]);
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <>
      <div className="options">
        {/* Manage Units */}
        {units && (
          <IconButton
            className="box d_block"
            onClick={() => {
              navigate("units");
            }}
          >
            {missingUnit && (
              <span className="missing d_block">
                <img
                  src={ExcalmatoryYellow}
                  className="w_100"
                  alt="Missing Icon"
                />
              </span>
            )}
            <div className="icon text-center mx-auto">
              <img className="h_100" src={Unit} alt="Unit Icon" />
            </div>
            <p className="text font_m fw_6 font_grey">
              {/* Manage <br />  */}
              Units
            </p>
          </IconButton>
        )}

        {/* Assign License */}
        {["Client"].includes(role) && (
          <IconButton
            className="box d_block"
            onClick={() => {
              navigate("license/assign");
            }}
          >
            {missingLicense && (
              <span className="missing d_block">
                <img
                  src={ExcalmatoryYellow}
                  className="w_100"
                  alt="Missing Icon"
                />
              </span>
            )}
            <div className="icon text-center mx-auto">
              <img className="h_100" src={License} alt="Unit Icon" />
            </div>
            <p className="text font_m fw_6 font_grey">
              Assign <br /> License
            </p>
          </IconButton>
        )}

        {/* Manage Teams  */}
        {teams && (
          <IconButton
            className="box d_block"
            onClick={() => {
              navigate("teams");
            }}
          >
            {missingTeam && (
              <span className="missing d_block">
                <img
                  src={ExcalmatoryYellow}
                  className="w_100"
                  alt="Missing Icon"
                />
              </span>
            )}
            <div className="icon text-center mx-auto">
              <img className="h_100" src={Teams} alt="Unit Icon" />
            </div>
            <p className="text font_m fw_6 font_grey">
              {/* Manage <br /> */}
              Teams
            </p>
          </IconButton>
        )}

        {/* Manage Tenants */}
        {tenants && (
          <IconButton
            className="box d_block"
            onClick={() => {
              navigate("tenants/existing");
            }}
          >
            <div className="icon text-center mx-auto">
              <img className="h_100" src={Tenants} alt="Unit Icon" />
            </div>
            <p className="text font_m fw_6 font_grey">
              {/* Manage <br />  */}
              Tenants
            </p>
          </IconButton>
        )}

        {/* Manage Maintenance */}
        {maintenance && (
          <IconButton
            className="box d_block"
            onClick={() => {
              navigate("maintenances/New");
            }}
          >
            <div className="icon text-center mx-auto">
              <img
                className="h_100"
                src={ManageMaintenance}
                alt="Maintenance Icon"
              />
            </div>
            <p className="text font_m fw_6 font_grey">
              {/* Manage <br />  */}
              Maintenance
            </p>
          </IconButton>
        )}

        {/* Routine Requests */}
        {routine_requests && (
          <IconButton
            className="box d_block"
            onClick={() => {
              navigate(
                `routine-request/${
                  isMT || isThirdPT || isAcc || isSO
                    ? "request/open"
                    : "task/active"
                }`
              );
            }}
          >
            <div className="icon text-center mx-auto">
              <img className="h_100" src={RoutineRequest} alt="Routine Icon" />
            </div>
            <p className="text font_m fw_6 font_grey">
              Routine <br /> Requests
            </p>
          </IconButton>
        )}

        {/* Internal Maintenance Requests */}
        {internal_maintenance && (
          <IconButton
            className="box d_block"
            onClick={() => {
              navigate("internal/maintenances/new");
            }}
          >
            <div className="icon text-center mx-auto">
              <img
                className="h_100"
                src={InternalMaintenance}
                alt="Internal Icon"
              />
            </div>
            <p className="text font_m fw_6 font_grey">
              Internal <br /> Maintenance
            </p>
          </IconButton>
        )}

        {/* INVOICING */}
        {invoicing && (
          <IconButton
            className="box d_block"
            onClick={() => {
              navigate(
                `invoicing/Tenant/${manageModel === 1 ? "other" : "rent"}`
              );
            }}
          >
            <div className="icon text-center mx-auto">
              <img className="h_100" src={Invoicing} alt="Unit Icon" />
            </div>
            <p className="text font_m fw_6 font_grey">Invoicing</p>
          </IconButton>
        )}

        {/* Voilation */}
        {violations && (
          <IconButton
            className="box d_block"
            onClick={() => {
              navigate("violations/tenants");
            }}
          >
            <div className="icon text-center mx-auto">
              <img className="h_100" src={Voilation} alt="Unit Icon" />
            </div>
            <p className="text font_m fw_6 font_grey">Violations</p>
          </IconButton>
        )}

        {/* Announcments */}
        {announcements && (
          <IconButton
            className="box d_block"
            onClick={() => {
              navigate("annoucements");
            }}
          >
            <div className="icon text-center mx-auto">
              <img className="h_100" src={Annoucments} alt="Internal Icon" />
            </div>
            <p className="text font_m fw_6 font_grey">Announcements</p>
          </IconButton>
        )}

        {/* Advertising */}
        {advertising && (
          <IconButton
            className="box d_block"
            onClick={() => {
              navigate("advertisments");
            }}
          >
            <div className="icon text-center mx-auto">
              <img className="h_100" src={Advertising} alt="Internal Icon" />
            </div>
            <p className="text font_m fw_6 font_grey">Advertisements</p>
          </IconButton>
        )}

        {/* Lost And Found */}
        {lost_found && (
          <IconButton
            className="box d_block"
            onClick={() => {
              navigate("lost_and_found/found");
            }}
          >
            <div className="icon text-center mx-auto">
              <img className="h_100" src={LostAndFound} alt="Unit Icon" />
            </div>
            <p className="text font_m fw_6 font_grey">
              Lost & <br /> Found
            </p>
          </IconButton>
        )}

        {/* Manage Security */}
        {security && (
          // manageModel !== 0 &&
          <IconButton
            className="box d_block"
            onClick={() => {
              navigate("security/complaints/open");
            }}
          >
            <div className="icon text-center mx-auto">
              <img className="h_100" src={Security} alt="Unit Icon" />
            </div>
            <p className="text font_m fw_6 font_grey">
              {/* Manage <br />  */}
              Security
            </p>
          </IconButton>
        )}

        {/* Property Settings */}
        {prop_settings && (
          <IconButton
            className="box d_block"
            onClick={() => {
              dispatch(setLoading(true));
              navigate("settings");
            }}
          >
            {missingPropertySetting && (
              <span className="missing d_block">
                <img
                  src={ExcalmatoryYellow}
                  className="w_100"
                  alt="Missing Icon"
                />
              </span>
            )}
            <div className="icon text-center mx-auto">
              <img className="h_100" src={SettingsFilledBlue} alt="Unit Icon" />
            </div>
            <p className="text font_m fw_6 font_grey">
              Property <br /> Settings
            </p>
          </IconButton>
        )}

        {/* Rating And Review */}
        {rating_review && (
          <IconButton
            className="box d_block"
            onClick={() => {
              navigate("ratingAndReviews/tenants");
            }}
          >
            <div className="icon text-center mx-auto">
              <img
                className="h_100"
                src={RatingAndReview}
                alt="Internal Icon"
              />
            </div>
            <p className="text font_m fw_6 font_grey">
              Ratings
              <br />& Reviews
            </p>
          </IconButton>
        )}

        {/* Visitor Access Pass */}
        {visitor_access_pass && manageModel === 2 && (
          <IconButton
            className="box d_block"
            onClick={() => {
              navigate("access_pass/visitors");
            }}
          >
            <div className="icon text-center mx-auto">
              <img className="h_100" src={VisitorPass} alt="Unit Icon" />
            </div>
            <p className="text font_m fw_6 font_grey">
              Visitor <br /> Access Pass
            </p>
          </IconButton>
        )}

        {/* Visitor Log Book */}
        {visitor_log_book && manageModel === 2 && (
          <IconButton
            className="box d_block"
            onClick={() => {
              navigate("visitor-logs");
            }}
          >
            <div className="icon text-center mx-auto">
              <img className="h_100" src={VisitorLogBook} alt="Unit Icon" />
            </div>
            <p className="text font_m fw_6 font_grey">
              Visitors <br /> Log Book
            </p>
          </IconButton>
        )}
      </div>

      <hr
        className="divider"
        // style={{ marginTop: "2rem", border: "2px solid #939393" }}
      />

      <div className="future-options">
        {/* FUTURE FEATURES */}

        {/* Manage Facilities */}

        {facilities && (
          <IconButton
            className="box d_block"
            // onClick={() => {
            //   navigate("/property/manage-facilities/list");
            // }}
          >
            <div className="icon text-center mx-auto">
              <img className="h_100" src={ManageFacilities} alt="Unit Icon" />
            </div>
            <p className="text font_m fw_6 font_grey">
              {/* Manage <br />  */}
              Facilities
            </p>
            <span className="text font_m fw_6 font_yellow my-2">
              Comming Soon!
            </span>
          </IconButton>
        )}

        {/* Internal Communication */}
        {internal_communication && (
          <IconButton
            className="box d_block"
            // onClick={() => {
            //   navigate("/property/internal-communication/list");
            // }}
          >
            <div className="icon text-center mx-auto">
              <img
                className="h_100"
                src={InternalCommunication}
                alt="Routine Icon"
              />
            </div>
            <p className="text font_m fw_6 font_grey">
              Internal <br /> Communication
            </p>
            <span className="text font_m fw_6 font_yellow my-2">
              Comming Soon!
            </span>
          </IconButton>
        )}

        {/* Blue Prints */}
        {blueprint && (
          <IconButton
            className="box d_block"
            // onClick={() => {
            //   navigate("/property/blueprints/list");
            // }}
          >
            <div className="icon text-center mx-auto">
              <img className="h_100" src={BluePrint} alt="Internal Icon" />
            </div>
            <p className="text font_m fw_6 font_grey">Blueprints</p>
            <span className="text font_m fw_6 font_yellow my-2">
              Comming Soon!
            </span>
          </IconButton>
        )}

        {/* Dashboards  */}
        {dashboards && (
          <IconButton
            className="box d_block"
            // onClick={() => {
            //   navigate("dashboard");
            // }}
          >
            <div className="icon text-center mx-auto">
              <img className="h_100" src={Dashboard} alt="Unit Icon" />
            </div>
            <p className="text font_m fw_6 font_grey">Dashboards</p>
            <span className="text font_m fw_6 font_yellow my-2">
              Comming Soon!
            </span>
          </IconButton>
        )}

        {/* Staff Schedule */}
        {staff_scheduling && (
          <IconButton
            className="box d_block"
            // onClick={() => {
            //   navigate("/property/staff-scheduling");
            // }}
          >
            <div className="icon text-center mx-auto">
              <img className="h_100" src={StaffSchedule} alt="Unit Icon" />
            </div>
            <p className="text font_m fw_6 font_grey">
              Staff <br /> Scheduling
            </p>
            <span className="text font_m fw_6 font_yellow my-2">
              Comming Soon!
            </span>
          </IconButton>
        )}
      </div>
    </>
  );
};
/* Component ends */

export default Modules;
