/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import GLOBAL from "../../../../../../setup/constants/global";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
/* Import configuration ends */

/* Import redux slices component starts */
import {
  fetchTenantInvoiceById,
  getTenantInvoice,
} from "../../../../../../setup/store/slices/invoiceSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
import {
  DocumentGreen,
  ExcalmatoryYellow,
  EyeFilledBlue,
  LocationPinFilledGrey,
  PrintIcon,
} from "../../../../../../setup/constants/images";
/* Import image and SVG ends */

/* Import local pages and component starts */
import TransactionStatus from "../../../../Payment/TransactionStatus/TransactionStatus";
import PaymentMethod from "../../../../Payment/PaymentMethod/PaymentMethod";
import FileViewerModal from "../../../../../ui/modal/FileViewerModal/FileViewerModal";
import { Table } from "react-bootstrap";
import IconButton from "../../../../../ui/button/IconButton/IconButton";
import InvoiceDetailModal from "../../../ClientInvoicing/InvoicingDetails/InvoiceDetailModal/InvoiceDetailModal";
/* Import local pages and component ends */

/* Component starts */
const TenantInvoiceDetail = (props) => {
  /* Props destructuring starts */
  const {} = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [transactionStatus, setTransactionStatus] = useState({});
  const [showTransactionStatus, setShowTransactionStatus] = useState(false);

  const [showFileViewer, setShowFileViewer] = useState(false);
  const [file, setFile] = useState({});

  const [showContractDetailModal, setShowContractDetailModal] = useState(false);
  const [detail, setDetail] = useState({});
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const dispatch = useDispatch();
  const { id } = useParams();
  const tenantInvoice = useSelector(getTenantInvoice);
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const application_id = tenantInvoice?.invoice?.tenant_application;
  const property_id = tenantInvoice?.invoice?.property?._id;
  const invoice_id = tenantInvoice?.invoice?._id;
  const stripePublicKey =
    tenantInvoice?.invoice?.payment_processor_details?.stripe_public_key;

  const USER_DATA = localStorage.getItem("user_data")
    ? JSON.parse(localStorage.getItem("user_data"))
    : null;
  const DATE_FORMAT = USER_DATA ? USER_DATA?.date_format : "";
  const TIME_FORMAT = USER_DATA ? USER_DATA?.time_format : "";
  const DATE_TIME_FORMAT = `${DATE_FORMAT} ${TIME_FORMAT}`;

  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */

  const getStatus = (status) => {
    let txt_status = status;
    let statusColor = "";
    switch (status) {
      case "pending_clearance":
        statusColor = "font_yellow";
        txt_status = "Pending Clearance";
        break;
      case "bounced":
        statusColor = "font_yellow";
        txt_status = "Bounced";
        break;
      case "succeeded":
        statusColor = "font_green";
        txt_status = "Success";
        break;
      // case GLOBAL.SECURITY_STATUS.SO_ASSIGNED:
      //   statusColor = "font_yellow";
      //   txt_status = "Security Officer Assigned";
      //   break;
      // case GLOBAL.SECURITY_STATUS.RESOLVED:
      //   statusColor = "font_green";
      //   txt_status = "resolved";
      //   break;
    }
    return (
      <span className={`font_s fw_4 ${statusColor}`}>
        {txt_status.toUpperCase()}
      </span>
    );
  };

  function printPDF(url) {
    // Open PDF in a new window/tab
    const newWindow = window.open(url);

    // Wait for the new window/tab to load
    newWindow.onload = function () {
      // Trigger print dialog
      newWindow.print();
    };
  }

  const handleView = (item, tenant) => {
    setDetail(item);
    setShowContractDetailModal(!showContractDetailModal);
  };

  /* Component function definition ends */

  /* Component useEffect starts */
  useEffect(() => {
    if (id) {
      dispatch(fetchTenantInvoiceById(id));
    }
  }, [id]);
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  // On generating transaction status after payment
  if (showTransactionStatus) {
    return (
      <div className="">
        <TransactionStatus
          onSuccessClose={() => {
            setShowTransactionStatus(false);
          }}
          onFailedClose={() => {
            setShowTransactionStatus(false);
          }}
          status={transactionStatus}
          setShowTransactionStatus={setShowTransactionStatus}
        />
      </div>
    );
  }

  return (
    <>
      <h1 className="text-center font_xxl mb-4">View Tenant Invoice</h1>

      <div className="detail-card bg_grey">
        <div className="d-flex justify-content-between">
          <p>
            Invoice#{" "}
            <span className="font_blue fw_6">
              {tenantInvoice?.invoice?.invoice_num}
            </span>
          </p>
          <p className="font_m fw_3 font_grey">
            Type :
            <span className="font_m fw_6 font_yellow">
              {" "}
              {tenantInvoice?.invoice?.type?.charAt(0).toUpperCase() +
                tenantInvoice?.invoice?.type?.slice(1)}
            </span>
          </p>
        </div>

        <hr />

        <div className="property-wrapper">
          <div className="property-data">
            <div>
              <span className="font_m fw_6 font_blue">
                {tenantInvoice?.invoice?.property?.property_name}
              </span>
            </div>

            <div>
              <p className="font_m fw_3 font_grey">
                Unit :{" "}
                <span className="font_m fw_6 font_blue">
                  {tenantInvoice?.invoice?.unit}
                </span>
              </p>
            </div>
          </div>

          <div className="property-data">
            <div>
              <img src={LocationPinFilledGrey} alt="Location-Pin" />
              <span className="font_s fw_4 font_grey">
                {tenantInvoice?.invoice?.property?.city},{" "}
                {tenantInvoice?.invoice?.property?.state},{" "}
                {tenantInvoice?.invoice?.property?.country}
              </span>
            </div>

            <div>
              <p className="font_m fw_3 font_grey">
                Tenant Name :{" "}
                <span className="font_m fw_6 font_blue">
                  {tenantInvoice?.invoice?.tenant}
                </span>
              </p>
            </div>
          </div>
        </div>

        <hr />

        <div className="invoice-data">
          <p className="font_m fw_3 font_grey">
            Invoice Date <br />
            <span className="font_m fw_4">
              {moment(tenantInvoice?.invoice?.invoice_date).format(DATE_FORMAT)}
            </span>
          </p>

          <p className="font_m fw_3 font_grey">
            Due Date <br />
            <span className="font_m fw_4">
              {moment(tenantInvoice?.invoice?.due_date).format(DATE_FORMAT)}
            </span>
          </p>
        </div>

        <hr />

        <div className="status-wrapper">
          <p className="font_m fw_4">Status</p>
          <p
            className={`font_m fw_6
        ${
          tenantInvoice?.invoice?.status === "paid" ||
          tenantInvoice?.invoice?.status === "collected"
            ? "font_green"
            : tenantInvoice?.invoice?.status === "unpaid" ||
              tenantInvoice?.invoice?.status === "not_collected"
            ? "font_yellow"
            : "font_red"
        }
        `}
          >
            {tenantInvoice?.invoice?.status === "collected"
              ? "Collected"
              : tenantInvoice?.invoice?.status === "partially_collected"
              ? "Partially Collected"
              : tenantInvoice?.invoice?.status === "not_collected"
              ? "Not Collected"
              : tenantInvoice?.invoice?.status === "partially_settled"
              ? "Partially Settled"
              : tenantInvoice?.invoice?.status === "settled"
              ? "Settled"
              : tenantInvoice?.invoice?.status?.charAt(0).toUpperCase() +
                tenantInvoice?.invoice?.status?.slice(1)}
          </p>
        </div>

        <hr />

        {/* <div className="payment-data">
          <p className="font_m fw_3">Invoice Amount</p>

          <p>
            <span className="font_s fw_4">
              Amount :{" "}
              {tenantInvoice?.invoice?.amount !== undefined
                ? tenantInvoice?.invoice?.amount?.toLocaleString("en-US")
                : "0"}
            </span>
            <br />
            <span className="font_s fw_4">
              VAT:{" "}
              {tenantInvoice?.invoice?.vat_amount !== undefined
                ? tenantInvoice?.invoice?.vat_amount?.toFixed(2)
                : "0"}
            </span>
            <br />
            <span className="font_s fw_4">
              Tax:{" "}
              {tenantInvoice?.invoice?.tax_amount !== undefined
                ? tenantInvoice?.invoice?.tax_amount?.toFixed(2)
                : "0"}
            </span>
            <hr />
            <span className="font_s fw_4">
              Total :{" "}
              <span className="font_m fw_6 font_blue">
                {tenantInvoice?.invoice?.total !== undefined
                  ? `${tenantInvoice?.invoice?.total?.toLocaleString(
                      "en-US"
                    )}${" "}${tenantInvoice?.invoice?.currency}`
                  : "-"}
              </span>
            </span>
          </p>
        </div> */}

        <div className="invoice-data-price">
          {/* <p className="font_m fw_4 font_grey">
            Description <br />
            <span className="font_xs fw_3">
              {tenantInvoice?.invoice?.description !== undefined
                ? tenantInvoice?.invoice?.description
                : "-"}
            </span>
          </p> */}

          {tenantInvoice?.invoice?.type !== "rent" && (
            <p className="font_m fw_4 font_grey">
              Description <br />
              <span className="font_xs fw_3">
                {tenantInvoice?.invoice?.description !== undefined
                  ? tenantInvoice?.invoice?.description
                  : "-"}
              </span>
            </p>
          )}

          {tenantInvoice?.invoice?.type === "rent" && (
            <p className="font_m fw_4 font_grey">
              Description <br />
              <span className="font_m fw_6">
                {tenantInvoice?.invoice?.instaName !== undefined
                  ? tenantInvoice?.invoice?.instaName
                  : "-"}
              </span>
            </p>
          )}

          <p className="font_m fw_4 font_grey">
            Quantity <br />
            <span className="font_m fw_4">
              {tenantInvoice?.invoice?.quantity !== undefined
                ? tenantInvoice?.invoice?.quantity
                : "1"}
            </span>
          </p>

          <div>
            <p className="font_m fw_4 font_grey">
              Invoice Amount <br />
            </p>
            <p>
              <span className="font_s fw_4">
                Amount :{" "}
                {tenantInvoice?.invoice?.amount?.toLocaleString("en-US")}
              </span>
              <br />
              <span className="font_s fw_4">
                VAT:{" "}
                {tenantInvoice?.invoice?.vat_amount
                  ?.toFixed(2)
                  ?.toLocaleString("en-US")}
              </span>
              <br />
              <span className="font_s fw_4">
                Tax:{" "}
                {tenantInvoice?.invoice?.tax_amount
                  ?.toFixed(2)
                  ?.toLocaleString("en-US")}
              </span>
              <hr />
              <span className="font_s fw_4">
                Total :{" "}
                <span className="font_m fw_6 font_blue">
                  {tenantInvoice?.invoice?.total !== undefined
                    ? `${tenantInvoice?.invoice?.total?.toLocaleString(
                        "en-US"
                      )}${" "}${tenantInvoice?.invoice?.currency}`
                    : "-"}
                </span>
              </span>
            </p>
          </div>
        </div>

        {tenantInvoice?.invoice?.invoice_pdf && (
          <>
            <hr />
            <div className="doc-wrapper">
              <p className="font_m fw_4 pr-2">Invoice</p>

              {tenantInvoice?.invoice?.invoice_pdf && (
                <>
                  <div className="doc">
                    <img
                      src={DocumentGreen}
                      onClick={() => {
                        setFile(tenantInvoice?.invoice?.invoice_pdf);
                        setShowFileViewer(true);
                      }}
                    />
                    <img
                      src={PrintIcon}
                      onClick={() => {
                        printPDF(tenantInvoice?.invoice?.invoice_pdf?.url);
                      }}
                    />
                  </div>
                </>
              )}
            </div>
          </>
        )}

        <hr />

        <div className="payment-data">
          <p className="font_l fw_4">Payment Details</p>

          {tenantInvoice?.invoice?.invoice_transaction?.length > 0 ? (
            <div className="trans-table">
              <Table className="custom-table font_s" borderless responsive>
                {/* Table Heading */}
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Amount</th>
                    <th>Method</th>
                    <th>Date</th>
                    {/* <th>Received By</th> */}
                    <th>Status</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                {/* Table Body */}
                <tbody>
                  {tenantInvoice?.invoice?.invoice_transaction?.map(
                    (trans, idx) => (
                      <tr key={trans._id}>
                        {/* ID */}
                        <td>{idx + 1}</td>

                        {/* Payment Amount */}
                        <td>
                          {trans?.associated_invoices?.length > 0
                            ? tenantInvoice?.invoice?.amount?.toLocaleString(
                                "en-US"
                              )
                            : trans?.payment_amount?.toLocaleString("en-US") ||
                              trans?.cheque_amount?.toLocaleString("en-US") ||
                              trans?.total_amount?.toLocaleString("en-US")}{" "}
                          {tenantInvoice?.invoice?.currency}
                        </td>

                        {/* Payment Method */}
                        <td className="font_blue">
                          {
                            trans?.payment_method?.charAt(0).toUpperCase() +
                              trans?.payment_method?.slice(1)
                            //  ||
                            // tenantInvoice?.invoice?.method
                            //   ?.charAt(0)
                            //   .toUpperCase() +
                            //   tenantInvoice?.invoice?.method?.slice(1)
                          }
                        </td>

                        {/* Date */}
                        <td>
                          {moment(trans?.createdAt).format(DATE_TIME_FORMAT)}
                        </td>

                        {/* Payment Recieved By */}
                        {/* <td>
                      {trans?.received_by?.first_name}{" "}
                      {trans?.received_by?.last_name}
                    </td> */}

                        {/* Status */}
                        {/* <td>
                        {trans?.sub_status ||
                          trans?.status?.charAt(0).toUpperCase() +
                            trans?.status?.slice(1)}
                      </td> */}

                        <td>{getStatus(trans?.status)}</td>

                        {/* Actions */}
                        <td>
                          <IconButton
                            className="action-button"
                            onClick={() =>
                              handleView(trans, tenantInvoice?.invoice?.tenant)
                            }
                          >
                            <img src={EyeFilledBlue} className="h_100" />
                          </IconButton>
                        </td>
                      </tr>
                    )
                  )}
                </tbody>
              </Table>
            </div>
          ) : (
            <div className="pay-data text-center">
              <h3 className=" font_m font_yellow fw_6">No Payment Details</h3>
            </div>
          )}
        </div>
      </div>

      {tenantInvoice?.invoice?.is_under_process ? (
        <>
          <div className="payment-method-check">
            <img src={ExcalmatoryYellow} alt="Doubt" className="w_100" />
            <p className="font_xl font_yellow fw_5">
              Please wait till your invoices are getting processed by{" "}
              {tenantInvoice?.invoice?.mgmt_model === 2
                ? "Accountant"
                : tenantInvoice?.invoice?.mgmt_model === 1
                ? "Facility Manager"
                : "Owner"}
            </p>
          </div>
        </>
      ) : (
        <>
          {tenantInvoice?.invoice?.is_payable_by_cheque ? (
            <>
              <div className="payment-method-check">
                <img src={ExcalmatoryYellow} alt="Doubt" className="w_100" />
                <p className="font_xl font_yellow fw_5">
                  Please reach to{" "}
                  {tenantInvoice?.invoice?.mgmt_model === 2
                    ? "Accountant"
                    : tenantInvoice?.invoice?.mgmt_model === 1
                    ? "Facility Manager"
                    : "Owner"}{" "}
                  to submit cheque{" "}
                </p>
              </div>
            </>
          ) : (
            <>
              {tenantInvoice?.invoice?.online_payment ? (
                <PaymentMethod
                  size="l"
                  setTransactionStatus={setTransactionStatus}
                  setShowTransactionStatus={setShowTransactionStatus}
                  receiverStripeKey={stripePublicKey}
                  payload={{ application_id, property_id, invoice_id }}
                  paymentType={GLOBAL.PAYMENT_TYPE.TENANT_INVOICE}
                />
              ) : (
                <>
                  <div className="payment-method-check">
                    <img
                      src={ExcalmatoryYellow}
                      alt="Doubt"
                      className="w_100"
                    />
                    <p className="font_xl font_yellow fw_5">
                      Please reach to{" "}
                      {tenantInvoice?.invoice?.mgmt_model === 2
                        ? "Accountant"
                        : tenantInvoice?.invoice?.mgmt_model === 1
                        ? "Facility Manager"
                        : "Owner"}{" "}
                      to clear invoice{" "}
                    </p>
                  </div>
                </>
              )}
            </>
          )}
        </>
      )}

      {/* File viewer modal */}
      <FileViewerModal
        show={showFileViewer}
        type={file?.type}
        file={file?.url}
        onClose={() => {
          setShowFileViewer(false);
          setFile({});
        }}
      />

      <InvoiceDetailModal
        showModal={showContractDetailModal}
        detail={detail}
        currency={tenantInvoice?.invoice?.currency}
        onClose={() => {
          setShowContractDetailModal(false);
        }}
      />
    </>
  );
};
/* Component ends */

export default TenantInvoiceDetail;
