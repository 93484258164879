/**
 *
 */

import React, { useEffect, useState, useRef } from "react";

/* Import configuration starts */
import { useDispatch, useSelector } from "react-redux";
import { Formik } from "formik";
import { Toastify } from "../../../../setup/utils/toast";
import { useNavigate, useParams } from "react-router-dom";
/* Import configuration ends */

/* Import redux slices component starts */
import {
  addProperty,
  fetchPropertyTypes,
  getPropertyTypes,
} from "../../../../setup/store/slices/propertySlice";
import { getUser, fetchUser } from "../../../../setup/store/slices/authSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import MUI component starts */
/* Import MUI component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import CustomButton from "../../../ui/button/CustomButton/CustomButton";
import CustomTextInput from "../../../ui/input/CustomTextInput/CustomTextInput";
import CustomSelectInput from "../../../ui/input/CustomSelectInput/CustomSelectInput";
import TextButton from "../../../ui/button/TextButton/TextButton";
/* Import local pages and component ends */

import "./style.scss";
import { setLoading } from "../../../../setup/store/slices/unpersistedSlice";

/* Component starts */
const AddPropertyUnderCommunity = (props) => {
  /* Props destructuring starts */
  const { onSuccess, onCancel } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [selectedPropertyType, setSelectedPropertyType] = useState(null);
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { communityId } = useParams();
  // Redux Selector
  const propertyTypes = useSelector(getPropertyTypes);
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const initialFormValues = {
    property_name: "",
  };
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  // Form validations
  const formValidation = (values) => {
    const error = {};

    /* Validation for Property Name starts */
    if (values.property_name.trim() === "") {
      error.property_name = "Property Name is required";
    }
    /* Validation for Property Name ends */

    return error;
  };

  // Adding property with community id
  const addPropertyWithCommunity = async (values) => {
    // Creating or collecting payload data to be sent
    let payload = {
      property_name: values.property_name,
      community_id: values.community_id,
      property_type_id: selectedPropertyType._id,
      is_part_community: "Yes",
    };

    // Backend response. Try, Catch
    try {
      dispatch(setLoading(true));
      const result = await dispatch(addProperty(payload));

      // Handling success response
      switch (result.meta.requestStatus) {
        case "fulfilled":
          navigate(
            // `?property_id=${result.payload._id}&community_id=${values.community_id}`
            `resume/${result?.payload?._id}`
          );
          dispatch(setLoading(false));
          Toastify("success", "Property added successfully!");
          onSuccess();
          break;

        case "rejected":
          dispatch(setLoading(false));
          Toastify("error", result.payload);
          break;

        default:
      }
    } catch (e) {
      dispatch(setLoading(false));
      // Handling error response
      console.error(e.message);
    }
  };

  // Handling on submit
  const handleSubmit = async (values) => {
    addPropertyWithCommunity({
      ...values,
      community_id: communityId,
    });
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  // On initial load
  useEffect(() => {
    dispatch(fetchPropertyTypes());
    dispatch(fetchUser());
  }, []);

  // Setting selected property types on default load of all property types
  useEffect(() => {
    if (propertyTypes != null) {
      setSelectedPropertyType(propertyTypes[0]);
    }
  }, [propertyTypes]);
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="add-property-under-community-wrapper-main">
      <h2 className="heading text-center font_xxl mb-5">
        Add Property Under Community
      </h2>
      <Formik
        initialValues={initialFormValues}
        validate={formValidation}
        onSubmit={handleSubmit}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          isValid,
        }) => (
          <form onSubmit={handleSubmit}>
            <div className="form-wrapper bg_grey">
              {/* Input for Property Name */}
              <div className="form-group">
                <CustomTextInput
                  name="property_name"
                  label="Property Name"
                  value={values.property_name}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  touched={touched.property_name}
                  errors={errors.property_name}
                />
              </div>

              {/* Input for Proprty Type */}
              <div className="form-group">
                <CustomSelectInput
                  name="property_type"
                  label="Property Type"
                  handleBlur={handleBlur}
                  onChange={handleChange}
                  valueExtractor={(item) => item?.name}
                  setValue={setSelectedPropertyType}
                  options={propertyTypes}
                  value={selectedPropertyType}
                  formikState={false}
                  emptyOption={false}
                />
              </div>
            </div>

            {/* Submit and Cancel button wrapper */}
            <div className="button-wrapper">
              <CustomButton type="submit" size="l">
                Add
              </CustomButton>
              <TextButton
                type="button"
                onClick={onCancel}
                className="fw_6 w_100 font_m"
              >
                Cancel
              </TextButton>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};
/* Component ends */

export default AddPropertyUnderCommunity;
