import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../axios";
import { PLATFORM } from "../../platformUtil";
import { API_URL } from "../../config/devKeys";
import { setLoading } from "./unpersistedSlice";
import {
  fetchTenantApplicationById,
  fetchTenantContracts,
} from "./tenantSlice";

const initialState = {
  contracts: [],
  move_out_requests: [],
};

export const fetchContractRenewalDetails = createAsyncThunk(
  "tenant/property/contracts",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const { data } = await axios.get(
        `${API_URL}/contract_renewal/get_contract_detail?application_id=${payload}`,
        // `http://localhost:3000/contract_renewal/get_contract_detail?application_id=${payload}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      dispatch(setLoading(false));
      if (data.success) {
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response.message);
    }
  }
);

export const tenantRejectRenewal = createAsyncThunk(
  "move_out/tenant_cancel_renewal",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const { data } = await axios.post(
        `${API_URL}/move_out/tenant_cancel_renewal`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      if (data.success) {
        await dispatch(fetchTenantContracts({ unit_id: payload?.unit_id }));
        await dispatch(setLoading(false));
        return data.data;
      } else {
        dispatch(setLoading(false));
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const fetchMoveOutRequestsByProperty = createAsyncThunk(
  // Done
  "move_out/by_property",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const { property_id, page } = payload;
      dispatch(setLoading(true));
      const { data } = await axios.post(
        API_URL + "/contract_renewal/get_move_out_request",
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      dispatch(setLoading(false));
      if (data.success) {
        return data.data;
      } else {
        return rejectWithValue(data);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const fetchTenancyContracts = createAsyncThunk(
  "tenant/contracts/list",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const { data } = await axios.post(
        API_URL + "/tenant/contracts",
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      dispatch(setLoading(false));
      if (data.success) {
        return data.data;
      } else {
        return rejectWithValue(data);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const requestAmendment = createAsyncThunk(
  "tenant/contract/request_amendment",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const { unit_id } = payload;
      dispatch(setLoading(true));
      const { data } = await axios.post(
        API_URL + "/contract_renewal/request_amendment",
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      dispatch(setLoading(false));
      if (data.success) {
        await dispatch(fetchTenantContracts({ unit_id }));
        return data.data;
      } else {
        return rejectWithValue(data);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const respondAmendment = createAsyncThunk(
  "tenant/contract/respond_amendment",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const { application_id, unit_id } = payload;
      dispatch(setLoading(true));
      const { data } = await axios.post(
        API_URL + "/contract_renewal/respond_amendment",
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      dispatch(setLoading(false));
      if (data.success) {
        await dispatch(fetchTenantApplicationById(application_id));
        await dispatch(fetchTenantContracts({ unit_id }));
        return data.data;
      } else {
        return rejectWithValue(data);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const acceptContractRenewalApplication = createAsyncThunk(
  "tenant/contract/accept_contract_renewal",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const { unit_id, property_id } = payload;
      dispatch(setLoading(true));
      const { data } = await axios.post(
        API_URL + "/contract_renewal/accept_renewal_application",
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      dispatch(setLoading(false));
      if (data.success) {
        await dispatch(fetchContractsByProperty({ property_id }));
        return data.data;
      } else {
        return rejectWithValue(data);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const fetchContractsByProperty = createAsyncThunk(
  "tenant/property/contracts",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const { property_id } = payload;
      dispatch(setLoading(true));
      const { data } = await axios.post(
        `${API_URL}/contract_renewal/get_contract_list/${property_id}`,
        // `http://localhost:3000/contract_renewal/get_contract_list/${property_id}`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      dispatch(setLoading(false));
      if (data.success) {
        return data.data;
      } else {
        return rejectWithValue(data);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

const contractSlice = createSlice({
  name: "contract",
  initialState: initialState,
  reducers: {},
  extraReducers: {
    [fetchTenancyContracts.rejected]: (state, action) => {
      state.contracts = [];
    },
    [fetchTenancyContracts.fulfilled]: (state, action) => {
      state.contracts = action.payload;
    },
    [fetchContractsByProperty.rejected]: (state, action) => {
      state.contracts = [];
    },
    [fetchContractsByProperty.fulfilled]: (state, action) => {
      state.contracts = action.payload;
    },
    [fetchMoveOutRequestsByProperty.rejected]: (state, action) => {
      state.move_out_requests = [];
    },
    [fetchMoveOutRequestsByProperty.fulfilled]: (state, action) => {
      state.move_out_requests = action.payload;
    },
  },
});

export const getTenancyContracts = (state) => state.contract.contracts;
export const getMoveOutRequests = (state) => state.contract.move_out_requests;

export default contractSlice.reducer;
