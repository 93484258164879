/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
/* Import configuration ends */

/* Import redux slices component starts */
import {
  fetchAdvertisementById,
  getAdvertisementById,
} from "../../setup/store/slices/advertisementSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import Header from "./local-component/Header";
import AdvertismentChart from "./local-component/AdvertismentChart";
import BreadCrumb from "../../components/ui/other/BreadCrumb/BreadCrumb";
import AdDetails from "./local-component/AdDetails";
/* Import local pages and component ends */

import "./style.scss";

/* Component starts */
const AdvertismentDetail = (props) => {
  /* Props destructuring starts */
  const {} = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const { advertisementId } = useParams();
  const dispatch = useDispatch();
  const advertisementDetail = useSelector(getAdvertisementById);
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  /* Component function definition ends */

  /* Component useEffect starts */
  useEffect(() => {
    if (advertisementId) {
      dispatch(fetchAdvertisementById(advertisementId));
    }
  }, [advertisementId]);
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <>
      <BreadCrumb type="advertisment_detail" />
      <div className="advertisment-detail-wrapper">
        <div className="header-advertsiment">
          <Header />
        </div>

        <div className="bg_grey ad-chart-details">
          <AdvertismentChart details={advertisementDetail} />
        </div>

        <AdDetails details={advertisementDetail} />
      </div>
    </>
  );
};
/* Component ends */

export default AdvertismentDetail;
