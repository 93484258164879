/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import { Link, useLocation, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import GLOBAL from "../../../setup/constants/global";
/* Import configuration ends */

/* Import redux slices component starts */
import { showErrorAlert } from "../../../setup/store/slices/globalAlertSlice";
import {
  fetchUserRoles,
  getUserRoles,
} from "../../../setup/store/slices/masterSlice";
import { fetchUser, getUser } from "../../../setup/store/slices/authSlice";
import {
  fetchAnnoucementsList,
  fetchAnnoucementsListBasedCommunity,
  fetchAnnoucementsListBasedProperty,
  fetchUsersAnnoucementsList,
  getAnnoucementsList,
  getCommunityAnnoucementsList,
  getPropertyAnnoucementsList,
  getUsersAnnoucementsList,
} from "../../../setup/store/slices/annoucementSlice";
import { setLoading } from "../../../setup/store/slices/unpersistedSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import Searchbar from "../../../components/ui/other/Searchbar/Searchbar";
import AnnoucementsTable from "../../../components/module/Annoucemnets/AnnoucementsTable/AnnoucementsTable";
import BreadCrumb from "../../../components/ui/other/BreadCrumb/BreadCrumb";
/* Import local pages and component ends */

// styles sheet
import "./style.scss";
import {
  fetchPropertyDetailByID,
  getPropertyDetail,
} from "../../../setup/store/slices/propertySlice";

/* Component starts */
const Annoucements = (props) => {
  /* Props destructuring starts */
  const {} = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [annoucements, setAnnoucements] = useState();
  const [role, setRole] = useState();
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const { pathname } = useLocation();
  const param = useParams();

  const dispatch = useDispatch();
  const userRoles = useSelector(getUserRoles);
  const user = useSelector(getUser);
  const annoucementList = useSelector(getAnnoucementsList);
  const propertyAnnoucementList = useSelector(getPropertyAnnoucementsList);
  const communityAnnoucementList = useSelector(getCommunityAnnoucementsList);
  const propertyDetail = useSelector(getPropertyDetail);
  // const userAnnoucementList = useSelector(getUsersAnnoucementsList);
  /* Other hooks declaration ends */

  // Redux Selectors

  /* Component variable declaration / object destructure starts */

  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  const loadAnnoucementList = async (page, search = "") => {
    // Creating or collecting payload data to be sent
    const payload = { page };

    if (search !== "") {
      payload["search"] = search;
    }

    console.log("Payload", payload);

    // Backend response. Try, Catch
    try {
      let result = {};

      if (pathname.includes("communities")) {
        result = await dispatch(
          fetchAnnoucementsListBasedCommunity({
            community_id: param?.communityId,
            ...payload,
          })
        );
      } else if (pathname.includes("independent")) {
        result = await dispatch(
          fetchAnnoucementsListBasedProperty({
            property_id: param?.propertyId,
            ...payload,
          })
        );
      } else {
        result = await dispatch(fetchAnnoucementsList());
      }

      console.log("Response", result);

      // Handling success response
      switch (result?.meta?.requestStatus) {
        case GLOBAL.REJECTED:
          dispatch(
            showErrorAlert({
              title: "Error!",
              description:
                typeof result?.payload === "string" ? result?.payload : "",
            })
          );
          break;

        case GLOBAL.FULFILLED:
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  useEffect(() => {
    if (searchQuery !== "") {
      loadAnnoucementList(currentPage, searchQuery);
    } else {
      loadAnnoucementList(currentPage, "");
    }
  }, [pathname, currentPage]);

  useEffect(() => {
    let timeoutid = null;
    if (searchQuery !== "") {
      timeoutid = setTimeout(() => {
        loadAnnoucementList(currentPage, searchQuery);
      }, 1000);
    } else {
      loadAnnoucementList(currentPage, "");
    }

    return () => {
      if (timeoutid) {
        clearTimeout(timeoutid);
      }
    };
  }, [searchQuery]);

  useEffect(() => {
    // if (pathname.includes("independent") && userAnnoucementList) {
    //   setAnnoucements(userAnnoucementList);
    // } else
    if (pathname.includes("communities") && communityAnnoucementList) {
      setAnnoucements(communityAnnoucementList);
    } else if (pathname.includes("independent") && propertyAnnoucementList) {
      setAnnoucements(propertyAnnoucementList);
    } else {
      setAnnoucements(annoucementList);
    }
  }, [
    annoucementList,
    pathname,
    communityAnnoucementList,
    propertyAnnoucementList,
    // userAnnoucementList,
  ]);

  useEffect(() => {
    (async () => {
      try {
        await dispatch(setLoading(true));
        await dispatch(fetchUserRoles());
        await dispatch(fetchUser());
        await dispatch(
          fetchPropertyDetailByID({ property_id: param?.propertyId })
        );
        await dispatch(setLoading(false));
      } catch (e) {}
    })();
  }, []);

  // Setting role after all user roles loaded
  useEffect(() => {
    let userRole = userRoles.filter((d) => d._id === user?.role_id)[0]?.name;
    setRole(userRole);
  }, [userRoles]);

  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */

  return (
    <>
      <BreadCrumb type="client_annoucement" />
      <div className="annoucement-wrapper">
        <div className="heading-wrapper">
          <div className="heading">
            <h2 className="font_xxl fw_5">Announcements</h2>
          </div>

          {/* (role === "Client" ||
            role === "Property Manager" ||
            role === "Owner")  */}

          {(((role === "Client" || role === "Facility Manager") &&
            (propertyDetail?.mgmt_model === 1 || pathname?.includes("home"))) ||
            ((role === "Client" || role === "Owner") &&
              propertyDetail?.mgmt_model === 0) ||
            ((role === "Client" || role === "Property Manager") &&
              (propertyDetail?.mgmt_model === 2 ||
                pathname?.includes("home")))) && (
            <div className="register-wrapper">
              <Link to="add" className="font_m fw_4 d_block link-security">
                + Create Announcement
              </Link>
            </div>
          )}
        </div>

        <Searchbar query={searchQuery} setQuery={setSearchQuery} />

        <AnnoucementsTable
          annoucementListData={
            annoucements?.result ? annoucements?.result : annoucements
          }
          totalCount={
            annoucements?.totalCount ? annoucements?.totalCount : null
          }
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </div>
    </>
  );
};
/* Component End */

export default Annoucements;
