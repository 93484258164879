/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import moment from "moment";
import GLOBAL from "../../../../../setup/constants/global";
/* Import configuration ends */

/* Import redux slices component starts */
import {
  adjustFromDeposit,
  updateTenantInvoice,
} from "../../../../../setup/store/slices/invoiceSlice";
import {
  showSuccessAlert,
  showErrorAlert,
} from "../../../../../setup/store/slices/globalAlertSlice";
import {
  fetchT2TenantInvoiceDetails,
  fetchTenantInvoiceDetails,
} from "../../../../../setup/store/slices/tenatDetailSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
import { Table } from "react-bootstrap";
/* Import react bootstrap component ends */

/* Import image and SVG starts */
import {
  EditGreen,
  ExcalmatoryYellow,
  EyeFilledBlue,
} from "../../../../../setup/constants/images";
/* Import image and SVG ends */

/* Import local pages and component starts */
import IconButton from "../../../../ui/button/IconButton/IconButton";
import { pathNamePopped } from "../../../../../setup/utils/global-helper";

import DetailViewModal from "./DetailViewModal/DetailViewModal";
import PaymentModal from "../../../Invoicing/ClientInvoicing/PaymentModal/PaymentModal";

/* Import local pages and component ends */

/* Component starts */
const Invoices = (props) => {
  /* Props destructuring starts */
  const { invoiceList, property, unitID } = props;

  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [showContractDetailModal, setShowContractDetailModal] = useState(false);
  const [detail, setDetail] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [selectedInvoice, setSelectedInvoice] = useState();
  const [fromInvoice, setFromInvoice] = useState(true);
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const { tenantId, unitId } = useParams();
  const dispatch = useDispatch();
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const USER_DATA = localStorage.getItem("user_data")
    ? JSON.parse(localStorage.getItem("user_data"))
    : null;
  const DATE_FORMAT = USER_DATA ? USER_DATA?.date_format : "";

  const isOwner = localStorage.getItem("user_role") === "Owner";
  const isObserver = localStorage.getItem("user_role") === "Observer";
  const isFM = localStorage.getItem("user_role") === "Facility Manager";
  const isAccountant = localStorage.getItem("user_role") === "Accountant";
  // const isClient = localStorage.getItem("user_role") === "Client";

  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */

  // const handlePaymentModal = (item) => {
  //   setInvoiceData(item);
  //   setShowModal(!showModal);
  // };

  const onSuccess = () => {
    setShowModal(!showModal);
  };

  const handleView = (item) => {
    setDetail(item);
    setShowContractDetailModal(!showContractDetailModal);
  };

  const handlePaymentModal = (item) => {
    setSelectedInvoice(item);
    setShowModal(!showModal);
  };

  const handleViewCheque = (item) => {
    const newPathname = pathNamePopped(pathname, 6);

    navigate(`${newPathname}/invoicing/Tenant/${item?.type}/${item?._id}`, {
      state: { pathname: pathname },
    });
  };

  /* Component function definition ends */

  /* Component useEffect starts */
  useEffect(() => {
    if (pathname) {
      setFromInvoice(pathname?.includes("existing"));
    }
  }, [pathname]);

  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="custom-table-wrapper invoice-table">
      {invoiceList?.length > 0 && (
        <Table className="custom-table font_s" borderless responsive>
          {/* Table Heading */}
          <thead>
            <tr>
              <th>Invoices #</th>
              <th>Created Date</th>
              <th>Due Date</th>
              <th>Type</th>
              <th>Payment Method</th>
              <th>Payment Status</th>
              <th>Amount ({property?.currency})</th>
              <th className="text-start">Action</th>
            </tr>
          </thead>
          {/* Table Body */}
          <tbody>
            {invoiceList?.map((item) => (
              <tr key={item?._id}>
                {/* ID */}
                <td>{item?.invoice_no}</td>

                {/* Item Created Date */}
                <td>{moment(item?.invoice_date).format(DATE_FORMAT)}</td>

                {/* Item Due Date */}
                <td>{moment(item?.due_date).format(DATE_FORMAT)}</td>

                {/* Type */}
                <td>
                  {item?.type?.charAt(0).toUpperCase() + item?.type?.slice(1)}
                </td>

                {/* Item Pay Method */}
                <td>
                  {item?.method
                    ? item?.method?.charAt(0).toUpperCase() +
                      item?.method?.slice(1)
                    : "-"}
                </td>

                {/* Status */}
                <td
                  className={
                    item?.status === "paid"
                      ? "font_green"
                      : item?.status === "unpaid"
                      ? "font_yellow"
                      : item?.status === "void"
                      ? "font_red"
                      : "font_yellow"
                  }
                >
                  {item?.status?.charAt(0).toUpperCase() +
                    item?.status?.slice(1)}
                </td>

                {/* Amount*/}
                <td>
                  {item?.total_amount?.toLocaleString("en-US")}{" "}
                  {property?.currency}
                </td>

                {/* Actions */}
                <td className="text-start txt-nowrap">
                  <IconButton
                    onClick={() =>
                      // navigate("/property/lostAndFound/detail-found", {
                      //   state: { item },
                      // })
                      handleView(item)
                    }
                    className="action-button"
                  >
                    <img
                      src={EyeFilledBlue}
                      className="h_100"
                      alt="View Details"
                    />
                  </IconButton>
                  {
                    // item?.status === GLOBAL.UNPAID
                    ((isOwner && property?.mgmt_model === 0) ||
                      (isFM && property?.mgmt_model === 1) ||
                      (isAccountant && property?.mgmt_model === 2)) &&
                      (item?.status === "unpaid" ||
                        item?.status === "partial paid") &&
                      !item?.is_payable_by_cheque && (
                        <IconButton
                          onClick={() => handlePaymentModal(item)}
                          className="edit-button"
                        >
                          <img
                            src={EditGreen}
                            className="h_100 edit-img"
                            alt="View Details"
                          />
                        </IconButton>
                      )
                  }

                  {
                    // item?.status === GLOBAL.UNPAID
                    ((isOwner && property?.mgmt_model === 0) ||
                      (isAccountant && property?.mgmt_model === 2)) &&
                      (item?.status === "unpaid" ||
                        item?.status === "partial paid") &&
                      item?.is_payable_by_cheque &&
                      item?.type === "rent" && (
                        <IconButton
                          onClick={() => handleViewCheque(item)}
                          className="edit-button"
                        >
                          <img
                            src={ExcalmatoryYellow}
                            className="h_100 edit-img"
                            alt="View Details"
                          />
                        </IconButton>
                      )
                  }

                  {
                    // item?.status === GLOBAL.UNPAID
                    ((isOwner && property?.mgmt_model === 0) ||
                      (isAccountant && property?.mgmt_model === 2)) &&
                      (item?.status === "unpaid" ||
                        item?.status === "partial paid") &&
                      item?.is_payable_by_cheque &&
                      item?.type !== "rent" && (
                        <IconButton
                          onClick={() => handlePaymentModal(item)}
                          className="edit-button"
                        >
                          <img
                            src={EditGreen}
                            className="h_100 edit-img"
                            alt="View Details"
                          />
                        </IconButton>
                      )
                  }
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}

      {invoiceList?.length === 0 && (
        <div className=" text-center">
          <h3 className=" font_m font_yellow fw_6">No Data</h3>
        </div>
      )}

      {property?.mgmt_model !== 1 &&
        !isObserver &&
        !pathname?.split("/")?.includes("previous-tenants") && (
          <p
            className="font_m fw_3 font_blue text-center"
            onClick={() => {
              // navigate(
              //   `${pathNamePopped(
              //     pathname,
              //     4
              //   )}/invoicing/Tenants/all/add?type=Tenants`,
              //   {
              //     state: { unitID, fromInvoice },
              //   }
              // );

              navigate(`invoice/add?type=Tenants&from=tenant_detail`, {
                state: { unitID, fromInvoice, tenantId },
              });
            }}
          >
            + Create Invoice
          </p>
        )}

      <DetailViewModal
        type="invoice"
        showModal={showContractDetailModal}
        detail={detail}
        onClose={() => {
          setShowContractDetailModal(false);
        }}
      />

      {/* Cheque Add Modal */}
      {/* <MakeInvoicePaidModal
        showModal={showModal}
        currency={property?.currency}
        onClose={() => setShowModal(false)}
        onSubmit={onMarkingPaid}
        online={false}
      /> */}

      <PaymentModal
        showModal={showModal}
        onClose={() => setShowModal(false)}
        onSuccess={() => onSuccess}
        invoiceData={selectedInvoice}
        newType="tenant-Other"
        property_id={property?._id}
        afterPayment={() => {
          if (property?.mgmt_model === 1) {
            dispatch(fetchT2TenantInvoiceDetails({ tenantId, unitId }));
          } else {
            dispatch(fetchTenantInvoiceDetails({ tenantId, unitId }));
          }
        }}
      />
    </div>
  );
};
/* Component ends */

export default Invoices;
