/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import { useDispatch } from "react-redux";
import GLOBAL from "../../../../../setup/constants/global";
/* Import configuration ends */

/* Import redux slices component starts */
import { rejectApplicationPostInitialAgreement } from "../../../../../setup/store/slices/tenantSlice";
import {
  showErrorAlert,
  showSuccessAlert,
} from "../../../../../setup/store/slices/globalAlertSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import ApplicationDataRejectionModal from "../../ApplicationDataRejectionModal/ApplicationDataRejectionModal";
import CustomButton from "../../../../ui/button/CustomButton/CustomButton";
/* Import local pages and component ends */

/* Component starts */
const StartEarlyMoveout = (props) => {
  /* Props destructuring starts */
  const { application_id, getApplication } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [showConfirmMoveOut, setShowConfirmMoveOut] = useState(false);
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const dispatch = useDispatch();
  const isObserver = localStorage.getItem("user_role") === "Observer";
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  const onRejectApplicationPostInitialAgreement = async (comment) => {
    setShowConfirmMoveOut(false);

    // Creating or collecting payload data to be sent
    const payload = {
      application_id,
      rejection_reason: comment,
    };

    // Backend response. Try, Catch
    try {
      const result = await dispatch(
        rejectApplicationPostInitialAgreement(payload)
      );
      // const result = {};
      console.log("Response", result);

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          dispatch(
            showErrorAlert({
              title: "Error!",
              description: result.payload,
            })
          );
          break;

        case GLOBAL.FULFILLED:
          getApplication();
          dispatch(
            showSuccessAlert({
              title: "Success!",
              description: result.payload.message,
            })
          );
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="early-move-out-btn-wrapper">
      {!isObserver && (
        <div className="btn-wrapper-lg">
          <CustomButton
            onClick={() => setShowConfirmMoveOut(true)}
            size="l"
            variant="red"
            className="force-move-out-btn"
          >
            Start Early Move Out
          </CustomButton>
        </div>
      )}

      <ApplicationDataRejectionModal
        showModal={showConfirmMoveOut}
        title="Are you sure you want to move out this tenant in the middle of the onboarding process ? Please note that this action is irreversible, once triggered you cannot undo the action."
        onRejectWithNote={onRejectApplicationPostInitialAgreement}
        onCancel={() => setShowConfirmMoveOut(false)}
      />
    </div>
  );
};
/* Component ends */

export default StartEarlyMoveout;
