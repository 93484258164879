/*
 * Slice to handle Violations Module
 */

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios, { axiosInstance } from "../axios";
import { API_URL } from "../../config/devKeys";
import {
  setLoading,
  setShowUploadProgress,
  setUploadProgress,
} from "./unpersistedSlice";
import { PLATFORM } from "../../platformUtil";

const initialState = {
  violationsList: [],
  tenantViolationsList: [],
  violation: null,
  escalatedIncidents: [],
  propertyViolations: [],
};

// FETCH Violations LIST - Client Based On Property
export const fetchViolationsList = createAsyncThunk(
  "issue-violation/list",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      // const headers = {
      //   Authorization: `Bearer ${localStorage.getItem("user_token")}`,
      //   "Content-Type": "application/json",
      // };

      // const response = await fetch(API_URL + "/issue-violation/list", {
      //   method: "POST",
      //   headers: headers,
      //   body: JSON.stringify({
      //     ...payload,
      //   }),
      // });

      // const data = await response.json();

      const { data } = await axios.post(
        API_URL + "/issue-violation/list",
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );

      dispatch(setLoading(false));
      if (data.success) {
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

// // ADD VIOLATIONS ISSUES -> Client Side
export const addViolationIssues = createAsyncThunk(
  "issue-violation/add",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const formData = new FormData();
      // formData.append("property_id", getState().selection.property._id);
      Object.keys(payload).map((key) => {
        if (key == "document" && payload["document"]) {
          if (PLATFORM !== "web") {
            formData.append(key, {
              name: payload[key].name,
              type: payload[key].type,
              uri:
                PLATFORM === "android"
                  ? payload[key].uri
                  : payload[key].uri.replace("file://", ""),
            });
          } else {
            formData.append(key, payload[key], payload[key].name);
          }
        } else {
          formData.append(key, payload[key]);
        }
      });

      const headers = {
        Authorization: `Bearer ${localStorage.getItem("user_token")}`,
      };
      if (PLATFORM !== "web") {
        headers["Content-Type"] = "multipart/form-data";
      }
      // const response = await fetch(API_URL + "/issue-violation/add", {
      //   method: "POST",
      //   headers: headers,
      //   body: formData,
      // });
      // const data = await response.json();
      dispatch(setShowUploadProgress(true));
      const config = {
        onUploadProgress: ({ loaded, total }) => {
          const progress = (loaded / total) * 100;
          dispatch(setUploadProgress(progress));
        },
        method: "POST",
        url: "/issue-violation/add",
        headers,
        transformRequest: (data, headers) => {
          return formData;
        },
        data: formData,
        responseType: "json",
      };

      // const data = await response.json();

      const { data } = await axiosInstance.request(config);

      dispatch(setLoading(false));
      if (data.success) {
        // await dispatch(fetchViolations({violator: payload.violator}));
        await dispatch(fetchViolationsList({ violator: payload.violator }));
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return error?.response;
    }
  }
);

// FETCH Violations LIST - Tenant Based On Unit_ID
export const fetchTenantViolationsList = createAsyncThunk(
  "tenant-issue-violation/list",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("user_token")}`,
        "Content-Type": "application/json",
      };
      const response = await fetch(API_URL + "/issue-violation/list", {
        method: "POST",
        headers: headers,
        body: JSON.stringify({
          ...payload,
        }),
      });

      const data = await response.json();
      dispatch(setLoading(false));
      if (data.success) {
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

// GET Violation Data Based on ID - Tenant / Client Side
export const fetchViolationBasedOnId = createAsyncThunk(
  "issue-violation/get",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const { data } = await axios.get(
        `${API_URL}/issue-violation/get?id=${payload.request_id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      dispatch(setLoading(false));
      if (data.success) {
        return data.data;
      } else {
        rejectWithValue(data.error);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

// FETCH ESCALATED Security Incidents -> Select Options For Escalated Incident Dropdown (Based On Property)
export const fetchEscalatedIncidentList = createAsyncThunk(
  "security_incident/security_incident_escalated_list",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const { data } = await axios.get(
        API_URL +
          `/security_incident/security_incident_escalated_list?id=${payload}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      dispatch(setLoading(false));
      if (data.success) {
        return data.data;
      } else {
        rejectWithValue(data.error);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

// FETCH Violations LIST - Property On Unit_ID
export const fetchTenantViolationsListByProperty = createAsyncThunk(
  "issue-violation/list_by_tenant_on_property",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      //const page = getState().pagination.page;
      const { data } = await axios.post(
        API_URL + "/issue-violation/list_by_tenant_on_property",
        { ...payload },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      dispatch(setLoading(false));
      if (data.success) {
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const violation = createSlice({
  name: "violations",
  initialState,
  extraReducers: {
    [fetchViolationsList.rejected]: (state, action) => {
      state.violationsList = [];
    },
    [fetchViolationsList.fulfilled]: (state, action) => {
      state.violationsList = action.payload;
    },
    [fetchTenantViolationsList.rejected]: (state, action) => {
      state.tenantViolationsList = [];
    },
    [fetchTenantViolationsList.fulfilled]: (state, action) => {
      state.tenantViolationsList = action.payload;
    },
    [fetchViolationBasedOnId.rejected]: (state, action) => {
      state.violation = null;
    },
    [fetchViolationBasedOnId.fulfilled]: (state, action) => {
      state.violation = action.payload;
    },
    [fetchEscalatedIncidentList.rejected]: (state, action) => {
      state.escalatedIncidents = [];
    },
    [fetchEscalatedIncidentList.fulfilled]: (state, action) => {
      state.escalatedIncidents = action.payload;
    },

    [fetchTenantViolationsListByProperty.rejected]: (state, action) => {
      state.propertyViolations = [];
    },
    [fetchTenantViolationsListByProperty.fulfilled]: (state, action) => {
      state.propertyViolations = action.payload;
    },
  },
});

// To Get The Violations List
export const getViolationsList = (state) => state.violations.violationsList;

// To Get Tenant Violations List
export const getTenantViolationsList = (state) =>
  state.violations.tenantViolationsList;

// To Get The Violations Date Based On ID
export const getViolationData = (state) => state.violations.violation;

// To Get The Escalated Security Incident List
export const getEscalatedIncidentList = (state) =>
  state.violations.escalatedIncidents;

// To Get The Violation List Based on PRoeprty
export const getPropertyViolationsList = (state) =>
  state.violations.propertyViolations;

export default violation.reducer;
