/**
 *
 */

import React, { useEffect, useState } from "react";

/* Import configuration starts */
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

/* Import configuration ends */

/* Import redux slices component starts */
import {
  fetchInfoTilesList,
  fetchInfoTilesListCommunity,
  fetchInfoTilesListCommunityNew,
  fetchInfoTilesListNewAPI,
  fetchInfoTilesListProperty,
  fetchInfoTilesListPropertyNew,
  getCommunityInfoTilesList,
  getInfoTilesList,
  getPropertyInfoTilesList,
} from "../../../../setup/store/slices/infoTileSlice";
import { setLoading } from "../../../../setup/store/slices/unpersistedSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import MUI component starts */
/* Import MUI component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import OverviewItem from "../OverviewItem/OverviewItem";
import CustomSlider from "../../../ui/other/CustomSlider/CustomSlider";
import { Toastify } from "../../../../setup/utils/toast";
/* Import local pages and component ends */

import "./style.scss";

/* Component starts */
const OverviewSlider = (props) => {
  /* Props destructuring starts */
  const { type = "", propertyId, communityID } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */

  const [InfoTiles, setInfoTiles] = useState([]);
  const [isAggregated, setIsAggregated] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [hoveredTile, setHoveredTile] = useState(null);
  const [originalTileData, setOriginalTileData] = useState(null); // Backup for original data
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const allInfo = useSelector(getInfoTilesList);
  const propertyInfo = useSelector(getPropertyInfoTilesList);
  const communityInfo = useSelector(getCommunityInfoTilesList);
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */

  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */

  // Adding new Community
  const fetchHoveredTileData = async (tile) => {
    // Creating or collecting payload data to be sent
    let payload = {};

    if (isAggregated) {
      // Backend Response. Try, Catch
      try {
        setIsLoading(true);
        let result;
        if (propertyId && type === "property") {
          payload = {
            property_id: propertyId,
            isAggregated,
            type: tile,
          };
          result = await dispatch(fetchInfoTilesListPropertyNew(payload));
        } else if (communityID && type === "community") {
          payload = {
            community_id: communityID,
            isAggregated,
            type: tile,
          };

          result = await dispatch(fetchInfoTilesListCommunityNew(payload));
        } else {
          payload = {
            isAggregated,
            type: tile,
          };

          result = await dispatch(fetchInfoTilesListNewAPI(payload));
        }

        // Handling success respose
        switch (result.meta.requestStatus) {
          case "fulfilled":
            // Update only the clicked slider item data
            const updatedTiles = InfoTiles.map((item) =>
              item.label === tile
                ? { ...item, data: result?.payload[0]?.data }
                : item
            );

            setInfoTiles(updatedTiles);
            setIsLoading(false);
            break;

          case "rejected":
            setIsLoading(false);
            Toastify("error", result.payload);
            break;

          default:
        }
      } catch (e) {
        dispatch(setLoading(false));
        // Handling error respose
        console.error(e.message);
      }
    }
  };

  // Restore original data for a specific tile
  const restoreOriginalData = () => {
    setHoveredTile(null); // Reset hovered tile
    setIsAggregated(false);
    setInfoTiles((prevTiles) =>
      prevTiles.map((item) =>
        item?.label === originalTileData?.label
          ? originalTileData // Restore original data
          : item
      )
    );
  };

  /* Component function definition ends */

  /* Component useEffect starts */

  useEffect(() => {
    if (type === "property") {
      (async () => {
        try {
          await dispatch(setLoading(true));
          await dispatch(
            fetchInfoTilesListProperty({
              property_id: propertyId,
              // isAggregated,
            })
          );
          await dispatch(setLoading(false));
        } catch (e) {}
      })();
    } else if (type === "community") {
      (async () => {
        try {
          await dispatch(setLoading(true));
          await dispatch(
            fetchInfoTilesListCommunity({
              community_id: communityID,
              // isAggregated,
            })
          );
          await dispatch(setLoading(false));
        } catch (e) {}
      })();
    } else {
      (async () => {
        try {
          await dispatch(setLoading(true));
          await dispatch(fetchInfoTilesList());
          await dispatch(setLoading(false));
        } catch (e) {}
      })();
    }
  }, [type]);

  useEffect(() => {
    if (isAggregated && hoveredTile) {
      if (type === "property") {
        fetchHoveredTileData(hoveredTile); // Fetch data for the hovered tile(propertyId, isAggregated);
      } else if (type === "community") {
        fetchHoveredTileData(hoveredTile); // Fetch data for the hovered tile
      } else {
        fetchHoveredTileData(hoveredTile); // Fetch data for the hovered tile
      }
    }
  }, [hoveredTile, isAggregated, type]);

  useEffect(() => {
    const path = pathname.split("/")[2];

    if (path === "home" && allInfo) {
      const info = allInfo;
      setInfoTiles(info);
    } else if (type === "property" && propertyInfo) {
      setInfoTiles(propertyInfo);
    } else if (type === "community" && communityInfo) {
      setInfoTiles(communityInfo);
    }
  }, [pathname, type, propertyInfo, communityInfo, allInfo]);

  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="overview">
      {InfoTiles?.length > 0 && (
        <CustomSlider type="overview">
          {InfoTiles?.map((info, idx) => (
            <div key={idx}>
              <OverviewItem
                info={info}
                isAggregated={isAggregated}
                type={type}
                isLoading={isLoading}
                hoveredTile={hoveredTile}
                onHover={() => {
                  setIsAggregated(!isAggregated);
                  setHoveredTile(info.label); // Set the hovered tile
                  setOriginalTileData(info); // Backup the original data
                }} // Trigger fetch on hover
                onHoverOut={(label) => restoreOriginalData(label)} // Restore original data on hover out
              />
            </div>
          ))}
        </CustomSlider>
      )}

      {InfoTiles?.length === 0 && type === "community" && (
        <p className="text-center font_l font_yellow fw_5">
          No Properties Added Under Community
        </p>
      )}
    </div>
  );
};
/* Component ends */

export default OverviewSlider;
