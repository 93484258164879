export const renderRoleAbbr = (i) => {
  switch (i) {
    case "OWNER":
      return "Owner";

    case "FMC":
      return "Facility Management Company";

    case "FM":
      return "Facility Manager";

    case "MT":
      return "Maintenance Technician";

    case "TPC":
      return "Third Party Company";

    case "TPM":
      return "Third Party Manager";

    case "TPT":
      return "Third Party Technician";

    default:
      return "";
  }
};

/* Owner Team Members Starts */
// Conditions for adding new Owner
export const addNewOwnerRules = (property_mgmt, teams) => {
  // T 1
  if (property_mgmt === 0) {
    return true;
  }
  // T 2
  else if (property_mgmt === 1) {
    return true;
  }
  // T 3
  else if (property_mgmt === 2) {
    return true;
  } else {
    return false;
  }
};
/* Owner Team Members Ends */

/* Observer Team Members Starts */
// Conditions for adding new Observer
export const addNewObserverRules = (property_mgmt, teams) => {
  // T 1
  if (property_mgmt === 0) {
    let activeObsrvrCount = 0;
    let activeOwnrCount = 0;
    let owners =
      teams["Owner"] && teams["Owner"]?.length > 0 ? teams["Owner"] : [];

    let observers =
      teams["Observer"] && teams["Observer"]?.length > 0
        ? teams["Observer"]
        : [];

    for (let i = 0; i < owners.length; i++) {
      if (owners[i]?.status === "active") {
        activeOwnrCount++;
      }
    }

    if (activeOwnrCount > 0) {
      for (let i = 0; i < observers.length; i++) {
        if (observers[i]?.status === "active") {
          activeObsrvrCount++;
        }
      }

      if (activeObsrvrCount < 2) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }
  // T 2
  else if (property_mgmt === 1) {
    return true;
  }
  // T 3
  else if (property_mgmt === 2) {
    return true;
  } else {
    return false;
  }
};
/* Observer Team Members Ends */

/* Property Management Company (PMC) Team Members Starts */
// Conditions for adding new Property Management Company
export const addNewPmcRules = (property_mgmt, teams) => {
  // T 1
  if (property_mgmt === 0) {
    return true;
  }
  // T 2
  else if (property_mgmt === 1) {
    return true;
  }
  // T 3
  else if (property_mgmt === 2) {
    return true;
  } else {
    return false;
  }
};

// Conditions for adding new Property Manager
export const addNewPmRules = (property_mgmt, teams) => {
  // T 1
  if (property_mgmt === 0) {
    return true;
  }
  // T 2
  else if (property_mgmt === 1) {
    return true;
  }
  // T 3
  else if (property_mgmt === 2) {
    return true;
  } else {
    return false;
  }
};

// Conditions for adding new PMC Accountant
export const addNewPmcActRules = (property_mgmt, teams) => {
  // T 1
  if (property_mgmt === 0) {
    return true;
  }
  // T 2
  else if (property_mgmt === 1) {
    return true;
  }
  // T 3
  else if (property_mgmt === 2) {
    return true;
  } else {
    return false;
  }
};
/* Property Management Company (PMC) Team Members Ends */

/* Facility Management Company (FMC) Team Members Starts */
// Conditions for adding new Property Management Company
export const addNewFmcRules = (property_mgmt, teams) => {
  // T 1
  if (property_mgmt === 0) {
    return true;
  }
  // T 2
  else if (property_mgmt === 1) {
    return true;
  }
  // T 3
  else if (property_mgmt === 2) {
    return true;
  } else {
    return false;
  }
};

// Conditions for adding new Property Manager
export const addNewFmRules = (property_mgmt, teams) => {
  // T 1
  if (property_mgmt === 0) {
    return true;
  }
  // T 2
  else if (property_mgmt === 1) {
    return true;
  }
  // T 3
  else if (property_mgmt === 2) {
    return true;
  } else {
    return false;
  }
};

// Conditions for adding new Maintenance Technician
export const addNewMtRules = (property_mgmt, teams) => {
  // T 1
  if (property_mgmt === 0) {
    return true;
  }
  // T 2
  else if (property_mgmt === 1) {
    return true;
  }
  // T 3
  else if (property_mgmt === 2) {
    return true;
  } else {
    return false;
  }
};

// Conditions for adding new PMC Accountant
export const addNewFmcActRules = (property_mgmt, teams) => {
  // T 1
  if (property_mgmt === 0) {
    return true;
  }
  // T 2
  else if (property_mgmt === 1) {
    const fmc =
      teams &&
      teams["Facility Management Company"] &&
      teams["Facility Management Company"]?.length > 0
        ? teams["Facility Management Company"]
        : [];

    const fm =
      fmc?.subMembers &&
      fmc?.subMembers["Facility Manager"] &&
      fmc?.subMembers["Facility Manager"]?.length > 0
        ? fmc?.subMembers["Facility Manager"]
        : [];

    const mt =
      fmc?.subMembers &&
      fmc?.subMembers["Maintenance Technician"] &&
      fmc?.subMembers["Maintenance Technician"]?.length > 0
        ? fmc?.subMembers["Maintenance Technician"]
        : [];

    if (fm?.length === 0 || mt?.length === 0) return false;

    let fmAdded = false;
    let mtAdded = false;

    for (let i = 0; i < fm.length; i++) {
      if (fm[i]?.status === "active") {
        fmAdded = true;
        break;
      }
    }

    for (let i = 0; i < mt.length; i++) {
      if (mt[i]?.status === "active") {
        mtAdded = true;
        break;
      }
    }

    return fmAdded && mtAdded;
  }
  // T 3
  else if (property_mgmt === 2) {
    return true;
  } else {
    return false;
  }
};
/* Facility Management Company (FMC) Team Members Ends */

/* Third-Party Management Company (TPC) Team Members Starts */
// Conditions for adding new Third-Party Management Company
export const addNewTpcRules = (property_mgmt, teams) => {
  // T 1
  if (property_mgmt === 0) {
    return true;
  }
  // T 2
  else if (property_mgmt === 1) {
    return true;
  }
  // T 3
  else if (property_mgmt === 2) {
    return true;
  } else {
    return false;
  }
};

// Conditions for adding new Third-Party Manager
export const addNewTpmRules = (property_mgmt, teams) => {
  // T 1
  if (property_mgmt === 0) {
    return true;
  }
  // T 2
  else if (property_mgmt === 1) {
    return true;
  }
  // T 3
  else if (property_mgmt === 2) {
    return true;
  } else {
    return false;
  }
};

// Conditions for adding new Third-Party Technician
export const addNewTptRules = (property_mgmt, teams) => {
  // T 1
  if (property_mgmt === 0) {
    return true;
  }
  // T 2
  else if (property_mgmt === 1) {
    return true;
  }
  // T 3
  else if (property_mgmt === 2) {
    return true;
  } else {
    return false;
  }
};
/* Third-Party Management Company (TPC) Team Members Ends */

/* T 1 Starts ************************************************************************************************************************** */
// **************************************************************************************************************************
/* Owner Team Members Starts */
// Conditions for adding new Owner
export const T1_OwnerRules = (property_mgmt, teams) => {
  // T 1
  if (property_mgmt === 0) {
    return true;
  }
  // T 2
  else if (property_mgmt === 1) {
    return true;
  }
  // T 3
  else if (property_mgmt === 2) {
    return true;
  } else {
    return false;
  }
};
/* Owner Team Members Ends */

/* Observer Team Members Starts */
// Conditions for adding new Observer
export const T1_ObserverRules = (property_mgmt, teams) => {
  // T 1
  if (property_mgmt === 0) {
    return true;
  }
  // T 2
  else if (property_mgmt === 1) {
    return true;
  }
  // T 3
  else if (property_mgmt === 2) {
    return true;
  } else {
    return false;
  }
};
/* Observer Team Members Ends */
// **************************************************************************************************************************
/* T 1 Ends ************************************************************************************************************************** */

/* T 1 Starts ************************************************************************************************************************** */
/* T 2 Ends ************************************************************************************************************************** */

/* T 3 Starts ************************************************************************************************************************** */
/* T 3 Ends ************************************************************************************************************************** */
