/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import { useParams, useNavigate } from "react-router-dom";
import GLOBAL from "../../setup/constants/global";
/* Import configuration ends */

/* Import redux slices component starts */
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import BreadCrumb from "../../components/ui/other/BreadCrumb/BreadCrumb";
import AddRoleUnderOwner from "../../components/module/Owner/AddRoleUnderOwner/AddRoleUnderOwner";
/* Import local pages and component ends */

import "./style.scss";

/* Component starts */
const AddRoleUnderOwnerPage = (props) => {
  /* Props destructuring starts */
  const {} = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const navigate = useNavigate();
  const { underCommunity, communityId, propertyId, ownerId, ownerSubRole } =
    useParams();
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const role =
    ownerSubRole === "or"
      ? GLOBAL.USER_ROLE.OR
      : ownerSubRole === "ob"
      ? GLOBAL.USER_ROLE.OB
      : "";
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <>
      <BreadCrumb type="manage_team_owner_submember" />
      <div className="add-role-under-owner-page-wrapper container_sm">
        <AddRoleUnderOwner
          underCommunity={underCommunity}
          communityId={communityId}
          propertyId={propertyId}
          ownerId={ownerId}
          ownerSubRole={ownerSubRole}
          role={role}
          onCancel={() => navigate(-1)}
          onSuccess={() => navigate(-1)}
        />
      </div>
    </>
  );
};
/* Component ends */

export default AddRoleUnderOwnerPage;
