/*
 * Slice to handle Annoucement Module
 */

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API_URL } from "../../config/devKeys";
import {
  setLoading,
  setShowUploadProgress,
  setUploadProgress,
} from "./unpersistedSlice";
import axios from "axios";
import { axiosInstance } from "../axios";
import { PLATFORM } from "../../platformUtil";

const initialState = {
  advertisementsList: [],
  tenantAdvertisementsList: [],
  // communityAnnoucementsList: [],
  // usersAnnoucementsList: [],
  advertisement: null,
};

// ADD Advertisement -> Client - ( Home, Property_id, Community_id )
export const addAdvertisement = createAsyncThunk(
  "advertisement/create",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));

      // const headers = {
      //   Authorization: `Bearer ${localStorage.getItem("user_token")}`,
      //   "Content-Type": "application/json",
      // };
      // const response = await fetch(API_URL + "/advertisement/create", {
      //   method: "POST",
      //   headers: headers,
      //   body: JSON.stringify(payload),
      // });
      // const data = await response.json();

      const formData = new FormData();
      Object.keys(payload).map((key) => {
        if (key === "document") {
          if (PLATFORM !== "web") {
            formData.append("document", {
              name: payload.document.name,
              type: payload.document.type,
              uri:
                PLATFORM === "android"
                  ? payload.document.uri
                  : payload.document.uri.replace("file://", ""),
            });
          } else {
            formData.append("document", payload[key], payload[key].name);
          }
        } else {
          formData.append(key, payload[key]);
        }
      });
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("user_token")}`,
      };
      if (PLATFORM !== "web") {
        headers["Content-Type"] = "multipart/form-data";
      }

      dispatch(setShowUploadProgress(true));
      const config = {
        onUploadProgress: ({ loaded, total }) => {
          const progress = (loaded / total) * 100;
          dispatch(setUploadProgress(progress));
        },
        method: "POST",
        url: "/advertisement/create",
        headers,
        transformRequest: (data, headers) => {
          return formData;
        },
        data: formData,
        responseType: "json",
      };

      // const data = await response.json();

      const { data } = await axiosInstance.request(config);

      dispatch(setLoading(false));
      if (data.success) {
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));

      return rejectWithValue(error.message);
    }
  }
);

// FETCH Advertisement LIST - Client Side (HOME)
export const fetchAdvertisementsList = createAsyncThunk(
  "advertisement/list",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("user_token")}`,
        "Content-Type": "application/json",
      };

      const response = await fetch(
        `${API_URL}/advertisement/list_advertisements`,
        {
          method: "POST",
          headers: headers,
          body: JSON.stringify({
            property_id: payload?.property_id,
            community_id: payload?.community_id,
            page: payload?.page,
          }),
        }
      );

      const data = await response.json();

      dispatch(setLoading(false));

      if (data.success) {
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

// FETCH ADVERTISEMENT LIST - TENANT Side
export const fetchTenantAdvertisement = createAsyncThunk(
  "advertisement/tenant/list",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("user_token")}`,
        "Content-Type": "application/json",
      };

      const response = await fetch(`${API_URL}/advertisement/tenant/list`, {
        method: "GET",
        headers: headers,
      });

      const data = await response.json();
      dispatch(setLoading(false));
      if (data.success) {
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

// // FETCH ANNOUCEMENTS LIST - Client Side  (Community_ID)
// export const fetchAnnoucementsListBasedCommunity = createAsyncThunk(
//   "announcement_community/list",
//   async (payload, { rejectWithValue, getState, dispatch }) => {
//     try {
//       dispatch(setLoading(true));
//       const headers = {
//         Authorization: `Bearer ${localStorage.getItem("user_token")}`,
//         "Content-Type": "application/json",
//       };

//       const response = await fetch(API_URL + `/announcement/list`, {
//         method: "POST",
//         headers: headers,
//         body: JSON.stringify({
//           community_id: payload?.community_id,
//           page: payload?.page,
//         }),
//       });

//       const data = await response.json();

//       dispatch(setLoading(false));
//       if (data.success) {
//         return data.data;
//       } else {
//         return rejectWithValue(data.error.message);
//       }
//     } catch (error) {
//       dispatch(setLoading(false));
//       return rejectWithValue(error?.response);
//     }
//   }
// );

// FETCH ADVERTISEMENT DATA BASED ON ID  - (ADVERTISEMENT_ID)
export const fetchAdvertisementById = createAsyncThunk(
  "advertisement/by_id",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("user_token")}`,
        "Content-Type": "application/json",
      };

      const response = await fetch(
        `${API_URL}/advertisement/by_id?id=${payload}`,
        {
          method: "GET",
          headers: headers,
        }
      );

      const data = await response.json();

      dispatch(setLoading(false));

      if (data.success) {
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

// FETCH ADVERTISEMENT DATA BASED ON ID FOR TENANT  - (ADVERTISEMENT_ID)
export const fetchAdvertisementForTenantById = createAsyncThunk(
  "advertisement/tenant/by_id",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("user_token")}`,
        "Content-Type": "application/json",
      };

      const response = await fetch(
        `${API_URL}/advertisement/tenant/by_id?id=${payload}`,
        {
          method: "GET",
          headers: headers,
        }
      );

      const data = await response.json();

      dispatch(setLoading(false));

      if (data.success) {
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

// UPDATE IF ADVERTISEMENT By ID - Exclude { CLIENT } (ALL-TEAMS & TENANT)
export const updateAdvertisementByID = createAsyncThunk(
  "advertisement/editAdvertisement",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      // const headers = {
      //   Authorization: `Bearer ${localStorage.getItem("user_token")}`,
      //   "Content-Type": "application/json",
      // };

      // const response = await fetch(
      //   `${API_URL}/advertisement/editAdvertisement`,
      //   {
      //     method: "POST",
      //     headers: headers,
      //     body: JSON.stringify(payload),
      //   }
      // );

      // const data = await response.json();

      const formData = new FormData();
      Object.keys(payload).map((key) => {
        if (key == "banner") {
          if (PLATFORM !== "web") {
            formData.append(key, {
              name: payload[key].name,
              type: payload[key].type,
              uri:
                PLATFORM === "android"
                  ? payload[key].uri
                  : payload[key].uri.replace("file://", ""),
            });
          } else {
            formData.append(key, payload[key], payload[key].name);
          }
        } else {
          formData.append(key, payload[key]);
        }
      });
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("user_token")}`,
      };
      if (PLATFORM !== "web") {
        headers["Content-Type"] = "multipart/form-data";
      }

      dispatch(setShowUploadProgress(true));
      const config = {
        onUploadProgress: ({ loaded, total }) => {
          const progress = (loaded / total) * 100;
          dispatch(setUploadProgress(progress));
        },
        method: "POST",
        url: "/advertisement/editAdvertisement",
        headers,
        transformRequest: (data, headers) => {
          return formData;
        },
        data: formData,
        responseType: "json",
      };

      const { data } = await axiosInstance.request(config);

      dispatch(setLoading(false));

      if (data.success) {
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

// DELETE ADVERTISEMENT DATA BASED ON ID  - (ANNOUCEMENT_ID)
export const deleteAdvertisement = createAsyncThunk(
  "advertisement/delete",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const { data } = await axios.delete(
        API_URL + "/advertisement/delete?id=" + payload._id,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );

      dispatch(setLoading(false));
      if (data.success) {
        await dispatch(fetchAdvertisementsList(payload));
        return data.data;
      } else {
        dispatch(setLoading(false));
        rejectWithValue(data.error);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

// UPDATE ADVERTISEMENT DATA BASED ON ID - FOR PAUSE - (ANNOUCEMENT_ID)
export const pauseAdvertisement = createAsyncThunk(
  "advertisement/setStatus",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const { data } = await axios.patch(
        API_URL + "/advertisement/setStatus?id=" + payload._id,
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );

      dispatch(setLoading(false));
      if (data.success) {
        await dispatch(fetchAdvertisementsList(payload));
        return data.data;
      } else {
        dispatch(setLoading(false));
        rejectWithValue(data.error);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const advertisementSlice = createSlice({
  name: "advertisement",
  initialState,
  extraReducers: {
    [fetchAdvertisementsList.rejected]: (state, action) => {
      state.advertisementsList = [];
    },
    [fetchAdvertisementsList.fulfilled]: (state, action) => {
      state.advertisementsList = action.payload;
    },
    [fetchTenantAdvertisement.rejected]: (state, action) => {
      state.tenantAdvertisementsList = [];
    },
    [fetchTenantAdvertisement.fulfilled]: (state, action) => {
      state.tenantAdvertisementsList = action.payload;
    },
    // [fetchAnnoucementsListBasedCommunity.rejected]: (state, action) => {
    //   state.communityAnnoucementsList = [];
    // },
    // [fetchAnnoucementsListBasedCommunity.fulfilled]: (state, action) => {
    //   state.communityAnnoucementsList = action.payload;
    // },
    [fetchAdvertisementForTenantById.rejected]: (state, action) => {
      state.advertisement = null;
    },
    [fetchAdvertisementForTenantById.fulfilled]: (state, action) => {
      state.advertisement = action.payload;
    },
    [fetchAdvertisementById.rejected]: (state, action) => {
      state.advertisement = null;
    },
    [fetchAdvertisementById.fulfilled]: (state, action) => {
      state.advertisement = action.payload;
    },
  },
});

// To Get The Advertisements List For Home
export const getAdvertisementsList = (state) =>
  state.advertisement.advertisementsList;

// To Get The Advertisement List For Tenant
export const getTenantAdvertisementsList = (state) =>
  state.advertisement.tenantAdvertisementsList;

// // To Get The Annoucements List For Community
// export const getCommunityAnnoucementsList = (state) =>
//   state.annoucement.communityAnnoucementsList;

// // To Get The Annoucements List For Users
// export const getUsersAnnoucementsList = (state) =>
//   state.annoucement.usersAnnoucementsList;

// To Get The Advertisment Based On ID
export const getAdvertisementById = (state) =>
  state.advertisement.advertisement;

// To Get The Advertisment Based On ID
export const getAdvertisementForTenantById = (state) =>
  state.advertisement.advertisement;

export default advertisementSlice.reducer;
