/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { pathNamePopped } from "../../../../../setup/utils/global-helper";
/* Import configuration ends */

/* Import redux slices component starts */
import { setLoading } from "../../../../../setup/store/slices/unpersistedSlice";
import {
  fetchPropInvoicesAggregationBasedOnProperty,
  fetchPropertyInvoices,
  getPropertyInvoices,
  getPropertyInvoicesAggregation,
} from "../../../../../setup/store/slices/propertyInvoiceSlice";
import {
  fetchPartyInvoices,
  fetchPartyInvoicesAggregationBasedOnProperty,
  getPartyInvoices,
  getPartyInvoicesAggregation,
} from "../../../../../setup/store/slices/partyInvoiceSlice";
import {
  fetchInvoicesAggregationBasedOnProperty,
  fetchInvoicesBasedOnProperty,
  getInvoicesAggregationBasedProperty,
  getInvoicesBasedProperty,
} from "../../../../../setup/store/slices/invoiceSlice";
import {
  fetchPropertyDetailByID,
  getPropertyDetail,
} from "../../../../../setup/store/slices/propertySlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import {
  buttonList,
  toShowTheAddInvoiceOption,
  generateRequestPayload,
} from "./helper";
import GLOBAL from "../../../../../setup/constants/global";

import TabButton from "../../../../ui/other/TabButton/TabButton";
import Searchbar from "../../../../ui/other/Searchbar/Searchbar";
import RentInvoicing from "./sub-components/TenantInvoicing/RentInvoicing";
import FacilitiesInvoicing from "./sub-components/TenantInvoicing/FacilitiesInvoicing";
import OthersInvoicing from "./sub-components/TenantInvoicing/othersInvoicing";
import DepositInvoicing from "./sub-components/TenantInvoicing/DepositInvoicing";
import UtilityInvoicing from "./sub-components/TenantInvoicing/UtilityInvoicing";
import OtherPropertyInvoicing from "./sub-components/PropertyInvoicing/otherPropertyInvoicing";
import UtilitiesInvoicing from "./sub-components/PropertyInvoicing/UtilitiesInvoicing";
import ContractsInvoicing from "./sub-components/PartiesInvoicing/ContractsInvoicing";
import SparePartsInvoicing from "./sub-components/PartiesInvoicing/SparePartsInvoicing";
import ActiveInvoicing from "./sub-components/AdvertismentInvoicing/ActiveInvoicing";
import ExpiredInvoicing from "./sub-components/AdvertismentInvoicing/ExpiredInvoicing";
import ApplicationFeeInvoice from "./sub-components/TenantInvoicing/ApplicationFeeInvoice";
import CommissionInvoicing from "./sub-components/TenantInvoicing/CommissionInvoicing";
/* Import local pages and component ends */

// Styles
import "./style.scss";
import {
  fetchAdvertisementInvoices,
  getAdverisementInvoices,
} from "../../../../../setup/store/slices/advertisementInvoiceSlice";

/* Component starts */
const Invoicing = (props) => {
  /* Props destructuring starts */
  const {} = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [currentKey, setCurrentKey] = useState(GLOBAL.INVOICING.TENANTS);
  const [currentSubKey, setCurrentSubKey] = useState("");
  const [buttonLists, setButtonLists] = useState([]);
  const [subButtonLists, setSubButtonLists] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  //   const dispatch = useDispatch();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  let { propertyId, tab, subTab } = useParams();
  const property = useSelector(getPropertyDetail);
  const tenantInvoices = useSelector(getInvoicesBasedProperty);
  // TENANT INVOICE AGGREGATION
  const tenantAggregationInvoices = useSelector(
    getInvoicesAggregationBasedProperty
  );

  const propertyInvoices = useSelector(getPropertyInvoices);
  // PROPERTY INVOICE AGGREGATION
  const propertyAggregationInvoices = useSelector(
    getPropertyInvoicesAggregation
  );

  const partyInvoices = useSelector(getPartyInvoices);
  // PARTY INVOICE AGGREGATION
  const partyAggregationInvoices = useSelector(getPartyInvoicesAggregation);

  // Advertisement Invoices
  const advertisementInvoices = useSelector(getAdverisementInvoices);

  console.log(advertisementInvoices, "[INV]");

  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const customTenantInvoice = [
    "facility",
    "utility",
    "others",
    "spare-parts",
    "contracts",
    "utilities",
  ];

  const isObserver =
    localStorage.getItem("user_role") &&
    localStorage.getItem("user_role") === "Observer";
  const isTPM = localStorage.getItem("user_role") === "Third Party Manager";
  const isOwner = localStorage.getItem("user_role") === "Owner";
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  const handleFetchTenantInvoices = async (payload, invListType) => {
    const { property_id } = payload;
    let type = "";

    switch (invListType) {
      case "facility":
        type = "shared facility";
        break;

      case "utility":
        type = "utility";
        break;

      case "deposits":
        type = "deposit";
        break;

      case "others":
        type = "other";
        break;

      case "commission":
        type = "commission";
        break;

      case "rent":
        type = "rent";
        break;

      default:
        type = "application-fee";
        break;
    }

    payload["type"] = type;
    await dispatch(fetchInvoicesBasedOnProperty(payload));

    // To fetch the Invoice Aggregation
    await dispatch(
      fetchInvoicesAggregationBasedOnProperty({
        property_id,
        type,
      })
    );
  };

  const handleFetchPropertyInvoices = async (payload, invListType) => {
    const { property_id } = payload;
    let type = "";

    switch (invListType) {
      case "others":
        type = "Other";
        break;

      default:
        type = "Utility";
        break;
    }

    payload["type"] = type;
    await dispatch(fetchPropertyInvoices(payload));

    // To fetch the Invoice Aggregation
    await dispatch(
      fetchPropInvoicesAggregationBasedOnProperty({
        property_id,
        type,
      })
    );
  };

  const handleFetchPartyInvoices = async (payload, invListType) => {
    const { property_id } = payload;
    let type = "";

    switch (invListType) {
      case "contracts":
        type = "Service contract";
        break;

      default:
        type = "Additional work";
        break;
    }

    payload["type"] = type;
    await dispatch(fetchPartyInvoices(payload));

    // To fetch the Invoice Aggregation
    await dispatch(
      fetchPartyInvoicesAggregationBasedOnProperty({
        property_id,
        type,
      })
    );
  };

  const handleFetchAdvertisementInvoices = async (payload, invListType) => {
    // const { property_id } = payload;
    let type = "";

    switch (invListType) {
      case "active":
        type = "active";
        break;

      default:
        type = "expired";
        break;
    }

    payload["type"] = type;
    await dispatch(fetchAdvertisementInvoices(payload));

    // To fetch the Invoice Aggregation
    // await dispatch(
    //   fetchPartyInvoicesAggregationBasedOnProperty({
    //     property_id,
    //     type,
    //   })
    // );
  };

  const handleFetchList = async (
    currentTab,
    currentSubTab,
    currentPage,
    searchKeyword = ""
  ) => {
    let payload = {
      property_id: propertyId,
      page: currentPage,
    };

    if (searchKeyword !== "") {
      payload["search"] = searchKeyword;
    }

    switch (currentTab) {
      case GLOBAL.INVOICING.TENANTS:
        await handleFetchTenantInvoices(payload, currentSubTab);
        break;

      case GLOBAL.INVOICING.PROPERTIES_INVOICES:
        await handleFetchPropertyInvoices(payload, currentSubTab);
        break;

      case GLOBAL.INVOICING.PARTIES_INVOICES:
        await handleFetchPartyInvoices(payload, currentSubTab);
        break;

      case GLOBAL.INVOICING.ADVERTISMENT:
        await handleFetchAdvertisementInvoices(payload, currentSubTab);
    }
  };

  const onTabSelect = (key) => {
    const newPathname = pathNamePopped(pathname, 2);
    if (key === GLOBAL.INVOICING.TENANTS) {
      navigate(`${newPathname}/${key}/rent`);
      setCurrentPage(1);
    } else if (key === GLOBAL.INVOICING.PROPERTIES_INVOICES) {
      navigate(`${newPathname}/${key}/utilities`);
      setCurrentPage(1);
    } else if (key === GLOBAL.INVOICING.PARTIES_INVOICES) {
      navigate(`${newPathname}/${key}/spare-parts`);
      setCurrentPage(1);
    } else {
      navigate(`${newPathname}/${key}/active`);
    }
    // setCurrentKey(key);
  };

  const onSubTabSelect = (key) => {
    const newPathname = pathNamePopped(pathname, 1);

    if (property?.mgmt_model === 1 && tab === "Property Invoice") {
      navigate(`${newPathname}/others`);
    } else {
      navigate(`${newPathname}/${key}`);
    }

    setCurrentPage(1);
    // setCurrentSubKey(key);
  };

  /* Component function definition ends */

  /* Component useEffect starts */
  useEffect(() => {
    (async () => {
      try {
        await dispatch(setLoading(true));
        await dispatch(fetchPropertyDetailByID({ property_id: propertyId }));
        await dispatch(setLoading(false));
      } catch (e) {}
    })();
  }, [propertyId]);

  useEffect(() => {
    if (property) {
      if (property?.mgmt_model === 0) {
        setButtonLists([
          { key: GLOBAL.INVOICING.TENANTS, label: "Tenants" },
          {
            key: GLOBAL.INVOICING.PROPERTIES_INVOICES,
            label: "Properties Invoices",
          },
          // { key: GLOBAL.INVOICING.ADVERTISMENT, label: "Advertisment" },
        ]);
      } else if (property?.mgmt_model === 1) {
        setButtonLists([
          { key: GLOBAL.INVOICING.TENANTS, label: "Tenants" },
          {
            key: GLOBAL.INVOICING.PROPERTIES_INVOICES,
            label: "Properties Invoices",
          },
          {
            key: GLOBAL.INVOICING.PARTIES_INVOICES,
            label: "Parties Invoices",
          },
          // { key: GLOBAL.INVOICING.ADVERTISMENT, label: "Advertisment" },
        ]);
      } else {
        setButtonLists(buttonList);
      }
    }
  }, [property]);

  useEffect(() => {
    if (tab === GLOBAL.INVOICING.TENANTS) {
      if (property?.mgmt_model === 0) {
        setSubButtonLists([
          { key: "rent", label: "Rent" },
          { key: "deposits", label: "Deposits" },
          { key: "application", label: "Application Fee" },
          { key: "commission", label: "Commission" },
          { key: "others", label: "Others" },
        ]);
      } else if (property?.mgmt_model === 1) {
        setSubButtonLists([{ key: "others", label: "Others" }]);
        subTab = "others";
      } else {
        setSubButtonLists([
          { key: "rent", label: "Rent" },
          // { key: "facility", label: "Facilities" },
          // { key: "utility", label: "Utility" },
          { key: "deposits", label: "Deposits" },
          { key: "application", label: "Application Fee" },
          { key: "commission", label: "Commission" },
          { key: "others", label: "Others" },
        ]);
      }
    } else if (tab === GLOBAL.INVOICING.PROPERTIES_INVOICES) {
      if (property?.mgmt_model === 1) {
        setSubButtonLists([
          // { key: "utilities", label: "Utilities" },
          { key: "others", label: "Others" },
        ]);
        subTab = "others";
      } else {
        setSubButtonLists([
          { key: "utilities", label: "Utilities" },
          { key: "others", label: "Others" },
        ]);
      }
    } else if (tab === GLOBAL.INVOICING.PARTIES_INVOICES) {
      setSubButtonLists([
        { key: "spare-parts", label: "Spare Parts" },
        { key: "contracts", label: "Contracts" },
      ]);
    } else {
      setSubButtonLists([
        { key: "active", label: "Active" },
        { key: "expired", label: "Expired" },
      ]);
    }
  }, [tab, property]);

  useEffect(() => {
    if (searchQuery !== "") {
      handleFetchList(tab, subTab, currentPage, searchQuery);
    } else {
      handleFetchList(tab, subTab, currentPage, "");
    }
  }, [tab, subTab, currentPage]);

  useEffect(() => {
    let timeoutid = null;
    if (searchQuery !== "") {
      timeoutid = setTimeout(() => {
        handleFetchList(tab, subTab, currentPage, searchQuery);
      }, 1000);
    } else {
      handleFetchList(tab, subTab, currentPage, "");
    }

    return () => {
      if (timeoutid) {
        clearTimeout(timeoutid);
      }
    };
  }, [searchQuery]);

  useEffect(() => {
    setCurrentPage(1);
  }, [searchQuery]);

  useEffect(() => {
    onSubTabSelect(subTab);
    setCurrentPage(1);
    setSearchQuery("");
  }, [tab, subTab, property]);

  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="invoicing-wrapper">
      <div className="heading">
        <h2 className="font_xxl fw_6">Invoicing</h2>
        {/* {tab !== GLOBAL.INVOICING.ADVERTISMENT &&
          (customTenantInvoice?.includes(subTab) ||
            (property?.mgmt_model === 1 && "others")) && (
            <Link to={`add?type=${tab}`} className="font_m fw_6 d_block link">
              + Create Invoice
            </Link>
          )} */}

        {!isObserver &&
          tab !== GLOBAL.INVOICING.ADVERTISMENT &&
          toShowTheAddInvoiceOption(
            tab,
            subTab,
            property?.mgmt_model,
            isTPM,
            isOwner
          ) && (
            <Link to={`add?type=${tab}`} className="font_m fw_6 d_block link">
              + Create Invoice
            </Link>
          )}
      </div>

      <Searchbar
        //  placeholder="search"
        query={searchQuery}
        setQuery={setSearchQuery}
      />

      {/* TAB BUTTONS */}
      <TabButton
        disabled={searchQuery !== ""}
        buttonList={buttonLists}
        currentKey={tab}
        onSelect={onTabSelect}
      />

      {/* Display Table Data Based On The Current Tab */}

      {tab === GLOBAL.INVOICING.TENANTS ? (
        <>
          <TabButton
            disabled={searchQuery !== ""}
            className="invoicing_sub_tab"
            buttonList={subButtonLists}
            currentSubKey={property?.mgmt_model === 1 ? "others" : subTab}
            onSelect={onSubTabSelect}
          />

          {subTab === "rent" ? (
            <RentInvoicing
              currentKey={tab}
              tenantInvoices={tenantInvoices?.invoice}
              totalCount={tenantInvoices?.totalCount}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              property={property}
              invoiceAggregation={tenantAggregationInvoices}
            />
          ) : subTab === "facility" ? (
            <FacilitiesInvoicing
              currentKey={tab}
              tenantInvoices={tenantInvoices?.invoice}
              totalCount={tenantInvoices?.totalCount}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              property={property}
              invoiceAggregation={tenantAggregationInvoices}
            />
          ) : subTab === "utility" ? (
            <UtilityInvoicing
              currentKey={tab}
              tenantInvoices={tenantInvoices?.invoice}
              totalCount={tenantInvoices?.totalCount}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              property={property}
              invoiceAggregation={tenantAggregationInvoices}
            />
          ) : subTab === "deposits" ? (
            <DepositInvoicing
              currentKey={tab}
              tenantInvoices={tenantInvoices?.invoice}
              totalCount={tenantInvoices?.totalCount}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              property={property}
              invoiceAggregation={tenantAggregationInvoices}
            />
          ) : subTab === "application" ? (
            <ApplicationFeeInvoice
              currentKey={tab}
              tenantInvoices={tenantInvoices?.invoice}
              totalCount={tenantInvoices?.totalCount}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              property={property}
              invoiceAggregation={tenantAggregationInvoices}
            />
          ) : subTab === "commission" ? (
            <CommissionInvoicing
              currentKey={tab}
              tenantInvoices={tenantInvoices?.invoice}
              totalCount={tenantInvoices?.totalCount}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              property={property}
              invoiceAggregation={tenantAggregationInvoices}
            />
          ) : (
            <OthersInvoicing
              currentKey={tab}
              tenantInvoices={tenantInvoices?.invoice}
              totalCount={tenantInvoices?.totalCount}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              property={property}
              invoiceAggregation={tenantAggregationInvoices}
            />
          )}
        </>
      ) : tab === GLOBAL.INVOICING.PROPERTIES_INVOICES ? (
        <>
          <TabButton
            disabled={searchQuery !== ""}
            buttonList={subButtonLists}
            currentSubKey={property?.mgmt_model === 1 ? "others" : subTab}
            onSelect={onSubTabSelect}
          />

          {subTab === "utilities" ? (
            <UtilitiesInvoicing
              propertyInvoices={propertyInvoices?.invoice}
              currentKey={tab}
              property={property}
              totalCount={propertyInvoices?.totalCount}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              invoiceAggregation={propertyAggregationInvoices}
            />
          ) : (
            <OtherPropertyInvoicing
              propertyInvoices={propertyInvoices?.invoice}
              currentKey={tab}
              property={property}
              totalCount={propertyInvoices?.totalCount}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              invoiceAggregation={propertyAggregationInvoices}
            />
          )}
        </>
      ) : tab === GLOBAL.INVOICING.PARTIES_INVOICES ? (
        <>
          <TabButton
            disabled={searchQuery !== ""}
            buttonList={subButtonLists}
            currentSubKey={subTab}
            onSelect={onSubTabSelect}
          />

          {subTab === "spare-parts" ? (
            <SparePartsInvoicing
              partiesInvoice={partyInvoices?.invoice}
              currentKey={tab}
              property={property}
              totalCount={partyInvoices?.totalCount}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              invoiceAggregation={partyAggregationInvoices}
            />
          ) : (
            <ContractsInvoicing
              partiesInvoice={partyInvoices?.invoice}
              currentKey={tab}
              property={property}
              totalCount={partyInvoices?.totalCount}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              invoiceAggregation={partyAggregationInvoices}
            />
          )}
        </>
      ) : (
        <>
          <TabButton
            disabled={searchQuery !== ""}
            buttonList={subButtonLists}
            currentSubKey={subTab}
            onSelect={onSubTabSelect}
          />

          {subTab === "active" ? (
            <ActiveInvoicing
              advertisementInvoice={advertisementInvoices?.invoice}
              currentKey={tab}
              property={property}
              totalCount={advertisementInvoices?.totalCount}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              // invoiceAggregation={partyAggregationInvoices}
            />
          ) : (
            <ExpiredInvoicing
              advertisementInvoice={advertisementInvoices?.invoice}
              currentKey={tab}
              property={property}
              totalCount={advertisementInvoices?.totalCount}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              // invoiceAggregation={partyAggregationInvoices}
            />
          )}
        </>
      )}
    </div>
  );
};
/* Component ends */

export default Invoicing;
