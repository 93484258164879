/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import { useSelector, useDispatch } from "react-redux";
import GLOBAL from "../../../../setup/constants/global";
/* Import configuration ends */

/* Import redux slices component starts */
import {
  fetchUnitTypes,
  getUnitTypes,
} from "../../../../setup/store/slices/masterSlice";
import { setUnitFilters } from "../../../../setup/store/slices/filterSlice";
import { getUserTheme } from "../../../../setup/store/slices/authSlice";
import { fetchUnits } from "../../../../setup/store/slices/unitSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
import { Modal } from "react-bootstrap";
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

import "./style.scss";

/* Import local pages and component starts */
import CustomSelectInput from "../../../ui/input/CustomSelectInput/CustomSelectInput";
import CustomRadioInput from "../../../ui/input/CustomRadioInput/CustomRadioInput";
import CustomButton from "../../../ui/button/CustomButton/CustomButton";
import TextButton from "../../../ui/button/TextButton/TextButton";

/* Import local pages and component ends */

/* Component starts */
const UnitTableFilterModal = (props) => {
  /* Props destructuring starts */
  const {
    show,
    size = "lg",
    onClose,
    propertyId,
    currentPage,
    setFilterConfig,
    filterConfig,
  } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [selectedUnitType, setSelectedUnitType] = useState("");
  const [status, setStatus] = useState();
  const [subStatus, setSubStatus] = useState();
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const dispatch = useDispatch();
  // Redux Selector
  const unitTypes = useSelector(getUnitTypes);
  const userTheme = useSelector(getUserTheme);
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  // Resetting all filters
  const clearFilters = () => {
    setSelectedUnitType(null);
    setStatus(null);
    setSubStatus(null);
    // dispatch(setUnitFilters(null));
    dispatch(
      fetchUnits(
        // { property_id: propertyId, page: currentPage }
        {
          property_id: propertyId,
          page: currentPage !== undefined ? currentPage : 1,
          filters: {
            status: null,
            sub_status: null,
            unit_type: null,
            query: "",
          },
        }
      )
    );
    onClose();
  };

  // On applying filters
  const onApply = () => {
    let payload = {
      unit_type: selectedUnitType || selectedUnitType?.name || null,
      status: status === 1 ? true : status === 0 ? false : null,
      sub_status: subStatus,
      query: "",
    };

    console.log(payload, "()==()");
    // dispatch(setUnitFilters(payload));
    // dispatch(
    //   fetchUnits(
    //     // { property_id: propertyId, page: currentPage }
    //     {
    //       property_id: propertyId,
    //       page: currentPage !== undefined ? currentPage : 1,
    //       filters: payload,
    //       // {
    //       //   status: null,
    //       //   sub_status: null,
    //       //   unit_type: null,
    //       //   query: "",
    //       // },
    //     }
    //   )
    // );
    onClose();
  };

  const handleChange = (e) => {
    setSelectedUnitType(e.target.value);
  };

  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  const onSelectingUnitType = (e) => {
    console.log(e);
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  // Fetch all unit types on initital loads
  useEffect(() => {
    dispatch(fetchUnitTypes());
  }, []);
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <Modal
      size={size}
      centered
      show={show}
      className="unit-table-filter-modal-wrapper"
    >
      <Modal.Body
        className={`${userTheme === "dark" ? "bg_darkTheme" : "bg_lightTheme"}`}
      >
        <h2 className="text-center font_blue font_xl">Filter Units</h2>
        <div className="filter-options">
          {/* Select Unit Type */}
          {/* <CustomSelectInput
            label="Unit Type"
            valueExtractor={(item) => item?.name}
            setValue={setSelectedUnitType}
            // handleBlur={handleBlur}
            handleChange={(e) => handleChange(e)}
            options={unitTypes}
            value={selectedUnitType}
            emptyOption={true}
            emptyOptionText="All"
          /> */}

          <CustomSelectInput
            label="Unit Type"
            handleChange={onSelectingUnitType}
            options={unitTypes}
            value={selectedUnitType}
            valueExtractor={(d) => d?.name}
            optionValueExtractor={(d) => d?._id}
          />

          {/* Select status */}
          <div className="status">
            <h3 className="font_m font_grey w_100">Unit Active Status</h3>
            {/* Active */}
            <CustomRadioInput
              className="filter-radio-btn"
              label="Active"
              isSelected={status === 1}
              onSelecting={() => {
                setStatus((prev) => (prev === 1 ? null : 1));
              }}
            />

            {/* Not Active */}
            <CustomRadioInput
              className="filter-radio-btn"
              label="Not Active"
              isSelected={status === 0}
              onSelecting={() => {
                setStatus((prev) => (prev === 0 ? null : 0));
              }}
            />
          </div>

          {/* Select sub-status if selected status is active */}
          {status === 1 && (
            <div className="sub-status">
              <h3 className="font_m font_grey w_100">Unit Sub Status</h3>
              {/* Vacant */}
              <CustomRadioInput
                className="filter-radio-btn"
                label="Vacant"
                isSelected={subStatus === GLOBAL.UNIT_STATUS.VACANT}
                onSelecting={() => {
                  setSubStatus(GLOBAL.UNIT_STATUS.VACANT);
                }}
              />

              {/* Reserved */}
              <CustomRadioInput
                className="filter-radio-btn"
                label="Reserved"
                isSelected={subStatus === GLOBAL.UNIT_STATUS.RESERVED}
                onSelecting={() => {
                  setSubStatus(GLOBAL.UNIT_STATUS.RESERVED);
                }}
              />

              {/* Occupied */}
              <CustomRadioInput
                className="filter-radio-btn"
                label="Occupied"
                isSelected={subStatus === GLOBAL.UNIT_STATUS.OCCUPIED}
                onSelecting={() => {
                  setSubStatus(GLOBAL.UNIT_STATUS.OCCUPIED);
                }}
              />
            </div>
          )}

          {/* Reset filters */}
          <div className="reset text-end">
            <TextButton
              onClick={clearFilters}
              className="reset-btn font_m fw_6"
            >
              Reset Filters
            </TextButton>
          </div>
        </div>

        {/* Apply and close button */}
        <div className="button-wrapper">
          <CustomButton onClick={onApply} size="m" className="w_100">
            Apply
          </CustomButton>
          <CustomButton
            onClick={onClose}
            variant="red"
            size="m"
            className="w_100"
          >
            Close
          </CustomButton>
        </div>
      </Modal.Body>
    </Modal>
  );
};
/* Component ends */

export default UnitTableFilterModal;
