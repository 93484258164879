/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import GLOBAL from "../../setup/constants/global";
import { pathNamePopped } from "../../setup/utils/global-helper";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
/* Import configuration ends */

/* Import redux slices component starts */
import {
  showErrorAlert,
  showSuccessAlert,
} from "../../setup/store/slices/globalAlertSlice";
import {
  fetchRoutineTaskGeneratedTicketsList,
  fetchRoutineTasks,
  getRoutineTaskLists,
  getRoutineTaskTicketLists,
} from "../../setup/store/slices/routineSlice";
import { setLoading } from "../../setup/store/slices/unpersistedSlice";
import {
  fetchPropertyDetailByID,
  getPropertyDetail,
} from "../../setup/store/slices/propertySlice";
import { fetchUser, getUser } from "../../setup/store/slices/authSlice";
import {
  fetchUserRoles,
  getUserRoles,
} from "../../setup/store/slices/masterSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
// import Searchbar from "../../components/ui/other/Searchbar/Searchbar";
import TabButton from "../../components/ui/other/TabButton/TabButton";
import ActiveTasks from "../../components/module/RoutineRequest/RoutineTaskTable/TasksTable/ActiveTasks";
import ExpiredTasks from "../../components/module/RoutineRequest/RoutineTaskTable/TasksTable/ExpiredTasks";
import OpenTasks from "../../components/module/RoutineRequest/RoutineTaskTable/RequestsTable/OpenTasks";
import ClosedTasks from "../../components/module/RoutineRequest/RoutineTaskTable/RequestsTable/ClosedTasks";
import Searchbar from "../../components/ui/other/Searchbar/Searchbar";
import BreadCrumb from "../../components/ui/other/BreadCrumb/BreadCrumb";
/* Import local pages and component ends */

// styles sheet
import "./style.scss";

const RoutineRequest = (props) => {
  /* Props destructuring starts */
  const {} = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [role, setRole] = useState();
  const [buttonLists, setButtonLists] = useState([]);
  const [subButtonLists, setSubButtonLists] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { tab, subTab, propertyId } = useParams();

  const routineTaskList = useSelector(getRoutineTaskLists);
  const routineTaskTicketList = useSelector(getRoutineTaskTicketLists);
  const propertyDetail = useSelector(getPropertyDetail);
  const user = useSelector(getUser);
  const userRoles = useSelector(getUserRoles);
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const isOwner = localStorage.getItem("user_role") === "Owner";
  const isPM = localStorage.getItem("user_role") === "Property Manager";
  const isSM = localStorage.getItem("user_role") === "Security Manager";
  const isFM = localStorage.getItem("user_role") === "Facility Manager";
  const isThirdPM = localStorage.getItem("user_role") === "Third Party Manager";

  const isTPT = localStorage.getItem("user_role") === "Third Party Technician";
  const isMT = localStorage.getItem("user_role") === "Maintenance Technician";
  const isAcc = localStorage.getItem("user_role") === "Accountant";
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */

  const onTabSelect = (key) => {
    const newPathname = pathNamePopped(pathname, 2);
    if (key === "task") {
      navigate(`${newPathname}/${key}/active`);
      setCurrentPage(1);
    } else {
      navigate(`${newPathname}/${key}/open`);
    }
  };

  const onSubTabSelect = (key) => {
    const newPathname = pathNamePopped(pathname, 1);
    navigate(`${newPathname}/${key}`);
    setCurrentPage(1);
    // setCurrentSubKey(key);
  };

  const handleFetchTask = async (payloadArgs, subTabArgs) => {
    // Creating or collecting payload data to be sent
    const payload = { ...payloadArgs };

    switch (subTabArgs) {
      case "active":
        payload["status"] = "Active";
        break;

      case "expired":
        payload["status"] = "Expired";
        break;
    }

    console.log("Payload", payload);

    // Backend response. Try, Catch
    try {
      const result = await dispatch(fetchRoutineTasks(payload));
      console.log("Response", result);

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          dispatch(
            showErrorAlert({
              title: "Error!",
              description:
                typeof result?.payload === "string" ? result?.payload : "",
            })
          );
          break;

        case GLOBAL.FULFILLED:
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };

  const handleFetchRequest = async (payloadArgs, subTabArgs) => {
    // Creating or collecting payload data to be sent
    const payload = { ...payloadArgs };

    switch (subTabArgs) {
      case "open":
        payload["status"] = "Open";
        break;

      default:
        payload["status"] = "Closed";
        break;
    }

    console.log("Payload", payload);

    // Backend response. Try, Catch
    try {
      const result = await dispatch(
        fetchRoutineTaskGeneratedTicketsList(payload)
      );
      console.log("Response", result);

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          dispatch(
            showErrorAlert({
              title: "Error!",
              description:
                typeof result?.payload === "string" ? result?.payload : "",
            })
          );
          break;

        case GLOBAL.FULFILLED:
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };

  const handleFetchList = async (
    tabArgs,
    subTabArgs,
    pageArgs,
    searchArgs = ""
  ) => {
    const payload = {
      property_id: propertyId,
      page: pageArgs,
    };

    if (searchArgs !== "") {
      payload["search"] = searchArgs;
    }

    switch (tabArgs) {
      case "task":
        await handleFetchTask(payload, subTabArgs);
        break;

      case "request":
        if (isAcc || isMT || isTPT) {
          payload["requester"] = user?._id;
        }
        await handleFetchRequest(payload, subTabArgs);
        break;
    }
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  useEffect(() => {
    if (
      [
        "Client",
        "Owner",
        "Property Manager",
        "Lease Officer",
        "Facility Management Company",
        "Facility Manager",
        "Security Management Company",
        "Security Manager",
        "Third-Party Company",
        "Third Party Manager",
      ].includes(role)
    ) {
      setButtonLists([
        { key: "task", label: "Task" },
        { key: "request", label: "Request" },
      ]);
      if (tab === "task") {
        setSubButtonLists([
          { key: "active", label: "Active" },
          { key: "expired", label: "Expired" },
        ]);
      } else {
        setSubButtonLists([
          { key: "open", label: "Open" },
          { key: "closed", label: "Closed" },
        ]);
      }
    } else {
      setButtonLists([{ key: "request", label: "Request" }]);
      if (tab === "request") {
        setSubButtonLists([
          { key: "open", label: "Open" },
          { key: "closed", label: "Closed" },
        ]);
      }
    }
  }, [tab, isMT, isAcc, isTPT, role]);

  useEffect(() => {
    if (searchQuery !== "") {
      handleFetchList(tab, subTab, currentPage, searchQuery);
    } else {
      handleFetchList(tab, subTab, currentPage, "");
    }
  }, [tab, subTab, propertyId, currentPage]);

  useEffect(() => {
    let timeoutid = null;

    if (searchQuery !== "") {
      timeoutid = setTimeout(() => {
        handleFetchList(tab, subTab, currentPage, searchQuery);
      }, 1000);
    } else {
      handleFetchList(tab, subTab, currentPage, "");
    }

    return () => {
      if (timeoutid) {
        clearTimeout(timeoutid);
      }
    };
  }, [searchQuery]);

  useEffect(() => {
    if (propertyId) {
      (async () => {
        try {
          await dispatch(setLoading(true));
          await dispatch(fetchUserRoles());
          await dispatch(fetchUser());
          await dispatch(fetchPropertyDetailByID({ property_id: propertyId }));
          await dispatch(setLoading(false));
        } catch (e) {}
      })();
    }
  }, [propertyId]);

  // Setting role after all user roles loaded
  useEffect(() => {
    let userRole = userRoles?.filter((d) => d?._id === user?.role_id)[0]?.name;
    setRole(userRole);
  }, [userRoles]);

  useEffect(() => {
    setCurrentPage(1);
  }, [searchQuery]);
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  /* Component starts */
  return (
    <>
      <BreadCrumb
        type="routineRequests"
        // from={pathname?.includes("ratingAndReview") ? "ratingAndReview" : ""}
      />
      <div className="routine-wrapper">
        <div className="routine-heading">
          <h2 className="font_xxl fw_6">Routine Requests</h2>
          {tab === "task" &&
            subTab === "active" &&
            ((propertyDetail?.mgmt_model === 0 && isOwner) ||
              isPM ||
              isFM ||
              isSM ||
              isThirdPM) && (
              <Link to="add" className="font_m fw_6 d_block link">
                + Create Task
              </Link>
            )}
        </div>

        {/* <Searchbar /> */}
        <Searchbar query={searchQuery} setQuery={setSearchQuery} />

        {/* TAB BUTTONS */}
        <TabButton
          buttonList={buttonLists}
          currentKey={tab}
          onSelect={onTabSelect}
        />

        {/* Display Table Data Based On The Current Tab */}

        {tab === "task" ? (
          <>
            <TabButton
              buttonList={subButtonLists}
              currentSubKey={subTab}
              onSelect={onSubTabSelect}
            />

            {subTab === "active" ? (
              <ActiveTasks
                taskLists={routineTaskList?.routine}
                totalCount={routineTaskList?.totalCount}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                property={propertyDetail}
              />
            ) : (
              <ExpiredTasks
                taskLists={routineTaskList?.routine}
                totalCount={routineTaskList?.totalCount}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                property={propertyDetail}
              />
            )}
          </>
        ) : (
          <>
            <TabButton
              buttonList={subButtonLists}
              currentSubKey={subTab}
              onSelect={onSubTabSelect}
            />

            {subTab === "open" ? (
              <OpenTasks
                ticketLists={routineTaskTicketList?.routine}
                totalCount={routineTaskTicketList?.totalCount}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
              />
            ) : (
              <ClosedTasks
                ticketLists={routineTaskTicketList?.routine}
                totalCount={routineTaskTicketList?.totalCount}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
              />
            )}
          </>
        )}
      </div>
    </>
  );
};
/* Component End */

export default RoutineRequest;
