import GLOBAL from "../../../../setup/constants/global";

const tenantMaintenanceTabName = (tab) => {
  let tabName = "";
  switch (tab) {
    case "new":
      tabName = GLOBAL.MAINTENANCE.NEW;
      break;

    case "ongoing":
      tabName = GLOBAL.MAINTENANCE.ONGOING;
      break;

    case "spare_parts":
      tabName = GLOBAL.MAINTENANCE.SPARE_PARTS;
      break;

    case "completed":
      tabName = GLOBAL.MAINTENANCE.COMPLETED;
      break;

    case "rejected":
      tabName = GLOBAL.MAINTENANCE.REJECTED;
      break;

    case "escalated":
      tabName = GLOBAL.MAINTENANCE.ESCALATED;
      break;

    case "move_in":
      tabName = GLOBAL.MAINTENANCE.MOVE_IN;
      break;

    case "move_out":
      tabName = GLOBAL.MAINTENANCE.MOVE_OUT;
      break;

    case "units":
      tabName = GLOBAL.MAINTENANCE.UNITS;
      break;

    case "common_area":
      tabName = GLOBAL.MAINTENANCE.COMMON_AREA;
      break;

    case "closed":
      tabName = GLOBAL.MAINTENANCE.CLOSED;
      break;

    default:
      break;
  }

  return encodeURIComponent(tabName);
};

export const generateNavigation = (notification) => {
  const reference = notification?.reference;
  const underCommunity = !!reference?.community_id;
  const communityId = reference?.community_id || "";
  const propertyId = reference?.property_id || "";
  const unitId = reference?.unit_id || "";
  const advertisementId = reference?.advertisement_id || "";
  const invoiceId = reference.invoice_id || "";
  const intMaintenanceId = reference.maintenance_id || "";
  const maintenanceId = reference.maintenance_id || "";
  const tab = reference?.tab || "";
  const securityComplainId = reference?.security_complain_id || "";
  const securityIncidentId = reference?.security_id || "";

  const applicationId = reference?.application_id || "";

  const lostOrFoundId = reference?.lost_found_id;

  let generatedItemLink = "";
  const propertyLink = underCommunity
    ? `communities/${communityId}/properties/${propertyId}`
    : `independent/properties/${propertyId}`;

  switch (notification?.type) {
    case "community":
      return `/communities/${communityId}/`;

    case "unit_list":
      generatedItemLink = `/${propertyLink}/units`;
      break;

    case "unit":
      generatedItemLink = `/${propertyLink}/units/${unitId}`;
      break;

    case "property":
      generatedItemLink = `/${propertyLink}`;
      break;

    case "property_setting":
      generatedItemLink = `/${propertyLink}/settings`;
      break;

    case "property_invoice":
      generatedItemLink = `/${propertyLink}/invoicing/?type=Properties/${invoiceId}`;
      break;

    case "party_invoice":
      generatedItemLink = `/${propertyLink}/invoicing/?type=Parties/${invoiceId}`;
      break;

    case "tenant_invoice":
      generatedItemLink = `/${propertyLink}/invoicing/?type=Tenants/${invoiceId}`;
      break;

    case "internal_rating":
    case "internal_quotation":
    case "internal_maintenance":
      generatedItemLink = `/${propertyLink}/internal/maintenances/${tab}/${intMaintenanceId}`;
      break;

    case "tenant_rating":
    case "maintenance":
    case "quotation":
      // generatedItemLink = `/${propertyLink}/maintenances/${tenantMaintenanceTabName(
      //   tab
      // )}/${maintenanceId}`;

      generatedItemLink = `/${propertyLink}/maintenances/${
        reference?.type === "Move In"
          ? "Move%20In"
          : reference?.type === "Move Out"
          ? "Move%20Out"
          : "Ongoing"
      }/${maintenanceId}`;

      break;

    case "tenant_maintenance":
      generatedItemLink = `/${propertyLink}/maintenances/${tenantMaintenanceTabName(
        tab
      )}/${maintenanceId}`;
      break;

    case "invoice":
      generatedItemLink = `/settings/manageLicense/invoices/${invoiceId}`;
      break;

    case "team_onboarding":
      generatedItemLink = `/${propertyLink}/teams`;
      break;

    case "security_complain":
      generatedItemLink = `/${propertyLink}/security/complaints/${tab}/${securityComplainId}`;
      break;

    case "security_incident":
      generatedItemLink = `/${propertyLink}/security/incidents/${tab}/${securityIncidentId}`;
      break;

    case "lost_found":
      generatedItemLink = `/${propertyLink}/lost_and_found/${tab}/${lostOrFoundId}`;
      break;

    case "tenant_onboarding":
      generatedItemLink = `/${propertyLink}/tenants/${tab}/${applicationId}`;
      break;

    case "contract_renewal":
      generatedItemLink = `/${propertyLink}/tenants/${reference?.type}/${applicationId}?tenant_id=${reference?.tenant_id}`;
      break;

    case "move_out":
      generatedItemLink = `/${propertyLink}/tenants/move-out/${reference?.move_out_id}`;
      break;

    case "routine_request":
      generatedItemLink = `/${propertyLink}/routine-request/${reference?.tab}/${reference?.subTab}/${reference?.routine_ticket_id}`;
      break;

    case "move_in":
      generatedItemLink = `/${propertyLink}/maintenances/Move%20In/${maintenanceId}`;
      break;

    default:
      break;
  }

  return generatedItemLink;
};

export const generateNavigationTenant = (notification) => {
  const reference = notification?.reference;
  const underCommunity = !!reference?.community_id;
  const communityId = reference?.community_id || "";
  const propertyId = reference?.property_id || "";
  const unitId = reference?.unit_id || "";
  const advertisementId = reference?.advertisement_id || "";
  const invoiceId = reference.invoice_id || "";
  const intMaintenanceId = reference.maintenance_id || "";
  const maintenanceId = reference.maintenance_id || "";
  const securityComplainId = reference?.security_complain_id || "";
  const moveOutId = reference?.move_out_id || "";
  const contractId = reference?.contract_id || "";

  let generatedItemLink = "";
  const unitLink = `tenant/unit/${unitId}`;

  switch (notification?.type) {
    case "maintenance":
      generatedItemLink = `/${unitLink}/maintenance_requests/${maintenanceId}`;
      break;

    case "tenant_maintenance":
      generatedItemLink = `/${unitLink}/maintenance_requests/${maintenanceId}`;
      break;

    case "move_out":
      if (reference?.type === "clearance") {
        generatedItemLink = `/${unitLink}/contracts/${contractId}/moveout/${moveOutId}/clearance`;
      } else {
        generatedItemLink = `/${unitLink}/contracts/${contractId}/moveout/${moveOutId}`;
      }
      break;

    // case "tenant_invoice":
    //   generatedItemLink = `/${unitLink}/invoicing/${invoiceId}`;
    // break;

    case "security_complain":
      generatedItemLink = `/${unitLink}/security_complains/${securityComplainId}`;
      break;

    case "lost_found":
      generatedItemLink = `/${unitLink}/lost_and_found/found`;
      break;

    case "contract_renewal":
      if (notification?.title !== "Pending Final Agreement Signature") {
        generatedItemLink = `/${unitLink}/contracts/${reference?.contract_id}/application/${reference?.application_id}/renew`;
      }
      break;

    case "tenant_onboarding":
      if (notification?.title === "Pending Initial Agreement Signature") {
        generatedItemLink = `/${unitLink}/application/${reference?.application_id}`;
      }
      // else if (
      //   notification?.notification === "Please upload the onboarding documents."
      // ) {
      //   generatedItemLink = `/${unitLink}/application/${reference?.application_id}`;
      // }
      break;

    default:
      break;
  }

  return generatedItemLink;
};
