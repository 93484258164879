export const initialValues = {
  title: "",
  vendor_name: "",
  email: "",
  amount: "",
  image: null,
  mobile_number: "",
};

export const validations = (values) => {
  const errorFields = {};

  /* Validations for Subject Starts */
  if (values.title === "") {
    errorFields.title = "Title is required";
  }

  /* Validations for Subject Ends */

  /* Validations for Description Starts */
  // if (values.description === "") {
  //   errorFields.description = "Description is required";
  // }
  /* Validations for Description Ends */

  return errorFields;
};

export const addDoc = (formRef, docList, setInitialFormValues, setDocList) => {
  // const length = docList.length;
  const generetedID =
    docList.length === 0 ? 0 : docList[docList.length - 1] + 1;
  const currentFormState = { ...formRef.current.values };
  setInitialFormValues(() => {
    // Generating new field name according to array indexes
    // currentFormState[`document_${length}`] = null;
    // return currentFormState;
    currentFormState[`document_${generetedID}`] = null;
    return currentFormState;
  });
  setDocList((p) => [...p, generetedID]);
};

export const removeDoc = (
  formRef,
  docList,
  setInitialFormValues,
  setDocList,
  index
) => {
  const removeID = docList[index];
  const currentFormState = { ...formRef.current.values };

  setInitialFormValues(() => {
    delete currentFormState[`document_${removeID}`];
    return currentFormState;
  });

  setDocList((prevState) => {
    const prevFields = [...prevState];
    prevFields.splice(index, 1);
    return prevFields;
  });
};

export const loadInitialValue = (
  passData,
  setInitialFormValues,
  setStartDate,
  setEndDate,
  setDescVal
) => {
  if (passData?.type === "internal") {
    setInitialFormValues({
      title: passData?.title || "",
      image: passData?.banner?.url ? passData?.banner?.url : null,
    });
  }

  if (passData?.type === "vendor") {
    setInitialFormValues({
      title: passData?.title || "",
      vendor_name: passData?.vendor_name || "",
      email: passData?.email || "",
      amount: passData?.amount || "",
      image: passData?.banner?.url ? passData?.banner?.url : null,
      mobile_number: `${passData?.countrycode || ""} ${passData?.mobile || ""}`,
    });
  }

  setDescVal({
    value: passData?.html_messege ? passData?.html_messege : "",
  });

  setStartDate(
    passData?.start_date ? new Date(passData?.start_date) : new Date()
  );
  setEndDate(passData?.end_date ? new Date(passData?.end_date) : new Date());
};
