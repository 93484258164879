/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import { Link, useLocation, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
/* Import configuration ends */

/* Import redux slices component starts */
import {
  fetchAdvertisementsList,
  getAdvertisementsList,
} from "../../setup/store/slices/advertisementSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import Searchbar from "../../components/ui/other/Searchbar/Searchbar";
import BreadCrumb from "../../components/ui/other/BreadCrumb/BreadCrumb";
import AdvertismentsTable from "../../components/module/Advertising/AdvertismentsTable/AdvertismentsTable";
/* Import local pages and component ends */

// styles sheet
import "./style.scss";

/* Component starts */
const Advertising = (props) => {
  /* Props destructuring starts */
  const {} = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [currentPage, setCurrentPage] = useState(1);
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const { pathname } = useLocation();
  const param = useParams();

  const dispatch = useDispatch();
  const advertisementsList = useSelector(getAdvertisementsList);
  /* Other hooks declaration ends */
  // Redux Selectors

  /* Component variable declaration / object destructure starts */
  const isClient = localStorage.getItem("user_role") === "Client";
  const isPM = localStorage.getItem("user_role") === "Property Manager";
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  const loadAnnoucementList = async () => {
    // if (pathname.includes("communities")) {
    //   await dispatch(
    //     fetchAnnoucementsListBasedCommunity({
    //       community_id: param?.communityId,
    //       page: currentPage,
    //     })
    //   );
    // }
    // // else if (pathname.includes("independent") && role !== "Client") {
    // //   await dispatch(
    // //     fetchUsersAnnoucementsList({
    // //       property_id: param?.propertyId,
    // //       page: currentPage,
    // //     })
    // //   );
    // // }
    // else
    if (pathname.includes("independent")) {
      await dispatch(
        fetchAdvertisementsList({
          property_id: param?.propertyId,
          page: currentPage,
        })
      );
    }
    // else {
    //   await dispatch(fetchAnnoucementsList());
    // }
  };
  /* Component function definition ends */

  /* Component useEffect starts */

  useEffect(() => {
    loadAnnoucementList();
  }, [pathname, currentPage]);

  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */

  return (
    <>
      <BreadCrumb type="advertisment_page" />

      <div className="advertisment-wrapper">
        <div className="heading-wrapper">
          <div className="register-wrapper">
            <h2 className="font_xxl fw_5">Advertisements</h2>
            {(isClient || isPM) && (
              <Link to="add" className="font_m fw_4 d_block link-security">
                + Create Advertisements
              </Link>
            )}
          </div>
        </div>

        <Searchbar />

        <AdvertismentsTable
          advertimentsList={
            advertisementsList?.result
              ? advertisementsList?.result
              : advertisementsList
          }
          totalCount={
            advertisementsList?.totalCount
              ? advertisementsList?.totalCount
              : null
          }
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </div>
    </>
  );
};
/* Component End */

export default Advertising;
