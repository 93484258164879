/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import GLOBAL from "../../setup/constants/global";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
/* Import configuration ends */

/* Import redux slices component starts */
import {
  showErrorAlert,
  showSuccessAlert,
} from "../../setup/store/slices/globalAlertSlice";
import {
  fetchContractsByProperty,
  fetchMoveOutRequestsByProperty,
} from "../../setup/store/slices/contractSlice";
import {
  fetchExistingTenantsByProperty,
  fetchNewTenantsByProperty,
  fetchOldTenantsByProperty,
  fetchTransitionedTenantsByProperty,
  fetchCancelledTenantsByProperty,
} from "../../setup/store/slices/propertySlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import ManageTenants from "../../components/module/Tenant/ManageTenants/ManageTenants";
/* Import local pages and component ends */

/* Component starts */
const ManageTenantsPage = (props) => {
  /* Props destructuring starts */
  const {} = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const dispatch = useDispatch();
  const { propertyId, tenantListCurrentTab } = useParams();
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const [tenantList, setTenantList] = useState([]);
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  const handleFetchTenantList = async (tab, property_id, page, search = "") => {
    // Creating or collecting payload data to be sent
    const payload = { property_id, page };

    if (search !== "") {
      payload["search"] = search;
    }

    console.log("Payload", payload);

    // Backend response. Try, Catch
    try {
      let result = {};

      switch (tab) {
        case "existing":
          result = await dispatch(fetchExistingTenantsByProperty(payload)); // Done
          break;

        case "transitioned":
          result = await dispatch(fetchTransitionedTenantsByProperty(payload));
          break;

        case "new":
          result = await dispatch(fetchNewTenantsByProperty(payload));
          break;

        case "previous-tenants":
          result = await dispatch(fetchOldTenantsByProperty(payload));
          break;

        case "cancelled":
          result = await dispatch(fetchCancelledTenantsByProperty(payload));
          break;

        case "renewals":
          result = await dispatch(fetchContractsByProperty(payload));
          break;

        case "move-out":
          result = await dispatch(fetchMoveOutRequestsByProperty(payload));
          break;

        default:
          break;
      }

      console.log("Responsedsas", result);

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          dispatch(
            showErrorAlert({
              title: "Error!",
              description:
                typeof result?.payload === "string" ? result?.payload : "",
            })
          );
          break;

        case GLOBAL.FULFILLED:
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  // Fetching tenants on initial load
  useEffect(() => {
    // dispatch(fetchPropertyTenants({ property_id: propertyId }));
    // dispatch(fetchMoveoutTenants({ property_id: propertyId }));
  }, []);
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div>
      <ManageTenants handleFetchTenantList={handleFetchTenantList} />
    </div>
  );
};
/* Component ends */

export default ManageTenantsPage;
