/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
/* Import configuration ends */

/* Import redux slices component starts */
import {
  showSuccessAlert,
  showErrorAlert,
} from "../../../../../../setup/store/slices/globalAlertSlice";
import { setLoading } from "../../../../../../setup/store/slices/unpersistedSlice";
import {
  fetchViolationsList,
  getViolationsList,
} from "../../../../../../setup/store/slices/violationSlice";
import {
  fetchUserRoles,
  getUserRoles,
} from "../../../../../../setup/store/slices/masterSlice";
import {
  fetchUser,
  getUser,
} from "../../../../../../setup/store/slices/authSlice";
import {
  fetchPropertyDetailByID,
  getPropertyDetail,
} from "../../../../../../setup/store/slices/propertySlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import GLOBAL from "../../../../../../setup/constants/global";
import Searchbar from "../../../../../ui/other/Searchbar/Searchbar";
import TabButton from "../../../../../ui/other/TabButton/TabButton";
import { buttonList } from "./helper";
import TenantsViolations from "./sub-components/TenantsViolations";
import TeamsViolations from "./sub-components/TeamsViolations";
import OthersViolations from "./sub-components/OthersViolations";
/* Import local pages and component ends */

// Styles
import "./style.scss";

/* Component starts */
const ClientViolationsTable = (props) => {
  /* Props destructuring starts */
  const {} = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [currentKey, setCurrentKey] = useState(GLOBAL.VIOLATION_TO.TENANT);
  const [buttonLists, setButtonLists] = useState([]);
  const [role, setRole] = useState();

  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState();
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { propertyId, tab } = useParams();
  const property = useSelector(getPropertyDetail);

  const violationLists = useSelector(getViolationsList);
  const userRoles = useSelector(getUserRoles);
  const user = useSelector(getUser);

  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  let payload = {};
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  const handleFetchViolations = async (tabArgs, pageArgs, searchArgs = "") => {
    // Creating or collecting payload data to be sent
    const payload = { property_id: propertyId, page: pageArgs };

    if (searchArgs !== "") {
      payload["search"] = searchArgs.toLowerCase();
    }

    switch (tabArgs) {
      case "tenants":
        payload["violator"] = "Tenants";
        break;

      case "teams":
        payload["violator"] = "Teams";
        break;

      default:
        payload["violator"] = "Others";
        break;
    }

    console.log("Payload", payload);

    // Backend response. Try, Catch
    try {
      const result = await dispatch(fetchViolationsList(payload));
      console.log("Response", result);

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          dispatch(
            showErrorAlert({
              title: "Error!",
              description:
                typeof result?.payload === "string" ? result?.payload : "",
            })
          );
          return [];
          break;

        case GLOBAL.FULFILLED:
          return result.payload;
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
      return [];
    }
  };

  const onTabSelect = (key) => {
    const pathnameArr = pathname.split("/");
    pathnameArr.pop();
    navigate(pathnameArr.join("/") + "/" + key);
    // setCurrentKey(key);
    setCurrentPage(1);
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  useEffect(() => {
    if (property) {
      if (property?.mgmt_model === 2) {
        setButtonLists(buttonList);
      } else {
        setButtonLists([
          { key: "tenants", label: "Tenants" },
          { key: "others", label: "Others" },
        ]);
      }
    }
  }, [property]);

  useEffect(() => {
    (async () => {
      try {
        await dispatch(setLoading(true));
        await dispatch(fetchPropertyDetailByID({ property_id: propertyId }));
        await dispatch(setLoading(false));
      } catch (e) {}
    })();
  }, [propertyId]);

  useEffect(() => {
    if (searchQuery !== "") {
      handleFetchViolations(tab, currentPage, searchQuery);
    } else {
      handleFetchViolations(tab, currentPage, "");
    }
  }, [tab, currentPage]);

  useEffect(() => {
    let timeoutid = null;
    if (searchQuery !== "") {
      timeoutid = setTimeout(() => {
        handleFetchViolations(tab, currentPage, searchQuery);
      }, 1000);
    } else {
      handleFetchViolations(tab, currentPage, "");
    }

    return () => {
      if (timeoutid) {
        clearTimeout(timeoutid);
      }
    };
  }, [searchQuery]);

  useEffect(() => {
    (async () => {
      try {
        await dispatch(setLoading(true));
        await dispatch(fetchUserRoles());
        await dispatch(fetchUser());
        await dispatch(setLoading(false));
      } catch (e) {}
    })();
  }, []);

  // Setting role after all user roles loaded
  useEffect(() => {
    let userRole = userRoles.filter((d) => d._id === user?.role_id)[0]?.name;
    setRole(userRole);
  }, [userRoles]);

  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */

  return (
    <div className="voilation-table">
      <div className="heading">
        <h2 className="font_xxl fw_6">Voilations</h2>
        {(role === "Property Manager" || role === "Owner") && (
          <Link to="add" className="font_m fw_6 d_block link">
            + Issue Voilations
          </Link>
        )}
      </div>

      <Searchbar
        //  placeholder="search"
        query={searchQuery}
        setQuery={setSearchQuery}
        //  onInputSubmit={handleSerach}
      />

      {/* TAB BUTTONS */}
      <TabButton
        buttonList={buttonLists}
        currentKey={tab}
        onSelect={onTabSelect}
      />

      {/* Display Table Data Based On The Current Tab */}

      {tab === "tenants" ? (
        <TenantsViolations
          violationLists={violationLists?.result}
          totalCount={violationLists?.totalCount}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      ) : tab === "teams" ? (
        <TeamsViolations
          violationLists={violationLists?.result}
          totalCount={violationLists?.totalCount}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      ) : (
        <OthersViolations
          violationLists={violationLists?.result}
          totalCount={violationLists?.totalCount}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      )}
    </div>
  );
};
/* Component Ends */
export default ClientViolationsTable;
