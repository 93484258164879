/**
 *
 */

import React, { useEffect, useState } from "react";

/* Import configuration starts */
/* Import configuration ends */

/* Import redux slices component starts */
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import MUI component starts */
/* Import MUI component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import CustomSlider from "../../../ui/other/CustomSlider/CustomSlider";
import PropertySliderItem from "../PropertySliderItem/PropertySliderItem";
/* Import local pages and component ends */

/* Component starts */
const PropertySlider = (props) => {
  /* Props destructuring starts */
  const { propertyList, onClickProperty, heading = "Properties" } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [pairList, setPairList] = useState([]);
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  /* Component function definition ends */

  /* Component useEffect starts */
  useEffect(() => {
    // const arr = [...propertyList];
    // const newArr = [];
    // while (arr.length) newArr.push(arr.splice(0, 2));
    // setPairList(newArr);
  }, [propertyList]);
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="property-slider-component-wrapper">
      {heading && <h2 className="heading font_xl">{heading}</h2>}
      <CustomSlider>
        {propertyList?.map((item, index) => (
          <div key={index}>
            <PropertySliderItem
              onClick={() => {
                onClickProperty(item);
              }}
              info={item}
            />
            {/* {pairList.map((item, index) => (
          <div key={index}>
            <PropertySliderItem
              onClick={() => {
                onClickProperty(item[0]);
              }}
              info={item[0]}
            />
            {item[1] && (
              <PropertySliderItem
                onClick={() => {
                  onClickProperty(item[1]);
                }}
                info={item[1]}
              />
            )} */}
          </div>
        ))}
      </CustomSlider>
    </div>
  );
};
/* Component ends */

export default PropertySlider;
