/**
 *
 */

import React, { useState } from "react";

/* Import configuration starts */
import moment from "moment";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
/* Import configuration ends */

/* Import redux slices component starts */
import {
  deleteAdvertisement,
  pauseAdvertisement,
} from "../../../../setup/store/slices/advertisementSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
import { Table } from "react-bootstrap";
/* Import react bootstrap component ends */

/* Import image and SVG starts */
import {
  DeleteRed,
  EditGreen,
  EyeFilledBlue,
  PauseFilledGray,
  PlayFilledBlue,
} from "../../../../setup/constants/images";
/* Import image and SVG ends */

/* Import local pages and component starts */
import GLOBAL from "../../../../setup/constants/global";
import IconButton from "../../../ui/button/IconButton/IconButton";
import Pagination from "../../../ui/other/Pagination/Pagination";
import ConfirmationModal from "../../../ui/modal/ConfirmationModal/ConfirmationModal";
/* Import local pages and component ends */

// Styles
import "./style.scss";

/* Component starts */
const AdvertismentsTable = (props) => {
  /* Props destructuring starts */
  const { advertimentsList, totalCount, currentPage, setCurrentPage } = props;

  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [advertisementId, setAdvertisementId] = useState("");
  const [modalDesc, setModalDesc] = useState("");

  const [title, setTitle] = useState("");
  const [type, setType] = useState("");
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { propertyId } = useParams();

  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */

  const USER_DATA = localStorage.getItem("user_data")
    ? JSON.parse(localStorage.getItem("user_data"))
    : null;
  const DATE_FORMAT = USER_DATA ? USER_DATA?.date_format : "";
  const isClient = localStorage.getItem("user_role") === "Client";
  const isPM = localStorage.getItem("user_role") === "Property Manager";
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  // On delete Advertisement
  const onDelete = (item) => {
    setType("delete");
    setAdvertisementId(item?._id);
    setTitle("Confirm Delete");
    setModalDesc("Are you sure you want to delete this Advertisement ?");
    setShowConfirmationModal(true);
  };

  // On confirming delete
  const confirmDelete = async () => {
    // Collecting or creating payload data to be sent
    const payload = {
      _id: advertisementId,
      property_id: propertyId,
      page: 1,
    };

    console.log(payload, "[PAY]");

    // Backend response. Try, catch
    try {
      const result = await dispatch(deleteAdvertisement(payload));
      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          setModalDesc(result.payload);
          setShowConfirmationModal(false);
          setShowErrorModal(true);
          break;
        case GLOBAL.FULFILLED:
          setModalDesc("Advertisement successfully deleted");
          setShowConfirmationModal(false);
          setShowSuccessModal(true);
          break;

        default:
      }
    } catch (error) {
      // Handling error response
      console.log("error", error);
    }
  };

  // On Pause Advertisement
  const onPause = (item) => {
    setType("pause");
    setAdvertisementId(item?._id);
    setTitle("Confirm Pause");
    setModalDesc("Are you sure you want to pause this Advertisement ?");
    setShowConfirmationModal(true);
  };

  // On confirming Pause
  const confirmPause = async () => {
    // Collecting or creating payload data to be sent
    const payload = {
      _id: advertisementId,
      property_id: propertyId,
      page: 1,
      pause: true,
    };

    console.log(payload, "[PAY]");

    // Backend response. Try, catch
    try {
      const result = await dispatch(pauseAdvertisement(payload));
      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          setModalDesc(result.payload);
          setShowConfirmationModal(false);
          setShowErrorModal(true);
          break;
        case GLOBAL.FULFILLED:
          setModalDesc("Advertisement successfully paused");
          setShowConfirmationModal(false);
          setShowSuccessModal(true);
          break;

        default:
      }
    } catch (error) {
      // Handling error response
      console.log("error", error);
    }
  };

  // On Play Advertisement
  const onPlay = (item) => {
    setType("play");
    setAdvertisementId(item?._id);
    setTitle("Confirm Resume");
    setModalDesc("Are you sure you want to resume this Advertisement ?");
    setShowConfirmationModal(true);
  };

  // On confirming Play
  const confirmPlay = async () => {
    // Collecting or creating payload data to be sent
    const payload = {
      _id: advertisementId,
      property_id: propertyId,
      page: 1,
      pause: false,
    };

    console.log(payload, "[PAY]");

    // Backend response. Try, catch
    try {
      const result = await dispatch(pauseAdvertisement(payload));
      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          setModalDesc(result.payload);
          setShowConfirmationModal(false);
          setShowErrorModal(true);
          break;
        case GLOBAL.FULFILLED:
          setModalDesc("Advertisement successfully paused");
          setShowConfirmationModal(false);
          setShowSuccessModal(true);
          break;

        default:
      }
    } catch (error) {
      // Handling error response
      console.log("error", error);
    }
  };

  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */

  return (
    <>
      <div className="custom-table-wrapper advertiments-table-wrapper">
        {advertimentsList?.length > 0 && (
          <Table className="custom-table font_s" borderless responsive>
            {/* Table Heading */}
            <thead>
              <tr>
                <th>Ad ID</th>
                <th>Created By</th>
                <th>Start Date</th>
                <th>End Date</th>
                <th>Title</th>
                {/* <th>Total Views</th> */}
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            {/* Table Body */}
            <tbody>
              {advertimentsList?.map((item) => (
                <tr key={item._id}>
                  {/* ID */}
                  <td>{item?.advertisement_number}</td>

                  {/* Client */}
                  <td>
                    {item?.user_id?.first_name} {item?.user_id?.last_name}
                  </td>

                  {/* Start Date */}
                  <td>{moment(item?.start_date).format(DATE_FORMAT)}</td>

                  {/* End Date */}
                  <td>{moment(item?.end_date).format(DATE_FORMAT)}</td>

                  {/* Note */}
                  <td>{item?.title}</td>

                  {/* Views */}
                  {/* <td>{item?.views}</td> */}

                  {/* Status */}
                  <td
                    className={
                      item?.status === "active"
                        ? "font_green"
                        : item?.status === "paused"
                        ? "font_yellow"
                        : "font_red"
                    }
                  >
                    {/* {item?.status?.chartAt(0)?.toUpperCase() +
                      item?.status?.slice(0)} */}

                    {item?.status?.charAt(0).toUpperCase() +
                      item?.status?.slice(1)}
                  </td>

                  {/* Actions */}
                  <td className="text-start txt-nowrap">
                    <IconButton
                      onClick={() => navigate(`detail/${item?._id}`)}
                      className="action-button"
                    >
                      <img
                        src={EyeFilledBlue}
                        className="h_100"
                        alt="View Details"
                      />
                    </IconButton>

                    {new Date(item?.end_date) >
                      new Date().setHours(0, 0, 0, 0) &&
                      (isClient || isPM) && (
                        <IconButton
                          onClick={() => navigate(`${item?._id}/edit`)}
                          className="action-button mx-1"
                        >
                          <img
                            src={EditGreen}
                            className="h_100"
                            alt="View Details"
                          />
                        </IconButton>
                      )}

                    {new Date(item?.end_date) >
                      new Date().setHours(0, 0, 0, 0) &&
                      (isClient || isPM) && (
                        <IconButton
                          className="action-button"
                          onClick={() => onDelete(item)}
                        >
                          <img
                            src={DeleteRed}
                            className="h_100"
                            alt="View Details"
                          />
                        </IconButton>
                      )}

                    {item?.status === "active" && (isClient || isPM) ? (
                      <IconButton
                        className="action-button mx-1"
                        onClick={() => onPause(item)}
                      >
                        <img
                          src={PlayFilledBlue}
                          alt="Play"
                          className="h_100"
                        />
                      </IconButton>
                    ) : (
                      <IconButton
                        className="action-button mx-1"
                        onClick={() => onPlay(item)}
                      >
                        <img
                          src={PauseFilledGray}
                          alt="Pause"
                          className="h_100"
                        />
                      </IconButton>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}

        {advertimentsList?.length === 0 && (
          <div className=" text-center">
            <h3 className=" font_m font_yellow fw_6">No Data</h3>
          </div>
        )}
      </div>

      {/* PAGINATION */}
      {totalCount !== null && advertimentsList?.length > 0 && (
        <Pagination
          itemsPerPage="10"
          totalItems={totalCount}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      )}

      {/* Modals */}
      {/* Confirmation Modal */}
      <ConfirmationModal
        showModal={showConfirmationModal}
        description={modalDesc}
        title={title}
        onClose={() => setShowConfirmationModal(false)}
        onConfirm={
          type === "delete"
            ? confirmDelete
            : type === "pause"
            ? confirmPause
            : confirmPlay
        }
      />
    </>
  );
};
/* Component Ends */
export default AdvertismentsTable;
