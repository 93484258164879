/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import { useDispatch } from "react-redux";
import { Navigate } from "react-router-dom";
import GLOBAL from "../../setup/constants/global";
/* Import configuration ends */

/* Import redux slices component starts */
import { fetchTenant } from "../../setup/store/slices/authSlice";
import { fetchTenantUnitsNew } from "../../setup/store/slices/tenantSlice";

import { fetchTenantAdvertisement } from "../../setup/store/slices/advertisementSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import UnitSliderTenantHome from "../../components/module/Unit/UnitSliderTenantHome/UnitSliderTenantHome";
/* Import local pages and component ends */

import "./style.scss";

/* Component starts */
const HomeTenant = (props) => {
  /* Props destructuring starts */
  const {} = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [userLoaded, setUserLoaded] = useState(false);
  const [userData, setUserData] = useState(null);
  const [unitsData, setUnitsData] = useState([]);

  const [tenantAds, setTenantAds] = useState([]);

  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const dispatch = useDispatch();

  // Redux Selector
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  const getUser = async () => {
    // Creating or collecting payload data to be sent
    const payload = {};
    console.log("Payload", payload);

    // Backend response. Try, Catch
    try {
      const result = await dispatch(fetchTenant());
      setUserLoaded(true);
      console.log("Response", result);

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          setUserData(null);
          break;

        case GLOBAL.FULFILLED:
          setUserData(result.payload);
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };

  const fetchTenantUnits = async () => {
    // Creating or collecting payload data to be sent
    const payload = {};
    console.log("Payload", payload);

    // Backend response. Try, Catch
    try {
      const result = await dispatch(fetchTenantUnitsNew(null));
      console.log("Response", result);

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          setUnitsData([]);
          break;

        case GLOBAL.FULFILLED:
          setUnitsData(result.payload);
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };

  const fetchTenantAdvertisements = async () => {
    // Creating or collecting payload data to be sent
    const payload = {};
    console.log("Payload", payload);

    // Backend response. Try, Catch
    try {
      const result = await dispatch(fetchTenantAdvertisement(null));
      console.log("Response", result);

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          setTenantAds([]);
          break;

        case GLOBAL.FULFILLED:
          setTenantAds(result.payload);
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };

  /* Component function definition ends */

  /* Component useEffect starts */
  // Fteching tenant and tenant units on initial load
  useEffect(() => {
    getUser();
    fetchTenantUnits();
    fetchTenantAdvertisements();
  }, []);
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  if (userLoaded && !userData)
    return (
      <>
        {console.log(userData, "User Data")}
        <Navigate to="/" replace />
      </>
    );

  return (
    <div className="tenant-home-page-wrapper container_lg">
      <UnitSliderTenantHome
        fetchTenantUnitsNew={fetchTenantUnits}
        units={unitsData}
        advertisements={tenantAds}
      />
    </div>
  );
};
/* Component ends */

export default HomeTenant;
