/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import GLOBAL from "../../setup/constants/global";
import { useDispatch } from "react-redux";
/* Import configuration ends */

/* Import redux slices component starts */
import { fetchNotifications } from "../../setup/store/slices/notificationSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import NotificationList from "../../components/module/Notificationx/NotificationList/NotificationList";
/* Import local pages and component ends */

/* Component starts */
const NotificationPage = (props) => {
  /* Props destructuring starts */
  const {} = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [notifications, setNotifications] = useState();
  const [notificationsCount, setNotificationsCount] = useState();
  const [showDelete, setShowDelete] = useState(false);
  const [notifToDelete, setNotifToDelete] = useState();

  const [currentPage, setCurrentPage] = useState(1);

  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const dispatch = useDispatch();
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const USER_DATA = localStorage.getItem("user_data")
    ? JSON.parse(localStorage.getItem("user_data"))
    : null;
  const isTenant = localStorage.getItem("user_role") === "Tenant";
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  const filterNotifications = (n) => {
    if (n && n?.length > 0) {
      return n?.filter(
        (i, indx) =>
          i.type === "internal_rating" ||
          i.type === "internal_maintenance" ||
          i.type === "maintenance" ||
          i.type === "tenant_rating" ||
          i.type === "security_complain" ||
          i.type === "tenant_maintenance" ||
          i.type === "security_incident" ||
          i.type === "lost_found" ||
          i.type === "property" ||
          i.type === "unit" ||
          i.type === "community" ||
          i.type === "internal_quotation" ||
          i.type === "move_out" ||
          i.type === "tenant_onboarding" ||
          i.type === "contract_renewal" ||
          i.type === "routine_request" ||
          i.type === "move_in" ||
          i.type === "quotation" ||
          i.type === "invoice" ||
          i.type === "tenant_invoice"
      );
    }
    return [];
  };

  const fetchNotifs = async () => {
    let payload = {
      user_id: USER_DATA ? USER_DATA?._id : "",
      page: currentPage,
    };

    try {
      const result = await dispatch(fetchNotifications(payload));

      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          setNotifications();
          break;

        case GLOBAL.FULFILLED:
          setNotifications(filterNotifications(result.payload.notifications));
          setNotificationsCount(result.payload.totalCount);
          break;

        default:
      }
    } catch (e) {
      console.error("error", e);
    }
  };

  /* Component function definition ends */

  /* Component useEffect starts */
  useEffect(() => {
    fetchNotifs();
  }, [currentPage]);
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="">
      <NotificationList
        fetchNotifs={fetchNotifs}
        notifications={notifications}
        isTenant={isTenant}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        totalCount={notificationsCount}
      />
    </div>
  );
};
/* Component ends */

export default NotificationPage;
