/**
 *
 */

import React, { useEffect, useState } from "react";

/* Import configuration starts */
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { sliderResponsive } from "./helper";
/* Import configuration ends */

/* Import redux slices component starts */
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import MUI component starts */
/* Import MUI component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
/* Import local pages and component ends */

import "./style.scss";
import { useRef } from "react";

/* Component starts */
const CustomSlider = (props) => {
  /* Props destructuring starts */
  const {
    children,
    type = "home",
    refValue = null,
    autoPlay = false,
    autoPlaySpeed = 0,
    infinite = false,
    showDots = true,
    arrows = true,
  } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */

  /* Component function definition ends */

  /* Component useEffect starts */
  // const sliderRef = useRef(null);
  const [slide, setSlide] = useState(0);

  //   const changeSlide = (index) => {
  //     sliderRef.current.goToSlide(index);
  //       console.log(sliderRef.current,'CRR', index,sliderRef.current.state.currentSlide);
  //   };

  //   useEffect(() => {
  //       changeSlide(slide)
  //   }, [slide])

  //   useEffect(() => {
  //     if (sliderRef.current.state.currentSlide === 0) {
  //         setSlide(0);
  //     }
  //     if (sliderRef.current.state.currentSlide === 1) {
  //         setSlide(1);
  //     }
  //     if (sliderRef.current.state.currentSlide === 2) {
  //         setSlide(2);
  //     }
  // }, [sliderRef])

  // console.log(slide,'{}{}');
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="custom-slider-wrapper-main">
      <Carousel
        responsive={sliderResponsive(type)}
        showDots={showDots}
        keyBoardControl={true}
        containerClass="custom-carousel-container"
        dotListClass="custom-dot-list-style"
        autoPlay={autoPlay}
        autoPlaySpeed={autoPlaySpeed}
        infinite={infinite}
        // centerMode={true}
        focusOnSelect={true}
        arrows={arrows}
        ref={(el) => {
          if (el) {
            var slidesToShow = el.state.slidesToShow;
            var currentSlide = el.state.currentSlide;

            // setCurrent();
            setSlide(slidesToShow + currentSlide);
          }
        }}
      >
        {children}
      </Carousel>
    </div>
  );
};
/* Component ends */

export default CustomSlider;
