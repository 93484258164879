/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import { useDispatch } from "react-redux";
import GLOBAL from "../../../../setup/constants/global";
import { Link, useParams, useNavigate } from "react-router-dom";
import {
  disabledStatusSwitch,
  canResendInvitation,
  canCancelInvitation,
} from "./helper";
/* Import configuration ends */

/* Import redux slices component starts */
import { showErrorAlert } from "../../../../setup/store/slices/globalAlertSlice";
import { updateApprovalStatus } from "../../../../setup/store/slices/teamSlice";
import { updateMemberStatus } from "../../../../setup/store/slices/teamSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
import Table from "react-bootstrap/Table";
/* Import react bootstrap component ends */

/* Import image and SVG starts */
import {
  DeleteGrey,
  DeleteRed,
  EyeFilledBlue,
} from "../../../../setup/constants/images";
/* Import image and SVG ends */

/* Import local pages and component starts */
import SliderButton from "../../../ui/button/SliderButton/SliderButton";
import SuccessModal from "../../../ui/modal/SuccessModal/SuccessModal";
import ErrorModal from "../../../ui/modal/ErrorModal/ErrorModal";
import IconButton from "../../../ui/button/IconButton/IconButton";
import TeamDetailModal from "../../../ui/modal/TeamDetailModal/TeamDetailModal";
import StatusHighLight from "../StatusHighLight/StatusHighLight";
import TextButton from "../../../ui/button/TextButton/TextButton";
/* Import local pages and component ends */

import "./style.scss";

/* Component starts */
const ObserverTable = (props) => {
  /* Props destructuring starts */
  const {
    observers,
    canInvite,
    setSelectedTeamMember,
    showWarning,
    isT1,
    isT2,
    isT3,
    userRoles,
    showAddMemberButton,
    teamMembers,
  } = props;

  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [detail, setDetail] = useState(null);
  const [modalDesc, setModalDesc] = useState("");
  const [showApprove, setShowApprove] = useState(false);
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const dispatch = useDispatch();
  const { propertyId } = useParams();
  const navigate = useNavigate();
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const showSlider = observers?.length > 0 ? true : false;
  const isClient = localStorage.getItem("user_role") === "Client";
  const isOwner = localStorage.getItem("user_role") === "Owner";
  const loggedInUserRole = localStorage.getItem("user_role");
  const user = localStorage.getItem("user_data")
    ? JSON.parse(localStorage.getItem("user_data"))
    : null;
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  // Checking if the current user can approve the request
  const canUserApprove = (approvers) => {
    return approvers.some((item) => item === user.role_id);
  };

  // On view detail
  const onViewDetail = (teamMember) => {
    setDetail(teamMember);
    setShowDetailsModal(true);
    setShowApprove(
      teamMember?.status === GLOBAL.USER_STATUS.PENDING_APPROVAL &&
        canUserApprove(teamMember?.approver_roles)
    );
  };

  // On toggle activating or suspending
  const toggleActiveSuspend = (user, status) => {
    setModalDesc(
      "Cannot deactivate. Need to have atleast one active member. To automatically deactivate, you need to activate a deactivated the member."
    );

    if (isT1) {
      let activeObCount = 0;
      if (status) {
        for (let i = 0; i < observers.length; i++) {
          if (
            observers[i]?.status === "active" ||
            observers[i]?.status === "suspended"
          ) {
            activeObCount++;
          }
        }
        if (activeObCount >= 2) {
          dispatch(
            showErrorAlert({
              title: "Error!",
              description:
                "Maximum two active Observer allowed. Please Suspend or Delete any one member to activate.",
            })
          );
          return;
        } else {
          changeStatus(user, GLOBAL.USER_STATUS.ACTIVE);
        }
      } else {
        changeStatus(user, GLOBAL.USER_STATUS.SUSPENDED);
      }
    } else if (isT2) {
      if (status) {
        changeStatus(user, GLOBAL.USER_STATUS.ACTIVE);
      } else {
        changeStatus(user, GLOBAL.USER_STATUS.DEACTIVATED);
      }
    } else if (isT3) {
      if (status) {
        changeStatus(user, GLOBAL.USER_STATUS.ACTIVE);
      } else {
        setShowErrorModal(true);
      }
    }
    return;

    if (status) {
      if (isT1) {
        changeStatus(user, GLOBAL.USER_STATUS.ACTIVE);
      } else if (isT2) {
        changeStatus(user, GLOBAL.USER_STATUS.ACTIVE);
      } else if (isT3) {
        changeStatus(user, GLOBAL.USER_STATUS.ACTIVE);
      }
    } else {
      if (isT1) {
      } else if (isT2) {
        setShowErrorModal(true);
      } else if (isT3) {
        setShowErrorModal(true);
      }
    }

    if (status) {
      changeStatus(user, GLOBAL.USER_STATUS.ACTIVE);
    } else if (isT1 && !status) {
      changeStatus(user, GLOBAL.USER_STATUS.SUSPENDED);
    } else if (isT2 && !status) {
      changeStatus(user, GLOBAL.USER_STATUS.DEACTIVATED);
    }
  };

  // On switching observer activate or deactivate status
  const changeStatus = async (observer, status) => {
    // Collecting or creating payload data to be sent
    const payload = {
      member_id: observer?.application_id,
      status: status,
      property_id: propertyId,
    };

    // Backend response. Try, Catch
    try {
      const result = await dispatch(updateMemberStatus(payload));

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          setModalDesc(result.payload);
          setShowErrorModal(true);
          break;

        case GLOBAL.FULFILLED:
          setModalDesc(result.payload.message);
          setShowSuccessModal(true);
          break;
      }
    } catch (e) {
      // Handling error response
      console.error(e.message);
    }
  };

  // On deleting selected observer
  const onDelete = (observer) => {
    changeStatus(observer, GLOBAL.USER_STATUS.DEACTIVATED);
  };

  // On viewing details of seleted observer
  const onView = (observer) => {
    setDetail(observer);
    setShowDetailsModal(true);
  };

  // On rejecting or approving request
  const requestAction = async (teamMember, action) => {
    // Collecting or creating payload data to be sent
    const payload = {
      approval_id: teamMember?.request_approval_id,
      status: action,
      property_id: propertyId,
    };

    // Backend response. Try, Catch
    try {
      const result = await dispatch(updateApprovalStatus(payload));

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          setModalDesc(result.payload);
          setShowDetailsModal(false);
          setShowErrorModal(true);
          break;

        case GLOBAL.FULFILLED:
          setModalDesc(result.payload.message);
          setShowDetailsModal(false);
          setShowSuccessModal(true);
          break;
      }
    } catch (e) {
      // Handling error response
      console.error(e.message);
    }
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="observer-table-module-wrapper">
      <div className="main-heading">
        <h3 className="fw_6 font_xl">Observer</h3>
        {(isClient || isOwner) &&
          showAddMemberButton("observer", observers) && (
            <p
              onClick={() => {
                canInvite(GLOBAL.USER_ROLE.OB, "observer/add");
              }}
              // to="observer/add"
              className="fw_6 font_m font_blue"
            >
              + Add Observer
            </p>
          )}
      </div>

      <div className="wrapper">
        {observers?.map((item, index) => (
          <div className="company_wrapper_outer">
            <div className="custom-table-wrapper">
              <Table className="custom-table font_s" borderless responsive>
                {/* Table Heading */}
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Name</th>
                    <th></th>
                    <th>Status</th>
                    <th className="">Action</th>
                  </tr>
                </thead>
                {/* Table Body */}
                <tbody>
                  <tr key={index}>
                    {/* ID */}
                    <td>
                      <p className="col-different1">#{index + 1}</p>
                    </td>

                    {/* Name */}
                    <td>
                      <p className="col-different2">
                        {item?.first_name + " " + item?.last_name}
                      </p>
                    </td>

                    {/* Status */}
                    <td>
                      {(showSlider || item?.status === "expired") && (
                        <div className="col-different3">
                          <SliderButton
                            active={item?.status === GLOBAL.USER_STATUS.ACTIVE}
                            disabled={disabledStatusSwitch(item?.status)}
                            onChange={(currentState) =>
                              (isClient || isOwner) &&
                              toggleActiveSuspend(item, currentState)
                            }
                          />
                        </div>
                      )}
                    </td>

                    {/* Invitaion Status */}
                    <td className="fw_6">
                      <p className="col-different4">
                        <StatusHighLight status={item?.status} />
                      </p>
                    </td>

                    {/* Actions */}
                    <td className="">
                      <IconButton
                        onClick={() => onView(item)}
                        className="action-button"
                      >
                        <img
                          src={EyeFilledBlue}
                          className="h_100"
                          alt="View Details"
                        />
                      </IconButton>
                      {/* //GLOBAL.USER_STATUS.SUSPENDED */}
                      {item?.status === "suspended" && (
                        <IconButton
                          onClick={() => onDelete(item)}
                          className="action-button"
                        >
                          <img src={DeleteRed} className="h_100" alt="Delete" />
                        </IconButton>
                      )}

                      {canResendInvitation(
                        item.status,
                        item,
                        loggedInUserRole
                      ) && (
                        <TextButton
                          onClick={() => {
                            setSelectedTeamMember(item);
                            showWarning("resend");
                          }}
                          className="font_m v_middle fw_6 action-txt-btn"
                        >
                          Resend
                        </TextButton>
                      )}
                      {canCancelInvitation(
                        item.status,
                        item,
                        loggedInUserRole
                      ) && (
                        <TextButton
                          onClick={() => {
                            setSelectedTeamMember(item);
                            showWarning("cancel");
                          }}
                          className="font_m v_middle fw_6 font_red action-txt-btn"
                        >
                          Cancel
                        </TextButton>
                      )}
                    </td>
                  </tr>
                </tbody>
              </Table>

              {/* If No PMC added yet */}
              {observers?.length === undefined && (
                <h3 className="not-added-yet text-center font_m font_yellow fw_6">
                  No Observer added yet
                </h3>
              )}
            </div>
          </div>
        ))}

        {/* If No PMC added yet */}
        {(!observers || observers?.length === 0) && (
          <h3 className="not-added-yet text-center font_m font_yellow fw_6">
            No Observer added yet
          </h3>
        )}
      </div>

      {/* Modals */}
      {/* Success Modal */}
      <SuccessModal
        showModal={showSuccessModal}
        title="Success"
        description={modalDesc}
        onClose={() => {
          setShowSuccessModal(false);
          setModalDesc("");
        }}
      />

      {/* Error Modal */}
      <ErrorModal
        showModal={showErrorModal}
        title="Error"
        description={modalDesc}
        onClose={() => {
          setShowErrorModal(false);
          setModalDesc("");
        }}
      />

      {/* Team Detail Modal */}
      <TeamDetailModal
        showModal={showDetailsModal}
        detail={detail}
        showApprovalReq={showApprove}
        requestAction={requestAction}
        user={user}
        onClose={() => setShowDetailsModal(false)}
      />
    </div>
  );
};
/* Component ends */

export default ObserverTable;
