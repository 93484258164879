/**
 *
 */

import React, { useState, useRef, useEffect } from "react";

/* Import configuration starts */
import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import GLOBAL from "../../../../setup/constants/global";
import { ownerValidation, ownerInitFormValue, formReset } from "./helper";
import { useSearchParams, useParams } from "react-router-dom";
/* Import configuration ends */

/* Import redux slices component starts */
import { inviteMember } from "../../../../setup/store/slices/teamSlice";
import { getUser, fetchUser } from "../../../../setup/store/slices/authSlice";
import {
  getUserRoles,
  fetchUserRoles,
} from "../../../../setup/store/slices/masterSlice";
import {
  getCountries,
  fetchCountries,
} from "../../../../setup/store/slices/countrySlice";
import { setLoading } from "../../../../setup/store/slices/unpersistedSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import MUI component starts */
/* Import MUI component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import ErrorModal from "../../../ui/modal/ErrorModal/ErrorModal";
import CustomSelectInput from "../../../ui/input/CustomSelectInput/CustomSelectInput";
import SearchForAccount from "../../../ui/other/SearchForAccount/SearchForAccount";
import CustomButton from "../../../ui/button/CustomButton/CustomButton";
import TextButton from "../../../ui/button/TextButton/TextButton";
import CustomRadioInput from "../../../ui/input/CustomRadioInput/CustomRadioInput";
import CustomFileInput from "../../../ui/input/CustomFileInput/CustomFileInput";
import CustomTextInput from "../../../ui/input/CustomTextInput/CustomTextInput";
import CustomPhoneInput from "../../../ui/input/CustomPhoneInput/CustomPhoneInput";
/* Import local pages and component ends */

import "./style.scss";

/* Component starts */
const AddRoleUnderOwner = (props) => {
  /* Props destructuring starts */
  const {
    onCancel,
    onSuccess,
    underCommunity,
    communityId,
    propertyId,
    ownerId,
    ownerSubRole,
    role,
  } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [existingOwner, setExistingOwner] = useState(false);
  const [message, setMessage] = useState("");
  const [showErrModal, setShowErrModal] = useState(false);
  const [ownerSubMemberRole, setOwnerSubMemberRole] = useState(null);
  const [selectedCountry, setSelectedCountry] = useState();
  const [initialFormValues, setInitialFormValues] =
    useState(ownerInitFormValue);
  const [memberSelected, setMemberSelected] = useState(false);

  // useRefs
  const customPhoneNum = useRef({ code: "", num: "" });
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();

  // Redux selector
  const countries = useSelector(getCountries);
  const user = useSelector(getUser);
  const userRoles = useSelector(getUserRoles);
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const property_id = searchParams.get("property_id") || propertyId;
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  // Form validations
  const formValidation = (values) => {
    return ownerValidation(values, customPhoneNum);
  };

  // On inviting searched owner
  const inviteSearched = (searchResult) => {
    setInitialFormValues({
      first_name: searchResult.first_name,
      last_name: searchResult.last_name,
      mobile: searchResult.countrycode + searchResult.mobile,
      email: searchResult.email,
    });
    customPhoneNum.current = {
      code: searchResult.countrycode,
      num: searchResult.mobile,
    };
    setSelectedCountry(
      countries.filter((country) => country.name === searchResult.country)[0]
    );
    setExistingOwner(false);
    setMemberSelected(true);
  };

  // Handling on form submit
  const handleSubmit = async (values) => {
    // Creating or collecting payload data to be sent
    const payload = {
      role_id: ownerSubMemberRole._id,
      parent_user_id: ownerId,
      property_id: property_id,
      first_name: values.first_name.trim(),
      last_name: values.last_name.trim(),
      email: values.email.toLowerCase().trim(),
      countrycode: customPhoneNum.current.code,
      mobile: customPhoneNum.current.num,
      nationality: selectedCountry.name,
    };
    console.log("Payload", payload);

    // Backend Response. Try, Catch
    try {
      const result = await dispatch(inviteMember(payload));
      console.log("Response", result);
      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          setMessage(result.payload);
          setShowErrModal(true);
          break;
        case GLOBAL.FULFILLED:
          onSuccess();
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  // Fetching countries, current user, user roles on initial load
  useEffect(() => {
    (async () => {
      try {
        await dispatch(setLoading(true));
        await dispatch(fetchCountries());
        await dispatch(fetchUser());
        await dispatch(fetchUserRoles());
        await dispatch(setLoading(false));
      } catch (e) {}
    })();
  }, []);

  // Extracting the owner user role id after all user roles loaded
  useEffect(() => {
    let userRole = userRoles.filter((d) => d.name == role)[0];
    setOwnerSubMemberRole(userRole);
  }, [userRoles]);

  // Setting current user country on loading of countries and current user
  useEffect(() => {
    if (user && countries.length > 0) {
      const countrySel = countries.filter((country) => {
        return country.name === user.country;
      });
      setSelectedCountry(countrySel[0]);
    }
  }, [user, countries]);
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="add-role-under-owner-wrapper">
      <h2 className="heading text-center font_xxl mb-5">Add {role}</h2>

      {ownerSubMemberRole !== null && (
        <>
          <Formik
            initialValues={initialFormValues}
            validate={formValidation}
            onSubmit={handleSubmit}
            enableReinitialize={true}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              isValid,
            }) => (
              <form onSubmit={handleSubmit}>
                <div className="form-wrapper bg_grey">
                  {/* Radio select if there is existing owner or not */}
                  <div className="form-group form-group-full w_100">
                    <p className="font_m font_grey w_100">
                      Adding existing {role?.toLowerCase()} ?
                    </p>
                    <CustomRadioInput
                      label="Yes"
                      onSelecting={() => {
                        setExistingOwner(true);
                      }}
                      isSelected={existingOwner || memberSelected}
                    />
                    <CustomRadioInput
                      label="No"
                      onSelecting={() =>
                        formReset(
                          setExistingOwner,
                          setInitialFormValues,
                          setMemberSelected,
                          customPhoneNum
                        )
                      }
                      isSelected={!existingOwner && !memberSelected}
                    />
                  </div>

                  {/* Creating new owner */}
                  {!existingOwner && (
                    <>
                      {/* Input For First Name */}
                      <div className="form-group">
                        <CustomTextInput
                          name="first_name"
                          label="First Name"
                          value={values.first_name}
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          touched={touched.first_name}
                          errors={errors.first_name}
                          readOnly={memberSelected}
                        />
                      </div>

                      {/* Input For Last Name */}
                      <div className="form-group">
                        <CustomTextInput
                          name="last_name"
                          label="Last Name"
                          value={values.last_name}
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          touched={touched.last_name}
                          errors={errors.last_name}
                          readOnly={memberSelected}
                        />
                      </div>

                      {/* Input For Email */}
                      <div className="form-group">
                        <CustomTextInput
                          name="email"
                          label="Email"
                          value={values.email}
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          touched={touched.email}
                          errors={errors.email}
                          readOnly={memberSelected}
                        />
                      </div>

                      {/* Input For Phone */}
                      <div className="form-group">
                        <CustomPhoneInput
                          name="mobile"
                          label={"Phone Number"}
                          customPhoneNum={customPhoneNum}
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          value={values.mobile}
                          countryCodeEditable={true}
                          errors={errors.mobile}
                          touched={touched.mobile}
                          readOnly={memberSelected}
                        />
                      </div>

                      {/* Input For Nationality */}
                      <div className="form-group">
                        <CustomSelectInput
                          name="country"
                          label="Country"
                          handleBlur={handleBlur}
                          onChange={handleChange}
                          valueExtractor={(item) => item?.name}
                          setValue={setSelectedCountry}
                          options={countries}
                          value={selectedCountry}
                          formikState={false}
                          emptyOption={false}
                          disabled={true}
                        />
                      </div>
                    </>
                  )}
                </div>
                <div className="button-wrapper text-center">
                  {!existingOwner && (
                    <>
                      <CustomButton type="submit" size="l">
                        Next
                      </CustomButton>
                      <TextButton
                        type="button"
                        onClick={onCancel}
                        className="fw_6 font-m"
                      >
                        Cancel
                      </TextButton>
                    </>
                  )}
                </div>
              </form>
            )}
          </Formik>

          {existingOwner && (
            <>
              <SearchForAccount
                roleType={ownerSubMemberRole}
                title="Search Owner"
                onInvite={inviteSearched}
              />
            </>
          )}
        </>
      )}

      <ErrorModal
        showModal={showErrModal}
        description={message}
        title="Error"
        onClose={() => {
          setShowErrModal(false);
        }}
      />
    </div>
  );
};
/* Component ends */

export default AddRoleUnderOwner;
