/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import moment from "moment";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
/* Import configuration ends */

/* Import redux slices component starts */
import { fetchAccessPassBasedOnId } from "../../../../setup/store/slices/visitorPass";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
import { Table } from "react-bootstrap";
/* Import react bootstrap component ends */

/* Import image and SVG starts */
import { EyeFilledBlue } from "../../../../setup/constants/images";
/* Import image and SVG ends */

/* Import local pages and component starts */

import GLOBAL from "../../../../setup/constants/global";
import IconButton from "../../../ui/button/IconButton/IconButton";

/* Import local pages and component ends */

// Styles
import "./style.scss";
import Pagination from "../../../ui/other/Pagination/Pagination";

/* Component starts */
const VisitorAccessPassTable = (props) => {
  /* Props destructuring starts */
  const { guestList, totalCount, currentPage, setCurrentPage } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [highlightedItems, setHighlightedItems] = useState([]);
  const [cookies, setCookie] = useCookies(["openedItems"]);
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const navigate = useNavigate();
  const dispatch = useDispatch();
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const USER_DATA = localStorage.getItem("user_data")
    ? JSON.parse(localStorage.getItem("user_data"))
    : null;
  const DATE_FORMAT = USER_DATA ? USER_DATA?.date_format : "";

  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */

  // To View The Item Report
  const handleViewReport = async (id) => {
    // Add the clicked item to cookies
    const openedItems = cookies.openedItems || [];
    if (!openedItems?.includes(id)) {
      openedItems.push(id);
      setCookie("openedItems", openedItems);
    }

    const itemData = await dispatch(fetchAccessPassBasedOnId(id));
    navigate(`${id}`, {
      state: itemData.payload,
    });
  };

  /* Component function definition ends */

  /* Component useEffect starts */

  useEffect(() => {
    // Update highlighted guestList based on cookies
    const openedItems = cookies.openedItems || [];
    const newItems = guestList?.filter(
      (item) => !openedItems?.includes(item._id)
    );

    setHighlightedItems(newItems);
  }, [guestList, cookies.openedItems]);

  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */

  return (
    <>
      <div className="custom-table-wrapper visitor-table">
        {guestList?.length > 0 && (
          <Table className="custom-table font_s" borderless responsive>
            {/* Table Heading */}
            <thead>
              <tr>
                <th>ID</th>
                <th>Unit </th>
                <th>Name</th>
                {/* <th>Type</th> */}
                <th>Reason For Access</th>
                <th>Start Date</th>
                <th>End Date</th>
                <th className="text-start">Action</th>
              </tr>
            </thead>
            {/* Table Body */}
            <tbody>
              {guestList?.map((item, index) => (
                <>
                  {highlightedItems?.includes(item) ? (
                    <>
                      <span className="font_xxl new-label"></span>
                      <tr key={item?._id}>
                        {/* ID */}
                        <td>{item?.visitorpassId}</td>

                        {/* Unit */}
                        <td>{item?.unit !== undefined ? item?.unit : "-"}</td>

                        {/* Name */}
                        <td>
                          {item?.guest_name !== undefined
                            ? item?.guest_name
                            : "-"}
                        </td>

                        {/* Reason For Access */}
                        <td>
                          {item?.guest_type !== undefined
                            ? item?.guest_type
                            : "-"}
                        </td>

                        {/* Type */}
                        {/* <td>
                          {item?.reason !== undefined ? item?.reason : "-"}
                        </td> */}

                        {/* Start Date */}
                        <td>{moment(item?.start_date).format(DATE_FORMAT)}</td>
                        {/* End Date */}
                        <td>
                          {moment(item?.end_date).format(DATE_FORMAT) === ""
                            ? "-"
                            : moment(item?.end_date).format(DATE_FORMAT)}
                        </td>

                        {/* Actions */}
                        <td className="text-start txt-nowrap">
                          <IconButton
                            onClick={() => handleViewReport(item?._id)}
                            className="action-button"
                          >
                            <img
                              src={EyeFilledBlue}
                              className="h_100"
                              alt="View Details"
                            />
                          </IconButton>
                        </td>
                      </tr>
                    </>
                  ) : (
                    <>
                      <tr key={item?._id}>
                        {/* ID */}
                        <td>{item?.visitorpassId}</td>

                        {/* Unit */}
                        <td>{item?.unit !== undefined ? item?.unit : "-"}</td>

                        {/* Name */}
                        <td>
                          {item?.guest_name !== undefined
                            ? item?.guest_name
                            : "-"}
                        </td>

                        {/* Type */}
                        <td>
                          {item?.guest_type !== undefined
                            ? item?.guest_type
                            : "-"}
                        </td>

                        {/* Reason For Access */}
                        {/* <td>
                          {item?.reason !== undefined ? item?.reason : "-"}
                        </td> */}

                        {/* Start Date */}
                        <td>{moment(item?.start_date).format(DATE_FORMAT)}</td>
                        {/* End Date */}
                        <td>
                          {moment(item?.end_date).format(DATE_FORMAT) === ""
                            ? "-"
                            : moment(item?.end_date).format(DATE_FORMAT)}
                        </td>

                        {/* Actions */}
                        <td className="text-start txt-nowrap">
                          <IconButton
                            onClick={() => handleViewReport(item?._id)}
                            className="action-button"
                          >
                            <img
                              src={EyeFilledBlue}
                              className="h_100"
                              alt="View Details"
                            />
                          </IconButton>
                        </td>
                      </tr>
                    </>
                  )}
                </>
              ))}
            </tbody>
          </Table>
        )}

        {guestList?.length === 0 && (
          <div className=" text-center">
            <h3 className=" font_m font_yellow fw_6">No Data</h3>
          </div>
        )}
      </div>

      {/* PAGINATION */}
      {guestList?.length > 0 && (
        <Pagination
          itemsPerPage="10"
          totalItems={totalCount}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      )}
    </>
  );
};
/* Component Ends */
export default VisitorAccessPassTable;
