/**
 *
 */

import React, { useState, useEffect, useRef } from "react";

/* Import configuration starts */
import GLOBAL from "../../../../setup/constants/global";
import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { initialValues, loadInitialValue, validations } from "./helper";
import { useLocation, useParams } from "react-router-dom";
/* Import configuration ends */

/* Import redux slices component starts */
import { setLoading } from "../../../../setup/store/slices/unpersistedSlice";
import {
  fetchPropertyDetailByID,
  getPropertyDetail,
} from "../../../../setup/store/slices/propertySlice";

import {
  showErrorAlert,
  showSuccessAlert,
} from "../../../../setup/store/slices/globalAlertSlice";
import {
  fetchAdvertisementById,
  getAdvertisementById,
  updateAdvertisementByID,
} from "../../../../setup/store/slices/advertisementSlice";

/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */

import CustomTextInput from "../../../ui/input/CustomTextInput/CustomTextInput";
import CustomDateInput from "../../../ui/input/CustomDateInput/CustomDateInput";
import CustomButton from "../../../ui/button/CustomButton/CustomButton";
import TextButton from "../../../ui/button/TextButton/TextButton";
import CustomRadioInput from "../../../ui/input/CustomRadioInput/CustomRadioInput";
import CustomFileInput from "../../../ui/input/CustomFileInput/CustomFileInput";
import CustomPhoneInput from "../../../ui/input/CustomPhoneInput/CustomPhoneInput";
import NewHTMLTextEditor from "../../../ui/other/NewHTMLTextEditor/NewHTMLTextEditor";
import CustomCurrencyInput from "../../../ui/input/CustomCurrencyInput/CustomCurrencyInput";
/* Import local pages and component ends */

// Styles
import "./style.scss";

/* Component starts */
const EditTask = (props) => {
  /* Props destructuring starts */
  const { onSuccess, onCancel } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [initialFormValues, setInitialFormValues] = useState(initialValues);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [type, setType] = useState("internal");
  const [descVal, setDescVal] = useState({ value: null });

  const [isEmptyContent, setIsEmptyContent] = useState(false);
  const [isEditorFocused, setIsEditorFocused] = useState(false); // State to track editor focus

  const [image, setImage] = useState(null);

  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const dispatch = useDispatch();
  const formRef = useRef();
  const { propertyId, advertisementId } = useParams();
  const { pathname } = useLocation();
  const param = useParams();
  const customPhoneNum = useRef({ code: "", num: "" });

  // const navigate = useNavigate();
  const advertisementDetail = useSelector(getAdvertisementById);
  const propertyDetail = useSelector(getPropertyDetail);
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */

  // Form validations
  const formValidation = (values) => {
    return validations(values);
  };

  const onSubmit = async (values) => {
    const payload = {
      id: advertisementId,
      html_messege: descVal?.value,
      start_date: startDate?.toString(),
      end_date: endDate?.toString(),
      type: type,

      // : advertisementDetail?.banner?.raw_url,
      title: values?.title,
    };

    if (pathname.includes("communities")) {
      payload["community_id"] = param?.communityId;
      payload["added_for"] = "community";
    } else if (pathname.includes("independent")) {
      payload["property_id"] = param?.propertyId;
      payload["added_for"] = "property";
    } else {
      payload["added_for"] = "all";
    }

    if (type === "vendor") {
      payload["vendor_name"] = values.vendor_name;
      payload["email"] = values.email;
      payload["countrycode"] = values?.mobile_number?.split(" ")[0];
      payload["mobile"] = values?.mobile_number?.split(" ")[1];
      payload["amount"] = values.amount;
      // payload["pause"] = true;
    }

    if (values?.image?.type) {
      payload["banner"] = values.image;
    }

    console.log(payload, "[PY-00]", values);

    // Backend response. Try, Catch
    try {
      const result = await dispatch(updateAdvertisementByID(payload));
      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          dispatch(
            showErrorAlert({
              title: "Error!",
              description: result.payload,
            })
          );
          break;
        case GLOBAL.FULFILLED:
          dispatch(
            showSuccessAlert({
              title: "Success!",
              description: result.payload.message,
            })
          );
          onSuccess();
          break;
        default:
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };

  /* Component function definition ends */

  /* Component useEffect starts */

  useEffect(() => {
    dispatch(fetchAdvertisementById(advertisementId));
  }, []);

  useEffect(() => {
    (async () => {
      try {
        await dispatch(setLoading(true));
        await dispatch(fetchPropertyDetailByID(propertyId));
        await dispatch(setLoading(false));
      } catch (e) {}
    })();
  }, []);

  useEffect(() => {
    if (advertisementDetail) {
      loadInitialValue(
        advertisementDetail,
        setInitialFormValues,
        setStartDate,
        setEndDate,
        setDescVal
      );
      setType(advertisementDetail?.type);
      setImage(advertisementDetail?.banner?.url);
    }
  }, [advertisementDetail]);

  // Setting selected Access Type after loading Access Type
  // useEffect(() => {
  //   if (frequency === "Daily") {
  //     if (frequencyList?.length > 0 && taskDetail) {
  //       let defaultFreq = frequencyList?.filter(
  //         (state) => state?.frequency === taskDetail?.frequency
  //       )[0];
  //       setSelectFrequency(defaultFreq || frequencyList);
  //     }
  //   }

  //   if (frequency === "Weekly") {
  //     if (frequencyList?.length > 0 && taskDetail) {
  //       let defaultFreq = frequencyList?.filter(
  //         (state) => state?.frequency === taskDetail?.frequency
  //       )[0];

  //       setSelectFrequency(defaultFreq || frequencyList);
  //     }
  //   }

  //   if (frequency === "Monthly") {
  //     if (frequencyList?.length > 0 && taskDetail) {
  //       let defaultFreq = frequencyList?.filter(
  //         (state) => state?.frequency === taskDetail?.frequency
  //       )[0];

  //       setSelectFrequency(defaultFreq || frequencyList);
  //     }
  //   }

  //   if (frequency === "Quarterly") {
  //     if (frequencyList?.length > 0 && taskDetail) {
  //       let defaultFreq = frequencyList?.filter(
  //         (state) => state?.frequency === taskDetail?.frequency
  //       )[0];

  //       setSelectFrequency(defaultFreq || frequencyList);
  //     }
  //   }

  //   if (frequency === "Semi Annual") {
  //     if (frequencyList?.length > 0 && taskDetail) {
  //       let defaultFreq = frequencyList?.filter(
  //         (state) => state?.frequency === taskDetail?.frequency
  //       )[0];

  //       setSelectFrequency(defaultFreq || frequencyList);
  //     }
  //   }

  //   if (frequency === "Annual") {
  //     if (frequencyList?.length > 0 && taskDetail) {
  //       let defaultFreq = frequencyList?.filter(
  //         (state) => state?.frequency === taskDetail?.frequency
  //       )[0];

  //       setSelectFrequency(defaultFreq || frequencyList);
  //     }
  //   }
  // }, [taskDetail]);

  // useEffect(() => {
  //   if (selectTeam === "Team") {
  //     setMember(taskDetail?.type);
  //     if (getActiveMembersList?.length > 0 && taskDetail) {
  //       let defaultFreq = getActiveMembersList?.filter(
  //         (state) => state?._id === taskDetail?.assigned?._id
  //       )[0];
  //       setTeamMember(defaultFreq || getActiveMembersList);
  //     }
  //   }

  //   if (selectTeam === "Another Party") {
  //     setMember(taskDetail?.type);
  //     if (getActiveManagersList?.length > 0 && taskDetail) {
  //       let defaultFreq = getActiveManagersList?.filter(
  //         (state) => state?._id === taskDetail?.assigned?._id
  //       )[0];
  //       setTeamMember(defaultFreq || getActiveManagersList);
  //     }
  //   }
  // }, [taskDetail]);

  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="edit-advertisement-module-wrapper">
      <h2 className="heading text-center font_xxl mb-5">
        Edit Advertisement Form
      </h2>

      <div className="add-new-form">
        <Formik
          onSubmit={onSubmit}
          initialValues={initialFormValues}
          validate={formValidation}
          validateOnBlur={true}
          enableReinitialize={true}
          innerRef={formRef}
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
            setFieldValue,
            setFieldTouched,
          }) => (
            <form onSubmit={handleSubmit}>
              <div className="form-wrapper">
                {/* Edit Advertisments */}
                <>
                  {/* Input for Title */}
                  <div className="form-group">
                    <CustomTextInput
                      name="title"
                      label="Title"
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      touched={touched.title}
                      errors={errors.title}
                      value={values.title}
                      required={true}
                    />
                  </div>
                  {/* Radio select the Type Of User */}
                  <div className="form-group d-flex align-items-center flex-row gap-5">
                    <CustomRadioInput
                      label="Internal"
                      onSelecting={() => {
                        setType("internal");
                      }}
                      isSelected={type === "internal"}
                    />
                    <CustomRadioInput
                      label="Vendor"
                      onSelecting={() => {
                        setType("vendor");
                      }}
                      isSelected={type === "vendor"}
                    />
                  </div>

                  {/* Input for First Name */}
                  {type === "vendor" && (
                    <>
                      <div className="form-group">
                        <CustomTextInput
                          name="vendor_name"
                          label="Vendor Name"
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          touched={touched.vendor_name}
                          errors={errors.vendor_name}
                          value={values.vendor_name}
                          required={true}
                          readOnly={true}
                        />
                      </div>
                      <div className="form-group">
                        <CustomTextInput
                          name="email"
                          label="Email"
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          touched={touched.email}
                          errors={errors.email}
                          value={values.email}
                          required={true}
                          readOnly={true}
                        />
                      </div>

                      <div className="form-group">
                        <CustomPhoneInput
                          name="mobile_number"
                          label={"Phone Number"}
                          customPhoneNum={customPhoneNum}
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          // onCountryCodeChanged={onCountryCodeChanged}
                          value={values.mobile_number}
                          countryCodeEditable={true}
                          errors={errors.mobile_number}
                          touched={touched.mobile_number}
                          required={true}
                          readOnly={true}
                        />
                      </div>

                      <div className="form-group">
                        <CustomCurrencyInput
                          name="Amount"
                          label={`Amount (${propertyDetail?.currency})`}
                          setFieldValue={setFieldValue}
                          setFieldTouched={setFieldTouched}
                          touched={touched.amount}
                          errors={errors.amount}
                          value={values.amount}
                          currency={propertyDetail?.currency}
                          required={true}
                        />
                      </div>
                    </>
                  )}

                  {image && (
                    <div class="aspect-ratio-box mb-4">
                      <img
                        src={image}
                        alt="Preview"
                        // className="w-10 h-10 object-cover rounded-lg"
                        // className="absolute top-0 left-0 w-full h-full object-cover"
                      />
                    </div>
                  )}

                  {/* Text Editor */}
                  <div className="form-group form-group-full">
                    <NewHTMLTextEditor
                      label="Add"
                      descVal={descVal}
                      setDescVal={setDescVal}
                      isEmptyContent={isEmptyContent}
                      setIsEmptyContent={setIsEmptyContent}
                      isEditorFocused={isEditorFocused}
                      setIsEditorFocused={setIsEditorFocused}
                    />

                    {(isEmptyContent && (
                      <p className="font_red fw_6 font_m">{`Editor content cannot be empty!`}</p>
                    )) ||
                      (isEditorFocused && (
                        <p className="font_red fw_6 font_m">{`Please finish editing before submitting!`}</p>
                      ))}
                  </div>

                  {/* Input for Start Date */}
                  <div className="form-group">
                    <CustomDateInput
                      name="start_date"
                      label="Start Date"
                      setFieldValue={setFieldValue}
                      value={startDate}
                      canSetOffset={true}
                      canSetTimezone={true}
                      offset={propertyDetail?.offset}
                      timeZone={propertyDetail?.time_zone}
                      customOnchange={(date) => {
                        setStartDate(date);
                        // setEndDate(date);
                        var day = new Date(date);
                        var nextDay = new Date(day);
                        nextDay.setDate(day.getDate() + 1);
                        setEndDate(nextDay);
                      }}
                      minDate={true}
                      minDateValue={new Date()}
                    />
                  </div>

                  {/* Input for End Date */}
                  <div className="form-group">
                    <CustomDateInput
                      name="end_date"
                      label="End Date"
                      setFieldValue={setFieldValue}
                      value={endDate}
                      canSetOffset={true}
                      canSetTimezone={true}
                      offset={propertyDetail?.offset}
                      timeZone={propertyDetail?.time_zone}
                      customOnchange={(date) => setEndDate(date)}
                      minDate={true}
                      minDateValue={(() => {
                        var day = new Date(startDate);
                        var nextDay = new Date(day);
                        nextDay.setDate(day.getDate() + 1);
                        return nextDay;
                      })()}
                    />
                  </div>

                  {/* Input for Photo */}
                  <div className="form-group">
                    <CustomFileInput
                      name="image"
                      label="Upload Banner"
                      setFieldValue={setFieldValue}
                      setFieldTouched={setFieldTouched}
                      formikState={true}
                      touched={touched.image}
                      errors={errors.image}
                      value={values.image}
                      required={false}
                      acceptable={true}
                      acceptType=".png, .jpg, .jpeg"
                      setImage={setImage}
                    />
                  </div>

                  <p className="font_yellow fw_4 font_xs">
                    Note :{" "}
                    <span className="font_yellow fw_4 font_xs">
                      The recommended banner aspect ratio is 5.18:1 e.g.: 1036px
                      x 200px or 1554px x 300px or 2072px x 400px
                    </span>
                  </p>
                </>
              </div>

              <div className="button-wrapper text-center">
                <>
                  <CustomButton type="submit" size="l">
                    Update
                  </CustomButton>
                  <TextButton
                    type="button"
                    onClick={onCancel}
                    className="font_m fw_6"
                  >
                    Cancel
                  </TextButton>
                </>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </div>
  );
};
/* Component Ends */
export default EditTask;
