/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
// import { BarChart, Bar, XAxis, YAxis, ResponsiveContainer } from "recharts";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  LabelList,
} from "recharts";

/* Import configuration ends */

/* Import redux slices component starts */
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
import {
  EnvelopeOutLinedBlue,
  PrintIcon,
} from "../../../setup/constants/images";
import { convertToDataset } from "../../../setup/utils/global-helper";
/* Import image and SVG ends */

/* Import local pages and component starts */
/* Import local pages and component ends */

/* Component starts */
const AdvertismentChart = (props) => {
  /* Props destructuring starts */
  const { details } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [visibleBar, setVisibleBar] = useState(null); // Initially null to show both bars
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */

  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  const data = convertToDataset(details?.stats);

  // Handle legend click to toggle between single or both bars
  const handleLegendClick = (e) => {
    setVisibleBar((prev) => (prev === e.dataKey ? null : e.dataKey));
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    // <div className="bar-chart-wrapper">
    //   <div className="chart-details">
    //     <h3 className="font_xl fw_6 font_blue text-center py-2">
    //       Sponsor a Radio Contest
    //     </h3>
    //     <div className="actions">
    //       <p className="font_xxs fw_4 font_grey">
    //         <img src={PrintIcon} alt="Print-Pic" />
    //         Print
    //       </p>
    //       <p className="font_xxs fw_4 font_grey">
    //         <img src={EnvelopeOutLinedBlue} alt="Email-Pic" />
    //         Email
    //       </p>
    //     </div>
    //     <div className="text-center">
    //       <p className="font_m font_grey fw_4">15 April - 21 April</p>
    //     </div>
    //   </div>
    //   <div className="chart-bar-details">
    //     <span className="font_l fw_4 font_grey text-rotate">
    //       Ad Views So Far
    //     </span>

    //     <ResponsiveContainer width="100%" aspect={3}>
    //       <BarChart height={250} data={data} barSize={60}>
    //         <Bar dataKey="value" fill="#37BDB0" />
    //         <XAxis dataKey="name" axisLine={false} tickLine={false} />
    //         <YAxis
    //           axisLine={false}
    //           padding={{ top: 20, bottom: 20 }}
    //           tickLine={false}
    //         />
    //       </BarChart>
    //     </ResponsiveContainer>
    //   </div>
    // </div>
    <>
      {data?.length > 0 ? (
        <div className="bar-chart-wrapper">
          <div className="chart-details">
            <h3 className="font_xl fw_6 font_yellow  py-2">{details?.title}</h3>
          </div>

          <div className="chart-bar-details">
            <ResponsiveContainer width="100%" height={400}>
              <BarChart
                data={data}
                margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
              >
                <defs>
                  {/* Define Gradient for Bars */}
                  <linearGradient id="barGradient" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="0%" stopColor="#88103D" stopOpacity={1} />{" "}
                    {/* Solid at Top */}
                    <stop
                      offset="100%"
                      stopColor="#88103D"
                      stopOpacity={0.25}
                    />{" "}
                    {/* Fades at Bottom */}
                  </linearGradient>
                </defs>

                <defs>
                  {/* Define Gradient for Bars */}
                  <linearGradient
                    id="barGradientClick"
                    x1="0"
                    y1="0"
                    x2="0"
                    y2="1"
                  >
                    <stop offset="0%" stopColor="#1DC5B4" stopOpacity={1} />{" "}
                    {/* Solid at Top */}
                    <stop
                      offset="100%"
                      stopColor="#1DC5B4"
                      stopOpacity={0.25}
                    />{" "}
                    {/* Fades at Bottom */}
                  </linearGradient>
                </defs>

                <XAxis dataKey="name" />
                <YAxis color="#C5C9CA" />
                {/* <Tooltip /> */}
                <Legend
                  onClick={handleLegendClick}
                  formatter={(value) =>
                    value?.charAt(0)?.toUpperCase() + value?.slice(1)
                  }
                  style={{ marginTop: "10px" }}
                />

                <Bar
                  dataKey="impression"
                  fill="url(#barGradient)"
                  barSize={40}
                  fillOpacity={
                    visibleBar === null || visibleBar === "impression" ? 1 : 0
                  }
                >
                  <LabelList
                    dataKey="impression"
                    position="top"
                    fill="#88103D"
                    fontSize={16}
                    fillOpacity={
                      visibleBar === null || visibleBar === "impression" ? 1 : 0
                    }
                  />
                </Bar>

                <Bar
                  dataKey="click"
                  fill="url(#barGradientClick)"
                  barSize={40}
                  fillOpacity={
                    visibleBar === null || visibleBar === "click" ? 1 : 0
                  }
                >
                  <LabelList
                    dataKey="click"
                    position="top"
                    fill="#1DC5B4"
                    fontSize={16}
                    fillOpacity={
                      visibleBar === null || visibleBar === "click" ? 1 : 0
                    }
                  />
                </Bar>
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>
      ) : (
        <div className=" text-center">
          <h3 className=" font_m font_yellow fw_6">No Data</h3>
        </div>
      )}
    </>
  );
};
/* Component ends */

export default AdvertismentChart;
