/**
 *
 */

import React from "react";

/* Import configuration starts */
import { Link } from "react-router-dom";
/* Import configuration ends */

/* Import redux slices component starts */
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import MUI component starts */
/* Import MUI component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
// import Searchbar from "../../../components/ui/other/Searchbar/Searchbar";
import OverviewSlider from "../../../components/module/Overview/OverviewSlider/OverviewSlider";
/* Import local pages and component ends */

/* Component starts */
const Overview = () => {
  /* Props destructuring starts */
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="overview-wrapper">
     

      <div className="slider">
        <OverviewSlider />
      </div>
    </div>
  );
};
/* Component ends */

export default Overview;
