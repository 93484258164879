/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
/* Import configuration ends */

/* Import redux slices component starts */
import {
  fetchClaimedItemList,
  fetchFoundItemList,
  fetchLostItemList,
  getClaimedItemsList,
  getFoundsItemsList,
  getLostItemsList,
} from "../../../setup/store/slices/lostAndFoundSlice";
import { showErrorAlert } from "../../../setup/store/slices/globalAlertSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import GLOBAL from "../../../setup/constants/global";
import Searchbar from "../../../components/ui/other/Searchbar/Searchbar";
import TabButton from "../../../components/ui/other/TabButton/TabButton";
import FoundItemTable from "../../../components/module/LostAndFound/ClientLostAndFoundModule/ClientFound/FoundItemsTable/FoundItemTable";
import LostItemTable from "../../../components/module/LostAndFound/ClientLostAndFoundModule/ClientLost/LostItemsTable/LostTable";
import ClaimedTable from "../../../components/module/LostAndFound/ClientLostAndFoundModule/ClientClaimed/ClaimedTable";
import BreadCrumb from "../../../components/ui/other/BreadCrumb/BreadCrumb";
/* Import local pages and component ends */

// styles sheet
import "./style.scss";

const ClientLostAndFound = (props) => {
  /* Props destructuring starts */
  const {} = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */

  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { propertyId, tab } = useParams();
  const navigate = useNavigate();
  /* Other hooks declaration ends */

  // Redux Selectors
  const foundList = useSelector(getFoundsItemsList);
  const lostList = useSelector(getLostItemsList);
  const claimedList = useSelector(getClaimedItemsList);

  /* Component variable declaration / object destructure starts */
  const buttonList = [
    { key: "found", label: "Found" },
    { key: "lost", label: "Lost" },
    { key: "claimed", label: "Claimed" },
  ];

  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  const onTabSelect = (key) => {
    const pathnameArr = pathname.split("/");
    pathnameArr.pop();
    navigate(pathnameArr.join("/") + "/" + key);
    setCurrentPage(1);
    // setCurrentKey(key);
  };

  const handleInitialResponseData = (result) => {
    switch (result.meta.requestStatus) {
      case GLOBAL.REJECTED:
        dispatch(
          showErrorAlert({
            title: "Error!",
            description:
              typeof result?.payload === "string" ? result?.payload : "",
          })
        );
        return [];
        break;

      case GLOBAL.FULFILLED:
        return result?.payload;
        break;
    }
  };

  const handleFetchFoundItems = async (payload) => {
    const result = await dispatch(fetchFoundItemList(payload));
    return await handleInitialResponseData(result);
  };

  const handleFetchLostItems = async (payload) => {
    const result = await dispatch(fetchLostItemList(payload));
    return await handleInitialResponseData(result);
  };

  const handleFetchClaimedItems = async (payload) => {
    const result = await dispatch(fetchClaimedItemList(payload));
    return await handleInitialResponseData(result);
  };

  const handleFetchData = async (tab, page, limit, search = "") => {
    // Creating or collecting payload data to be sent
    const payload = {
      property_id: propertyId,
      page,
      limit,
      type: tab === "found" ? "Found" : tab === "lost" ? "Lost" : "Claimed",
    };

    if (search !== "") {
      payload["search"] = search;
    }

    if (tab !== "claimed") {
      payload["status"] = "Open";
    }

    // Backend response. Try, Catch
    try {
      let result = {};
      switch (tab) {
        case "found":
          result = await handleFetchFoundItems(payload);
          break;

        case "lost":
          result = await handleFetchLostItems(payload);
          break;

        case "claimed":
          result = await handleFetchClaimedItems(payload);
          break;

        default:
          break;
      }
      // Handling success response
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };

  /* Component function definition ends */

  /* Component useEffect starts */

  useEffect(() => {
    if (searchQuery !== "") {
      handleFetchData(tab, currentPage, 10, searchQuery);
    } else {
      handleFetchData(tab, currentPage, 10, "");
    }
  }, [tab, propertyId, currentPage]);

  useEffect(() => {
    let timeoutid = null;
    if (searchQuery !== "") {
      timeoutid = setTimeout(() => {
        handleFetchData(tab, currentPage, 10, searchQuery);
      }, 1000);
    } else {
      handleFetchData(tab, currentPage, 10, "");
    }

    return () => {
      if (timeoutid) {
        clearTimeout(timeoutid);
      }
    };
  }, [searchQuery]);

  useEffect(() => {
    setCurrentPage(1);
  }, [searchQuery]);

  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  /* Component starts */
  return (
    <>
      <BreadCrumb type="client_lostAndFound" />
      <div className="found-wrapper">
        <div className="found-heading">
          <h2 className="font_xxl fw_6">Lost And Found</h2>
          {tab === "found" ? (
            <Link to="add" className="font_m fw_6 d_block link">
              + Add Found Item
            </Link>
          ) : (
            ""
          )}
        </div>

        <Searchbar query={searchQuery} setQuery={setSearchQuery} />

        {/* TAB BUTTONS */}
        <TabButton
          disabled={searchQuery !== ""}
          buttonList={buttonList}
          currentKey={tab}
          onSelect={onTabSelect}
        />

        {/* Display Table Data Based On The Current Tab */}

        {tab === "found" ? (
          <FoundItemTable
            foundList={foundList?.result}
            totalCount={foundList?.totalCount}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        ) : tab === "lost" ? (
          <LostItemTable
            lostList={lostList?.result}
            totalCount={lostList?.totalCount}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        ) : (
          <ClaimedTable
            claimedList={claimedList?.result}
            totalCount={claimedList?.totalCount}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        )}
      </div>
    </>
  );
};
/* Component End */

export default ClientLostAndFound;
