/**
 * Root reducer for store
 */

import authReducer from "./slices/authSlice";
import countryReducer from "./slices/countrySlice";
import localeReducer from "./slices/localeSlice";
import masterReducer from "./slices/masterSlice";
import unpersistedReducer from "./slices/unpersistedSlice";
import propertyReducer from "./slices/propertySlice";
import selectionReducer from "./slices/selectionSlice";
import communityReducer from "./slices/communitySlice";
import licenseReducer from "./slices/licenseSlice";
import settingReducer from "./slices/settingSlice";
import paymentReducer from "./slices/paymentSlice";
import invoiceReducer from "./slices/invoiceSlice";
import teamReducer from "./slices/teamSlice";
import unitReducer from "./slices/unitSlice";
import filterReducer from "./slices/filterSlice";
import otpReducer from "./slices/otpSlice";
import tenantReducer from "./slices/tenantSlice";
import globalAlertReducer from "./slices/globalAlertSlice";
import securityReducer from "./slices/securitySlice";
import lostAndFoundReducer from "./slices/lostAndFoundSlice";

import visitorLogReducer from "./slices/visitorLogBook";
import visitorAccessPassReducer from "./slices/visitorPass";
import visitorpassReducer from "./slices/visitorpassSlice";
import internalMaintenanceReducer from "./slices/internalMaintenanceSlice";
import violationReducer from "./slices/violationSlice";
import guestPassReducer from "./slices/guestPass";
import maintenanceReducer from "./slices/maintenanceSlice";
import emergencyContactReducer from "./slices/emergencyContactSlice";
import moveInReducer from "./slices/moveInSlice";
import moveOutReducer from "./slices/moveOutSlice";
import facilityReducer from "./slices/facilitySlice";
import contractReducer from "./slices/contractSlice";

import tenantDetailsReducer from "./slices/tenatDetailSlice";
import infoTilesReducer from "./slices/infoTileSlice";

import annoucementReducer from "./slices/annoucementSlice";
import routineReducer from "./slices/routineSlice";

// INVOICES
import propertyInvoiceReducer from "./slices/propertyInvoiceSlice";
import partyInvoiceReducer from "./slices/partyInvoiceSlice";

import advertisementInvoiceReducer from "./slices/advertisementInvoiceSlice";

import tenantInfoReducer from "./slices/tenantInfoSlice";
import getByIdReducer from "./slices/getByIdSlice";

// DASHBOARDS
import occupancyDashboardReducer from "./slices/occupancyDashboardSlice";
import contractDashboardReducer from "./slices/contractDashboardSlice";

import maintenanceInDashboardReducer from "./slices/maintenanceInDashboardSlice";
import internalMaintenanceDashboardReducer from "./slices/internalMaintenanceDashboardSlice";

import securityDashboardReducer from "./slices/securityDashboardSlice";
import ratingDashboardReducer from "./slices/ratingDashboardSlice";

import financialDashboardReducer from "./slices/financialDashboardSlice";
import sparePartsDashboardReducer from "./slices/sparePartsDashboardSlice";
import violationDashboardReducer from "./slices/violationDashboardSlice";
import teamsDashboardReducer from "./slices/teamsDashboardSlice";
import movesDashboardReducer from "./slices/movesDashboardSlice";
import moveOutDashboardReducer from "./slices/moveOutDashboardSlice";

import ratingReducer from "./slices/ratingSlice";
import severityReducer from "./slices/severitySlice";
import chequeReducer from "./slices/chequeSlice";
import notificationReducer from "./slices/notificationSlice";
import templateReducer from "./slices/templateSlice";

import FAQsReducer from "./slices/faqsSlice";
import advertisementReducer from "./slices/advertisementSlice";

import { combineReducers } from "redux";
// import visitorLogBook from "./slices/visitorLogBook";

/**
 * combined reducers
 */
const combinedReducer = combineReducers({
  auth: authReducer,
  country: countryReducer,
  locale: localeReducer,
  master: masterReducer,
  unpersisted: unpersistedReducer,
  property: propertyReducer,
  selection: selectionReducer,
  community: communityReducer,
  license: licenseReducer,
  setting: settingReducer,
  payment: paymentReducer,
  invoice: invoiceReducer,
  team: teamReducer,
  unit: unitReducer,
  filter: filterReducer,
  otp: otpReducer,
  tenant: tenantReducer,
  security: securityReducer,
  globalAlert: globalAlertReducer,
  lostFound: lostAndFoundReducer,
  visitorLog: visitorLogReducer,
  visitorPass: visitorAccessPassReducer,
  visitorpass: visitorpassReducer,
  internalMaintenance: internalMaintenanceReducer,
  violations: violationReducer,
  guestPass: guestPassReducer,
  maintenance: maintenanceReducer,
  emergency: emergencyContactReducer,
  movein: moveInReducer,
  moveout: moveOutReducer,
  facility: facilityReducer,
  contract: contractReducer,
  tenantDetails: tenantDetailsReducer,
  infoTiles: infoTilesReducer,
  annoucement: annoucementReducer,
  routine: routineReducer,

  propertyInvoice: propertyInvoiceReducer,
  advertisementInvoice: advertisementInvoiceReducer,
  partyInvoice: partyInvoiceReducer,
  tenantInfo: tenantInfoReducer,
  getById: getByIdReducer,

  // DASHBOARDS
  occupancy: occupancyDashboardReducer,
  contractDash: contractDashboardReducer,

  maintenanceInDash: maintenanceInDashboardReducer,
  internalMaintenanceDash: internalMaintenanceDashboardReducer,

  securityDash: securityDashboardReducer,
  violationDash: violationDashboardReducer,
  teamDash: teamsDashboardReducer,
  financialDash: financialDashboardReducer,
  sparePartDash: sparePartsDashboardReducer,
  ratingDash: ratingDashboardReducer,

  moveDash: movesDashboardReducer,
  moveOutDash: moveOutDashboardReducer,
  rating: ratingReducer,
  severity: severityReducer,
  cheque: chequeReducer,
  notification: notificationReducer,
  template: templateReducer,

  faqs: FAQsReducer,
  advertisement: advertisementReducer,
});

/**
 * root reducer which resets store if logout is called
 */
const rootReducer = (state, action) => {
  if (action.type === "master/logout") {
    state = {
      setting: state.setting,
      master: state.master,
      locale: state.locale,
    };
  }
  return combinedReducer(state, action);
};

export default rootReducer;
