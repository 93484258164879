/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import moment from "moment";
import GLOBAL from "../../../../../setup/constants/global";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  logTitle,
  canAssignTechnician,
  mtRespondUIRCP1Init,
  canFillURICP1,
  mtRespondUIRCP1Finl,
  canSkipFinalAsessment,
  canStartP2,
  pmCanApproveUIRCP1Init,
  pmCanApproveUIRCP1Finl,
  orderStatusGenerate,
  fmCanChangeOrderStatus,
  fmCanAssignPhase2MT,
  pmCanApproveQuote,
  fmCanEditQuote,
  pmCanRespondKeyReject,
  canFillURICP1_t1,
  canFillURICFinl_t1,
  ownerAddQuote,
  ownerCompletesTask,
  canFmRespond2PMrejectUIRC,
  canAddQuote,
  logActions,
} from "../helper";
/* Import configuration ends */

/* Import redux slices component starts */
import {
  showErrorAlert,
  showSuccessAlert,
} from "../../../../../setup/store/slices/globalAlertSlice";
import {
  MTrespondMoveInRequest,
  mtRespondToFinalCard,
  skipFinalUIRCP1,
  pmRespondInitialCard,
  pmRespondFinalCard,
  changeOrderStatus,
  t1SkipFinl,
  t1markJobDone,
  FMrespondReadinessCard,
  fmRespondFinalCard,
} from "../../../../../setup/store/slices/moveInSlice";
import { pmRespondTenantKeyRejection } from "../../../../../setup/store/slices/tenantSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import CustomButton from "../../../../ui/button/CustomButton/CustomButton";
import AssignTechnicianMoveInModal from "../../AssignTechnicianMoveInModal/AssignTechnicianMoveInModal";
import NoteModal from "../../NoteModal/NoteModal";
import UIRCP1 from "../../UIRCP1/UIRCP1";
import SparePartQuoteModal from "../../SparePartQuoteModal/SparePartQuoteModal";
import UIRCEstimationReviseModal from "../../UIRCEstimationReviseModal/UIRCEstimationReviseModal";
import FileViewerModal from "../../../../ui/modal/FileViewerModal/FileViewerModal";
import CustomRadioInput from "../../../../ui/input/CustomRadioInput/CustomRadioInput";
import OnboardKeyRejectionModal from "../../../Tenant/OnboardKeyRejectionModal/OnboardKeyRejectionModal";
import { EyeFilledBlue } from "../../../../../setup/constants/images";
import IconButton from "../../../../ui/button/IconButton/IconButton";
import { pathNamePopped } from "../../../../../setup/utils/global-helper";
/* Import local pages and component ends */

/* Component starts */
const SimpleLog = (props) => {
  /* Props destructuring starts */
  const {
    property,
    maintenance_id,
    maintenance,
    item,
    status,
    isT1,
    isT3,
    takeAction,
    user,
  } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [showAssignTechnician, setShowAssignTechnician] = useState(false);
  const [isApproved, setIsApproved] = useState(null);
  const [openNote, setOpenNote] = useState(false);
  const [showURICP1Form, setShowURICP1Form] = useState(false);
  const [addQuotation, setAddQuotation] = useState(false);
  const [additionalTask, setAdditionalTask] = useState([]);
  const [isUIRCRviewModalOpened, setIsUIRCRviewModalOpened] = useState(false);
  const [showUIRCRviewModal, setShowUIRCRviewModal] = useState(false);
  const [file, setFile] = useState(null);
  const [showFile, setShowFile] = useState(false);
  const [fmActionOption, setFmActionOption] = useState(1);
  const [fmResubmitComment, setFmResubmitComment] = useState(false);
  const [
    showMaintenanceReviewRejectModal,
    setShowMaintenanceReviewRejectModal,
  ] = useState(false);
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const dispatch = useDispatch();
  const { propertyId } = useParams();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const name = item?.requester
    ? `${item?.requester?.first_name} ${item?.requester?.last_name}`
    : `${item?.tenant?.first_name} ${item?.tenant?.last_name}`;
  const role = item?.requester_role?.name;
  const createdAt = item?.createdAt;
  const action = item?.action;
  const currentStatus = item?.current_status;
  const logType = item?.log_type;
  const comment = item?.comment;
  const initialURIC = maintenance?.initial_URIC;
  const finalUIRC = maintenance?.final_URIC;
  const maintenanceType = maintenance?.type;
  const unitCard = maintenance?.unit_card;
  const quote = maintenance?.quotes;
  const p2URIC = maintenance?.phase2_URIC;
  const applicationID = maintenance?.application_id;

  const USER_DATA = localStorage.getItem("user_data")
    ? JSON.parse(localStorage.getItem("user_data"))
    : null;
  const DATE_FORMAT = USER_DATA ? USER_DATA?.date_format : "";
  const TIME_FORMAT = USER_DATA ? USER_DATA?.time_format : "";
  const DATE_TIME_FORMAT = `${DATE_FORMAT} ${TIME_FORMAT}`;
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  // On performing respond action with note such as Reject and Approve
  const respondAction = (value) => {
    // MT approving URIC Phase 1 Initial
    mtRespondUIRCP1Init(item, user) && mtRespondToAssign_moveIn(value);

    // MT approving URIC Phase 1 Final
    mtRespondUIRCP1Finl(item, user) && mtRespondToFinal_moveIn(value);

    // PM approving URIC Phase 1 Initial
    pmCanApproveUIRCP1Init(item, user) && pmApproveUIRCP1Init(value);

    // PM approving URIC Phase 1 Final
    pmCanApproveUIRCP1Finl(item, user) && pmApproveUIRCP1Finl(value);

    // PM or Owner responding to key rejection
    pmCanRespondKeyReject(item, user) && respondingKeyRejection(value);
  };

  // On triggering maintenance job done for tenant Move In by Owner in T1 Property
  const moveInMaintenanceDone = async () => {
    // Creating or collecting payload data to be sent
    const payload = {
      maintenance_id: maintenance._id,
      phase2_card: p2URIC?._id,
      status: "Approved",
      type: maintenance.type,
      property_id: propertyId,
    };

    // Backend response. Try, Catch
    try {
      const result = await dispatch(t1markJobDone(payload));

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          dispatch(
            showErrorAlert({
              title: "Error!",
              description: result.payload,
            })
          );
          break;

        case GLOBAL.FULFILLED:
          dispatch(
            showSuccessAlert({
              title: "Success!",
              description: result.payload.message,
            })
          );
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };

  // On responding to assign request sent by FM on Tenant Move In process
  const mtRespondToAssign_moveIn = async (note) => {
    setIsApproved(null);
    setOpenNote(false);
    // Creating or collecting payload data to be sent
    const payload = {
      maintenance_id,
      status: isApproved ? "Accepted" : "Rejected",
      readiness_card: initialURIC._id,
      type: maintenanceType,
      property_id: propertyId,
      note,
    };

    // Backend response. Try, Catch
    try {
      const result = await dispatch(MTrespondMoveInRequest(payload));

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          dispatch(
            showErrorAlert({
              title: "Error!",
              description: result.payload,
            })
          );
          break;

        case GLOBAL.FULFILLED:
          dispatch(
            showSuccessAlert({
              title: "Success!",
              description: result.payload.message,
            })
          );
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };

  // On responding to assign request sent by FM on Tenant Move In process
  const mtRespondToFinal_moveIn = async (note) => {
    setIsApproved(null);
    setOpenNote(false);
    // Creating or collecting payload data to be sent
    const payload = {
      maintenance_id,
      status: isApproved ? "Accepted" : "Rejected",
      readiness_card: finalUIRC._id,
      type: maintenanceType,
      note,
      property_id: propertyId,
    };

    // Backend response. Try, Catch
    try {
      const result = await dispatch(mtRespondToFinalCard(payload));

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          dispatch(
            showErrorAlert({
              title: "Error!",
              description: result.payload,
            })
          );
          break;

        case GLOBAL.FULFILLED:
          dispatch(
            showSuccessAlert({
              title: "Success!",
              description: result.payload.message,
            })
          );
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };

  // On skipping UIRC Phase 1 Final
  const skipFinalAssessment = async () => {
    // Creating or collecting payload data to be sent
    const payload = {
      maintenance_id: maintenance._id,
      type: maintenance.type,
      final_readiness_card: finalUIRC?._id,
      property_id: propertyId,
    };

    // Backend response. Try, Catch
    try {
      let result;
      if (isT1) {
        result = await dispatch(t1SkipFinl(payload));
      } else {
        result = await dispatch(skipFinalUIRCP1(payload));
      }

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          dispatch(
            showErrorAlert({
              title: "Error!",
              description: result.payload,
            })
          );
          break;

        case GLOBAL.FULFILLED:
          dispatch(
            showSuccessAlert({
              title: "Success!",
              description: result.payload.message,
            })
          );
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };

  // On approving or rejecting UIRC Phase 1 Initial by PM
  const pmApproveUIRCP1Init = async (note) => {
    setIsApproved(null);
    setOpenNote(false);

    // Creating or collecting payload data to be sent
    const payload = {
      maintenance_id,
      status: isApproved ? "Approved" : "Rejected",
      readiness_card: initialURIC._id,
      type: maintenanceType,
      note,
      property_id: propertyId,
      log_id: item._id,
    };

    // Backend response. Try, Catch
    try {
      const result = await dispatch(pmRespondInitialCard(payload));

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          dispatch(
            showErrorAlert({
              title: "Error!",
              description: result.payload,
            })
          );
          break;

        case GLOBAL.FULFILLED:
          dispatch(
            showSuccessAlert({
              title: "Success!",
              description: result.payload.message,
            })
          );
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };

  // On approving or rejecting UIRC Phase 1 Initial by PM
  const pmApproveUIRCP1Finl = async (note) => {
    setIsApproved(null);
    setOpenNote(false);

    // Creating or collecting payload data to be sent
    const payload = {
      maintenance_id,
      status: isApproved ? "Approved" : "Rejected",
      readiness_card: finalUIRC._id,
      type: maintenanceType,
      note,
      property_id: propertyId,
      log_id: item._id,
    };

    // Backend response. Try, Catch
    try {
      const result = await dispatch(pmRespondFinalCard(payload));

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          dispatch(
            showErrorAlert({
              title: "Error!",
              description: result.payload,
            })
          );
          break;

        case GLOBAL.FULFILLED:
          dispatch(
            showSuccessAlert({
              title: "Success!",
              description: result.payload.message,
            })
          );
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };

  // On changing order status by FM
  const fmChangeOrderStatus = async () => {
    setIsApproved(null);
    setOpenNote(false);

    // Creating or collecting payload data to be sent
    const payload = {
      maintenance_id,
      quotation_id: quote?._id,
      status: orderStatusGenerate(currentStatus).status,
      quotation_type: logType === "additional" ? "additional" : "order",
      type: maintenanceType,
      property_id: propertyId,
    };

    // Backend response. Try, Catch
    try {
      const result = await dispatch(changeOrderStatus(payload));

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          dispatch(
            showErrorAlert({
              title: "Error!",
              description: result.payload,
            })
          );
          break;

        case GLOBAL.FULFILLED:
          dispatch(
            showSuccessAlert({
              title: "Success!",
              description: result.payload.message,
            })
          );
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };

  // On FM resubmit Estimation
  const fmCanResubmitEstimation = async (note) => {
    setFmResubmitComment(false);
    // Creating or collecting payload data to be sent
    const payload = {
      maintenance_id,
      type: maintenance?.type,
      note,
      log_id: item?._id,
      status: "Approved",
    };
    const items = item?.data?.items?.map((i) => ({
      item_id: i._id,
      estimate: i.estimate,
    }));
    payload["items"] = JSON.stringify(items);
    if (logType === "URIC Initial") {
      payload["readiness_card"] = initialURIC?._id;
    } else {
      payload["readiness_card"] = finalUIRC?._id;
    }

    // Backend response. Try, Catch
    try {
      let result = {};
      if (logType === "URIC Initial") {
        result = await dispatch(FMrespondReadinessCard(payload));
      } else {
        result = await dispatch(fmRespondFinalCard(payload));
      }

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          dispatch(
            showErrorAlert({
              title: "Error!",
              description: result?.payload || "",
            })
          );
          break;

        case GLOBAL.FULFILLED:
          dispatch(
            showSuccessAlert({
              title: "Success!",
              description: result?.payload?.message || "",
            })
          );
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };

  // PM or Owner responding to key rejection
  const respondingKeyRejection = async (v) => {
    // Creating or collecting payload data to be sent
    const payload = {
      maintenance_id,
      rejected_reason: v?.reason,
      application_id: applicationID,
      status: "Rejected",
      property_id: propertyId,
      type: maintenanceType,
      document: [],
    };
    v?.document?.forEach((item, index) => {
      payload.document.push({ file: item, name: item?.name });
    });
    console.log("Payload -> ", payload);

    // Backend response. Try, Catch
    try {
      const result = await dispatch(pmRespondTenantKeyRejection(payload));
      console.log("Response -> ", result);
      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          dispatch(
            showErrorAlert({
              title: "Error!",
              description:
                typeof result?.payload === "string" ? result?.payload : "",
            })
          );
          break;

        case GLOBAL.FULFILLED:
          dispatch(
            showSuccessAlert({
              title: "Success!",
              description:
                typeof result?.payload?.message === "string"
                  ? result?.payload?.message
                  : "",
            })
          );
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };

  // To View The Tenant Invoice
  const handleView = (id) => {
    const newPathname = pathNamePopped(pathname, 3);

    navigate(`${newPathname}/invoicing/Tenant/others/${id}`, {
      state: { pathname: pathname },
    });
  };

  /* Component function definition ends */

  /* Component useEffect starts */
  useEffect(() => {
    if (maintenance && p2URIC && p2URIC?.items && p2URIC?.items?.length > 0) {
      setAdditionalTask(() =>
        p2URIC?.items.filter(
          (item) =>
            item?.type &&
            item.type === "Additional" &&
            item.status !== "Approved"
        )
      );
    }
  }, [maintenance, p2URIC]);
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="time-line simple-log bg_grey">
      {/* Dot */}

      <div className="dot-wrapper">
        <i className="dot"></i>
        <div className="vl"></div>
      </div>

      <div className="detail-wrapper">
        {/* Title */}
        <h3 className="title font_m fw_6">{logTitle(item)}</h3>

        {/* Technician Name and Date */}
        <div className="black-container-sm fw_5 font_s bg_black flex-between">
          {/* Name */}
          <p className="left">
            <span className="font_grey">{role} </span>
            <span className="font_white"> {name}</span>
          </p>

          {/* Date and Time */}
          <p className="right font_grey">
            {moment(createdAt).format(DATE_TIME_FORMAT)}
          </p>
        </div>

        {action === "Waiting for tenant payment" && item?.tenant_invoice_id && (
          <div className="black-container-sm fw_5 font_s bg_black flex-between rescheduled_date_time my-2">
            {/* Name */}
            <p className="left">
              <span className="font_white">Tenant Invoice</span>
            </p>

            {/* Date and Time */}
            <p className="right font_grey">
              {/* //{item?.tenant_invoice_id} */}
              <IconButton onClick={() => handleView(item?.tenant_invoice_id)}>
                <img src={EyeFilledBlue} alt="view" className="viewImg" />
              </IconButton>
            </p>
          </div>
        )}

        {/* Log Footer */}
        <div className="log-footer">
          {/* Comments */}
          {comment && (
            <p className="comments font_s fw-5">
              <span className="font_grey fw_6">Comments: </span>
              <span className="fw_5 font_red"> {comment}</span>
            </p>
          )}

          {/* Action Buttons */}
          {takeAction && (
            <div className="button-wrapper">
              {/* <>
                {logActions(
                  logTitle,
                  canAssignTechnician(item, user),
                  mtRespondUIRCP1Init(item, user),
                  canFillURICP1(item, user),
                  mtRespondUIRCP1Finl(item, user),
                  canSkipFinalAsessment(item, user, finalUIRC?.status),
                  canStartP2(item, user),
                  pmCanApproveUIRCP1Init(item, user),
                  pmCanApproveUIRCP1Finl(item, user),
                  orderStatusGenerate,
                  fmCanChangeOrderStatus(item, user),
                  fmCanAssignPhase2MT(item, user),
                  pmCanApproveQuote(item, user),
                  fmCanEditQuote(item, user),
                  pmCanRespondKeyReject(item, user),
                  canFillURICP1_t1(item, user),
                  canFillURICFinl_t1(item, user),
                  ownerAddQuote(item, user),
                  ownerCompletesTask(item, user),
                  canFmRespond2PMrejectUIRC(item, user),
                  canAddQuote(item, user)
                )}
              </> */}

              {/* FM Assigning Technician */}
              {canAssignTechnician(item, user) && (
                <CustomButton
                  onClick={() => setShowAssignTechnician(true)}
                  variant="yellow"
                  size="m"
                >
                  Assign Technician
                </CustomButton>
              )}

              {/* Technician Approving UIRC Phase 1 Initial */}
              {mtRespondUIRCP1Init(item, user) && (
                <>
                  <CustomButton
                    onClick={() => {
                      setIsApproved(true);
                      setOpenNote(true);
                    }}
                    size="m"
                    variant="yellow"
                  >
                    {/* Approve */}
                    Accept
                  </CustomButton>
                  <CustomButton
                    onClick={() => {
                      setIsApproved(false);
                      setOpenNote(true);
                    }}
                    variant="red"
                    size="m"
                  >
                    Reject
                  </CustomButton>
                </>
              )}

              {/* Technician Approving UIRC Phase 1 Final */}
              {mtRespondUIRCP1Finl(item, user) && (
                <>
                  <CustomButton
                    onClick={() => {
                      setIsApproved(true);
                      setOpenNote(true);
                    }}
                    size="m"
                    variant="yellow"
                  >
                    {/* Approve */}
                    Accept
                  </CustomButton>
                  <CustomButton
                    onClick={() => {
                      setIsApproved(false);
                      setOpenNote(true);
                    }}
                    variant="red"
                    size="m"
                  >
                    Reject
                  </CustomButton>
                </>
              )}

              {/* Technician filling URIC Phase 1 Initial */}
              {canFillURICP1(item, user) && (
                <CustomButton
                  onClick={() => {
                    setShowURICP1Form(true);
                  }}
                  size="l"
                  variant="yellow"
                >
                  {/* Initial Unit Readiness Phase1 Form */}
                  {logType === "URIC Initial"
                    ? "Upload Unit Estimation Report"
                    : "Upload Unit Estimation Report"}
                  {/* Unit Readiness Final Assessment */}
                </CustomButton>
              )}

              {/* PM Approving URIC Phase 1 Initial */}
              {pmCanApproveUIRCP1Init(item, user) && (
                <>
                  <CustomButton
                    onClick={() => {
                      setIsApproved(true);
                      setOpenNote(true);
                    }}
                    size="m"
                    variant="yellow"
                  >
                    Approve
                  </CustomButton>
                  <CustomButton
                    onClick={() => {
                      setIsApproved(false);
                      setOpenNote(true);
                    }}
                    variant="red"
                    size="m"
                  >
                    Reject
                  </CustomButton>
                </>
              )}

              {/* FM responding to PM rejects UIRC asessment */}
              {canFmRespond2PMrejectUIRC(item, user) && (
                <>
                  <div className="radio-container fm-respond-pm-rejects">
                    <CustomRadioInput
                      isSelected={fmActionOption === 1}
                      onSelecting={() => setFmActionOption(1)}
                      label="Assign Technician"
                    />
                    <CustomRadioInput
                      isSelected={fmActionOption === 2}
                      onSelecting={() => setFmActionOption(2)}
                      label="Revise Esitmation"
                    />
                  </div>
                  <div className="radio-container fm-respond-pm-rejects">
                    <CustomRadioInput
                      isSelected={fmActionOption === 3}
                      onSelecting={() => setFmActionOption(3)}
                      label="Resubmit with comments"
                    />

                    <CustomButton
                      onClick={() => {
                        fmActionOption === 1 && setShowAssignTechnician(true);
                        fmActionOption === 3 && setFmResubmitComment(true);
                        fmActionOption === 2 && setShowUIRCRviewModal(true);
                        fmActionOption === 2 && setIsUIRCRviewModalOpened(true);
                      }}
                      variant="yellow"
                      size="m"
                    >
                      Submit
                    </CustomButton>
                  </div>

                  {/* <CustomButton
                    onClick={() => {
                      setShowAssignTechnician(true);
                    }}
                    variant="yellow"
                    size="m"
                  >
                    Assign Technician
                  </CustomButton>

                  <CustomButton
                    onClick={() => {
                      setShowUIRCRviewModal(true);

                      setIsUIRCRviewModalOpened(true);
                    }}
                    variant="yellow"
                    size="m"
                  >
                    Revise Esitmation
                  </CustomButton>

                  <CustomButton
                    onClick={() => {
                      setFmResubmitComment(true);
                    }}
                    variant="yellow"
                    size="m"
                  >
                    Resubmit with comments
                  </CustomButton> */}
                </>
              )}

              {/* PM Approving URIC Phase 1 Final */}
              {/* {pmCanApproveUIRCP1Finl(item, user) && (
                <>
                  <CustomButton
                    onClick={() => {
                      setIsApproved(true);
                      setOpenNote(true);
                    }}
                    size="m"
                    variant="yellow"
                  >
                    Approve
                  </CustomButton>
                  <CustomButton
                    onClick={() => {
                      setIsApproved(false);
                      setOpenNote(true);
                    }}
                    variant="red"
                    size="m"
                  >
                    Reject
                  </CustomButton>
                </>
              )} */}

              {/* FM skip final asessment report */}
              {canSkipFinalAsessment(item, user, finalUIRC?.status) && (
                <CustomButton
                  onClick={skipFinalAssessment}
                  variant="yellow"
                  size="m"
                >
                  Skip Final Assessment
                </CustomButton>
              )}

              {/* FM can add quote */}
              {canAddQuote(item, user) && (
                <>
                  <CustomButton
                    onClick={() => setAddQuotation(true)}
                    variant="yellow"
                    size="m"
                  >
                    Generate Quote
                  </CustomButton>
                </>
              )}

              {/* FM can start Phase 2 UIRC by assigning multiple MT to task or Generating Quotation */}
              {canStartP2(item, user) && (
                <>
                  <CustomButton
                    onClick={() => setShowAssignTechnician(true)}
                    size="m"
                    variant="yellow"
                  >
                    Assign Technician
                  </CustomButton>
                </>
              )}

              {/* FM can change Order Status */}
              {fmCanChangeOrderStatus(item, user) && (
                <CustomButton
                  onClick={fmChangeOrderStatus}
                  variant="yellow"
                  size="m"
                >
                  {orderStatusGenerate(currentStatus).button}
                </CustomButton>
              )}

              {/* FM can assign multiple technician to multiple UIRC inspections items */}
              {fmCanAssignPhase2MT(item, user) && (
                <CustomButton
                  onClick={() => setShowAssignTechnician(true)}
                  variant="yellow"
                  size="m"
                >
                  Assign Technician
                </CustomButton>
              )}

              {/*  FM can update quote */}
              {fmCanEditQuote(item, user) && (
                <CustomButton
                  onClick={() => setAddQuotation(true)}
                  variant="yellow"
                  size="m"
                >
                  Generate Quote
                </CustomButton>
              )}

              {/* PM can respond to key rejection */}
              {pmCanRespondKeyReject(item, user) && (
                <>
                  <CustomButton
                    onClick={() => {
                      setIsApproved(true);
                      setShowURICP1Form(true);
                    }}
                    size="m"
                    variant="yellow"
                  >
                    Approve
                  </CustomButton>
                  <CustomButton
                    onClick={() => {
                      setShowMaintenanceReviewRejectModal(true);
                    }}
                    variant="red"
                    size="m"
                  >
                    Reject
                  </CustomButton>
                </>
              )}

              {canFillURICP1_t1(item, user) && (
                <CustomButton
                  onClick={() => {
                    setShowURICP1Form(true);
                  }}
                  size="m"
                  variant="yellow"
                >
                  {/* Initial Unit Readiness Phase1 Form */}
                  Unit Readiness Initial Assessment
                </CustomButton>
              )}

              {isT1 && canFillURICFinl_t1(item, user) && (
                <CustomButton
                  onClick={() => {
                    setShowURICP1Form(true);
                  }}
                  size="m"
                  variant="yellow"
                >
                  {/* Initial Unit Readiness Phase1 Form */}
                  Unit Readiness Final Assessment
                </CustomButton>
              )}

              {ownerAddQuote(item, user) && (
                <CustomButton
                  onClick={() => setAddQuotation(true)}
                  variant="yellow"
                  size="m"
                >
                  Generate Quote
                </CustomButton>
              )}

              {ownerCompletesTask(item, user) && (
                <CustomButton
                  onClick={() => moveInMaintenanceDone()}
                  variant="yellow"
                  size="m"
                >
                  Complete Task
                </CustomButton>
              )}
            </div>
          )}
        </div>

        {/* Modals */}
        {/* Assign Technician Modal */}
        {takeAction && (
          <>
            <OnboardKeyRejectionModal
              title="Reject Maintenance Review"
              showModal={showMaintenanceReviewRejectModal}
              onReject={respondingKeyRejection}
              attachmentRequired={false}
              onClose={() => setShowMaintenanceReviewRejectModal(false)}
            />

            {/* UIRC Phase 1 Form Modal */}
            <UIRCP1
              maintenance_id={maintenance_id}
              showModal={showURICP1Form}
              maintenanceType={maintenanceType}
              initialURIC={initialURIC}
              finalUIRC={finalUIRC}
              onClose={() => setShowURICP1Form(false)}
              keyRejection={logType === "keys" && currentStatus === "Rejected"}
              applicationID={applicationID}
              data={logType === "URIC Initial" ? initialURIC : finalUIRC}
              maintenance={maintenance}
              isT1={isT1}
              currency={property?.currency}
            />

            <AssignTechnicianMoveInModal
              property={property}
              maintenance_id={maintenance_id}
              maintenanceType={maintenance.type}
              showModal={showAssignTechnician}
              onClose={() => setShowAssignTechnician(false)}
              initialURIC={initialURIC}
              finalURIC={finalUIRC}
              UIRCP2={p2URIC}
              type={
                logType === "quoatation" && item?.data?.is_part_exist === false
                  ? "URIC Phase 2"
                  : logType
              }
              additionalTask={additionalTask}
              takeAction={takeAction}
              taskHeading={p2URIC ? true : false}
            />

            {/* Adding Note Modal */}
            <NoteModal
              showModal={openNote}
              title="Note"
              btnText={isApproved ? "Approve" : "Reject"}
              onCancel={() => setOpenNote(false)}
              actionWithNote={respondAction}
            />

            {/* Spare Part Quotation Modal */}
            <SparePartQuoteModal
              showModal={addQuotation}
              onClose={() => setAddQuotation(false)}
              type="move_in"
              maintenance_id={maintenance_id}
              maintenanceType={maintenanceType}
              unitCard={unitCard}
              user={user}
              data={logType === "quoatation" ? item?.data : null}
              currentLog={item}
              // edit={quote?.status === "Rejected"}
              edit={currentStatus === "Rejected" && logType === "quoatation"}
              isT1={isT1}
              isT3={isT3}
            />

            <UIRCEstimationReviseModal
              showModal={showUIRCRviewModal}
              item={item}
              data={item?.data}
              setFile={setFile}
              setShowFile={setShowFile}
              logType={logType}
              maintenance={maintenance}
              maintenance_id={maintenance_id}
              setShowUIRCRviewModal={setShowUIRCRviewModal}
              currency={property?.currency}
              onClose={() => {
                setShowUIRCRviewModal(false);
                setIsUIRCRviewModalOpened(false);
              }}
            />

            <NoteModal
              showModal={fmResubmitComment}
              title="Resubmit with comment"
              btnText={"Submit"}
              onCancel={() => setFmResubmitComment(false)}
              actionWithNote={fmCanResubmitEstimation}
            />

            <FileViewerModal
              show={showFile}
              file={file?.url}
              type={file?.type}
              onClose={() => {
                setShowFile(false);
                setFile(null);
                isUIRCRviewModalOpened && setShowUIRCRviewModal(true);
              }}
            />
          </>
        )}
      </div>
    </div>
  );
};
/* Component ends */

export default SimpleLog;
