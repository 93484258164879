import GLOBAL from "../../../../../setup/constants/global";

export const buttonList = [
  { key: GLOBAL.INVOICING.TENANTS, label: "Tenants" },
  { key: GLOBAL.INVOICING.PROPERTIES_INVOICES, label: "Properties Invoices" },
  { key: GLOBAL.INVOICING.PARTIES_INVOICES, label: "Parties Invoices" },
  { key: GLOBAL.INVOICING.ADVERTISMENT, label: "Advertisment" },
];

export const generateRequestPayload = (
  currentTab,
  currentSubTab,
  currentPage,
  propertyId,
  search = ""
) => {
  let payload;

  if (currentTab === GLOBAL.INVOICING.TENANTS) {
    if (currentSubTab === "facility") {
      payload = {
        property_id: propertyId,
        type: "shared facility",
        page: currentPage,
      };
    } else if (currentSubTab === "utility") {
      payload = {
        property_id: propertyId,
        type: "utility",
        page: currentPage,
      };
    } else if (currentSubTab === "deposits") {
      payload = {
        property_id: propertyId,
        type: "deposit",
        page: currentPage,
      };
    } else if (currentSubTab === "others") {
      payload = {
        property_id: propertyId,
        type: "other",
        page: currentPage,
      };
    } else if (currentSubTab === "commission") {
      payload = {
        property_id: propertyId,
        type: "commission",
        page: currentPage,
      };
    } else if (currentSubTab === "rent") {
      payload = {
        property_id: propertyId,
        type: "rent",
        page: currentPage,
      };
    } else {
      payload = {
        property_id: propertyId,
        type: "application-fee",
        page: currentPage,
      };
    }
  } else if (currentTab === GLOBAL.INVOICING.PROPERTIES_INVOICES) {
    if (currentSubTab === "others") {
      payload = {
        property_id: propertyId,
        type: "Other",
        page: currentPage,
      };
    } else {
      payload = {
        property_id: propertyId,
        type: "Utility",
        page: currentPage,
      };
    }
  } else if (currentTab === GLOBAL.INVOICING.PARTIES_INVOICES) {
    if (currentSubTab === "contracts") {
      payload = {
        property_id: propertyId,
        type: "Service contract",
        page: currentPage,
      };
    } else {
      payload = {
        property_id: propertyId,
        type: "Additional work",
        page: currentPage,
      };
    }
  }

  if (search !== "") {
    payload["search"] = search;
  }
};

export const toShowTheAddInvoiceOption = (
  tab,
  subTab,
  mgmt_model,
  tpm,
  owner
) => {
  if (mgmt_model === 0) {
    return true;
  }

  if (mgmt_model === 1) {
    if (tab === "Tenants" && subTab === "Others") {
      return false;
    } else if (
      tab === "Property Invoice" &&
      (subTab === "utilities" || subTab === "others") &&
      !tpm &&
      !owner
    ) {
      return true;
    } else if (tab === "Parties Invoice" && tpm) {
      return true;
    }
  }
};
