/**
 *
 */

import React, { useState, useEffect } from "react";
import InternalMaintenanceRequestsList from "../../components/module/InternalMaintenance/InternalMaintenanceRequestsList/InternalMaintenanceRequestsList";

/* Import configuration starts */
import GLOBAL from "../../setup/constants/global";
import { useDispatch } from "react-redux";
/* Import configuration ends */

/* Import redux slices component starts */
import {
  showSuccessAlert,
  showErrorAlert,
} from "../../setup/store/slices/globalAlertSlice";
import {
  clearInternalMaintenance,
  fetchAllClosedInternalMaintenance,
  fetchAllCompletedInternalMaintenance,
  fetchAllNewInternalMaintenance,
  fetchAllOngoingInternalMaintenance,
  fetchAllRejectedInternalMaintenance,
  fetchAllSparePartsInternalMaintenance,
  getInternalMaintenanceList,
  getInternalMaintenanceSpareList,
} from "../../setup/store/slices/internalMaintenanceSlice";
import { setLoading } from "../../setup/store/slices/unpersistedSlice";
import { fetchPropertyDetailByID } from "../../setup/store/slices/propertySlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */

/* Import local pages and component ends */

/* Component starts */
const InternalMaintenancePage = (props) => {
  /* Props destructuring starts */
  const {} = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const dispatch = useDispatch();
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  const handleFetchPropertyData = async (property_id) => {
    // Creating or collecting payload data to be sent
    const payload = { property_id };
    console.log("Payload", payload);

    // Backend response. Try, Catch
    try {
      await dispatch(setLoading(true));
      const result = await dispatch(fetchPropertyDetailByID(payload));
      await dispatch(setLoading(false));
      console.log("Response", result);

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          dispatch(
            showErrorAlert({
              title: "Error!",
              description:
                typeof result?.payload === "string" ? result?.payload : "",
            })
          );
          break;

        case GLOBAL.FULFILLED:
          // dispatch(
          //   showSuccessAlert({
          //     title: "Success!",
          //     description:
          //       typeof result?.payload?.message === "string"
          //         ? result?.payload?.message
          //         : "",
          //   })
          // );
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };

  const handleFetchData = async (property_id, tab, page, search = "") => {
    // Creating or collecting payload data to be sent
    const payload = { property_id, page, limit: 10 };

    if (search !== "") {
      payload["search"] = search;
    }

    console.log("Payload", payload);

    // Backend response. Try, Catch
    try {
      let result = {};

      switch (tab) {
        case "new":
          result = await dispatch(fetchAllNewInternalMaintenance(payload));
          break;

        case "ongoing":
          result = await dispatch(fetchAllOngoingInternalMaintenance(payload));
          break;

        case "spareParts":
          result = await dispatch(
            fetchAllSparePartsInternalMaintenance(payload)
          );
          break;

        case "completed":
          result = await dispatch(
            fetchAllCompletedInternalMaintenance(payload)
          );
          break;

        case "rejected":
          result = await dispatch(fetchAllRejectedInternalMaintenance(payload));
          break;

        case "closed":
          result = await dispatch(fetchAllClosedInternalMaintenance(payload));
          break;

        default:
          break;
      }

      console.log("Response", result);

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          dispatch(
            showErrorAlert({
              title: "Error!",
              description:
                typeof result?.payload === "string" ? result?.payload : "",
            })
          );
          break;

        case GLOBAL.FULFILLED:
          // dispatch(
          //   showSuccessAlert({
          //     title: "Success!",
          //     description:
          //       typeof result?.payload?.message === "string"
          //         ? result?.payload?.message
          //         : "",
          //   })
          // );
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div>
      <InternalMaintenanceRequestsList
        handleFetchPropertyData={handleFetchPropertyData}
        handleFetchData={handleFetchData}
      />
    </div>
  );
};
/* Component ends */

export default InternalMaintenancePage;
