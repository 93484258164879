/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import { ACTION_REQ_SECTION } from "../helper";
import { useNavigate } from "react-router-dom";
/* Import configuration ends */

/* Import redux slices component starts */
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
import {
  ExcalmatoryYellow,
  WarningRoundedOutlineYellow,
} from "../../../../../setup/constants/images";
/* Import image and SVG ends */

/* Import local pages and component starts */
import CustomButton from "../../../../../components/ui/button/CustomButton/CustomButton";
import FileGroupModal from "../../../../ui/modal/FileGroupModal/FileGroupModal";
import FileViewerModal from "../../../../ui/modal/FileViewerModal/FileViewerModal";
/* Import local pages and component ends */

/* Component starts */
const MoveInPendingKey = (props) => {
  /* Props destructuring starts */
  const { unit } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [showFileGroup, setShowFileGroup] = useState(false);
  const [showFile, setShowFile] = useState(false);
  const [file, setFile] = useState({});
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const navigate = useNavigate();
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  const handleSelectFile = (file) => {
    setFile(file);
    setShowFileGroup(false);
    setShowFile(true);
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div>
      <div className="status-icon-wrapper text-center">
        <img src={ExcalmatoryYellow} alt="Warning" />
      </div>

      <h3 className="text-center fw_6 font_xxl font_blue mt-4">
        Action Required
      </h3>
      <p className="font_l fw_5 text-center mt-4">Keys are ready to collect</p>

      {/* <p className="font_l fw_5 text-center mt-4 font_yellow">
        Pending Key Collection
      </p> */}

      <div className={`button-wrapper`}>
        <CustomButton
          size="l"
          className="mt-5"
          onClick={() =>
            navigate(
              `/tenant/unit/${unit?._id}/application/${unit.application_id}`,
              {
                state: { unit: unit, step: "keys" },
              }
            )
          }
        >
          Take Action
        </CustomButton>

        {/* {unit?.key_docs?.length > 0 && (
          <CustomButton
            size="l"
            className="mt-5"
            onClick={() => setShowFileGroup(true)}
          >
            View Files
          </CustomButton>
        )} */}
      </div>

      {/* <FileGroupModal
        showModal={showFileGroup}
        title="Key Rejection"
        files={unit?.key_docs}
        onSelectFile={handleSelectFile}
        onClose={() => setShowFileGroup(false)}
      />
      <FileViewerModal
        show={showFile}
        file={file?.url}
        type={file?.type}
        onClose={() => {
          setShowFile(false);
          setShowFileGroup(true);
        }}
      /> */}
    </div>
  );
};
/* Component ends */

export default MoveInPendingKey;
