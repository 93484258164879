/**
 *
 */

import React, { useState } from "react";

/* Import image and SVG starts */
import { SearchOutlineWhite } from "../../../../setup/constants/images";
/* Import image and SVG ends */

import "./style.scss";

/* Component starts */
const Searchbar = (props) => {
  /* Props destructuring starts */
  const {
    onInputChange = null,
    onInputSubmit,
    query = null,
    setQuery,
    placeholder = "",
    className = null,
  } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [value, setValue] = useState("");
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  const handleChange = (e) => {
    setValue(e.target.value);
    onInputChange && onInputChange(e.target.value);
  };
  const handleSubmit = (e) => {
    e.preventDefault();

    onInputSubmit(value);
  };
  /* Component function definition ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="searchbar-component-wrapper">
      <form
        className={
          className === null ? "w_100 search-form" : `w_100 ${`dash-search`}`
        }
        onSubmit={handleSubmit}
      >
        {query === null && (
          <input
            className="d_block w_100 bg_lightgrey font_white font_xs"
            type="text"
            value={value}
            onChange={handleChange}
            // placeholder="Specify your search"
            placeholder={
              placeholder === "" ? "Specify your search" : placeholder
            }
          />
        )}
        {query !== null && (
          <input
            className="d_block w_100 bg_lightgrey font_white font_xs"
            type="text"
            value={query}
            onChange={(e) => setQuery(e.target.value)}
            placeholder={
              placeholder === "" ? "Specify your search" : placeholder
            }
          />
        )}
      </form>

      <img src={SearchOutlineWhite} alt="Search Icon" className="icon" />
    </div>
  );
};
/* Component ends */

export default Searchbar;
