/**
 *
 */

import React, { useState } from "react";

/* Import configuration starts */
import { useDispatch } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import GLOBAL from "../../../setup/constants/global";
/* Import configuration ends */

/* Import redux slices component starts */
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import MUI component starts */
/* Import MUI component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import PropertySlider from "../../../components/module/Property/PropertySlider/PropertySlider";
/* Import local pages and component ends */

/* Component starts */
const Properties = (props) => {
  /* Props destructuring starts */
  const { propertyList } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isClient = localStorage.getItem("user_role") === "Client";
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const onPropertyItemClicked = async (property) => {
    if (property.wizard !== GLOBAL.PROP_STEP.DONE) {
      navigate(`/independent/properties/add/resume/${property._id}`);
    } else {
      navigate(`/independent/properties/${property._id}`);
    }
  };
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="properties">
      <div className="heading">
        <h2 className="font_xl fw_6">Properties ({propertyList?.length})</h2>
        {isClient && (
          <Link
            to="/properties/add"
            className="font_m fw_6 d_block"
          >
            + Add property
          </Link>
        )}
      </div>

      {propertyList?.length > 0 ? (
        <PropertySlider
          heading=""
          onClickProperty={onPropertyItemClicked}
          propertyList={propertyList}
        />
      ) : (
        <div className="no-comm-prop-added">
          <h3 className="text-center fw_6 font_m font_yellow">
            No Property Added!
          </h3>
        </div>
      )}
    </div>
  );
};
/* Component ends */

export default Properties;
