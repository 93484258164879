/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
/* Import configuration ends */

/* Import redux slices component starts */
/* Import redux slices component ends */

/* Import react bootstrap component starts */
import { Table } from "react-bootstrap";
/* Import react bootstrap component ends */

/* Import image and SVG starts */
import {
  EditGreen,
  EyeFilledBlue,
} from "../../../../../../../setup/constants/images";
/* Import image and SVG ends */

/* Import local pages and component starts */
import IconButton from "../../../../../../ui/button/IconButton/IconButton";
import moment from "moment";
import Pagination from "../../../../../../ui/other/Pagination/Pagination";
import PaymentModal from "../../../PaymentModal/PaymentModal";
import { useNavigate } from "react-router-dom";
/* Import local pages and component ends */

/* Component starts */
const ActiveInvoicing = (props) => {
  /* Props destructuring starts */
  const {
    advertisementInvoice,
    property,
    currentPage,
    setCurrentPage,
    totalCount,
  } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [showModal, setShowModal] = useState(false);
  // const [showFileViewer, setShowFileViewer] = useState(false);
  // const [file, setFile] = useState({});
  const [invoiceData, setInvoiceData] = useState();
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const navigate = useNavigate();
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const USER_DATA = localStorage.getItem("user_data")
    ? JSON.parse(localStorage.getItem("user_data"))
    : null;
  const DATE_FORMAT = USER_DATA ? USER_DATA?.date_format : "";
  const isAccountant = localStorage.getItem("user_role") === "Accountant";
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  const onClose = () => {
    setShowModal(!showModal);
  };

  const onSuccess = () => {
    setShowModal(!showModal);
    navigate(-1);
  };

  const handleReportView = (item) => {
    navigate(`${item?._id}`, {
      state: item,
    });
  };

  const handlePaymentModal = (item) => {
    setInvoiceData(item);
    setShowModal(!showModal);
  };

  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <>
      <div className="custom-table-wrapper advertisment-invoice-table">
        {advertisementInvoice?.length > 0 && (
          <Table className="custom-table font_s" borderless responsive>
            {/* Table Heading */}
            <thead>
              <tr>
                <th>Invoice</th>
                {/* <th>Community</th>
              <th>Property</th> */}
                <th>Ad No.</th>
                <th>Vendor</th>
                <th>Vendor Email</th>
                <th>Ad Start Date</th>
                <th>Ad End Date</th>
                <th>Total</th>
                <th>Due Date</th>
                <th>Method</th>
                <th>Status</th>
                <th className="text-center">Action</th>
              </tr>
            </thead>
            {/* Table Body */}
            <tbody>
              {advertisementInvoice?.map((item) => (
                <tr key={item?._id}>
                  {/* ID */}
                  <td>#{item?.invoice_number}</td>

                  {/* Community */}
                  {/* <td>
                  {item?.community_name !== undefined
                    ? item?.community_name
                    : "-"}
                </td> */}

                  {/* Property */}
                  {/* <td>
                  {item?.property_name !== undefined
                    ? item?.property_name
                    : "-"}
                </td> */}

                  <td>{item?.advt?.advertisement_number}</td>

                  {/* Vendor */}
                  <td>{item?.vendor_name}</td>

                  {/* Vendor Email */}
                  <td>{item?.vendor_email}</td>

                  {/*  Start Date */}
                  <td>{moment(item?.advt?.start_date).format(DATE_FORMAT)}</td>

                  {/*  End Date */}
                  <td>{moment(item?.advt?.end_date).format(DATE_FORMAT)}</td>

                  <td>{`${item?.total_amount?.toLocaleString("en-US")}${" "}${
                    property?.currency
                  }`}</td>

                  {/*  Date */}
                  <td>{moment(item?.due_date).format(DATE_FORMAT)}</td>

                  {/* Method */}
                  <td>
                    {item?.method !== undefined
                      ? item?.method?.charAt(0)?.toUpperCase() +
                        item?.method?.slice(1)
                      : "-"}
                  </td>

                  {/* Status */}
                  <td
                    className={`${
                      item?.status === "paid"
                        ? "font_green"
                        : item?.status === "unpaid"
                        ? "font_yellow"
                        : "font_red"
                    }`}
                  >
                    {item?.status?.charAt(0).toUpperCase() +
                      item?.status?.slice(1)}
                  </td>

                  {/* Actions */}
                  <td className="text-center txt-nowrap">
                    <IconButton
                      onClick={() => handleReportView(item)}
                      className="action-button"
                    >
                      <img
                        src={EyeFilledBlue}
                        className="h_100"
                        alt="View Details"
                      />
                    </IconButton>

                    {/* {isAccountant &&
                      property?.mgmt_model === 2 &&
                      (item?.status === "unpaid" ||
                        item?.status === "partial paid") && (
                        <IconButton
                          onClick={() => handlePaymentModal(item)}
                          className="action-button "
                        >
                          <img
                            // src={EditWhite}
                            src={EditGreen}
                            className="h_100 mx-2"
                            alt="View Details"
                          />
                        </IconButton>
                      )} */}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}

        {advertisementInvoice?.length === 0 && (
          <div className=" text-center">
            <h3 className=" font_m font_yellow fw_6">No Data</h3>
          </div>
        )}

        <PaymentModal
          showModal={showModal}
          onClose={onClose}
          onSuccess={onSuccess}
          invoiceData={invoiceData}
          property_id={property?._id}
          currency={property?.currency}
        />
      </div>

      {/* PAGINATION */}
      {advertisementInvoice?.length > 0 && (
        <Pagination
          itemsPerPage="10"
          totalItems={totalCount}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          // type="invoice"
          currency={property?.currency}
          // paid={invoiceAggregation?.paid_Other_amount}
          // unPaid={invoiceAggregation?.unpaid_Other_amount}
          // overdue={invoiceAggregation?.overDue_Other_amount}
          // totalAmount={invoiceAggregation?.total_amount}
          // voidAmount={invoiceAggregation?.void_Other_amount}
        />
      )}
    </>
  );
};
/* Component ends */

export default ActiveInvoicing;
